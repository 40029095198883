import React, { useState } from 'react';
import { withGoogleMap, GoogleMap, DirectionsRenderer } from 'react-google-maps';
import get from 'lodash/get';
import styles from './SearchInput.module.scss';

interface MapComponentProps {
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: {
    latitude: number;
    longitude: number;
  };
  origin?: { lat: number; lng: number };
  destination?: { lat: number; lng: number };
  setDistance: (val: number) => void;
}

const Map = withGoogleMap((props: any) => {
  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={10}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      onBoundsChanged={props.onBoundsChanged}
    />
  );
});

const DirectionMap = withGoogleMap((props: any) => {
  /*eslint-disable  no-undef*/
  const directionsService = new google.maps.DirectionsService();
  const [directions, setDirections] = useState<any>();

  directionsService.route(
    {
      origin: props.origin,
      destination: props.destination,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
      }
    }
  );

  if (directions) {
    const distance = get(directions, 'routes[0].legs[0].distance.value');
    props.setDistance(distance);
  }

  return directions ? (
    <DirectionsRenderer directions={directions}>
      <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={10}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        onBoundsChanged={props.onBoundsChanged}
      />
    </DirectionsRenderer>
  ) : null;
});

const MapComponent = ({
  coords,
  origin,
  destination,
  setDistance,
  isGeolocationAvailable,
  isGeolocationEnabled,
}: MapComponentProps) => {
  if (!isGeolocationEnabled) {
    console.warn('isGeolocationEnabled');
  }

  return !isGeolocationAvailable ? (
    <h3>Your browser does not support Geolocation</h3>
  ) : coords ? (
    <div>
      {origin && destination ? (
        <DirectionMap
          setDistance={setDistance}
          origin={origin}
          destination={destination}
          lng={coords.longitude}
          lat={coords.latitude}
          containerElement={<div className={styles.cMap} />}
          mapElement={<div className={styles.map} />}
        />
      ) : (
        <Map
          lng={coords.longitude}
          lat={coords.latitude}
          containerElement={<div className={styles.cMap} />}
          mapElement={<div className={styles.map} />}
        />
      )}
    </div>
  ) : (
    <h3>Getting the location data </h3>
  );
};

export default React.memo(MapComponent);

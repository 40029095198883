import React from 'react';
import classNames from 'classnames';
import { fadeInDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import error from 'assets/i-error.svg';
import warning from 'assets/i-warning.svg';
import success from 'assets/i-success.svg';
import styles from './Warning.module.scss';

interface Props {
  title: string;
  type?: 'warning' | 'error' | 'success';
  text?: string;
}

const getImage = (type: 'warning' | 'error' | 'success') => {
  switch (type) {
    case 'error':
      return error;
    case 'warning':
      return warning;
    case 'success':
      return success;
  }
};

const animation = {
  fadeInRight: {
    animation: 'x 1.5s',
    animationName: Radium.keyframes(fadeInDown, 'fadeInDown'),
  },
};

const Warning = ({ title, type = 'error', text = 'Something went wrong' }: Props) => {
  return (
    <StyleRoot>
      <div
        className={classNames(styles.cContent, {
          [styles.warning]: type === 'warning',
          [styles.success]: type === 'success',
        })}
        style={animation.fadeInRight}
      >
        <div>
          <img src={getImage(type)} alt="" />
        </div>
        <div className={styles.cDescription}>
          <h4>{title}</h4>
          <div className={styles.description}>
            {text}
            {!!text && ','} please{' '}
            <span
              className={styles.updateBtn}
              onClick={() => {
                window.location.reload();
              }}
            >
              update the page.
            </span>
          </div>
        </div>
      </div>
    </StyleRoot>
  );
};
export default Warning;

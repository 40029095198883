import React, { useEffect, useRef } from 'react';
import { Button, Collapse, Loader, SubscribeSection, TopBanner } from 'components';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import { RouteComponentProps } from 'react-router';
import MainWidget from '../Estimator/components/MainWidget';
import { geolocated } from 'react-geolocated';
import styles from './EstimatorCharging.module.scss';

export interface Props extends RouteComponentProps {
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: {
    latitude: number;
    longitude: number;
  };
}

const EstimatorCharging = observer((props: Props) => {
  const { vehicleStore, cmsStore } = useStores();
  const plannerRef = useRef(null);

  useEffect(() => {
    cmsStore.getPage('estimator-charging');
  }, [vehicleStore, cmsStore]);

  const {
    pageContent: { fields },
  } = cmsStore;

  if (cmsStore.loading) return <Loader />;

  return (
    <div className={styles.cPage}>
      {fields?.topBanner && <TopBanner topBanner={fields?.topBanner} />}

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/*--------- Charging your car -----------*/}
          <div className={classNames(styles.cChargingYourCar, styles.desktopContentWidth, styles.cHorOffsetsMobile)}>
            <h3>{fields?.chargingYourCar?.title}</h3>
            <p>{fields?.chargingYourCar?.description}</p>
            <Button
              captionFont={'NHG'}
              buttonColor={'black'}
              size={'medium'}
              arrow={'right'}
              onClick={() => {
                if (plannerRef !== null && plannerRef.current !== null) {
                  //@ts-ignore
                  plannerRef.current.scrollIntoView();
                }
              }}
            >
              Route Charge Planner
            </Button>
          </div>
        </div>

        {/*--------- Types of Chargers -----------*/}
        <div className={styles.bgDark}>
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cTypesChargers)}>
            <h3>{fields?.typesOfChargers?.title}</h3>
            <p className={styles.subTitle}>{fields?.typesOfChargers?.description}</p>
            <div className={styles.cChargers}>
              {fields?.typesOfChargers?.chargers?.map((item: any, index: number) => {
                return (
                  <div className={styles.charger} key={index}>
                    <h4>{item?.title}</h4>
                    <h5>{item?.subTitle}</h5>
                    <img src={item?.illustration} alt="" />
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: item?.description }} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/*--------- Home on the Range -----------*/}
        <div className={styles.homeRange} style={{ backgroundImage: `url(${fields?.homeOnTheRange?.illustration})` }}>
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cInner)}>
            <span dangerouslySetInnerHTML={{ __html: fields?.homeOnTheRange?.title }} />
          </div>
        </div>

        {/* ------ Widget -------*/}
        <div ref={plannerRef} className={classNames(styles.desktopWidth, styles.cWidget)}>
          <div className={styles.cHorOffsetsMobile}>
            <h4>{fields?.chargingPlanner?.subTitle}</h4>
            <h3>{fields?.chargingPlanner?.title}</h3>
            <p>{fields?.chargingPlanner?.description}</p>
          </div>

          <MainWidget
            isGeolocationAvailable={props.isGeolocationAvailable}
            isGeolocationEnabled={props.isGeolocationEnabled}
            coords={props.coords}
          />
        </div>

        {/* ------ About -------*/}
        <div className={styles.bgDark}>
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cAbout)}>
            <div className={styles.leftCol}>
              <h3>{fields?.about?.title}</h3>
              <p>{fields?.about?.description}</p>
              <Button
                captionFont={'NHG'}
                buttonColor={'black'}
                size={'medium'}
                arrow={'right'}
                onClick={() => {
                  props.history.push('/ev-101#map');
                }}
              >
                Find charging stations near you
              </Button>
            </div>

            <div className={styles.rightCol}>
              {fields?.about?.rightColumn?.map((item: any, index: number) => {
                return (
                  <div className={styles.colItem} key={index}>
                    <img src={item.icon} alt="" />
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/* ------ FAQ -------*/}
          <div className={classNames(styles.cFAQ, styles.desktopContentWidth)}>
            <div className={styles.titlesSection}>
              <h3>{fields?.frequentlyAskedQuestions?.title}</h3>
              <p>{fields?.frequentlyAskedQuestions?.description}</p>
            </div>

            <div className={styles.cFaqOffsets}>
              {fields?.frequentlyAskedQuestions?.accordionItem?.map(
                (item: { question: string; answer: string }, index: number) => {
                  return (
                    <Collapse
                      title={item.question}
                      wrapperStyle={styles.wrapper}
                      titleStyle={styles.faqTitle}
                      key={index}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </Collapse>
                  );
                }
              )}
            </div>
          </div>
          <SubscribeSection />
        </div>

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(EstimatorCharging);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useStores } from 'hooks/use-stores';
import { Field, Formik } from 'formik';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import ReactTooltip from 'react-tooltip';

import { getError } from 'appConstants/validations';
import { schemaVerification } from './validationSchema';
import { Button, Input } from 'components';
import { smsDelay } from 'appConstants';
import { setPhoneFormat } from '../../helpers/formatters/phone';

import styles from './Auth.module.scss';

interface Props {
  type: string;
  goBack: () => void;
  onSuccess?: () => void;
  phone: string;
}

const Verification = ({ type, goBack, phone, onSuccess }: Props) => {
  const { authStore } = useStores();
  const history = useHistory();
  const [delay, setDelay] = useState(smsDelay);

  useEffect(() => {
    const interval = setInterval(() => {
      setDelay(delay => {
        if (delay > 0) {
          return delay - 1;
        } else {
          return delay;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const renderBottomDescription = () => {
    return (
      <div className={styles.bottomDescription}>
        Having problems? You can{' '}
        {type !== 'signUp' && (
          <>
            <Link
              className={styles.underline}
              onClick={e => {
                e.preventDefault();
                goBack();
              }}
              to={'/'}
            >
              go back
            </Link>{' '}
            to correct your number or{' '}
          </>
        )}
        <>
          {delay === 0 ? (
            <Link
              className={styles.underline}
              onClick={e => {
                e.preventDefault();
                setDelay(smsDelay);
                authStore.sendPhone({
                  phone,
                });
              }}
              to={'/'}
            >
              resend the code
            </Link>
          ) : (
            <>
              <span data-tip="You can resend the code once per minute" className={styles.blockedLink}>
                resend the code
              </span>
              <ReactTooltip />
            </>
          )}
        </>
      </div>
    );
  };

  return (
    <Formik
      initialValues={{
        smsCode: '',
      }}
      validationSchema={schemaVerification}
      onSubmit={async (values, { setErrors }) => {
        const otpWithoutSpaces = values.smsCode.replace(/\s/g, '');

        authStore.sendOtpCode({
          otp: otpWithoutSpaces,
          phone,
          onSuccess: () => {
            ReactGA.event({
              category: 'User',
              action: 'Logged in',
              label: phone || '',
            });
            if (onSuccess) {
              onSuccess();
            } else {
              history.push('/');
            }
          },
          setErrors: setErrors,
        });
      }}
    >
      {formik => {
        return (
          <form className={styles.cContent} onSubmit={formik.handleSubmit}>
            <div className={classNames([styles.cTop, styles.textCenter])}>
              <p className={styles.topDescription}>
                We’ve sent a six-digit code to <span>{setPhoneFormat(phone)}</span>. <br /> Please enter it below
              </p>
              <Field name="smsCode">
                {({ field }: { field: object }) => (
                  <InputMask mask="9  9  9  9  9  9" value={formik.values.smsCode} {...field}>
                    <Input
                      //@ts-ignore
                      autoComplete="one-time-code"
                      autoFocus
                      id="smsCode"
                      errorText={getError('smsCode', formik.errors, formik.touched)}
                      containerStyle={styles.cMask}
                      name="smsCode"
                      value={formik.values.smsCode}
                      onChange={formik.handleChange}
                    />
                  </InputMask>
                )}
              </Field>
            </div>

            <div className={styles.cBottom}>
              {renderBottomDescription()}

              <div className={styles.cBottomContent}>
                <Button
                  type="submit"
                  size="big"
                  buttonColor="blue"
                  disabled={!!formik.errors.smsCode || !formik.dirty}
                  fullWidth
                >
                  {type === 'signIn' ? 'Sign in' : 'Create account'}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
export default Verification;

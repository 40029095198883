import { BuildCategory } from '../types/Vehicle';

export const AESMapUrl = 'https://goo.gl/maps/gg4ADLCvJaxzo6Sq5';
export const appStoreLink = 'https://apps.apple.com/us/app/motor-drive/id1501427414';
export const playMarketLink = 'https://play.google.com/store/apps/details?id=com.driveclutch.motordrive';
export const applyFinancing = 'https://www.700dealer.com/QuickQualify/6e81da310e304f4aa32491e9aa8f2599-20201125';

export const appStoreHeader = 'https://apps.apple.com/app/apple-store/id1501427414?pt=120603018&ct=aes-in_header&mt=8';
export const playMarketHeader =
  'https://play.google.com/store/apps/details?id=com.driveclutch.motordrive&referrer=utm_source%3Daes-in%26utm_medium%3Dweb%26utm_campaign%3Dheader';

export const appStoreFooter = 'https://apps.apple.com/app/apple-store/id1501427414?pt=120603018&ct=aes-in_footer&mt=8';
export const playMarketFooter =
  'https://play.google.com/store/apps/details?id=com.driveclutch.motordrive&referrer=utm_source%3Daes-in%26utm_medium%3Dweb%26utm_campaign%3Dfooter';

export const appStoreSubscription =
  'https://apps.apple.com/app/apple-store/id1501427414?pt=120603018&ct=aes-in_cta&mt=8';
export const playMarketSubscription =
  'https://play.google.com/store/apps/details?id=com.driveclutch.motordrive&referrer=utm_source%3Daes-in%26utm_medium%3Dweb%26utm_campaign%3Dcta';

export const smsDelay = 60; // (sec)

export const holdingFee = '0';

export const buildCategories: BuildCategory[] = [
  { id: 0, title: 'Exterior' },
  { id: 1, title: 'Interior' },
];

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const subscriptionOrderStatusBar = ['Bundle options', 'Finalize Order', 'Next steps', 'Apply for Financing'];
export const subscriptionOrderStatusBarCash = [
  'Bundle options',
  'Finalize Order',
  'Next steps',
  'Apply for Financing (N/A)',
];
export const standAloneChargerStatusBar = ['Bundle options', 'Finalize Order', 'Schedule installation'];

export const annualMileage = [
  { label: '7,500 mi', value: '7500' },
  { label: '12,500 mi', value: '12500' },
  { label: '17,500 mi', value: '17500' },
  { label: '22,500 mi', value: '22500' },
  { label: '27,500 mi', value: '27500' },
  { label: '32,500 mi', value: '32500' },
  { label: '37,500 mi', value: '37500' },
];

export const learningPreferences = [
  { id: 'phone_call', value: 'phone_call', label: 'Chat with someone online' },
  { id: 'chat_online', value: 'chat_online', label: 'Talk to an expert on the phone' },
  { id: 'read_materials', value: 'read_materials', label: 'Read educational materials' },
  { id: 'tying_for_hour', value: 'tying_for_hour', label: 'Trying one for hour' },
  { id: 'tying_for_week', value: 'tying_for_week', label: 'Trying one for week' },
];

export const preferences = ['cost', 'range', 'performance', 'environment_impact', 'space'];

export const preferencesMatch = [
  { id: 'cost', name: 'Cost' },
  { id: 'range', name: 'Range' },
  { id: 'performance', name: 'Performance' },
  { id: 'environment_impact', name: 'Impact on environment' },
  { id: 'space', name: 'Space' },
  { id: 'make', name: 'My current car’s make' },
];

export const preferencesMatchResults = [
  { id: 'cost', name: 'Affordable base price' },
  { id: 'range', name: 'Good range per charge' },
  { id: 'performance', name: 'A sporty, responsive performance' },
  { id: 'space', name: 'A spacious interior' },
  { id: 'make', name: 'Similar to current model' },
];

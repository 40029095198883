import { action, observable } from 'mobx';
import get from 'lodash/get';

import { getDistenceInMiles } from '../helpers/formatters/vehicle';
import UserApi from '../services/user/userApi';
import { AdditionalCost, ChargerStation } from '../types/User';

export interface quizStep {
  id: number;
  type: string;
  question: string;
  description?: string;
  options?: { id: number; text: string }[];
}

export interface MapCoords {
  origin: {
    lat: number;
    lng: number;
  };
  destination: {
    lat: number;
    lng: number;
  };
}

export class AdvisorStore {
  userApi = new UserApi();

  quizDB: quizStep[] = [
    {
      id: 1,
      question: 'What’s most important to you about owning a car?',
      description:
        'If you combine your car with charging on a renewable rate, you can have <strong>100% carbon free transportation</strong>',
      type: 'multi',
      options: [
        { id: 1, text: 'Fuel economy' },
        { id: 2, text: 'New and exciting technology' },
        { id: 3, text: 'Environmental impact' },
        { id: 4, text: 'Performance and handling' },
        { id: 5, text: 'Price' },
        { id: 6, text: 'Safety and reliability' },
      ],
    },
    {
      id: 3,
      type: 'multi',
      question: 'What is most appealing to you about owning an electric vehicle?',
      description:
        'If you combine your vehicle with charging on a renewable rate, you can have 100% carbon free transportation',
      options: [
        { id: 5, text: 'Fuel savings' },
        { id: 6, text: 'New and exciting technology' },
        { id: 7, text: 'Environmental impact' },
        { id: 8, text: 'Tax credits' },
      ],
    },
    {
      id: 4,
      type: 'slider',
      question: 'How many miles do you drive on a typical weekday?',
      description:
        'With the most common electric cars, you would only have to charge your vehicle for 30 minutes a day to meet your driving needs',
    },
    {
      id: 5,
      type: 'single',
      question: 'Where do you park at home?',
      description:
        'Consider charging near your place of work: with 4 hours of weekly charging, you can fuel all of your commuting needs',
      options: [
        { id: 9, text: 'Personal garage' },
        { id: 10, text: 'Building garage' },
        { id: 11, text: 'Driveway' },
        { id: 12, text: 'On the street' },
      ],
    },
  ];

  @observable currentStepData: quizStep = {} as quizStep;
  @observable progress: number = 0;
  @observable finalStep: number = this.quizDB.length;
  @observable loading: boolean = false;
  @observable fetchError: string | null = null;
  @observable additionalCost: AdditionalCost | null = null;
  @observable chargingStations: ChargerStation[] | null[] = [];

  @action
  async getStepData(step: number = 1) {
    this.currentStepData = this.quizDB[step - 1];
  }

  @action getProgress(step: number) {
    this.progress = ((step - 1) * 100) / this.quizDB.length;
  }

  @action async getAdditionalCost({ distance, mileage }: { distance: number; mileage: number }) {
    try {
      this.loading = true;
      const resp = await this.userApi.getAdditionalCost({ distance: getDistenceInMiles(distance), mileage });
      if (!resp) return;

      this.additionalCost = resp;
    } catch ({ response }) {
      const error =
        get(response, 'data.detail.distance_mi[0]') ||
        get(response, 'data.detail.miles_to_gallon[0]') ||
        'SomethingWentWrong';
      this.fetchError = error;
    } finally {
      this.loading = false;
    }
  }

  @action async getChargingStations({ origin, destination }: MapCoords) {
    try {
      this.chargingStations = [];
      const resp = await this.userApi.getChargingStation({ origin, destination });
      if (!resp) return;

      this.chargingStations = resp;
      return resp;
    } catch ({ response }) {
      const error = get(response, 'data.error') || get(response, 'error') || 'SomethingWentWrong';
      this.fetchError = error;
    }
  }
}

import React from 'react';
import Slider from 'react-slick';
import { desktopWidth } from 'appConstants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CarouselSliderSubscriptionProps, LearningSlideContent } from 'types/Slider';
import styles from './SubscriptionCarouselSlider.module.scss';
import Loader from '../../Loader/Loader';

const RenderSlides = (slides: LearningSlideContent[]) => {
  if (!slides || !slides.length) return <Loader />;
  return slides.map((item, index) => (
    <div key={index} className={styles.cSliderItem}>
      <div className={styles.sliderItem}>
        <img src={item.illustration} alt="" />
        <h4>{item.title}</h4>
        <p
          className={styles.sliderItemText}
          dangerouslySetInnerHTML={{ __html: item.description ? item.description : '' }}
        />
      </div>
    </div>
  ));
};

const SubscriptionCarouselSlider = (props: CarouselSliderSubscriptionProps) => {
  const { width } = useWindowDimensions();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  if (width < desktopWidth) {
    return <Slider {...settings}>{RenderSlides(props.slidesList)}</Slider>;
  } else return <div className={styles.cDesktopSlider}>{RenderSlides(props.slidesList)}</div>;
};
export default SubscriptionCarouselSlider;

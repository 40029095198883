import React from 'react';
import Select from 'react-select';
import { Props as SelectProps } from 'react-select/creatable';
import classNames from 'classnames';
import styles from './CustomSelect.module.scss';

interface Props extends SelectProps<any, any> {
  style?: string;
  containerStyle?: string;
  errorText?: string | false;
}

const CustomSelect = ({ placeholder, errorText = '', text = '', style, containerStyle, name, ...props }: Props) => (
  <div className={classNames(styles.cInput, errorText ? styles.cError : null, containerStyle)}>
    <Select
      {...props}
      id={name}
      placeholder={placeholder}
      className={classNames(styles.select, errorText ? styles.error : null, style)}
      classNamePrefix="selectHolder"
    />
    {<div className={classNames(props.value ? styles.cLabelHolder : styles.cLabel)}>{props.value && placeholder}</div>}
    {errorText ? <p className={styles.errorText}>{errorText}</p> : text ? <p>{text}</p> : null}
  </div>
);

export default CustomSelect;

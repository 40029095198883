import React from 'react';
import Select from 'react-select';
import { Props as SelectProps } from 'react-select/creatable';
import classNames from 'classnames';
import styles from './CustomSelectRestyle.module.scss';
import ReactTooltip from 'react-tooltip';

interface Props extends SelectProps<any, any> {
  style?: string;
  containerStyle?: string;
  errorText?: string | false;
  label?: string;
  smallDescriptionSelect?: 'annualMileage' | 'parkingLocation';
}

const CustomSelectRestyle = ({
  placeholder,
  errorText = '',
  text = '',
  style,
  containerStyle,
  name,
  label,
  smallDescriptionSelect,
  ...props
}: Props) => (
  <div className={classNames(styles.cInput, errorText ? styles.cError : null, containerStyle)}>
    <div className={styles.cLabel}>
      <label htmlFor={name}>{label}</label>
      {smallDescriptionSelect === 'annualMileage' && (
        <div
          data-tip={'<p>Your annual mileage is used to calculate cost of ownership information throughout the site</p>'}
          data-class={styles.tooltipText}
          className={styles.sd}
        >
          What is this used for?
        </div>
      )}

      {smallDescriptionSelect === 'parkingLocation' && (
        <div
          data-tip={'<p>Based on where you park, we can recommend specific charging solutions to fit your needs</p>'}
          data-class={styles.tooltipText}
          className={styles.sd}
        >
          Why does this matter?
        </div>
      )}
      <ReactTooltip className={styles.tooltip} html={true} />
    </div>
    <Select
      {...props}
      id={name}
      placeholder={placeholder}
      className={classNames(styles.select, errorText ? styles.error : null, style)}
      classNamePrefix="selectHolder"
    />
    {errorText ? <p className={styles.errorText}>{errorText}</p> : text ? <p>{text}</p> : null}
  </div>
);

export default CustomSelectRestyle;

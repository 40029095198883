import React, { useState } from 'react';
import { Loader } from '../index';
import config from '../../environment';
import styles from './TDScheduler.module.scss';

interface Props {
  orderId: string;
  categoryId: string;
  height?: string;
  userInfo: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
}

export const TDScheduler = ({ orderId, categoryId, userInfo, height }: Props) => {
  const [isLoadFrame, setLoadFrame] = useState(true);

  const { firstName, lastName, email, phone } = userInfo;
  const userName = firstName + ' ' + lastName;
  const sbConfig = {
    widget_type: 'iframe',
    url: 'https://' + config.simplyBook.companyName + '.simplybook.it',
    theme: 'dainty',
    theme_settings: {
      timeline_show_end_time: '0',
      timeline_hide_unavailable: '1',
      sb_base_color: '#214ADE',
      secondary_color: '#e4ebf5',
      sb_text_color: '#a1a1a1',
      display_item_mode: 'list',
      body_bg_color: '#ffffff',
      sb_background_image: '',
      sb_review_image: '',
      dark_font_color: '#242937',
      light_font_color: '#ffffff',
      sb_company_label_color: '#ffffff',
      sb_cancellation_color: '#ff7a93',
      hide_img_mode: '1',
    },
    timeline: 'modern_week',
    datepicker: 'inline_datepicker',
    is_rtl: false,
    app_config: {
      allow_switch_to_ada: 0,
      predefined: {
        category: categoryId,
        client: {
          name: userName,
          email: email,
          phone: phone,
        },
        fields: {
          aff2f67dd6de3229af5ae7a39341d433: orderId,
        },
      },
    },
  };

  const renderScheduler = () => {
    return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
      <title>Test Drive</title>
      </head>
    <body>
    <script src="//widget.simplybook.it/v2/widget/widget.js"></script>
    <script>
      var widget = new SimplybookWidget(${JSON.stringify(sbConfig)});</script>
      <body>
    </html>`;
  };

  return (
    <>
      {isLoadFrame && <Loader />}
      <iframe
        onLoad={() => {
          setLoadFrame(false);
          setTimeout(() => {
            const iframe: HTMLElement | HTMLIFrameElement | null = document.getElementById('iframeController');
            // @ts-ignore
            if (iframe) {
              // @ts-ignore
              iframe.height = iframe.contentWindow.document.body.scrollHeight + 'px';
              // @ts-ignore
              console.log(iframe.height);
            }
          }, 1000);
        }}
        id={'iframeController'}
        className={styles.cFrame}
        title="testDrive"
        width="100%"
        height={height || '1000'}
        frameBorder="no"
        srcDoc={renderScheduler()}
      />
    </>
  );
};

export default TDScheduler;

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { FormikHelpers } from 'formik';
import 'react-phone-number-input/style.css';
import { ChargerOrderForm } from 'components/sections';
import { NavigationBar, StatusBar } from 'components';
import { SummaryPanel } from './sections';
import { formValues } from '../../components/sections/Order/ChargerForm/validationSchema';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';

import styles from './OrderCharger.module.scss';
import { standAloneChargerStatusBar } from '../../appConstants';

const OrderCharger = observer(() => {
  const { orderStore, authStore } = useStores();

  const isEnroll: string | null = sessionStorage.getItem('standalone-charger/is-enroll');
  useEffect(() => {
    orderStore.getCharger();
  }, [orderStore]);

  const handleSubmit = async (values: formValues, actions: FormikHelpers<any>) => {
    if (!orderStore.charger) return null;

    const {
      fullName,
      email,
      phone,
      address,
      city,
      state,
      zipCode,
      mailingAddressCheckbox,
      mailingAddress,
      mailingCity,
      mailingState,
      mailingZipCode,
    } = values;
    actions.setStatus();

    const order = {
      fullName: fullName,
      email: email,
      phone: phone,
      residenceAddress: {
        address: address,
        city: city,
        state: state,
        zipCode: zipCode,
      },
      mailingAddress: {
        address: mailingAddressCheckbox ? address : mailingAddress,
        city: mailingAddressCheckbox ? city : mailingCity,
        state: mailingAddressCheckbox ? state : mailingState,
        zipCode: mailingAddressCheckbox ? zipCode : mailingZipCode,
      },
      isEnroll: isEnroll === '1',
      chargerBundle: orderStore.charger.id,
    };

    await orderStore.placeChargerOrder(values.phone, order, authStore.isSignedIn, true);

    if (!authStore.isSignedIn) {
      authStore.sendPhone({ phone });
    }

    if (orderStore.fetchError) {
      actions.setStatus({ fetchError: orderStore.fetchError });
    }
  };

  return (
    <>
      <NavigationBar goBack linkText={'Back to bundle options'} />
      <StatusBar steps={standAloneChargerStatusBar} activeStep={'Finalize Order'} />
      <div className={styles.cContent}>
        <div>
          <h1 className={classNames(styles.textLeft, styles.offsetBottom24)}>Finalize order</h1>
          <h2>AES Indiana | Motor Level 2 Charger Bundle</h2>
          <SummaryPanel charger={orderStore.charger} />
          <ChargerOrderForm initialFormValues={orderStore.getOrderInitialValues()} handleSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
});

export default OrderCharger;

import prod from './index.prod';
import dev from './index.dev';
import local from './index.local';

type StageApp = 'prod' | 'dev' | 'local' | undefined;

const getConfig = (env: StageApp) => {
  const configList = {
    prod: prod,
    dev: dev,
    local: local,
    default: local,
  };
  return env ? configList[env] : configList['default'];
};

const config = getConfig(process.env.REACT_APP_STAGE as StageApp);

export default {
  ...config,
};

import React from 'react';
import styles from './Pagination.module.scss';
import { PaginationComponent } from '../../types/Configuration';

interface Props {
  currentPage: number | undefined;
  className?: string;
  data: PaginationComponent;
  clickHandler: (targetPage: number) => void;
}

const Pagination = ({ data, currentPage, clickHandler }: Props) => {
  const page: number = currentPage ? currentPage : 1;
  let lastPage: number = Math.floor(data.count / 10);
  if (Math.floor(data.count / 10) < data.count / 10) {
    lastPage = +lastPage + 1;
  }

  const numberItems = 6;
  const renderItems = () => {
    let html = [];
    if (lastPage < numberItems) {
      for (let i = 0; i < lastPage; ++i) {
        let p = i + 1;
        html.push(
          <li key={`page${p}`} className={i === page - 1 ? styles.active : ''}>
            <button onClick={() => clickHandler(p)}>{p}</button>
          </li>
        );
      }
    } else {
      for (let i = 0; i < numberItems; ++i) {
        let p = +page + i;
        console.log('p', p);
        if (p <= lastPage) {
          html.push(
            <li key={`page${p}`} className={i === 0 ? styles.active : ''}>
              <button onClick={() => clickHandler(p)}>{p}</button>
            </li>
          );
        }
      }
    }

    return html;
  };
  return (
    <ul className={styles.cPagination}>
      {page > 1 && (
        <li className={styles.prev}>
          <button onClick={() => clickHandler(+page - 1)}>Previous</button>
        </li>
      )}
      {page > 7 && (
        <li>
          <div>...</div>
        </li>
      )}
      {renderItems()}
      {+page + numberItems <= lastPage && (
        <li>
          <div>...</div>
        </li>
      )}
      {data.next && (
        <li className={styles.next}>
          <button onClick={() => clickHandler(+page + 1)}>Next</button>
        </li>
      )}
    </ul>
  );
};

export default Pagination;

import React, { useEffect } from 'react';
import { TwoBanners } from 'components';
import { observer } from 'mobx-react';
//import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
//import { useStores } from '../../hooks/use-stores';
import styles from './ExploreCharging.module.scss';

const ExploreCharging = observer(() => {
  //const { vehicleStore } = useStores();

  useEffect(() => {}, []);

  //const history = useHistory();
  return (
    <div className={styles.cPage}>
      <div className={styles.topBanner}>
        <div className={classNames(styles.innerTopBanner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <p className={styles.smallTitle}>Explore charging with AES Indiana</p>
          <h1>Electrify your life</h1>
        </div>
      </div>

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/*--------- Maximum Savings -----------*/}
          <div className={styles.cWhyCharge}>
            <h3>Why charge with AES?</h3>
            <h4>Faster chargers:</h4>
            <p>
              When you purchase a charger AES Indiana, Motor will install a faster charger at your home and make sure
              that you’re on the best AES Indiana rate possible. Get back what you put in. [something about charging
              times here]
            </p>

            <h4>Best rates and incentives:</h4>
            <p>
              AES Indiana offers a rate plan that helps you save money when you charge at night. You won't be the only
              one recharging your battery when day is done.
            </p>

            <h4>Easy delivery and installation:</h4>
            <p>
              Purchase your charger, fill out the quote generator, schedule install, all in one, streamlined process.
            </p>
          </div>

          <div className={styles.splitter} />

          <TwoBanners
            sectionLeft={{
              image: { url: '/images/two-sections/sb3.png', position: 'right center' },
              description: 'Electric cars save your money and our planet. Numbers don’t lie.',
              link: { caption: 'Calculate your savings', url: '/estimator/cost' },
            }}
            sectionRight={{
              image: { url: '/images//two-sections/sb4.png', position: 'left center' },
              description:
                'Ready to get behind the wheel? We have vehicle and ownership options for all electrified lifestyles.',
              link: { caption: 'Explore ownership options', url: '/own' },
            }}
          />
        </div>
        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default ExploreCharging;

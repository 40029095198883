import { History } from 'history';
import { action, observable } from 'mobx';
import {
  Vehicle,
  CurrentBuildAnswer,
  VehicleInventory,
  makeModelsFilters,
  MMVehicle,
  Make,
  Model,
  ParkingLocation,
} from 'types/Vehicle';

import { PaginationComponent } from '../types/Configuration';
import { TestDriveVehicle } from 'types/TestDrive';
import { getVehicleImgList } from '../helpers/formatters/vehicle';
import { getVehicleImages } from '../helpers/visualSet';
import { SlideModelContent } from '../types/Slider';
import { setRegularFormat } from '../helpers/formatters/money';
import { VehicleModel } from '../types/MakesModels';
import { SlideMatchesModelContent } from 'types/Slider';

export class VehicleStore {
  vehicleApi;
  routerStore;

  constructor(VehicleAPI: any, routerStore: History) {
    this.vehicleApi = new VehicleAPI();
    if (routerStore) {
      this.routerStore = routerStore;
    }
  }

  @observable loading: boolean = false;
  @observable loadingMatches: boolean = false;
  @observable loadingFilters: boolean = false;
  @observable fetchError: string = '';
  @observable error: string | null = null;
  @observable showcaseVehicles: Vehicle[] = [];
  @observable invVehicles: VehicleInventory[] = [];
  @observable ownVehicles: {
    slidesList: SlideModelContent[];
  } = { slidesList: [] };
  @observable currentBuild: VehicleInventory | undefined = undefined;
  @observable vehicleDetails: Vehicle | undefined = undefined;
  @observable invVehicleDetails: VehicleInventory | undefined = undefined;
  @observable subscVehicleDetails: VehicleInventory | undefined = undefined;
  @observable buildDueNowPrice: { total: string; holdingFee: string } | undefined = undefined;
  @observable currentBuildByBuildId: CurrentBuildAnswer | undefined = undefined;
  @observable imageList: string[] = [];
  @observable testDriveVehicles: TestDriveVehicle[] = [];
  @observable filters: makeModelsFilters | undefined = undefined;
  @observable makesModels: VehicleModel[] | undefined = [];
  @observable makesModelsPreowned: VehicleModel[] | undefined = [];
  @observable pagination: PaginationComponent | undefined = undefined;
  @observable makeModelsTab: string = 'new';
  @observable MMVehicle: MMVehicle | undefined = undefined;
  @observable similarVehicles: MMVehicle[] | undefined = undefined;
  @observable makesList: Make[] | undefined = undefined;
  @observable modelsList: Model[] | undefined = undefined;
  @observable parkingLocationList: ParkingLocation[] | undefined = undefined;
  @observable matchesVehicles: SlideMatchesModelContent[] | undefined = undefined;
  @action setTab(tab: string) {
    this.makeModelsTab = tab;
  }

  @action
  async getTestDriveVehicles() {
    try {
      this.loading = true;
      const response = await this.vehicleApi.getTestDriveVehicles();
      this.testDriveVehicles = response as TestDriveVehicle[];
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async getShowcaseVehicles() {
    try {
      this.loading = true;
      const response = await this.vehicleApi.getVehicles();
      this.showcaseVehicles = response as Vehicle[];
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async getInventoryVehicles() {
    try {
      this.loading = true;
      const response = await this.vehicleApi.getInventoryVehicles();
      this.invVehicles = response as VehicleInventory[];
      this.ownVehicles.slidesList = response.map((car: VehicleInventory) => {
        return {
          id: car.id,
          title: car.year + ' ' + car.vehicle.model,
          price: '$' + setRegularFormat(car.sellingPrice),
          image: getVehicleImages(car)[0],
          featuresList: car.vehicle.highlevelFeatures.map(feature => feature.title),
        };
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  public async getVehicleDetails(slug: string) {
    try {
      this.loading = true;
      const result = await this.vehicleApi.getVehicleDetails(slug);
      sessionStorage.setItem('vehicleDetails/vehicleSlug', slug);
      this.vehicleDetails = result;
      if (this.vehicleDetails) {
        this.imageList = getVehicleImgList(this.vehicleDetails.visualSet);
      }

      return result;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  public async getInventoryVehicleDetails(id: string) {
    try {
      this.loading = true;
      const result = await this.vehicleApi.getInventoryVehicleDetails(id);
      this.invVehicleDetails = result;
      if (this.invVehicleDetails) {
        this.imageList = getVehicleImgList(
          this.invVehicleDetails.visualSet
            ? this.invVehicleDetails.visualSet
            : this.invVehicleDetails.vehicle.visualSet,
          false
        );
      }
      return result;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  public async getSubscriptionVehicleByVin(vin: string) {
    try {
      this.loading = true;
      const result = await this.vehicleApi.getSubscriptionInventoryVehicleDetails(vin);
      this.subscVehicleDetails = result;
      if (this.subscVehicleDetails) {
        this.imageList = getVehicleImgList(
          this.subscVehicleDetails.visualSet
            ? this.subscVehicleDetails.visualSet
            : this.subscVehicleDetails.vehicle.visualSet,
          false
        );
      }
      return result;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async saveBuildCharger({
    buildID,
    chargerPackageID,
    isEnroll,
    isChargerPackage,
  }: {
    buildID: string;
    chargerPackageID: string;
    isEnroll: boolean;
    isChargerPackage: boolean;
  }) {
    try {
      this.loading = true;
      await this.vehicleApi.saveBuildCharger({
        buildID,
        chargerPackageID,
        isEnroll,
        isChargerPackage,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getBuildByOrderId(orderId: string) {
    try {
      this.fetchError = '';
      this.loading = true;
      this.currentBuild = undefined;
      const currentBuild = await this.vehicleApi.getBuildByOrderId(orderId);
      this.currentBuild = currentBuild;
      this.buildDueNowPrice = { total: currentBuild.holdingFee, holdingFee: currentBuild.holdingFee };
      return currentBuild;
    } catch (err) {
      console.log(err);
      this.fetchError = err;
    } finally {
      this.loading = false;
    }
  }

  @action
  async getBuildByID(buildID: string) {
    try {
      this.loading = true;
      const resp = await this.vehicleApi.getBuildVarietyByID(buildID);
      this.currentBuildByBuildId = resp;
      this.buildDueNowPrice = { total: resp.dueNowTotal, holdingFee: resp.trim.holdingFee };
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async getFilters(type?: string) {
    try {
      this.loadingFilters = true;
      this.filters = await this.vehicleApi.getFilters(type);
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingFilters = false;
    }
  }

  @action async getMakesAndModels(page?: number, filtersResult: string = '') {
    try {
      this.pagination = undefined;
      this.loading = true;
      this.makesModels = [];
      let result = await this.vehicleApi.getMakesAndModels(page, filtersResult);
      this.pagination = result;
      this.makesModels = result.results;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getMakesAndModelsPreowned(page?: number, filtersResult: string = '') {
    try {
      this.pagination = undefined;
      this.makesModelsPreowned = [];
      this.loading = true;
      let result = await this.vehicleApi.getMakesAndModelsPreowned(page, filtersResult);
      this.pagination = result;
      this.makesModelsPreowned = result.results;
      console.log('result3', result);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getMMVehicle(vehicleId: string, condition: string) {
    try {
      this.loading = true;
      let result = await this.vehicleApi.getMMVehicle(vehicleId, condition);
      console.log('result', result);
      this.MMVehicle = result;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getMMSimilarVehicles(vehicleId: string) {
    try {
      this.similarVehicles = await this.vehicleApi.getSimilarVehicles(vehicleId);
    } catch (err) {
      console.log(err);
    }
  }

  @action async getMakes() {
    try {
      this.loading = true;
      this.makesList = await this.vehicleApi.getMakes();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getModelsByMakeId(id: string) {
    try {
      this.loading = true;
      this.modelsList = await this.vehicleApi.getModelsByMakeId(id);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getParkingLocation() {
    try {
      this.loading = true;
      this.parkingLocationList = await this.vehicleApi.getParkingLocation();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  @action async getMatchesVehicles(uuid: string) {
    try {
      this.loadingMatches = true;
      this.matchesVehicles = await this.vehicleApi.getMatchesVehicles(uuid);
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingMatches = false;
    }
  }
}

import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import styles from './MyNextCar.module.scss';
import { InputRestyle, CheckboxGroupRestyle } from '../../../../components';
import { learningPreferences, getError } from '../../../../appConstants';
import Slider from '@mui/material/Slider';
import ReactTooltip from 'react-tooltip';

interface ItemType {
  id: string;
  name: string;
}

export interface Props {
  formik: any;
}

const MyNextCar = (props: Props) => {
  return (
    <div className={styles.cNextCar}>
      <div className={styles.cContent}>
        <h3>My next car</h3>
        <InputRestyle
          value={props.formik.values.zipCode}
          placeholder="Enter zip code..."
          name="zipCode"
          label={'Zip code'}
          onChange={e => props.formik.setFieldValue('zipCode', e.target.value)}
          errorText={getError('zipCode', props.formik.errors, props.formik.touched)}
          containerStyle={styles.select50}
          smallDescription={'zip'}
          onBlur={() => {
            props.formik.setFieldTouched('zipCode');
          }}
        />

        <div>
          <div className={styles.cLabel}>
            <label>My car budget</label>
            <div
              data-tip={
                '<p>We use your budget for your next car to match you with electric cars that may be a good fit in terms of what you’re hoping to spend</p>'
              }
              data-class={styles.tooltipText}
              className={styles.sd}
            >
              What is this used for?
            </div>
          </div>
        </div>

        <ReactTooltip className={styles.tooltip} html={true} />

        <div className={styles.cSlider}>
          <div className={styles.leftLabel}>Less than $25k</div>
          <div className={styles.rightLabel}>More than $60k</div>
          <Slider
            min={25}
            max={60}
            defaultValue={40}
            size={'medium'}
            track={false}
            aria-label="Always visible"
            value={parseInt(props.formik.values.budget)}
            //@ts-ignore
            onChange={event => props.formik.setFieldValue('budget', event.target.value)}
            step={1}
            valueLabelDisplay="on"
            valueLabelFormat={value => '$' + value + 'k'}
          />
        </div>
        <div className={styles.cLabel}>
          <label>
            Factors that influence my next car purchase, from most to least important (1-
            {props.formik.values.preferences.length}) <div className={styles.sl}>&nbsp; Drag to reorder</div>
          </label>
        </div>
        <ReactSortable
          className={styles.sorter}
          list={props.formik.values.preferences}
          setList={val => props.formik.setFieldValue('preferences', val)}
        >
          {props.formik.values.preferences.map((item: ItemType, index: number) => (
            <div key={index}>{item.name}</div>
          ))}
        </ReactSortable>

        <CheckboxGroupRestyle
          smallDescription={'learningPreferences'}
          name={'learningPreferences'}
          label={'I would like to learn about electric vehicles by...'}
          options={learningPreferences}
          value={props.formik.values.learningPreferences}
          onChange={res => {
            props.formik.setFieldValue('learningPreferences', res);
          }}
          errorText={props.formik.errors['learningPreferences'] ? props.formik.errors['learningPreferences'] : false}
        />
      </div>
    </div>
  );
};

export default MyNextCar;

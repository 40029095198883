import React from 'react';
import mainImage from '../../assets/main-404.svg';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import styles from './NoData.module.scss';
import { useStores } from '../../hooks/use-stores';

const NoData = observer(() => {
  const { errorsStore } = useStores();
  return (
    <div className={classNames([styles.cPage, styles.cHorOffsets])}>
      <div className={styles.cContent}>
        <img src={mainImage} alt="" />
        <h1>{errorsStore.serverGetError}</h1>

        <p>
          Please reload the page or{' '}
          <Link to="/support">
            Contact <br /> Support
          </Link>{' '}
          if the problem appears again
        </p>
      </div>
    </div>
  );
});

export default NoData;

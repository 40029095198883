import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './MayLike.module.scss';
import { setFinalPrice } from '../../../../helpers/formatters/money';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import { MMVehicle } from '../../../../types/Vehicle';

export interface Props {
  vehicleId: string;
}

const MayLike = observer((props: Props) => {
  const { vehicleStore } = useStores();
  const { vehicleId } = props;
  useEffect(() => {
    vehicleStore.getMMSimilarVehicles(vehicleId);
  }, [vehicleId, vehicleStore]);

  if (!props.vehicleId) return null;
  return (
    <div className={styles.cContent}>
      <h3>You may also like</h3>
      <ul className={styles.cMayLike}>
        {vehicleStore.similarVehicles?.map((vehicle: MMVehicle, index: number) => {
          const { year, make, model, msrp, id, image } = vehicle;
          return (
            <li key={`recomendation${index}`}>
              <div
                className={styles.illustration}
                style={{ backgroundImage: image ? `url(${image})` : `url("/images/default-recomendations.png")` }}
              />
              <div>
                <h4>
                  {year} {make} {model}
                </h4>
                <p>Starting at {setFinalPrice(msrp ? msrp.toString() : '0')}</p>

                <Link to={`/new-vehicle-details/${id}`} className={styles.gradient}>
                  Learn more
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default MayLike;

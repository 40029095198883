import React from 'react';
import styles from './CarDescription.module.scss';
//import { StoresButtons } from '../../index';
//import { Link } from 'react-router-dom';
import { SlideModelSubscriptionContent } from '../../../types/Slider';
//import { appStoreSubscription, playMarketSubscription } from '../../../appConstants';
import { Button } from '../../../components';
interface Props {
  carData: SlideModelSubscriptionContent;
  closePopup: () => void;
}

/*export const getPopupContent = (activeCar: string) => {
  console.log('activeCar', activeCar);
  return modelsSliderContent.slidesList.find(i => {
    return i.id === activeCar;
  });
};*/

const CarDescription = ({ carData, closePopup }: Props) => {
  if (!carData) return <div />;

  const { meta, image, price, popupTitle, popupFeaturesList } = carData;
  return (
    <div key={meta.id} className={styles.carPopup}>
      <div>
        <h1>{popupTitle}</h1>
        <h3 className={styles.cPrice}>Subscribe starting from {price}</h3>
        <ul>
          {popupFeaturesList.map((feature, index) => (
            <li key={index}>• &nbsp; {feature}</li>
          ))}
        </ul>
      </div>
      <div>
        <Button onClick={() => {
          window.open('https://trymotor.com/', '_blank', 'noopener,noreferrer');
        }}
          buttonColor={'black'}
          size={'medium'}> Get Started </Button>
      </div>
      <img src={image} alt={popupTitle} />
    </div>
  );
};

export default CarDescription;

import React from 'react';
import Slider from 'react-slick';
import { desktopWidth } from 'appConstants';
import { Button } from 'components';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CarouselSliderModelsProps, SlideModelContent } from 'types/Slider';
import classNames from 'classnames';
import styles from './ModelsCarouselSlider.module.scss';

export interface ModelsProps extends CarouselSliderModelsProps {
  showPopup: (id: string) => void;
  isPurchase?: boolean;
}
const ModelsCarouselSlider = (props: ModelsProps) => {
  const { width } = useWindowDimensions();
  const { showPopup } = props;
  let slider: Slider | null = null;

  const renderDesktopSlider = () => {
    if (props.slidesList.length <= 3) {
      return <div className={styles.cSliderStatic}>{RenderSlides(props.slidesList)}</div>;
    }
    return (
      <div className={styles.cSlider}>
        <div
          className={classNames(styles.sliderButton, styles.leftButton)}
          onClick={() => {
            if (slider) {
              slider.slickPrev();
            }
          }}
        />
        <div
          className={classNames(styles.sliderButton, styles.rightButton)}
          onClick={() => {
            if (slider) {
              slider.slickNext();
            }
          }}
        />
        <Slider ref={c => (slider = c)} {...settingsDesktop}>
          {RenderSlides(props.slidesList)}
        </Slider>
      </div>
    );
  };

  const RenderSlides = (slides: SlideModelContent[]) => {
    return slides.map((item, index) => (
      <div key={index} className={styles.cSliderItem}>
        <div className={styles.sliderItem}>
          <div>
            <h4 dangerouslySetInnerHTML={{ __html: item.title ? item.title : '' }} />
            <div className={styles.cImage}>
              <img src={item.image} alt="" />
            </div>
            <div className={styles.price}>
              <span>{item.price} </span>
            </div>
            <ul>
              {item.featuresList.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <Button
            size={'medium-rounded'}
            buttonColor={'black'}
            className={styles.cuButton}
            onClick={() => showPopup(item.id)}
          >
            Learn More
          </Button>
        </div>
      </div>
    ));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const settingsDesktop = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    centerMode: true,
  };
  if (width < desktopWidth) {
    return (
      <Slider className={styles.cSlider} {...settings}>
        {RenderSlides(props.slidesList)}
      </Slider>
    );
  } else return renderDesktopSlider();
};
export default ModelsCarouselSlider;

import * as Yup from 'yup';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';
import get from 'lodash/get';

const cardNumberRegExp = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
const withoutNumbersRegExp = /^([^0-9]*)$/;
const onlyNumberRegExp = /^[0-9]*$/;

export const VALIDATION_RULES = {
  role: (name: string = 'This field') => Yup.string().required(`${name} is required`),
  roleMinMax: (name: string = 'This field') =>
    Yup.string()
      .min(2, `${name} must be more than 2 characters`)
      .max(20, `${name} must be less than 20 characters`)
      .required(`${name} is required`),
  fullName: Yup.string()
    .matches(withoutNumbersRegExp, "Doesn't look like Full name")
    .min(4, 'Full name must be more than 4 characters')
    .max(60, 'This field must be less than 60 characters')
    .required('Full name is required'),
  firstName: Yup.string()
    .matches(withoutNumbersRegExp, "Doesn't look like First name")
    .max(30, 'This field must be less than 60 characters')
    .required('First name is required'),
  middleName: Yup.string()
    .matches(withoutNumbersRegExp, "Doesn't look like Middle name")
    .max(30, 'This field must be less than 60 characters'),
  lastName: Yup.string()
    .matches(withoutNumbersRegExp, "Doesn't look like Last name")
    .max(30, 'This field must be less than 60 characters')
    .required('Last name is required'),
  dob: Yup.date()
    .required('Date of birth is required')
    .nullable()
    .typeError('Date of birth must be in format MM/DD/YYYY'),
  address: Yup.string()
    .min(4, 'Address must be more than 4 characters')
    .max(40, 'This field must be less than 40 characters')
    .required('Address is required'),
  phone: Yup.string()
    .min(12, 'Phone must be in format +12027953213')
    .max(14, 'Phone must be in format +12027953213')
    .required('Phone is required'),
  email: Yup.string().email("Doesn't look like email").required('The field is required'),
  cardNumber: Yup.string().matches(cardNumberRegExp, 'The card number is invalid').required('This field is required'),
  cvc: Yup.string().min(3, 'The cvc number is invalid').max(3, 'The cvc number is invalid').required('Cvc is required'),
  smsCode: Yup.string()
    .matches(/^[0-9 ]+$/, "Doesn't look like sms code")
    .min(6, 'Length should be 6 digits')
    .required('Sms code is required'),
  password: Yup.string().min(5, 'Password must be more than 5 characters').required('Password is required'),
  ssn: Yup.string()
    .matches(onlyNumberRegExp, "Doesn't look like SSN")
    .min(9, 'Social Security Number must be up to 9 digits without dashes')
    .max(11, 'Social Security Number must be no more than 11 characters')
    .required('Social Security Number is required'),
  zipCode: Yup.string()
    .matches(onlyNumberRegExp, "Doesn't look like zip code")
    .min(5, 'Zip Code must be 5 digits')
    .max(5, 'Zip Code must be 5 digits')
    .required('Zip Code is required'),
  driveLicenseNumber: Yup.string().required('Number is required'),
  state: Yup.string().required('State is required'),
  acceptCheckbox: Yup.boolean().oneOf([true], 'Please confirm the field'),
  learningPreferences: Yup.array().max(4, 'You can select 4 items maximum'),
  make: Yup.string().required('Make is required'),
  model: Yup.string().required('Model is required'),
  annualMileage: Yup.string().required('Annual mileage is required'),
  parkingLocation: Yup.string().required('Parking location is required'),
};

export const getError = (
  filedName: string,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>
): string | undefined => {
  // @ts-ignore
  const errorText: string | undefined = get(errors, `${filedName}`);
  const isTouched = get(touched, `${filedName}`);
  return errorText && isTouched ? errorText : undefined;
};

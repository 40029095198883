import React from 'react';
import classNames from 'classnames';

import { StatusBar } from 'components';
import styles from './ApplyFinancing.module.scss';
import { subscriptionOrderStatusBar, applyFinancing } from '../../appConstants';
import { Link } from 'react-router-dom';

const ApplyFinancing = () => {
  return (
    <div className={classNames([styles.cPage, styles.cHorOffsetsMobile])}>
      <StatusBar steps={subscriptionOrderStatusBar} activeStep={'Apply for Financing'} />
      <div className={styles.cContent}>
        <Link to="/" className={styles.cLogo}>
          <img className={styles.logo} src={'/images/rebrand/partnership.svg'} alt="AES Indiana" />
        </Link>
        <h3>Financing Application</h3>
        <div className={styles.cBrand}>
          <span>Financing through our partner, </span>
          <div className={styles.illustr} />
        </div>
        <p>
          In order to match you with appropriate lenders, please provide the following information. After you complete
          the application, the AES + Motor team will contact you via email to present financing options and finalize the
          transaction.
        </p>
      </div>
      <iframe title="Apply for Financing" src={applyFinancing} frameBorder="0" />
    </div>
  );
};

export default ApplyFinancing;

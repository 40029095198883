import React, { useEffect } from 'react';
import { BrandsCarouselSlider, TDInfoSection, ModalFrame, Loader, SingleModelSlider } from 'components';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { reportsSliderContent } from '../../appConstants/sliders';
import { Feature, VehicleInventory } from '../../types/Vehicle';
import { setFinalPrice } from '../../helpers/formatters/money';
import { getVehicleImages } from '../../helpers/visualSet';
import useWindowDimensions from 'hooks/useWindowDimensions';
import styles from './VehicleDetails.module.scss';
import { desktopWidth } from '../../appConstants';
import { RouteComponentProps } from 'react-router-dom';

const renderModalFrame = (invVehicleDetails: VehicleInventory | undefined) => {
  if (!invVehicleDetails) return;
  const { vin, color, interior, type, trim, mileage, modelNumber, stock, sellingPrice, dealerCode, msrp } =
    invVehicleDetails;

  return (
    <ModalFrame
      extDealerCode={dealerCode}
      vin={vin}
      msrp={msrp}
      price={sellingPrice}
      modelNum={modelNumber}
      modelTrim={trim.title}
      stockCode={stock}
      extColor={color.title}
      intColor={interior.title}
      isNew={type === 'new' ? 'true' : 'false'}
      miles={mileage.toString()}
    />
  );
};

const renderProperties = (invVehicleDetails: VehicleInventory | undefined) => {
  if (!invVehicleDetails) return;
  const { vin, color, interior, mileage, trim } = invVehicleDetails;
  return (
    <ul className={styles.mainProperties}>
      <li>
        <span>VIN:</span>
        <span>{vin}</span>
      </li>
      <li>
        <span>Mileage:</span>
        <span>{mileage} miles</span>
      </li>
      <li>
        <span>Range:</span>
        <span>{trim.range} miles battery only</span>
      </li>
      <li>
        <span>Exterior:</span>
        <span>{color.title}</span>
      </li>
      <li>
        <span>Interior:</span>
        <span>{interior.title}</span>
      </li>
    </ul>
  );
};

const renderFeatures = (viewDetails: VehicleInventory | undefined) => {
  if (!viewDetails) return;
  const { overviewFeatures, warranty } = viewDetails.vehicle;
  return (
    <div className={styles.cFeatures}>
      {overviewFeatures
        .filter(f => f.description && f.title)
        .map((f: Feature, i: number) => {
          return (
            <div key={i}>
              <h5 className={styles.title}>{f.title}</h5>
              {<div dangerouslySetInnerHTML={{ __html: f.description }} />}
            </div>
          );
        })}

      <div>
        <h5 className={styles.title}>Safety & Reliability</h5>
        {warranty && <div dangerouslySetInnerHTML={{ __html: warranty }} />}
      </div>
    </div>
  );
};

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { id: string };
  };
}

const VehicleDetails = observer((props: Props) => {
  const { width } = useWindowDimensions();
  const { vehicleStore, cmsStore } = useStores();
  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    async function getVehicle() {
      await vehicleStore.getInventoryVehicleDetails(id);
      await cmsStore.getPage('vehicle-details');
    }
    getVehicle();
  }, [id, vehicleStore, cmsStore]);

  const renderCMSDescription = (description: any[]) => {
    return description.map((item, index) => {
      return (
        <div key={index} className={styles.cCMSContent}>
          <div className={styles.jsIllustration} style={{ backgroundImage: `url(${item.illustration})` }} />
          <h2>{item.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      );
    });
  };

  const renderVehicleDetails = () => {
    const {
      pageContent: { fields },
    } = cmsStore;

    const currVehicle: any = fields?.vehicle?.find(
      (vehicle: { vin: string }) => vehicle.vin.trim() === vehicleStore.invVehicleDetails?.vin
    );

    const renderKeyFeatures = () => {
      if (currVehicle?.hideKeyFeatures === true || fields?.hideKeyFeatures === true) return;
      return (
        <div className={styles.cKeyFeatures}>
          <div>
            <h2>Key Features</h2>
            {renderFeatures(vehicleStore.invVehicleDetails)}
          </div>

          {!fields?.hideWindowSticker && (
            <div className={styles.cLink}>
              <a href="/">View window sticker</a>
            </div>
          )}
        </div>
      );
    };

    const renderChargerBundle = () => {
      if (currVehicle?.hideChargingBundle === true || fields?.hideChargingBundle === true) return;
      return (
        <div>
          <div className={styles.cJumpStartContent}>
            <div className={styles.jsIllustration} />
            <h2>Add your AES Indiana Level 2 Charger Bundle Powered by Motor</h2>
            <p>
              Upgrade your charger, schedule installation and get the best energy rates when you add the bundle onto
              your vehicle purchase. Learn more <Link to={'/charge-at-home'}>here</Link>.
            </p>
            <h3 className={styles.offsetTop}>+ Charger and Installation</h3>
            <p>
              Our all-in-one bundles include a Level 2, wi-fi enabled smart charger, installation at your home, and
              enrollment into our EV Managed Charging program. Our approved electricians will make sure your charger is
              installed safely and efficiently.
            </p>
            <p className={styles.note}>
              *Get <span className={styles.highlightingText}>$250 off</span> your bundle when you enroll in EV Managed
              Charging
            </p>

            <h3 className={styles.offsetTop}>+ Optimal Charging Rates</h3>
            <p>
              On occasional days when energy demand is extra-high, EV Managed Charging gives AES Indiana permission to
              automatically adjust your charger, finding the best times to charge your car at a lower rate.
            </p>
          </div>
        </div>
      );
    };
    return (
      <div className={styles.cFullDescription}>
        {renderChargerBundle()}
        {renderKeyFeatures()}
        {!!currVehicle?.description?.length && renderCMSDescription(currVehicle.description)}
      </div>
    );
  };

  const renderModalPanel = (invVehicleDetails: VehicleInventory | undefined) => {
    if (!invVehicleDetails) return;
    const { vehicle, sellingPrice, trim, year } = invVehicleDetails;
    return (
      <div className={styles.modalPanel}>
        <h1>
          {year} {vehicle.model} {trim.title}
        </h1>

        <div className={styles.totalPrice}>Price: {setFinalPrice(sellingPrice)}</div>

        {width < desktopWidth && (
          <div className={styles.cSlider}>
            <SingleModelSlider imagesList={getVehicleImages(vehicleStore.invVehicleDetails)} />
          </div>
        )}

        {renderProperties(invVehicleDetails)}

        <div className={styles.cJumpStart}>
          <div className={styles.jumpstartMarker} /> Add the AES Charging Bundle
        </div>
        <div className={styles.cFrame}>{renderModalFrame(invVehicleDetails)}</div>
      </div>
    );
  };

  if (vehicleStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      <div className={styles.cContent}>
        <div className={styles.cSticky}>
          {renderModalPanel(vehicleStore.invVehicleDetails)}
          <div className={styles.cModal}>
            {width >= desktopWidth && (
              <div className={styles.cSlider}>
                <SingleModelSlider imagesList={getVehicleImages(vehicleStore.invVehicleDetails)} />
              </div>
            )}
          </div>

          {renderVehicleDetails()}
        </div>
        <BrandsCarouselSlider sliderTitle={'Available reports:'} slidesList={reportsSliderContent.slidesList} />
        <TDInfoSection imageURL={'/images/test-drive.png'} />
      </div>
    </div>
  );
});

export default VehicleDetails;

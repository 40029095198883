import React from 'react';
import styles from './RadioCarLabel.module.scss';

interface RadioLabelProps {
  inputId: string;
  itemTitle: string;
  description: string;
  image: string;
}

const RadioCarLabel = (props: RadioLabelProps) => {
  const { inputId, itemTitle, description, image } = props;
  let descriptionArray = description.split('\n');
  return (
    <label className={styles.contentRadioLabel} htmlFor={inputId} style={{ backgroundImage: `url(${image})` }}>
      <div>
        <h4>{itemTitle}</h4>
        {descriptionArray.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
      <p className={styles.cSelected}>Selected</p>
    </label>
  );
};
export default RadioCarLabel;

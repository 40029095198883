import React from 'react';
import styles from './MyCurrentCar.module.scss';
import { CustomSelectRestyle } from '../../../../components';
import { getError } from '../../../../appConstants';
import { SelectOptions } from 'types/Configuration';

export interface Props {
  lists: {
    makes: SelectOptions[] | undefined;
    models: SelectOptions[] | undefined;
    annualMileage: SelectOptions[] | undefined;
    parkingLocation: SelectOptions[] | undefined;
  };
  formik: any;
  getModels: (id: string) => void;
}

const MyCurrentCar = (props: Props) => {
  const { makes, models, annualMileage, parkingLocation } = props.lists;
  const findSelectedItem = (sel: SelectOptions[] | undefined, fieldName: string) => {
    if (!sel) return null;
    const selectedItem: SelectOptions | undefined = sel.find(
      (el: SelectOptions) => el.value === props.formik.values[fieldName]
    );
    return selectedItem ? selectedItem : null;
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <div className={styles.cCurrentCar}>
      <div className={styles.cLeftPanel}>
        <h3>My current car</h3>

        <div className={styles.controlsRow}>
          <CustomSelectRestyle
            label={'Make'}
            options={makes}
            value={findSelectedItem(makes, 'make')}
            placeholder="Select make..."
            name="make"
            onChange={e => {
              props.formik.setFieldValue('make', e.value);
              props.formik.setFieldValue('model', '');
              props.getModels(e.value);
            }}
            onBlur={() => {
              props.formik.setFieldTouched('make');
            }}
            errorText={getError('make', props.formik.errors, props.formik.touched)}
            containerStyle={styles.select50}
          />

          <CustomSelectRestyle
            label={'Model'}
            options={models}
            value={findSelectedItem(models, 'model')}
            placeholder="Select model..."
            name="model"
            onChange={e => {
              props.formik.setFieldValue('model', e.value);
            }}
            onBlur={() => {
              props.formik.setFieldTouched('model');
            }}
            errorText={getError('model', props.formik.errors, props.formik.touched)}
            containerStyle={styles.select50}
          />
        </div>

        <div className={styles.controlsRow}>
          <CustomSelectRestyle
            label={'Annual mileage'}
            smallDescriptionSelect={'annualMileage'}
            options={annualMileage}
            value={findSelectedItem(annualMileage, 'annualMileage')}
            placeholder="Select annual mileage..."
            name="annualMileage"
            onChange={e => {
              props.formik.setFieldValue('annualMileage', e.value);
            }}
            onBlur={() => {
              props.formik.setFieldTouched('annualMileage');
            }}
            errorText={getError('annualMileage', props.formik.errors, props.formik.touched)}
            containerStyle={styles.select50}
          />
        </div>

        <div className={styles.controlsRow}>
          <CustomSelectRestyle
            label={'Parking location at home'}
            smallDescriptionSelect={'parkingLocation'}
            options={parkingLocation}
            value={findSelectedItem(parkingLocation, 'parkingLocation')}
            placeholder="Select parking location..."
            name="parkingLocation"
            onChange={e => {
              props.formik.setFieldValue('parkingLocation', e.value);
            }}
            onBlur={() => {
              props.formik.setFieldTouched('parkingLocation');
            }}
            errorText={getError('parkingLocation', props.formik.errors, props.formik.touched)}
            containerStyle={styles.select50}
          />
        </div>
      </div>
    </div>
  );
};

export default MyCurrentCar;

import React from 'react';
import Slider from 'react-slick';
import { desktopWidth } from 'appConstants';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { SlideMatchesModelContent } from 'types/Slider';
import classNames from 'classnames';
import styles from './MatchesModelsCarouselSlider.module.scss';
import { setFinalPrice } from '../../../helpers/formatters/money';

export interface ModelsProps {
  slidesList: SlideMatchesModelContent[];
}
const MatchesModelsCarouselSlider = (props: ModelsProps) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  let slider: Slider | null = null;

  const renderDesktopSlider = () => {
    if (props.slidesList.length <= 3) {
      return <div className={styles.cSliderStatic}>{RenderSlides(props.slidesList)}</div>;
    }
    return (
      <div className={styles.cSlider}>
        <div
          className={classNames(styles.sliderButton, styles.leftButton)}
          onClick={() => {
            if (slider) {
              slider.slickPrev();
            }
          }}
        />
        <div
          className={classNames(styles.sliderButton, styles.rightButton)}
          onClick={() => {
            if (slider) {
              slider.slickNext();
            }
          }}
        />
        <Slider ref={c => (slider = c)} {...settingsDesktop}>
          {RenderSlides(props.slidesList)}
        </Slider>
      </div>
    );
  };

  const getMatchesLevel = (matchScore: number) => {
    switch (true) {
      case matchScore > 80:
        return 'Excellent';
      case matchScore > 70:
        return 'Great';
      case matchScore > 50:
        return 'Good';
      default:
        return 'Regular';
    }
  };

  const RenderSlides = (slides: SlideMatchesModelContent[]) => {
    return slides.map((item, index) => {
      let mLevel = getMatchesLevel(parseInt(item.matchScore));
      return (
        <div key={index} className={styles.cSliderItem}>
          <div className={styles.sliderItem}>
            <div
              className={classNames(styles.cMatchPanel, {
                [styles.pmExcellent]: mLevel === 'Excellent',
                [styles.pmGreat]: mLevel === 'Great',
                [styles.pmGood]: mLevel === 'Good',
              })}
            >
              <div className={styles.roundLabel}>{item.matchScore}</div>
              <div className={styles.matchDescription}>{mLevel} Match</div>
            </div>
            <div>
              <h4>
                {item.year} {item.make} <br /> {item.model}
              </h4>
              <div className={styles.cImage}>
                <img src={item.image ? item.image : 'images/temp/image77.png'} alt="" />
              </div>
              <div className={styles.priceBar}>
                <p className={styles.smallDescription}>
                  MSRP <br />
                  from
                </p>
                <div className={styles.cPrice}>{setFinalPrice(item.msrp)}</div>
              </div>
            </div>
            <Button
              size={'medium-rounded'}
              buttonColor={'black'}
              className={styles.cuButton}
              onClick={() => {
                history.push(`/new-vehicle-details/${item.id}`);
              }}
            >
              Learn More
            </Button>
          </div>
        </div>
      );
    });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const settingsDesktop = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    centerMode: true,
  };
  if (width < desktopWidth) {
    return (
      <Slider className={styles.cSlider} {...settings}>
        {RenderSlides(props.slidesList)}
      </Slider>
    );
  } else return renderDesktopSlider();
};
export default MatchesModelsCarouselSlider;

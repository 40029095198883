import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { Input } from 'components';
import styles from './SearchInput.module.scss';

interface Props {
  placeholder?: string;
  handleSelect?: (coords: { lat: number; lng: number }, name: string) => void;
  value: string;
  onChange: (name: string) => void;
}

interface SuggestionProps {
  loading: boolean;
  suggestions: Array<{ active: boolean; description: string }>;
  getSuggestionItemProps: (sugestion: any, props: any) => any;
}

const Suggestion = ({ loading, suggestions, getSuggestionItemProps }: SuggestionProps) => {
  const height = suggestions && suggestions.length ? 35 * suggestions.length : 30;

  return (
    <div className={styles.dropdown} style={{ height }}>
      {loading && <div>Loading...</div>}
      {suggestions.map((suggestion: any, index: number) => {
        const className = suggestion.active ? styles.suggestionItemActive : styles.suggestionItem;

        return (
          <div
            key={index}
            {...getSuggestionItemProps(suggestion, {
              className,
            })}
          >
            <p>{suggestion.description}</p>
          </div>
        );
      })}
    </div>
  );
};

const SearchInput = (props: Props) => {
  const handleSelect = async (address: any) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      if (props.handleSelect) {
        props.handleSelect(latLng, address);
      }
    } catch (error) {
      console.log('handleSelect Error', error);
    }
  };

  return (
    <PlacesAutocomplete value={props.value} onChange={props.onChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
        <div className={styles.cDropdown}>
          <Input
            id={props.placeholder}
            value={props.value}
            {...getInputProps({
              placeholder: props.placeholder,
              id: props.placeholder,
            })}
            style={styles.searchInput}
          />

          {suggestions.length ? (
            <Suggestion loading={loading} suggestions={suggestions} getSuggestionItemProps={getSuggestionItemProps} />
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default SearchInput;

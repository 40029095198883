import React from 'react';
import Slider from 'react-slick';
import { desktopWidth } from 'appConstants';
import classNames from 'classnames';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CarouselSliderBrandsProps, SlideBrandContent } from 'types/Slider';
import styles from './BrandsCarouselSlider.module.scss';

const RenderSlides = (slides: SlideBrandContent[]) => {
  return slides.map((item, index) => (
    <div key={index} className={styles.cSliderItem}>
      <a href={item.url} target="_blank" rel={'noreferrer noopener'} className={styles.sliderItem}>
        <img src={item.image} alt="" />
      </a>
    </div>
  ));
};

export interface Props extends CarouselSliderBrandsProps {
  sliderTitle?: string;
}

const BrandsCarouselSlider = (props: Props) => {
  const { width } = useWindowDimensions();
  const { sliderTitle, slidesList } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const isDesktop = width < desktopWidth;
  const title = sliderTitle ? sliderTitle : 'As seen on:';
  if (!slidesList) return null;
  if (isDesktop) {
    return (
      <div className={styles.brandsSection}>
        <div className={styles.sliderTitle}>{title}</div>
        <div>{<Slider {...settings}>{RenderSlides(slidesList)}</Slider>}</div>
      </div>
    );
  } else
    return (
      <div className={classNames(styles.cBrands, styles.brandsSection)}>
        <div className={styles.sliderTitle}>{title}</div>
        <div className={styles.tiles}>{RenderSlides(slidesList)}</div>
      </div>
    );
};
export default BrandsCarouselSlider;

import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { useStores } from 'hooks/use-stores';
import { observer } from 'mobx-react';

import { Header, Footer, Warning } from 'components';

import HomePage from '../../pages/Home';
import OrderFinal from '../../pages/OrderFinal';
import VehicleDetails from '../../pages/VehicleDetails/VehicleDetails';
import PaymentPage from '../../pages/Payment';
import AccountPage from '../../pages/Account';
import Page404 from '../../pages/Page404';

import PrivacyPolicy from '../../pages/StaticPages/PrivacyPolicy';
import TermsService from '../../pages/StaticPages/TermsService';
import ContactSupport from '../../pages/StaticPages/ContactSupport';
import HoldingFee from '../../pages/StaticPages/HoldingFee';

import ElectricCarLifestyle from '../../pages/SliderContentPages/ElectricCarLifestyle';
import Subscription from '../../pages/Subscription/Subscription';
import Own from '../../pages/Own/Own';
import ExploreCharging from '../../pages/ExploreCharging/ExploreCharging';
import SubscriptionFAQ from '../../pages/SliderContentPages/SubscriptionFAQ';

import EstimatorCost from '../../pages/EstimatorCost/EstimatorCost';
import EstimatorCharging from '../../pages/EstimatorCharging/EstimatorCharging';
import TestDrive from '../../pages/TestDrive';
import TestDriveCompleted from '../../pages/TestDriveCompleted';

import TokenStore from '../../stores/TokenStore/TokenStore';
import TestDriveScheduler from '../../pages/TestDriveScheduler';
import ChargerOffer from '../../pages/ChargerOffer/ChargerOffer';
import ChargerOfferForSubscription from '../../pages/ChargerOfferForSubscription/ChargerOfferForSubscription';
import SubscribeToBuy from '../../pages/SubscribeToBuy/SubscribeToBuy';
import ConfirmationSubscribeToBuy from '../../pages/ConfirmationSubscribeToBuy/ConfirmationSubscribeToBuy';
import ApplyFinancing from '../../pages/ApplyFinancing/ApplyFinancing';
import ChargeAtHome from '../../pages/ChargeAtHome/ChargeAtHome';
import BundleOptions from '../../pages/BundleOptions/BundleOptions';
import OrderCharger from '../../pages/OrderCharger/OrderCharger';
import ConfirmationChargerOrder from '../../pages/ConfirmationChargerOrder/ConfirmationChargerOrder';
import Ev101 from '../../pages/Ev101/Ev101';
import MakesAndModels from '../../pages/MakesAndModels/MakesAndModels';
import MakesAndModelsVehicleDetails from '../../pages/MakesAndModelsVehicleDetails/MakesAndModelsVehicleDetails';
import EnvironmentalImpact from '../../pages/EnvironmentalImpact/EnvironmentalImpact';
import DriverProfile from '../../pages/DriverProfile/DriverProfile';
import styles from './Main.module.scss';

const Main = observer((props: RouteComponentProps) => {
  const { authStore, errorsStore } = useStores();
  TokenStore.history = props.history;

  const renderError = () => {
    if (errorsStore.serverError) {
      setTimeout(() => {
        errorsStore.clearServerError();
      }, 8000);
      return <Warning title={errorsStore.serverError} type="error" />;
    }
  };

  const renderLimitedTemplate = () => {
    return (
      <div className={styles.cContentLimit}>
        <Switch>
          <Route exact path="/vehicle-details/:id" component={VehicleDetails} />
          <Route exact path="/vehicle-details/:vehicleId/charger-offer" component={ChargerOffer} />
          <Route exact path="/order/:id/payment" component={PaymentPage} />
          <Route exact path="/order/:id/completed" component={OrderFinal} />
          <Route exact path="/test-drive/:orderId/scheduler" component={TestDriveScheduler} />
          <Route exact path="/test-drive/:orderId/completed" component={TestDriveCompleted} />
          <Route exact path="/account" component={AccountPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-service" component={TermsService} />
          <Route exact path="/holding-fee" component={HoldingFee} />
          <Route exact path="/support" component={ContactSupport} />
          <Route exact path="/subscription-faq" component={SubscriptionFAQ} />
          <Route exact path="/subscribe-to-buy/:vin" component={SubscribeToBuy} />
          <Route exact path="/subscribe-to-buy/:vin/charger-offer" component={ChargerOfferForSubscription} />
          <Route exact path="/subscribe-to-buy/confirmation/:paymentMethod" component={ConfirmationSubscribeToBuy} />
          <Route exact path="/apply-financing" component={ApplyFinancing} />
          <Route exact path="/charge-at-home/order" component={OrderCharger} />
          <Route exact path="/charge-at-home/order/:orderId/confirmation" component={ConfirmationChargerOrder} />
          <Route exact path="/new-vehicle-details/:id" component={MakesAndModelsVehicleDetails} />
          <Route exact path="/driver-profile" component={DriverProfile} />
          <Route exact path="*" component={Page404} />
        </Switch>
      </div>
    );
  };

  return (
    <div className={styles.cApp}>
      <Header isLoggedIn={authStore.isSignedIn} pathname={props.location.pathname} />
      <div className={styles.cContent}>
        {renderError()}
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/test-drive" component={TestDrive} />
          <Route exact path="/electric-car-lifestyle" component={ElectricCarLifestyle} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/own" component={Own} />
          <Route exact path="/explore-charging" component={ExploreCharging} />
          <Route exact path="/estimator/cost" component={EstimatorCost} />
          <Route exact path="/estimator/charging" component={EstimatorCharging} />
          <Route exact path="/charge-at-home" component={ChargeAtHome} />
          <Route exact path="/charge-at-home/bundle-options" component={BundleOptions} />
          <Route exact path="/electric-cars-101" component={Ev101} />
          <Route exact path="/ev-101" component={Ev101} />
          <Route exact path="/makes-and-models/" component={MakesAndModels} />
          <Route exact path="/makes-and-models/:carsState" component={MakesAndModels} />
          <Route exact path="/makes-and-models/:carsState/:page" component={MakesAndModels} />
          <Route exact path="/environmental-impact" component={EnvironmentalImpact} />
          <Route exact path="*" component={renderLimitedTemplate} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
});
export default Main;

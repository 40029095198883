import React, { useState, useMemo } from 'react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import get from 'lodash/get';
import ReactGA from 'react-ga';

import Button from 'components/Button/Button';
import styles from './ChargerPayment.module.scss';

const cardOptions = {
  hidePostalCode: true,
  placeholder: 'Credit card number',
  style: {
    base: {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '16px',
      fontWeight: '400',
      color: '#000000',
      backgroundColor: '#F5F5F5',
      padding: '5px',
      letterSpacing: '0.1px',
      '::placeholder': {
        color: '#707070',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const cardDateOptions = { ...cardOptions, placeholder: 'Expiration date' };
const cardCvcOptions = { ...cardOptions, placeholder: 'CVC' };

const useOptions = () => {
  return useMemo(() => [cardOptions, cardDateOptions, cardCvcOptions], []);
};

const PaymentForm = observer(({ updatePaymentIntent }: { updatePaymentIntent?: (id: string) => Promise<void> }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardOptions, cardDateOptions, cardCvcOptions] = useOptions();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElements = elements.getElement(CardNumberElement);
    if (!cardElements) return;

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElements,
    });

    if (payload.error) {
      const errorMessage = get(payload, 'error.message', 'Credit card error');
      setError(errorMessage);
    }

    if (typeof payload.paymentMethod?.id === 'string' && updatePaymentIntent) {
      updatePaymentIntent(payload.paymentMethod?.id);
    }

    ReactGA.event({
      category: 'User',
      action: 'Added credit card',
    });
  };

  return (
    <div className={styles.cForm}>
      <div className={styles.form}>
        <form onSubmit={handleSubmit}>
          <label>
            <div className={styles.input}>
              <label>
                <CardNumberElement
                  options={cardOptions}
                  onChange={() => {
                    setError(null);
                  }}
                />
              </label>
            </div>

            <div className={styles.inputWrap}>
              <div className={styles.input50}>
                <label>
                  <CardExpiryElement
                    options={cardDateOptions}
                    onChange={() => {
                      setError(null);
                    }}
                  />
                </label>
              </div>
              <div className={styles.input50}>
                <label>
                  <CardCvcElement
                    options={cardCvcOptions}
                    onChange={() => {
                      setError(null);
                    }}
                  />
                </label>
              </div>
            </div>
          </label>
          {error && <h5 className={styles.error}>{error}</h5>}
          <Button
            className={styles.btn}
            fullWidth
            buttonColor="green"
            size="medium-plus"
            type="submit"
            disabled={!stripe}
          >
            Complete Order
          </Button>
        </form>
      </div>
    </div>
  );
});
export default PaymentForm;

import classNames from 'classnames';
import SearchInput from './SearchInput';
import { setMyLocationToDestination, setMyLocationToOrigin } from '../helpers';
import Map from './Map';
import ParkComponent from './ParkComponent';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
import styles from './Estimator.module.scss';

export interface Props {
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: {
    latitude: number;
    longitude: number;
  };
}

const MainWidget = observer((props: Props) => {
  const [distance, setDistance] = useState<number | null>(null);
  const [origin, setOrigin] = useState<{ lat: number; lng: number } | undefined>();
  const [originName, setOriginName] = useState<string>('');
  const [destination, setDestination] = useState<{ lat: number; lng: number } | undefined>();
  const [destinationName, setDestinationName] = useState<string>('');
  const { advisorStore } = useStores();
  const defaultCoords = { latitude: 39.84017713, longitude: -86.16989136 };

  useEffect(() => {
    if (!origin || !destination || !distance) return;

    advisorStore.getChargingStations({
      origin: {
        lat: origin.lat,
        lng: origin.lng,
      },
      destination: {
        lat: destination.lat,
        lng: destination.lng,
      },
    });
  }, [origin, destination, distance, advisorStore]);

  return (
    <div className={classNames(styles.cContent, styles.desktopWidth)}>
      <h3 className={styles.title}>What’s your most frequent route?</h3>
      <div>
        <div className={styles.cSearchInput}>
          <SearchInput
            value={originName}
            onChange={setOriginName}
            placeholder="Starting location"
            handleSelect={(originCoords: { lat: number; lng: number }, name) => {
              setOrigin(originCoords);
              setOriginName(name);
            }}
          />
          <img
            onClick={() => setMyLocationToOrigin(setOrigin, setOriginName)}
            className={styles.geoImg}
            src="/images/crosshair.svg"
            alt=""
          />
        </div>
        <div className={styles.cSearchInput}>
          <SearchInput
            value={destinationName}
            onChange={setDestinationName}
            placeholder="Destination"
            handleSelect={(destinationCoords: { lat: number; lng: number }, name) => {
              setDestination(destinationCoords);
              setDestinationName(name);
            }}
          />
          <img
            onClick={() => setMyLocationToDestination(setDestination, setDestinationName)}
            className={styles.geoImg}
            src="/images/crosshair.svg"
            alt=""
          />
          {!props.isGeolocationEnabled && <p className={styles.errorText}>Geolocation is turned off</p>}
        </div>

        <Map
          isGeolocationAvailable={props.isGeolocationAvailable}
          isGeolocationEnabled={props.isGeolocationEnabled}
          coords={props.coords || defaultCoords}
          origin={origin}
          destination={destination}
          setDistance={setDistance}
        />
      </div>
      <h3>Where do you park your ride overnight?</h3>

      <ParkComponent
        origin={origin}
        destination={destination}
        distance={distance}
        coords={props.coords || defaultCoords}
        isGeolocationEnabled={props.isGeolocationEnabled}
        isGeolocationAvailable={props.isGeolocationAvailable}
        pins={advisorStore.chargingStations}
      />
    </div>
  );
});

export default MainWidget;

import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import { Button, Input, Checkbox } from 'components';
import { formValues, schema } from './validationSchema';
import { getError } from 'appConstants/validations';
import styles from './ChargerForm.module.scss';

interface Props {
  handleSubmit: (values: formValues, actions: FormikHelpers<any>) => void;
  initialFormValues: formValues;
}

const ChargerForm = (props: Props) => {
  return (
    <div className={styles.cContent}>
      <Formik initialValues={props.initialFormValues} validationSchema={schema} onSubmit={props.handleSubmit}>
        {formik => {
          const { values, errors, touched, handleChange, handleSubmit } = formik;
          const onChangeInput = (e: ChangeEvent<HTMLInputElement>, fieldName: string, isCheckbox = false) => {
            handleChange(e);
            sessionStorage.setItem(`orderForm/${fieldName}`, isCheckbox ? `${e.target.checked}` : e.target.value);
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.cForm}>
                <div className={styles.formGroup}>
                  <h4>Personal details</h4>

                  <Input
                    value={values.fullName}
                    placeholder="Full name"
                    name="fullName"
                    onChange={e => onChangeInput(e, 'fullName')}
                    errorText={getError('fullName', errors, touched)}
                  />

                  <Input
                    value={values.email}
                    placeholder="Email address"
                    name="email"
                    onChange={e => onChangeInput(e, 'email')}
                    errorText={getError('email', errors, touched)}
                  />

                  <PhoneInput
                    name="phone"
                    id="phone"
                    className={classNames(getError('phone', errors, touched) ? styles.cPhoneError : '')}
                    countries={['US', 'RU', 'UA']}
                    defaultCountry="US"
                    placeholder="Phone number"
                    value={values.phone}
                    limitMaxLength
                    onChange={(val: string = '') => {
                      handleChange({
                        target: {
                          name: 'phone',
                          value: val,
                        },
                      });
                      sessionStorage.setItem('orderForm/phone', val);
                    }}
                  />
                  {getError('phone', errors, touched) && (
                    <p className={styles.errorText}>{getError('phone', errors, touched)}</p>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <h4>Residence address</h4>

                  <Input
                    value={values.address}
                    placeholder="Address"
                    name="address"
                    onChange={e => onChangeInput(e, 'address')}
                    errorText={getError('address', errors, touched)}
                  />

                  <Input
                    value={values.city}
                    placeholder="City"
                    name="city"
                    onChange={e => onChangeInput(e, 'city')}
                    errorText={getError('city', errors, touched)}
                  />

                  <div className={styles.flex}>
                    <Input
                      value={values.state}
                      placeholder="State"
                      name="state"
                      onChange={e => onChangeInput(e, 'state')}
                      errorText={getError('state', errors, touched)}
                      containerStyle={styles.input50}
                    />
                    <Input
                      value={values.zipCode}
                      placeholder="Zip code"
                      name="zipCode"
                      onChange={e => onChangeInput(e, 'zipCode')}
                      errorText={getError('zipCode', errors, touched)}
                      containerStyle={styles.input50}
                    />
                  </div>
                </div>

                <div className={classNames(styles.formGroup, styles.mb35)}>
                  <h4>Mailing address</h4>
                  <Checkbox
                    markerColor="purple"
                    text="Same as residence address"
                    checked={values.mailingAddressCheckbox}
                    name="mailingAddressCheckbox"
                    onChange={e => onChangeInput(e, 'mailingAddressCheckbox', true)}
                  />
                  {!values.mailingAddressCheckbox && (
                    <>
                      <Input
                        id="mailingAddress"
                        value={values.mailingAddress}
                        placeholder="Address"
                        name="mailingAddress"
                        onChange={e => onChangeInput(e, 'mailingAddress')}
                        errorText={getError('mailingAddress', errors, touched)}
                      />

                      <Input
                        id="mailingCity"
                        value={values.mailingCity}
                        placeholder="City"
                        name="mailingCity"
                        onChange={e => onChangeInput(e, 'mailingCity')}
                        errorText={getError('mailingCity', errors, touched)}
                      />

                      <div className={styles.flex}>
                        <Input
                          id="mailingState"
                          value={values.mailingState}
                          placeholder="State"
                          name="mailingState"
                          onChange={e => onChangeInput(e, 'mailingState')}
                          errorText={getError('mailingState', errors, touched)}
                          containerStyle={styles.input50}
                        />
                        <Input
                          id="mailingZipCode"
                          value={values.mailingZipCode}
                          placeholder="Zip code"
                          name="mailingZipCode"
                          onChange={e => onChangeInput(e, 'mailingZipCode')}
                          errorText={getError('mailingZipCode', errors, touched)}
                          containerStyle={styles.input50}
                        />
                      </div>
                    </>
                  )}
                </div>

                <p className={styles.description}>
                  By placing this order, you agree to{' '}
                  <a
                    href="https://trymotor.com/drive-member-agreement"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    Terms and Conditions
                  </a>{' '}
                  and{' '}
                  <a href="/privacy-policy" target="_blank" rel="noopener norefferer" className={styles.link}>
                    Privacy Policy
                  </a>
                </p>
              </div>
              <FormikErrorFocus offset={200} align={'middle'} focusDelay={200} ease={'linear'} duration={1000} />
              <div>{formik.status && <p className={styles.cError}>{formik.status.fetchError}</p>}</div>
              <Button buttonColor="green" fullWidth size="big" type="submit">
                Next
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChargerForm;

import React from 'react';
import classNames from 'classnames';

import styles from './SocialLinks.module.scss';

interface Props {
  style?: string;
  color?: 'black' | 'white';
}

const SocialLinks = ({ style = '', color = 'black' }: Props) => {
  return (
    <ul className={classNames(styles.cSocials, color === 'black' ? styles.cBlack : styles.cWhite, style)}>
      <li>
        <a
          className={styles.facebook}
          href="https://www.facebook.com/MotorEVIndy"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;
        </a>
      </li>
      <li>
        <a
          className={styles.instagram}
          href="https://www.instagram.com/motor_ev/"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;
        </a>
      </li>
      <li>
        <a
          className={styles.linkedin}
          href="https://www.linkedin.com/company/motor-ev/"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;
        </a>
      </li>
      <li>
        <a className={styles.twitter} href="https://twitter.com/motor_ev" target="_blank" rel="noreferrer noopener">
          &nbsp;
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;

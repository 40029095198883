import React from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

interface Props {
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  text: string;
  name?: string;
  markerColor?: 'purple' | 'blue' | 'black';
  rounded?: boolean;
  className?: string;
}

const Checkbox = ({ text, checked, onChange, markerColor = 'blue', rounded, className, ...props }: Props) => {
  return (
    <div>
      <label className={classNames(styles.container, className ? className : '')}>
        <p>{text}</p>
        <input type="checkbox" checked={checked} onChange={onChange} {...props} />
        <span
          className={classNames(
            styles.checkmark,
            {
              [styles.purple]: markerColor === 'purple',
              [styles.black]: markerColor === 'black',
            },
            rounded ? styles.rounded : ''
          )}
        />
      </label>
    </div>
  );
};

export default Checkbox;

import {
  CreateOrder,
  CreateOrderAPI,
  Order,
  OrderAPI,
  OrderDocumentsAPI,
  OrderDocuments,
  CreateTDriveOrder,
  CreateTDriveOrderAPI,
  CreateTDriveOrderAnswer,
  CreateTDriveOrderAnswerAPI,
  TDriveOrderAnswerAPI,
  TDriveOrderAnswer,
  TDSchedulerAPI,
  TDScheduler,
  ChargerAnswer,
  CreateChargerOrder,
  CreateChargerOrderAPI,
  ChargerOrderAPI,
  ChargerOrder,
} from '../../types/Order';

import { PaymentInstructionsAPI, PaymentInstructions } from '../../types/Payment';
import { adaptTestDriveVehicleApi } from '../vehicle/adapter';
import { ChargerPackageAPI, ChargerPackage } from '../../types/Vehicle';
export const adaptCharger = (chargerPackage: ChargerAnswer) => {
  return {
    is_enroll: chargerPackage.isEnroll,
    is_jumpstart: chargerPackage.isJumpstart,
    is_fast_charger_kept: chargerPackage.isFastChargerKept,
    package: chargerPackage?.package,
  };
};

export const adaptChargerAPI = (chargerPackage: ChargerPackageAPI): ChargerPackage => {
  return {
    maxPrice: chargerPackage.charger_install_max_price,
    minPrice: chargerPackage.charger_install_min_price,
    stationPrice: chargerPackage.charger_station_price,
    description: chargerPackage.description,
    enrollPrice: chargerPackage.enroll_get_off_price,
    image: chargerPackage.image,
    title: chargerPackage.title,
    id: chargerPackage.id,
    dueNow: chargerPackage.due_now,
  };
};

export const adaptOrder = (order: CreateOrder): CreateOrderAPI => {
  return {
    full_name: order.fullName,
    email: order.email,
    phone: order.phone,
    co_owner: order.coOwner || null,
    vehicle_build: order.vehicleBuild,
    residence_address: {
      address: order.residenceAddress.address,
      city: order.residenceAddress.city,
      state: order.residenceAddress.state,
      zip_code: order.residenceAddress.zipCode,
    },
    mailing_address: {
      address: order.mailingAddress.address,
      city: order.mailingAddress.city,
      state: order.mailingAddress.state,
      zip_code: order.mailingAddress.zipCode,
    },
    payment_type: order.paymentType,
    driver_license: order.driverLicense,
    charger_package: order.chargerPackage ? adaptCharger(order.chargerPackage) : undefined,
  };
};

export const adaptChargerOrder = (order: CreateChargerOrder): CreateChargerOrderAPI => {
  return {
    full_name: order.fullName,
    email: order.email,
    phone: order.phone,
    residence_address: {
      address: order.residenceAddress.address,
      city: order.residenceAddress.city,
      state: order.residenceAddress.state,
      zip_code: order.residenceAddress.zipCode,
    },
    mailing_address: {
      address: order.mailingAddress.address,
      city: order.mailingAddress.city,
      state: order.mailingAddress.state,
      zip_code: order.mailingAddress.zipCode,
    },
    is_enroll: order.isEnroll,
    charger_bundle: order.chargerBundle,
  };
};

export const adaptOrderApi = (order: OrderAPI): Order => {
  const data: Order = {
    id: order.id,
    createdAt: order.created_at,
    status: order.status,
    zipCode: order.zip_code,
    fullName: order.full_name,
    email: order.email,
    phone: order.phone,
    coOwner: order.co_owner,
    vehicleBuild: {
      id: order.vehicle_build.id,
      type: order.vehicle_build.type,
      vehicle: order.vehicle_build.vehicle,
      trim: order.vehicle_build.trim,
      color: order.vehicle_build.color,
      interior: order.vehicle_build.interior,
      packages: order.vehicle_build.packages,
      incentives: order.vehicle_build.incentives,
      customFeatures: order.vehicle_build.custom_features,
    },
    residenceAddress: {
      id: order.residence_address.id,
      address: order.residence_address.address,
      city: order.residence_address.city,
      state: order.residence_address.state,
      zipCode: order.residence_address.zip_code,
    },
    mailingAddress: {
      id: order.mailing_address.id,
      address: order.mailing_address.address,
      city: order.mailing_address.city,
      state: order.mailing_address.state,
      zipCode: order.mailing_address.zip_code,
    },
    statusShort: order.status_short,
    deliveryDate: order.delivery_date,
    estimatedCompletionDate: order.estimated_completion_date,
    bookingCode: order.booking_code,
    paymentType: order.payment_type,
  };

  if (order.charger_package) {
    data.chargerPackage = {
      id: order.charger_package.id,
      isEnroll: order.charger_package.is_enroll,
      isPaid: order.charger_package.is_paid,
      schedule: order.charger_package.schedule,
      totalAmount: order.charger_package.total_amount,
    };
  }

  return data;
};

export const adaptChargerOrderApi = (order: ChargerOrderAPI): ChargerOrder => {
  return {
    id: order.id,
  };
};

export const adaptOrderDocs = (orderDocs: OrderDocumentsAPI): OrderDocuments => {
  const data: OrderDocuments = {
    id: orderDocs.id,
    order: orderDocs.order,
    vehicleDocument: orderDocs.vehicle_order_document,
    odometerStatement: orderDocs.odometer_statement,
    powerOfAttorney: orderDocs.power_of_attorney,
    deliveryReceipt: orderDocs.conditional_delivery_receipt,
    certificateForVehicle: orderDocs.application_for_certificate_of_title_for_a_vehicle,
    certificateOfGross: orderDocs.certificate_of_gross_retail_or_use_tax_paid,
    convenienceFee: orderDocs.convenience_fee_disclosure,
    finalVehicleOrder: orderDocs.final_vehicle_order,
  };

  if (orderDocs.charger_installation_authorization) {
    data.chargerInstallation = orderDocs.charger_installation_authorization;
  }

  if (orderDocs.landlord_approval) {
    data.landlordApproval = orderDocs.landlord_approval;
  }

  return data;
};

export const adaptPaymentInstructionsAPI = (instructions: PaymentInstructionsAPI): PaymentInstructions => {
  return {
    accountName: instructions.account_name,
    accountNumber: instructions.account_number,
    bank: instructions.bank,
    reference: instructions.reference,
  };
};

export const adaptTDriveScheduleApi = (schedule: { start_datetime: string }): { startDateTime: string } => {
  return { startDateTime: schedule.start_datetime };
};

export const adaptTDriveOrder = (order: CreateTDriveOrder): CreateTDriveOrderAPI => {
  return {
    first_name: order.firstName,
    middle_name: order.middleName,
    last_name: order.lastName,
    driver_license: order.driverLicense,
    state: order.state,
    date_of_birthday: order.dateOfBirthday,
    email: order.email,
    phone: order.phone,
    vehicle: order.vehicle,
  };
};

export const adaptTDriveOrderAPI = (order: TDriveOrderAnswerAPI): TDriveOrderAnswer => {
  return {
    bookingCode: order.booking_code,
    createdBy: order.created_by,
    email: order.email,
    firstName: order.first_name,
    lastName: order.last_name,
    id: order.id,
    phone: order.phone,
    status: order.status,
    schedule: order.schedule ? adaptTDriveScheduleApi(order.schedule) : null,
    vehicle: adaptTestDriveVehicleApi(order.vehicle),
  };
};

export const adaptCreateTDriveOrderAPI = (order: CreateTDriveOrderAnswerAPI): CreateTDriveOrderAnswer => {
  return {
    bookingCode: order.booking_code,
    createdBy: order.created_by,
    email: order.email,
    firstName: order.first_name,
    lastName: order.last_name,
    id: order.id,
    phone: order.phone,
    status: order.status,
    schedule: order.schedule ? adaptTDriveScheduleApi(order.schedule) : null,
    vehicle: order.vehicle,
  };
};

export const adaptTDSchedulerAPI = (data: TDSchedulerAPI): TDScheduler => {
  return {
    simpleBookCompanyName: data.simple_book_company_name,
    testDriveCategoryId: data.test_drive_category_id,
  };
};

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { SummaryPanel } from './../OrderCharger/sections';
import { useStores } from '../../hooks/use-stores';
import { StatusBar, StatusHeader } from 'components';
import PaymentForm from './Form';
import config from '../../environment';

import styles from './ChargerPayment.module.scss';
import { standAloneChargerStatusBar } from '../../appConstants';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { id: string };
  };
}

const stripePromise = loadStripe(config.stripeKey);

const ChargerPayment = observer((props: Props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { orderStore, authStore, vehicleStore } = useStores();
  const userId = get(authStore, 'user.id');
  const chargerPrice = sessionStorage.getItem('chargerPackagePrice');

  useEffect(() => {
    orderStore.getCharger();
  }, [orderStore]);

  useEffect(() => {
    if (!authStore.user?.id) {
      authStore.getCurrentUser();
      return;
    }
    orderStore.updateChargerOrder(authStore.user.id, id);
  }, [authStore, authStore.user, id, orderStore, userId, vehicleStore]);

  const updatePaymentIntent = async (paymentMethodID: string) => {
    if (!chargerPrice) return;

    await orderStore.createChargerPaymentIntent({ orderId: id, amount: chargerPrice, currency: 'usd' });

    const paymentIntentID = orderStore.chargerPaymentIntent?.id;
    if (!paymentIntentID) return;
    await orderStore.updateChargePaymentIntent(paymentMethodID, paymentIntentID, id);
  };

  if (!orderStore.charger) return null;
  return (
    <>
      <StatusHeader title="Enter payment information" progress={0} />
      <StatusBar steps={standAloneChargerStatusBar} activeStep={'Finalize Order'} />
      <div className={styles.cContent}>
        <SummaryPanel charger={orderStore.charger} />

        <Elements stripe={stripePromise}>
          <div className={styles.cPaymentForm}>
            <PaymentForm updatePaymentIntent={updatePaymentIntent} />
          </div>
        </Elements>
      </div>
    </>
  );
});
export default ChargerPayment;

import React from 'react';

interface Props {
  extDealerCode: string;
  vin: string;
  msrp: string;
  price: string;
  modelNum: string;
  modelTrim: string;
  stockCode: string;
  extColor: string;
  intColor: string;
  isNew: string;
  miles: string;
}

const ModalFrame = (props: Props) => {
  const { extDealerCode, vin, msrp, price, modelNum, modelTrim, stockCode, extColor, intColor, isNew, miles } = props;

  return (
    <span
      className="drivemotors"
      data-ext-dealer-code={extDealerCode}
      data-vin={vin}
      data-msrp={msrp}
      data-price={price}
      data-model-num={modelNum}
      data-model-trim={modelTrim}
      data-stock-code={stockCode}
      data-ext-color={extColor}
      data-int-color={intColor}
      data-is-new={isNew}
      data-miles={miles}
    />
  );
};

export default ModalFrame;

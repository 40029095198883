import React from 'react';
import styles from './StaticPages.module.scss';

/* eslint-disable no-irregular-whitespace */

const PrivacyPolicy = () => {
  return (
    <div className={styles.cPage}>
      <h1>PRIVACY POLICY</h1>
      <h5>Last Updated: August 12, 2020</h5>
      <p>
        Uplight, Inc. (“<strong>Uplight</strong>,” “<strong>we</strong>,” “<strong>our</strong>,” or “
        <strong>us</strong>”) delivers end-to-end consumer engagement products, applications, and services (the “
        <strong>Services</strong>”) via our open, secure, and scalable platform. This Online Privacy Policy (this “
        <strong>Privacy Policy</strong>”) explains how we may collect, use, and disclose your personal information. This
        Privacy Policy applies to Uplight Services and Uplight’s websites (the “<strong>Sites</strong>”).
      </p>
      <p>
        In some cases, we may act as an agent of the energy service provider with which you have a customer relationship
        (the “<strong>Utility Provider</strong>”).  In such a situation, the Utility Provider has engaged Uplight to
        provide Services. This Privacy Policy does not apply to the non-personal information described below that is
        collected by, used by, disclosed by, or disclosed to your Utility Provider. This Privacy Policy only applies to
        Uplight’s collection, use, and disclosure of personal information. For more information about your Utility
        Provider’s privacy practices please contact your Utility Provider directly.
      </p>
      <h4>1. PERSONAL INFORMATION WE COLLECT</h4>
      <p>
        <strong>Personal Information</strong>. We collect personal information about you both online and offline in
        various ways when you use our Sites or Services. “<strong>Personal information</strong>” as used in this Privacy
        Policy means information that identifies, relates to, describes, is reasonably capable of being associated with,
        or could reasonably be linked, directly or indirectly, with a particular consumer or household. The personal
        information we collect allows us to offer our Services in a personalized and meaningful manner. The following
        are the types of personal information we collect:
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Categories</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Examples of Personal Information Uplight Collects</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Processing Purpose</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>A. Individual Identifiers and Demographic Information</p>
            </td>
            <td>
              <p>
                A real name, postal address, online identifier, Internet Protocol address, email address, account name,
                or other similar identifiers such as phone number, zip code, google pay accounts.
              </p>
            </td>
            <td>
              <p>
                ·     Create Analytic Reports (internal that inform our service to enhance our products and Services)
              </p>
              <p>·     Purchase transactions</p>
              <p>·     Shipment/Fulfillment</p>
              <p>·     Create Analytic Reports back to our Utility Partners (Home Energy Reports)</p>
              <p>·     Provide Our Products, Services, or Home Energy Reports</p>
              <p>·     Improve Our Products, Services, and Sites</p>
              <p>·     Understand Your Interests</p>
              <p>·     Process Your Payment for Products and Services</p>
              <p>·     Respond to Your Requests or Questions</p>
              <p>·     Determine Your Eligibility and Set Up Your Account</p>
              <p>·     Market Products or Services for Utility Partners</p>
              <p>·     Secure the Services and Sites</p>
              <p>·     Communicate with You</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>B. Commercial Information</p>
            </td>
            <td>
              <p>
                Records of energy management devices and appliances, products or services purchased, obtained, or
                considered, or other purchasing or consuming histories or tendencies.
              </p>
            </td>
            <td>
              <p>
                ·     Create Analytic Reports (internal that inform our service to enhance our products and Services)
              </p>
              <p>·     Purchase transactions</p>
              <p>·     Shipment/Fulfillment</p>
              <p>·     Create Analytic Reports back to our Utility Partners (external)</p>
              <p>·     Provide Our Products, Services, or Home Energy Reports</p>
              <p>·     Improve Our Products, Services, and Sites</p>
              <p>·     Understand Your Interests</p>
              <p>·     Market Products or Services for Utility Partners</p>
              <p>·     Communicate with You for Utility Partners</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>C. Internet of Network Activity</p>
            </td>
            <td>
              <p>
                Browsing history, search history, information on an individual’s interaction with a website,
                application, or advertisement.
              </p>
            </td>
            <td>
              <p>·     Provide Our Products, Services, or Home Energy Reports</p>
              <p>·     Improve Our Products, Services, and Sites</p>
              <p>·     Understand Your Interests</p>
              <p>·     Market Products or Services for Utility Partners</p>
              <p>Communicate with You for Utility Partners</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>D. Home Information</p>
            </td>
            <td>
              <p>
                Information collected in connection with your home, building, or dwelling, home, building, or dwelling
                images, energy services received, service requests, and other home, building, or dwelling related
                information.
              </p>
            </td>
            <td>
              <p>·     Provide Our Products, Services, or Home Energy Reports</p>
              <p>·     Improve Our Products, Services, and Sites</p>
              <p>·     Understand Your Interests</p>
              <p>·     Market Products or Services for Utility Partners</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>E. Energy Usage Information</p>
            </td>
            <td>
              <p>Energy usage information from energy management system, devices, or appliances.</p>
              <p>&nbsp;</p>
            </td>
            <td>
              <p>·     Provide Our Products, Services, or Home Energy Reports</p>
              <p>·     Improve Our Products, Services, and Sites</p>
              <p>·     Understand Your Interests</p>
              <p>·     Market Products or Services for Utility Partners</p>
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>F. Inferences Drawn from Personal Information</p>
            </td>
            <td>
              <p>Inferences regarding energy use and behaviors.</p>
            </td>
            <td>
              <p>·     Provide Our Products, Services, or Home Energy Reports</p>
              <p>·     Improve Our Products, Services, and Sites</p>
              <p>·     Understand Your Interests</p>
              <p>·     Market Products or Services for Utility Partners</p>
              <p>&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Non-Personal Information</strong>. Personal information does not include:
      </p>

      <ul>
        <li>
          <u>Publicly Available Data</u>: Publicly available information from government records.
        </li>
        <li>
          <u>Deidentified Information or Aggregate Information</u>: “<strong>Deidentified Information</strong>” means
          information that cannot reasonably identify, relate to, describe, be capable of being associated with, or be
          linked, directly or indirectly, to a particular individual, and for which Uplight has implemented technical
          safeguards and business processes that prohibit reidentification of the individual. “
          <strong>Aggregate Information</strong>” means information that relates to a group or category of individuals,
          from which individual identities have been removed, that is not linked or reasonably linkable to any
          individual or household, including via a device.
        </li>
        <li>
          <u>Excluded Information</u>: Certain laws require separate privacy notices or are exempt from general personal
          information privacy policy disclosure requirements. Such laws include the Fair Credit Reporting Act (FCRA).
        </li>
      </ul>

      <p>
        <strong>Sources of Personal Information</strong>. We obtain the categories of personal information listed above
        on or through our Services and Sites from the following categories of sources:
      </p>

      <ul>
        <li>
          <u>Personal Information You Give Us</u>: We receive the personal information you provide to us such as your
          name, email address, phone number, address, and home profile. Our Services offer you the opportunity to create
          a profile and submit questions and comments that may be visible to other users of the Services. The
          information you provide in areas that are visible by other logged-in users of the Services may be read,
          collected, and used by others who access them. You can choose not to provide certain information, but that may
          limit your ability to take full advantage of the Services we provide. We may also collect personal information
          that is necessary for our legitimate interests, which will be disclosed to you at the time of collection.
          Uplight will use this personal information for the purposes of which it was collected.
        </li>
        <li>
          <u>Automatically Collected Information</u>: We receive and store certain types of information from you every
          time you use the Sites or Services. For example we may automatically log your browser type, language
          preference, the date and time of your visit to the Sites, your Internet Protocol (IP) address, and the website
          you visited before our Sites. In addition, we receive and store information about your use and activity on our
          Sites and within our Services.  Uplight collects personal information automatically using cookies or other
          online tracking technologies as described in our Cookies Policy, available at{' '}
          <a href="https://uplight.com/cookie-policy/" target="_blank" rel="noreferrer noopener">
            https://uplight.com/cookie-policy/
          </a>
          .
        </li>
        <li>
          <u>Personal Information from Devices and Appliances</u>: We may receive and store energy usage information
          directly from your energy management system, devices, or appliances to create a profile of your energy usage
          (a “<strong>Home Energy Report</strong>”). For example, we may receive and securely store the times at which
          your household energy use is at its highest or lowest, and may store your settings on certain Home Area
          Network appliances such as a smart thermostat.
        </li>
        <li>
          <u>Personal Information from Your Utility Provider</u>: We may receive and store certain personal information
          about you from your Utility Provider. For example, your Utility Provider may provide us your historic energy
          usage information so that we may present more meaningful information to you and personalize Services in a
          meaningful manner.
        </li>
      </ul>

      <h4>2. HOW WE USE THE PERSONAL INFORMATION WE COLLECT</h4>

      <p>
        We use and disclose your personal information only as described in this Privacy Policy or as disclosed to you on
        our Sites or within our Services. For example, we may use your personal information:
      </p>
      <ul>
        <li>
          <strong>To Provide Our Products, Services, or Home Energy Reports</strong>.  This could include fulfilling
          your requests for products, Services, and Home Energy Reports. It could also include processing purchases or
          other transactions.  For example, if you share your personal information to request a price quote or ask a
          question about our Services, we will use that personal information to respond to your inquiry.  If you provide
          your personal information to purchase a product, Service, or Home Energy Report, we will use such information
          to process your payment and facilitate delivery.  We may also save your personal information to facilitate new
          product or service orders, or process returns.
        </li>
        <li>
          <strong>To Improve Our Products, Services, and Sites</strong>.  We use personal information to understand and
          analyze the usage trends and preferences of our users to make our products, Services or Sites better, diagnose
          technical issues, prevent fraud, and develop new features and functionality. 
        </li>
        <li>
          <strong>To Understand Your Interests</strong>.  We use your personal information to personalize your
          experience on with the Services and Sites, and to deliver information about Services relevant to your
          interests. 
        </li>
        <li>
          <strong>To Process Your Payment for Products and Services</strong>. We use personal information to facilitate
          payment for products and Services that you select through our Sites.
        </li>
        <li>
          <strong>To Respond to Your Requests or Questions</strong>.  We use personal information to provide you with
          support and to respond to your requests for customer support, including to investigate and address your
          concerns and monitor and improve our responses.  This may also include responding to your feedback.
        </li>
        <li>
          <strong>To Determine Your Eligibility and Set Up Your Account</strong>.  We use your personal information to
          verify your identity or determine your eligibility for some of our products, services, and promotions.  For
          example, this may include verifying your age, date of birth, and place of residence.  If you set up an account
          through the Services on any Site, we will use the registration information you provide in relation to that
          account creation to create, maintain, customize, and secure your account. 
        </li>
        <li>
          <strong>To Market Products or Services</strong>.  We provide you with information and promotions about
          products and Services.  For example, we may use cookies or other online tracking technologies to provide
          customized advertisements, content, and information; monitor and analyze the effectiveness of marketing
          activities; and track your entries, submissions, and status in any promotions.  These might be third party
          offers we think you might find interesting.  These communications may come in the form of interest-based
          advertising using information gathered across multiple websites, devices, or other platforms. 
        </li>
        <li>
          <strong>To Secure the Services and Sites</strong>.  We use personal information to maintain the safety,
          security, and integrity of our Services, Sites, databases, and other technology assets, business, and other
          users.
        </li>
        <li>
          <strong>To Communicate with You</strong>.  We may communicate with you about your account or our
          relationship.  We may also contact you about this Privacy Policy.
        </li>
        <li>
          <strong>As Required by Law</strong>.  We use personal information to respond to requests from law enforcement
          and as required by applicable law, court order, or government investigation.
        </li>
      </ul>
      <p>
        We may create non-personal Deidentified Information or Aggregate Information from the information we collect, as
        permitted by law. We may use that data for our own purposes or disclose it in a non-personally identifiable
        manner to:
      </p>
      <ul>
        <li>Advertisers and third parties for their marketing and promotional purposes;</li>
        <li>
          Organizations and government entities that conduct research into energy usage or smart-grid technologies;
        </li>
        <li>Other users of the Services for purposes of comparing energy usage to the broader community; or</li>
        <li>Other interested third parties.</li>
      </ul>

      <h4>3. HOW WE SHARE THE PERSONAL INFORMATION WE COLLECT</h4>

      <p>
        In addition to the specific situations discussed elsewhere in this Privacy Policy, Uplight may share your
        personal information in the following circumstances:
      </p>
      <ul>
        <li>
          <strong>Service Providers</strong>. To third party service providers (i.e., device manufacturers, website
          hosting providers, payment processors, log monitoring analysts, forensic data scientists, threat monitoring
          aggregators, user data analysts, marketing assistants, and other service providers for legitimate purposes
          permitted by applicable law) that provide services to us and are contractually bound to safeguard your
          personal information, in which case Uplight will remain liable for the acts and/or omissions of such third
          party service providers with respect to the processing of your personal information.
        </li>
        <li>
          <strong>Partners</strong>. Via our cloud-based platform, we collect information that allows us to provide the
          Services.  In some cases, we may collect this information at the direction of partner companies (“
          <strong>Partners</strong>”), and we may have no direct relationship with the individuals whose personal
          information we process.  In these cases, we work with our Partners to help them provide notices concerning the
          purpose for which personal information is collected and used.  If you are a customer of one of our Partners
          and would no longer like to be contacted by that Partner or seek access to the personal data we have collected
          at the direction of the Partner, please contact that Partner directly.    Uplight may transfer personal
          information to companies that help us provide our Services. Transfers to subsequent third parties are covered
          by the provisions of this Privacy Policy regarding notice and choice and the service agreements with our
          Partners.  Uplight will retain personal information we process on behalf of our Partners for as long as needed
          to provide the Services.  Uplight will retain and use this personal information as necessary to comply with
          our legal obligations, resolve disputes, and enforce our agreements.  Certain sections of www.uplight.com
          utilize framing and/or masking techniques to serve content from our Partners while preserving the look and
          feel of our Site.  Please be aware that you are providing your personal information to these third parties and
          not to Uplight.
        </li>
        <li>
          <strong>Utility Providers</strong>. To your Utility Provider for their own purposes and subject to their
          privacy policy and practices.
        </li>
        <li>
          <strong>Social Media Sites</strong>. Certain sections of our Sites may include social media sites.  These
          social media sites may collect your IP address, which page you are visiting on our Site, and may set a cookie
          to enable the social media site features to function properly.  Your interactions with these social media
          sites are governed by the privacy policy of the social media site.
        </li>
        <li>
          <strong>Related Companies</strong>. To our corporate subsidiaries and divisions (i.e., affiliates, parent
          company, sister companies, joint ventures, or other companies under common control) listed at the end of this
          Privacy Policy.
        </li>
        <li>
          <strong>M&amp;A Transactions</strong>. To third parties in connection with any merger, acquisition,
          reorganization, change in capital structure, or sale of all or part of the business or any sale or transfer of
          assets of Uplight.
        </li>
        <li>
          <strong>Legal Compliance</strong>. When we have a good faith belief that disclosure is necessary to comply
          with law, for national security reasons, to protect or defend our rights or the rights of other users of our
          Sites or Service, to enforce our contracts, or act in an emergency to protect someone’s safety.
        </li>
        <li>
          <strong>Consent</strong>. Otherwise with your express consent.
        </li>
      </ul>

      <h4>4. DATA RETENTION</h4>
      <p>
        We will retain your personal information for as long as your account is active, as needed to provide you
        Services, and as necessary to comply with our legal obligations, to resolve disputes, and to enforce our
        agreements. If you wish to cancel your account or request that we no longer use your personal information,
        contact us using the contact information provided below.
      </p>

      <h4>5. SECURITY OF PERSONAL INFORMATION</h4>
      <p>
        We take reasonable steps to help protect your personal information in an effort to prevent loss, misuse,
        unauthorized access, disclosure, alteration and destruction.  We follow reasonable standards to protect the
        personal information submitted to us, both during transmission and once we receive it.  However, no method of
        transmission over the Internet, or method of electronic storage is 100% secure and we do not guarantee its
        absolute security.  If you have any questions about the security of our Sites or Services, you can contact
        privacy@uplight.com. In the event that we are required by law to inform you of any unauthorized access or
        acquisition of your personal information we may notify you electronically, in writing, or by telephone, if
        permitted to do so by law.
      </p>

      <h4>6. ACCESS, CHANGE, AND DELETION</h4>
      <p>You can make the following choices regarding your personal information:</p>
      <ul>
        <li>
          <strong>Access to Your Personal Information</strong>. We make reasonable efforts to allow you to make
          inquiries about the personal information we collect from you, and when appropriate we allow you access to your
          personal information for review and updating.  You may request access to your personal information by
          contacting us as described below.  We will grant you reasonable access to the data that we have about you as
          required by law. 
        </li>
        <li>
          <strong>Changes to Your Personal Information</strong>. We rely on you to update and correct the personal
          information contained in your account.  If you have an account with us, you can control preferences regarding
          the use and disclosure of your personal information on your user profile.  Note that we may keep historical
          information in our backup files as permitted by law.  If our Site does not permit you to update or correct
          certain personal information, please contact us as described below.
        </li>
        <li>
          <strong>Deletion of Your Personal Information</strong>. Typically, we retain your personal information for the
          period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is
          required or permitted by law.  You may, however, request that we delete your personal information by
          contacting us contacting us as described below.  We will grant a request to delete information as required by
          law, but you should note that in many situations we must keep your personal information to comply with our
          legal obligations, resolve disputes, enforce our agreements, or for another one of our business purposes. 
          Except as provided above, we will delete, aggregate, or de-identify all of your personal information as
          described in this subsection within the timeframes required by law.
        </li>
      </ul>

      <h4>7. YOUR CHOICES</h4>
      <p>You can make the following choices to opt out of certain activities regarding your personal information:</p>
      <ul>
        <li>
          <strong>Promotional E-mails</strong>. You may choose to provide us with your e-mail address for the purpose of
          allowing us to send newsletters, surveys, offers, and other promotional materials related to our Services and
          Site, as well as targeted offers from third parties.  You can stop receiving promotional e-mails by clicking
          the “
          <a
            href="http://go.uplight.com/emailPreference/e/epc/796823/F9qATRMxzsMmah4d9aEoBFk4TXW1YYL_rQQDlEADXJE/227"
            target="_blank"
            rel="noreferrer noopener"
          >
            unsubscribe
          </a>
          ” links in the e-mails or by contacting us as described below.  If you wish to unsubscribe from newsletters or
          marketing emails, follow the unsubscribe instructions included in these emails.  If you decide not to receive
          promotional emails, we may still send you transactional communications, such as those about your account or to
          ask you to rate a Service you have requested.
        </li>
        <li>
          <strong>Behavioral-Based Advertising</strong>. We participate in behavioral-based advertising.  This means
          that a third party may use technology (e.g., a cookie) to collect information about your use of our Sites so
          that we can provide advertising about products and Services tailored to your interest.  That advertising may
          appear either on our Site, or on other websites.  If you wish to limit third parties’ collection of
          information about your use of our Site, you can opt-out of such at the{' '}
          <a href="http://optout.aboutads.info/" target="_blank" rel="noreferrer noopener">
            Digital Advertising Alliance
          </a>{' '}
          in the US, the{' '}
          <a href="https://youradchoices.ca/choices/" target="_blank" rel="noreferrer noopener">
            Digital Advertising Alliance of Canada
          </a>{' '}
          in Canada, or the{' '}
          <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer noopener">
            European Digital Advertising Alliance
          </a>{' '}
          in Europe.  PLEASE NOTE THAT OPTING-OUT OF BEHAVIORAL ADVERTISING DOES NOT MEAN THAT YOU WILL NOT RECEIVE
          ADVERTISING WHILE USING THE SITE.  IT WILL, HOWEVER, EXCLUDE YOU FROM INTEREST-BASED ADVERTISING CONDUCTED
          THROUGH PARTICIPATING NETWORKS, AS PROVIDED BY THEIR POLICIES AND CHOICE MECHANISMS.
        </li>
        <li>
          <strong>Do-Not-Track</strong>. Some web browsers and devices permit you to broadcast a preference that you not
          be “tracked” online.  At this time we do not modify your experience based upon whether such a signal is
          broadcast.
        </li>
      </ul>

      <h4>8. RESIDENTS OF CALIFORNIA</h4>
      <p>The following applies to residents of California.</p>
      <p>
        <strong>Notice of Disclosure for California Residents</strong>. Uplight must disclose whether the following
        categories of personal information are disclosed for a “business purpose” or “valuable consideration” as those
        terms are defined under California law.  Note that while a category below may be marked, that does not
        necessarily mean that we have personal information in that category about you.  In the preceding twelve months,
        we have disclosed the following categories of personal information in the manner described.
      </p>

      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Category</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Personal Information is Disclosed for a Business Purpose</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Personal Information is Disclosed for Valuable Consideration</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>A. Individual Identifiers and Demographic Information</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>No</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>B. Commercial Information</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>No</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>C. Internet of Network Activity</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>No</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>D. Home Information</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>No</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>E. Energy Usage Information</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>No</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>F. Inferences Drawn from Personal Information</p>
            </td>
            <td>
              <p>Yes</p>
            </td>
            <td>
              <p>No</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Notice of Disclosure for Direct Marketing</strong>. Under California Civil Code sections
        1798.83-1798.84, California residents who have an established business relationship with Uplight are entitled to
        ask us for a notice describing what categories of personal information we share with third parties for their
        direct marketing purposes.  This notice will identify the categories of information shared with and will include
        a list of the third parties with which it is shared, along with their names and addresses.  If you are a
        California resident and would like a copy of this notice, please submit a written request to us at
        privacy@uplight.com.
      </p>

      <h4>9. PRIVACY SHIELD</h4>
      <p>
        Uplight complies with the EU-U.S. Privacy Shield Program (“Privacy Shield”) and its principles as set forth by
        the US Department of Commerce and the European Commission regarding the collection, use, and retention of
        personal data from EU member states. Uplight has certified to the Department of Commerce that it adheres to the
        Privacy Shield Principles. Our collection, use, and disclosure of personal information transferred pursuant to
        the Privacy Shield is subject to the investigative and enforcement powers of the Federal Trade Commission. If
        there is any conflict between the terms in this Privacy Policy and the Privacy Shield Principles, the Privacy
        Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification,
        please visit 
        <a href="https://www.privacyshield.gov/" target="_blank" rel="noreferrer noopener">
          https://www.privacyshield.gov/
        </a>
        .
      </p>
      <p>
        In compliance with the Privacy Shield principles, Uplight commits to resolve complaints about our collection or
        use of your personal information. EU individuals with inquiries or complaints regarding our Privacy Shield
        policy should first contact our privacy and security personnel at{' '}
        <a href="mailto:privacy@uplight.com" target="_blank" rel="noreferrer noopener">
          privacy@uplight.com
        </a>
        . If your question or concern is not satisfied through this process Uplight has further committed to refer
        unresolved Privacy Shield complaints to JAMS, an independent dispute resolution provider located in the United
        States.  Information about how to file a complaint with JAMS related to our Privacy Shield program can be found
        at{' '}
        <a
          href="https://nam03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.jamsadr.com%2Feu-us-privacy-shield&amp;data=02%7C01%7Cjill.joerling%40maritz.com%7C7b62bf230cfa414ab4d108d6ca4c0a1c%7C6cf8654c45df474480e53c1656731b8e%7C0%7C0%7C636918825280545934&amp;sdata=1l2oiP8AgeOSE5vYeM0MkXj%2BJCsjAP6Y9v%2BqBLKUyg0%3D&amp;reserved=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://www.jamsadr.com/eu-us-privacy-shield
        </a>
        .  The services of JAMS are provided at no cost to you. 
      </p>
      <p>
        For residual Privacy Shield disputes that cannot be resolved by the methods above, you may be able to invoke a
        binding arbitration process under certain conditions. To find out more about the Privacy Shield’s binding
        arbitration scheme, please see:{' '}
        <a
          href="http://www.privacyshield.gov/article?id=ANNEX-I-introduction"
          target="_blank"
          rel="noreferrer noopener"
        >
          www.privacyshield.gov/article?id=ANNEX-I-introduction
        </a>
        .
      </p>

      <h4>10. RESIDENTS OF THE EUROPEAN ECONOMIC AREA</h4>
      <p>The following applies to individuals in the European Economic Area (EEA).</p>
      <p>
        <strong>Lawful bases for processing</strong>. Uplight is required to inform you of the lawful bases of our
        processing of your personal information, which are described in the table below.  If you have questions about
        the lawful basis of how we process your personal information, contact us at the address listed in the section
        titled “CONTACTING US” below.
      </p>

      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Processing Purpose</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Lawful Basis</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>Collection of Sensitive Personal Information.</li>
                <li>To Provide Our Products, Services, or Home Energy Reports.</li>
                <li>To Market Products or Services. </li>
              </ul>
            </td>
            <td>
              <p>
                <u>Processing is based on your consent</u>. Where we rely on your consent you have the right to withdraw
                it anytime in the manner indicated on the Site or through the Services.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>To Provide Our Products, Services, or Home Energy Reports.</li>
                <li>To Communicate with You.</li>
                <li>To Understand Your Interests.</li>
                <li>To Process Your Payment for Products and Services.</li>
                <li>To Respond to Your Requests or Questions.</li>
                <li>To Determine Your Eligibility and Set Up Your Account. </li>
              </ul>
            </td>
            <td>
              <p>
                <u>Processing is necessary for the performance of a contract</u>.  You are subject to a contract with us
                and we need to use your personal information to provide products and Services.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>To Secure the Services and Sites.</li>
                <li>As Required by Law.</li>
              </ul>
            </td>
            <td>
              <p>
                <u>Processing is necessary to comply with our legal obligations</u>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>To Improve Our Products, Services, and Sites.</li>
                <li>To Understand Your Interests.</li>
                <li>To Market Products or Services.</li>
                <li>To Secure the Services and Sites.</li>
                <li>To Use Personal Information as Otherwise Described in this Privacy Policy.</li>
              </ul>
            </td>
            <td>
              <p>
                <u>Processing activities constitute our legitimate interests</u>.  We consider and balance the potential
                impact on your rights before we process your personal information for our legitimate interests.  We do
                not use your personal information for activities where our interests are overridden by the impact on you
                (unless we have your consent, or we are otherwise required to perform a contract or by law).
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        We will use your personal information only for the purposes for which we collected it, unless we reasonably
        determine we need to use it for another reason and that reason is compatible with the original purpose.  For
        example, we consider deidentification, aggregation, and anonymization of personal information to be compatible
        with the purposes listed above and in your interest, because the deidentification, aggregation, and
        anonymization of such information reduces the likelihood of improper disclosure of that information.
      </p>

      <p>
        PLEASE NOTE WE MAY PROCESS YOUR PERSONAL INFORMATION WITHOUT YOUR KNOWLEDGE OR CONSENT, IN COMPLIANCE WITH THE
        ABOVE RULES, WHERE THIS IS REQUIRED OR PERMITTED BY LAW.
      </p>

      <p>
        <strong>Your Rights</strong>. European data protection laws give you certain rights regarding your personal
        information.  You may ask us to take the following actions in relation to your personal information that we
        hold:
      </p>

      <ul>
        <li>
          <u>Access</u>. Provide you with information about our processing of your personal information and give you
          access to your personal information.
        </li>
        <li>
          <u>Transfer</u>. Transfer a machine-readable copy of your personal information to you or a third party of your
          choice.
        </li>
        <li>
          <u>Correct</u>. Update or correct inaccuracies in your personal information.
        </li>
        <li>
          <u>Restrict</u>. Restrict the processing of your personal information.
        </li>
        <li>
          <u>Object</u>. Object to our reliance on our lawful basis as the basis of our processing of your personal
          information that impacts your rights.
        </li>
        <li>
          <u>Delete</u>. Delete your personal information.
        </li>
      </ul>

      <p>
        When we receive your request, we may ask you to verify your identity before we can act on your request. We may
        withhold information where we are required by law to do so or if the search for that information would require
        disproportionate effort or have a disproportionate effect to, for example, the cost of providing the
        information, the time it would take to retrieve the data, or how difficult it may be to obtain the information
        requested.
      </p>

      <p>
        If you are a resident EEA and would like to exercise any of these rights, please submit your request to the
        address listed below.  If you would like to submit a complaint about our use of your personal information or
        response to your requests regarding your personal information, you may contact us or submit a complaint to the
        data protection authority in your jurisdiction.  You can find your data protection regulator{' '}
        <a
          href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </a>
        . 
      </p>

      <h4>11. EXERCISING YOUR PRIVACY RIGHTS</h4>
      <p>When exercising the rights or options described in this Privacy Policy, the following guidelines apply:</p>
      <ul>
        <li>
          <strong>No Fee Usually Required</strong>. You will not have to pay a fee to access your personal information
          (or to exercise any of the other rights).  However, we may charge a reasonable fee or decline to comply with
          your request if your request is clearly unfounded, repetitive, or excessive.
        </li>
        <li>
          <strong>What We May Need from You</strong>. When exercising your rights or otherwise assisting you, we may
          need to request specific information from you to help us confirm your identity.  This is a security measure to
          ensure we do not disclose personal information to any person who is not entitled to receive it.   We may also
          contact you to ask you for further information in relation to your request to speed up our response.
        </li>
        <li>
          <strong>Time to Respond</strong>. We try to respond to all legitimate requests within 30 days of your
          request.  Occasionally it may take us longer than 30 days to respond, for instance if your request is
          particularly complex or you have made a number of requests.  In this case, we will notify you of the delay,
          and may continue to update you regarding the progress of our response.
        </li>
        <li>
          <strong>No discrimination</strong>. You will not be subject to discrimination as a result of exercising the
          rights described herein.   In some cases, when you exercise one of your rights, we will be unable to comply
          with the request due to legal obligations or otherwise, or we will be unable to provide you certain products
          or Services.  These responses are not discrimination and our reasons for declining your request or ceasing
          Services will be provided at that time.
        </li>
        <li>
          <strong>Authorized Agent</strong>. You may designate an authorized agent to make a request on your behalf.  In
          order to designate an authorized agent to make a request on your behalf, you must provide a valid power of
          attorney, the requester’s proof of identification, and the authorized agent’s proof of identification.
        </li>
      </ul>

      <h4>12. OTHER WEBSITES</h4>
      <p>
        The Sites and Services may contain links, content, and advertisements from third parties.  Such links, content,
        and advertisements are not endorsements, authorizations, or representations of our affiliation with that third
        party.  We are not responsible for the privacy practices or the content of third parties, and we encourage you
        to carefully review the privacy policy of any website you visit.
      </p>

      <h4>13. CHANGES TO THIS PRIVACY POLICY</h4>
      <p>
        This Privacy Policy is subject to occasional revision without advance notice. If we make any changes in the way
        we use your personal information, we will notify you via email and/or post the modification on the Sites or
        within the Services prior to the change becoming effective. We will also update the “Last Updated” date in this
        Privacy Policy.
      </p>

      <h4>14. ADDITIONAL INFORMATION</h4>
      <p>
        For more information about this Privacy Policy or our privacy practices generally, please contact us at 
        <a href="mailto:privacy@uplight.com" target="_blank" rel="noreferrer noopener">
          privacy@uplight.com
        </a>
        .
      </p>
      <p>
        Uplight, Inc. 
        <br />
        2580 55th Street, Suite 100 
        <br />
        Boulder, CO 80301 
      </p>
      <p>
        To exercise your rights as described in this Privacy Policy, please download this{' '}
        <a
          href="https://uplight.com/wp-content/uploads/2020/02/Uplight-Privacy-Request-Form-1.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          PDF
        </a>
        .
      </p>
      <p>This Privacy Policy encompasses the Uplight family of companies:</p>
      <p>
        UPLIGHT, INC.
        <br />
        TENDRIL EA, LLC
        <br />
        TENRDIL OE, LLC 
        <br />
        ENERGYSAVVY INC.
        <br />
        FIRSTFUEL SOFTWARE, INC.
        <br />
        SIMPLE ENERGY, INC.
      </p>
      <p>ECOTAGIOUS INC.</p>
      <p>FIRST FUEL EMEA LIMITED</p>
      <p>TENDRIL AUSTRALIA PTY LIMITED</p>
      <p>TENDRIL INTERNATIONAL B.V</p>
      <p>TENDRIL NETWORKS UK, LTD.</p>
      <p>
        For inquiries in the European Union, please contact our GDPR Representative Tendril International B.V. <br />
        Attn: GDPR Services Team <br />
        Prins Bernhardplein 200 <br />
        1097 JB Amsterdam
      </p>
      <p>The Netherlands</p>
      <p>Email: GDPR-services@intertrustgroup.com</p>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useEffect } from 'react';
import { Loader, NoData, TDScheduler } from 'components';
import styles from './TestDriveScheduler.module.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
const TestDriveScheduler = observer((props: any) => {
  const {
    match: {
      params: { orderId },
    },
  } = props;
  const { orderStore } = useStores();

  useEffect(() => {
    async function getInitialData() {
      await orderStore.getTDriveOrderById(orderId);
      if (orderStore.currentTDriveOrder) {
        await orderStore.getTestDriveScheduler(orderStore.currentTDriveOrder.vehicle.id);
      }
    }
    getInitialData();
  }, [orderStore, orderId]);

  if (orderStore.loading) return <Loader />;
  if (!orderStore.currentTDriveCalendarId) return <NoData />;
  return (
    <div className={styles.cPage}>
      <div className={styles.cContent}>
        <h1>Schedule test drive</h1>

        {orderStore.currentTDScheduler?.testDriveCategoryId && orderStore.currentTDScheduler?.simpleBookCompanyName && (
          <TDScheduler
            categoryId={orderStore.currentTDScheduler?.testDriveCategoryId}
            orderId={orderId}
            userInfo={{
              firstName: orderStore.tdUserInfo.firstName || '',
              lastName: orderStore.tdUserInfo.lastName || '',
              phone: orderStore.tdUserInfo.phone || '',
              email: orderStore.tdUserInfo.email || '',
            }}
          />
        )}
      </div>
    </div>
  );
});

export default TestDriveScheduler;

export default {
  ENV: 'dev',
  apiUrls: {
    auth: '/api/v1.0',
    vehicle: '/api/v1.0',
  },
  googleAnalyticsId: 'UA-180841530-1',
  appStoreLink: 'https://apps.apple.com/us/app/motor-drive/id1501427414',
  playMarketLink: 'https://play.google.com/store/apps/details?id=com.driveclutch.motordrive',
  stripeKey: 'pk_test_D94xLfLA7htTEye7pjkf8wCr00w8BGqE3s',
  chargemapAPIKey: '20b40a23-9d43-46fb-94bf-79321ca80d4c',
  simplyBook: {
    companyName: 'aesmotor',
    orderCategoryId: '3',
    fieldOrderId: 'aff2f67dd6de3229af5ae7a39341d433',
  },
  enableLoanPayment: true,
  enableTestDrive: false,
};

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './NavigationBar.module.scss';

interface NavigationBarProps {
  redirectURL?: string;
  linkText: string;
  goBack?: boolean;
}
const NavigationBar = (props: NavigationBarProps) => {
  const history = useHistory();

  const handleClick = (e: React.MouseEvent) => {
    if (props.goBack) {
      e.preventDefault();
      history.go(-1);
    }
  };
  return (
    <div className={styles.cContainer}>
      <Link onClick={handleClick} to={props.redirectURL ? props.redirectURL : '/'}>
        <img src="/images/back-arrow.svg" alt="" /> {props.linkText}
      </Link>
    </div>
  );
};
export default NavigationBar;

import React, { useEffect, ChangeEvent } from 'react';
import { Field, Formik, FormikHelpers, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import FormikErrorFocus from 'formik-error-focus';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useStores } from '../../hooks/use-stores';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { setFinalPrice } from 'helpers/formatters/money';
import { Button, Input, RadioCarLabel, CustomSelect, Loader } from 'components';
import { formValues, schema } from './validationSchema';
import InputMask from 'react-input-mask';
import { getError, states, AESMapUrl } from 'appConstants';
import styles from './TestDrive.module.scss';
import { TestDriveVehicle } from '../../types/TestDrive';

const TestDrive = observer(() => {
  const { orderStore, authStore, vehicleStore } = useStores();

  useEffect(() => {
    vehicleStore.getTestDriveVehicles();
  }, [vehicleStore, orderStore]);

  const handleSubmit = async (values: formValues, actions: FormikHelpers<formValues>) => {
    const { firstName, middleName = '', lastName, dob, driveLicense, state, email, phone, vehicles } = values;
    actions.setStatus();
    actions.setStatus({ fetchError: '', loading: true });

    const order = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      driverLicense: driveLicense,
      state: state,
      dateOfBirthday: moment(dob).format('YYYY/MM/DD'),
      email: email,
      phone: phone,
      vehicle: vehicles.toString(),
    };

    await orderStore.placeTDriveOrder(values.phone, order, authStore.isSignedIn);

    if (!authStore.isSignedIn) {
      authStore.sendPhone({ phone });
    }
  };

  const initialValues = {
    firstName: sessionStorage.getItem('testDriveForm/firstName') || '',
    lastName: sessionStorage.getItem('testDriveForm/lastName') || '',
    middleName: sessionStorage.getItem('testDriveForm/middleName') || '',
    driveLicense: sessionStorage.getItem('testDriveForm/driveLicense') || '',
    state: sessionStorage.getItem('testDriveForm/state') || '',
    dob: sessionStorage.getItem('testDriveForm/dob') || '',
    email: sessionStorage.getItem('testDriveForm/email') || '',
    phone: sessionStorage.getItem('testDriveForm/phone') || '',
    vehicles: vehicleStore.testDriveVehicles[0]?.id,
    isFairCredit: false,
    isBackgroundInvestigation: false,
  };

  const renderCarsList = (formik: FormikValues, vehicles: TestDriveVehicle[]) => {
    return vehicles.map((vehicle, index) => {
      const { id, model, visualSet, fullTestDrivePrice, quickTestDrivePrice } = vehicle;
      return (
        <div key={id}>
          <input
            id={`vehicle${id}`}
            name="vehicles"
            type="radio"
            checked={formik.values.vehicles === id}
            onChange={() => {
              formik.setFieldValue('vehicles', id);
            }}
          />
          <RadioCarLabel
            inputId={`vehicle${id}`}
            itemTitle={model}
            description={`30 min for ${setFinalPrice(quickTestDrivePrice.toString())} \n 24h for ${setFinalPrice(
              fullTestDrivePrice.toString()
            )}`}
            image={visualSet.backgroundSmall || ''}
          />
        </div>
      );
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} enableReinitialize={true}>
      {formik => {
        const { values, errors, touched, handleChange, handleSubmit } = formik;
        const onChangeInput = (e: ChangeEvent<HTMLInputElement>, fieldName: string, isCheckbox = false) => {
          handleChange(e);
          sessionStorage.setItem(`testDriveForm/${fieldName}`, isCheckbox ? `${e.target.checked}` : e.target.value);
        };

        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.bgHead}>
              <div className={styles.desktopWidth}>
                <h1>
                  <strong>Take one out for a spin</strong>
                </h1>
              </div>
            </div>
            <div className={classNames(styles.cContent, styles.desktopWidth, styles.cHorOffsetsMobile)}>
              <h4 className={styles.topTitle}>
                Once you get behind the wheel of an electric car, you’ll never want to go to the pump again.
              </h4>
              <h4 className={styles.secondTitle}>We offer two types of test drives:</h4>

              <div className={styles.cPrice}>
                <div className={classNames(styles.priceItem)}>
                  <div className={styles.caption}>
                    quick and {setFinalPrice(vehicleStore.testDriveVehicles[0]?.quickTestDrivePrice.toString())}
                  </div>
                  <div className={styles.price}>30 min</div>
                </div>

                <div className={styles.splitter} />

                <div className={classNames(styles.priceItem)}>
                  <div className={styles.caption}>
                    Full day for {setFinalPrice(vehicleStore.testDriveVehicles[0]?.fullTestDrivePrice.toString())}
                  </div>
                  <div className={styles.price}>24h</div>
                </div>
              </div>

              <h2 className={styles.textLeft}>How it Works</h2>
              <ol>
                <li>Schedule a time for a hassle-free test drive. </li>
                <li>
                  Pick up your car at the{' '}
                  <a href={AESMapUrl} target="_blank" rel="noreferrer noopener">
                    AES Indiana office
                  </a>{' '}
                  and we’ll provide a contactless handoff for you to try out the car.
                </li>
                <li>Bring it back after 30 minutes or 24 hours.</li>
              </ol>

              <h2 className={styles.textLeft}>Let’s get started</h2>

              <div className={styles.cForm}>
                <div className={styles.formOffset}>
                  <h4>We’ll need some basics to lock you in:</h4>
                  <div>
                    <p className={styles.pInfo}>
                      <span
                        className={styles.iconInfo}
                        data-tip="Motor EV LLC (the “Company”) has engaged Checkr, Inc. to obtain a consumer report and/or investigative consumer report, as defined in California, for employment purposes. Checkr Inc. will provide a background investigation as a pre-condition of your engagement with the Company and in compliance with applicable law."
                      />{' '}
                      Why we need this info?
                    </p>
                    <ReactTooltip />
                  </div>

                  <Input
                    value={values.firstName}
                    placeholder="First name"
                    name="firstName"
                    onChange={e => onChangeInput(e, 'firstName')}
                    errorText={getError('firstName', errors, touched)}
                  />

                  <Input
                    value={values.middleName}
                    placeholder="Middle name"
                    name="middleName"
                    onChange={e => onChangeInput(e, 'middleName')}
                    errorText={getError('middleName', errors, touched)}
                  />

                  <Input
                    value={values.lastName}
                    placeholder="Last name"
                    name="lastName"
                    onChange={e => onChangeInput(e, 'lastName')}
                    errorText={getError('lastName', errors, touched)}
                  />

                  <Field name="dob">
                    {({ field }: { field: object }) => (
                      <InputMask
                        mask="99/99/9999"
                        value={values.dob}
                        {...field}
                        onChange={e => {
                          onChangeInput(e, 'dob');
                        }}
                      >
                        <Input
                          id="dob"
                          placeholder="Date of birth"
                          errorText={getError('dob', errors, touched)}
                          containerStyle={styles.input50}
                          name="dob"
                          value={values.dob}
                        />
                      </InputMask>
                    )}
                  </Field>

                  <h5>Driver’s license</h5>
                  <div className={styles.flex}>
                    <Input
                      value={values.driveLicense}
                      placeholder="Number"
                      name="driveLicense"
                      onChange={e => onChangeInput(e, 'driveLicense')}
                      errorText={getError('driveLicense', errors, touched)}
                      containerStyle={styles.input50}
                    />

                    <CustomSelect
                      options={states}
                      value={states.find((el: { label: string; value: string }) => el.value === values.state)}
                      placeholder="State"
                      name="state"
                      onChange={e => {
                        formik.setFieldValue('state', e.value);
                        sessionStorage.setItem(`testDriveForm/state`, e.value);
                      }}
                      errorText={getError('state', errors, touched)}
                      containerStyle={styles.select50}
                    />
                  </div>

                  <Input
                    value={values.email}
                    placeholder="Email address"
                    name="email"
                    onChange={e => onChangeInput(e, 'email')}
                    errorText={getError('email', errors, touched)}
                  />

                  <PhoneInput
                    name="phone"
                    id="phone"
                    className={classNames(getError('phone', errors, touched) ? styles.cPhoneError : '')}
                    countries={['US', 'RU', 'UA']}
                    defaultCountry="US"
                    placeholder="Phone number"
                    value={values.phone}
                    limitMaxLength
                    onChange={(val: string = '') => {
                      handleChange({
                        target: {
                          name: 'phone',
                          value: val,
                        },
                      });
                      sessionStorage.setItem('testDriveForm/phone', val);
                    }}
                  />
                  {getError('phone', errors, touched) && (
                    <p className={styles.errorText}>{getError('phone', errors, touched)}</p>
                  )}
                </div>

                <div className={styles.cRegularCheckbox}>
                  <input
                    id="isFairCredit"
                    name="isFairCredit"
                    type="checkbox"
                    checked={values.isFairCredit}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="isFairCredit">
                    I acknowledge receipt of{' '}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://help.checkr.com/hc/en-us/article_attachments/360003885807/Summary_of_Rights_Under_the_Fair_Credit_Reporting_Act__eff._9.21.2018_.pdf"
                    >
                      the Summary of Your Rights Under the Fair Credit Reporting Act (FCRA)
                    </a>{' '}
                    and certify that I have read and understand this document.
                    {getError('isFairCredit', errors, touched) && (
                      <p className={styles.errorText}>{getError('isFairCredit', errors, touched)}</p>
                    )}
                  </label>
                </div>

                <div className={styles.cRegularCheckbox}>
                  <input
                    id="isBackgroundInvestigation"
                    name="isBackgroundInvestigation"
                    type="checkbox"
                    checked={values.isBackgroundInvestigation}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="isBackgroundInvestigation">
                    I acknowledge receipt of{' '}
                    <a href="https://checkr.com/customer-agreement" target="_blank" rel="noreferrer noopener">
                      the Disclosure Regarding Background Investigation
                    </a>{' '}
                    and certify that I have read and understand this document.
                    {getError('isBackgroundInvestigation', errors, touched) && (
                      <p className={styles.errorText}>{getError('isBackgroundInvestigation', errors, touched)}</p>
                    )}
                  </label>
                </div>

                <div className={styles.cRadioBox}>
                  <h4 className={styles.rsTitle}>Which model would you like to drive?</h4>
                  <div className={styles.cRadioScroll}>{renderCarsList(formik, vehicleStore.testDriveVehicles)}</div>
                </div>

                <h5 className={styles.description}>
                  By placing this order, you agree to{' '}
                  <a
                    href="https://trymotor.com/go-terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    Terms and Conditions
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://trymotor.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    Privacy Policy
                  </a>
                </h5>
              </div>

              <FormikErrorFocus offset={200} align={'middle'} focusDelay={200} ease={'linear'} duration={1000} />
              <div>
                <p className={styles.cError}>{orderStore.fetchError}</p>
              </div>

              {formik.status?.loading ? (
                <Loader />
              ) : (
                <Button buttonColor="green" fullWidth size="big" type="submit">
                  Proceed to scheduling
                </Button>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
});

export default TestDrive;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './TwoBanners.module.scss';

interface bannerData {
  description: string;
  image: { url: string; position?: string };
  link: { caption: string; url: string };
}
interface TwoBannersProps {
  sectionLeft: bannerData;
  sectionRight: bannerData;
}
const TwoBanners = (props: TwoBannersProps) => {
  const { sectionLeft, sectionRight } = props;
  const bgPositionLeftSection = sectionLeft.image.position ? sectionLeft.image.position : 'center';
  const bgPositionRightSection = sectionRight.image.position ? sectionRight.image.position : 'center';
  return (
    <div className={styles.rowForDesktop}>
      <div
        className={classNames(styles.imageBanner)}
        style={{ backgroundImage: `url(${sectionLeft.image.url})`, backgroundPosition: bgPositionLeftSection }}
      >
        <div className={styles.cBottomPanel}>
          <p>{sectionLeft.description}</p>
          <Link className={classNames(styles.seeLink, styles.white)} to={sectionLeft.link.url}>
            {sectionLeft.link.caption}
          </Link>
        </div>
      </div>

      <div
        className={classNames(styles.imageBanner, styles.cChargers)}
        style={{ backgroundImage: `url(${sectionRight.image.url})`, backgroundPosition: bgPositionRightSection }}
      >
        <div className={styles.cBottomPanel}>
          <p>{sectionRight.description}</p>
          <Link className={classNames(styles.seeLink, styles.white)} to={sectionRight.link.url}>
            {sectionRight.link.caption}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TwoBanners;

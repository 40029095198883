import React from 'react';
import { Dividing } from 'components';
import styles from './StaticPages.module.scss';

const ContactSupport = () => {
  return (
    <div className={styles.cPage}>
      <h1>Contact Support</h1>
      <Dividing />
      <div>
        <h5>For questions or technical support contact us at:</h5>
        <p>
          <strong>Email: </strong>
          <a
            href="mailto:support@trymotor.com?subject=AES Indiana MOTOR Support Request"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@trymotor.com
          </a>
        </p>
        <p>
          <strong>Phone: </strong>
          <a href="tel:833-66867-38" target="_blank" rel="noopener noreferrer">
            833-MOTOR-EV
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactSupport;

import React, { useEffect, useRef } from 'react';
import { Button, Collapse, Loader, SubscribeSection, TopBanner } from 'components';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import styles from './EstimatorCost.module.scss';

const EstimatorCost = observer(() => {
  const { vehicleStore, cmsStore } = useStores();
  const tcoRef = useRef(null);

  useEffect(() => {
    cmsStore.getPage('estimator-cost');
    // @ts-ignore
    window.se_customer_state = 'IN';
    const scriptTag = document.createElement('script');
    scriptTag.src =
      'https://content-components.prd.rotw.uplight.io/init?hostId=ipl&v=1639000557511&components=se_content_tco_calculator';
    document.body.insertBefore(scriptTag, document.body.firstChild);
  }, [vehicleStore, cmsStore]);

  const {
    pageContent: { fields },
  } = cmsStore;

  const renderBenefits = () => {
    return (
      <div className={styles.cBenefits}>
        <div className={classNames(styles.cInner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {fields?.benefitsEi?.map((item: any, index: number) => {
            return (
              <div className={styles.container} key={`${index}benefit`}>
                <div className={styles.cIllustration}>
                  <img src={item?.illustration} alt="" />
                  <div className={styles.icon} style={{ backgroundImage: `url(${item?.icon})` }} />
                </div>
                <div className={styles.cInfo}>
                  <h3>
                    <span>{item?.title}</span> {item?.subTitle}
                  </h3>
                  <p>{item?.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (cmsStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      {fields?.topBanner && <TopBanner topBanner={fields?.topBanner} />}

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/*--------- Electric fueling -----------*/}
          <div className={classNames(styles.cElectricFueling, styles.desktopContentWidth, styles.cHorOffsetsMobile)}>
            <img src={fields?.electricFueling?.illustration} alt="" />
            <h3>{fields?.electricFueling?.title}</h3>
            <p>{fields?.electricFueling?.description}</p>
            <Button
              captionFont={'NHG'}
              buttonColor={'black'}
              size={'big'}
              arrow={'right'}
              onClick={() => {
                if (tcoRef !== null && tcoRef.current !== null) {
                  //@ts-ignore
                  tcoRef.current.scrollIntoView();
                }
              }}
            >
              Cost of Ownership Calculator
            </Button>
          </div>
        </div>

        {/*--------- Benefits -----------*/}
        {renderBenefits()}

        <div ref={tcoRef} className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.cTCOCalculator}>
            <h4>{fields?.ownershipCalculator?.subTitle}</h4>
            <h3>{fields?.ownershipCalculator?.title}</h3>
            <p>{fields?.ownershipCalculator?.description}</p>
            <div id="se_content_tco_calculator" />
          </div>
        </div>

        <div className={styles.cFeatureIsElectric}>
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
            <div
              className={styles.featureIsElectric}
              style={{ backgroundImage: `url(${fields?.futureIsElectric?.backgroundImage})` }}
            >
              <h3>{fields?.futureIsElectric?.title}</h3>
            </div>
            <div className={styles.cColumns}>
              {fields?.futureIsElectric?.columns?.map(
                (item: { title?: string; description?: string }, index: number) => {
                  return (
                    <div key={index}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/* ------ FAQ -------*/}
          <div className={classNames(styles.cFAQ, styles.desktopContentWidth)}>
            <div className={styles.titlesSection}>
              <h3>{fields?.frequentlyAskedQuestions?.title}</h3>
              <p>{fields?.frequentlyAskedQuestions?.description}</p>
            </div>

            <div className={styles.cFaqOffsets}>
              {fields?.frequentlyAskedQuestions?.accordionItem?.map(
                (item: { question: string; answer: string }, index: number) => {
                  return (
                    <Collapse
                      title={item.question}
                      wrapperStyle={styles.wrapper}
                      titleStyle={styles.faqTitle}
                      key={index}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </Collapse>
                  );
                }
              )}
            </div>
          </div>
          <SubscribeSection />
        </div>

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default EstimatorCost;

import React, { useEffect } from 'react';
import { Button, Loader, MMSingleModelSlider, SubscribeSection } from 'components';
import { VehicleSpecs, InfoList, MayLike } from './sections';
import { Charger } from '../ChargeAtHome/sections';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { MMVehicle } from '../../types/Vehicle';
import { setFinalPrice } from '../../helpers/formatters/money';
import { getVehicleImgList } from '../../helpers/formatters/vehicle';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { desktopWidth } from '../../appConstants';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styles from './MakesAndModelsVehicleDetails.module.scss';
import classNames from 'classnames';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { condition: string; id: string };
  };
}

const MakesAndModelsVehicleDetails = observer((props: Props) => {
  const { width } = useWindowDimensions();
  const { vehicleStore, cmsStore } = useStores();
  const history = useHistory();

  const {
    match: {
      params: { id },
    },
  } = props;

  const {
    pageContent: { fields },
  } = cmsStore;

  const condition = 'new';

  useEffect(() => {
    async function getVehicle() {
      await vehicleStore.getMMVehicle(id, condition);
      await cmsStore.getPage('charge-at-home');
    }
    getVehicle();
  }, [id, condition, vehicleStore, cmsStore]);

  const renderVehiclePanel = (vehicleDetails: MMVehicle | undefined) => {
    if (!vehicleDetails) return;
    const { model, make, msrp, year } = vehicleDetails;
    const imagesList = getVehicleImgList(vehicleDetails.visualSet).length
      ? getVehicleImgList(vehicleDetails.visualSet)
      : ['/images/cars/comfort.jpg'];
    return (
      <div className={styles.cVehicleInfo}>
        <div className={styles.cSpecsPanel}>
          <h1>
            {year} {make} {model}
          </h1>
          <h3>Starting at {setFinalPrice(msrp ? msrp.toString() : '0')}</h3>

          {width < desktopWidth && (
            <div className={styles.cSlider}>
              <MMSingleModelSlider imagesList={imagesList} />
            </div>
          )}
          <VehicleSpecs vehicleDetails={vehicleDetails} />
          {vehicleDetails.dealerLink && (
            <a
              className={styles.gradientLink}
              target="_blank"
              rel="noopener noreferrer"
              href={vehicleDetails.dealerLink}
            >
              See all trims and features↗
            </a>
          )}
        </div>
        {width >= desktopWidth && (
          <div className={styles.cSlider}>
            <MMSingleModelSlider imagesList={imagesList} />
          </div>
        )}
      </div>
    );
  };

  if (vehicleStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      <div className={styles.cBtnBack}>
        <Button
          size={'medium'}
          buttonColor={'black'}
          arrow={'left'}
          className={styles.orderBtn}
          onClick={() => history.push('/makes-and-models')}
        >
          All makes and models
        </Button>
      </div>
      <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
        {renderVehiclePanel(vehicleStore.MMVehicle)}
        <InfoList vehicleDetails={vehicleStore.MMVehicle as MMVehicle} />
        {fields?.level2Charger && <Charger level2Charger={fields?.level2Charger} />}
        <MayLike vehicleId={id} />
        <SubscribeSection className={styles.subscribeOffset} />
      </div>
    </div>
  );
});

export default MakesAndModelsVehicleDetails;

import get from 'lodash/get';
import { action, observable, computed, runInAction } from 'mobx';
import moment from 'moment';

import { SERVER_ERROR } from 'appConstants/errors';
import TokenStore from '../stores/TokenStore/TokenStore';
import { AuthState } from 'appConstants';
import { sendPhone, getAccessToken, getAccessTokenGuest, getConfiguration } from '../services/auth/authApi';
import UserApi from '../services/user/userApi';
import { DriverProfile, User } from '../types/User';
import { Configuration } from '../types/Configuration';

interface SignInGuestFields {
  userName?: string;
  password?: string;
}

interface SendPhoneProps {
  phone: string;
  setErrors?: (error: string) => void;
  onSuccess?: () => void;
}
interface SendOtpProps {
  otp: string;
  phone?: string;
  setErrors?: (signInFields: { smsCode?: string }) => void;
  onSuccess?: () => void;
}
interface SignInGuestProps {
  userName: string;
  password: string;
  setErrors?: (signInFields: SignInGuestFields) => void;
  onSuccess?: () => void;
}

export class AuthStore {
  private userApi: UserApi = new UserApi();

  @observable guestExpiresIn: number | null = Number(localStorage.getItem('eMobility/guest/expires in'));
  @observable isSignedIn = TokenStore.accessToken !== null && TokenStore.refreshToken !== null;
  @observable userPhone = '';
  @observable fetchError = '';
  @observable loading = false;
  @observable user: User | undefined = {} as User;
  @observable configuration: Configuration | null = null;
  @computed get authState() {
    return this.isSignedIn ? AuthState.SIGNED_IN : AuthState.SIGN_IN;
  }
  @observable isSubscribed: boolean = false;
  @observable isShowingPopup: boolean = false;
  @observable driverProfile: DriverProfile | undefined = undefined;
  @observable driverProfileTemp: DriverProfile | undefined = undefined;
  @observable dpId: string | undefined = undefined;

  @computed get isGuestSignedIn() {
    const currentDay = moment();
    const unix = moment.unix(this.guestExpiresIn as number);

    return currentDay.isBefore(unix);
  }

  @action setRenderPopup(show: boolean = false) {
    this.isShowingPopup = show;
  }

  @action
  async sendPhone({ phone, setErrors = () => {}, onSuccess = () => {} }: SendPhoneProps) {
    try {
      await sendPhone(phone);

      runInAction(() => {
        this.setUserPhone(phone);
      });
      onSuccess();
    } catch ({ response }) {
      const error = get(response, 'data.phone', 'Something went wrong');
      setErrors(error);
    }
  }

  @action
  async sendOtpCode({ otp, phone = this.userPhone, setErrors = () => {}, onSuccess = () => {} }: SendOtpProps) {
    try {
      const { refresh, access } = await getAccessToken({ otp, phone });
      if (!refresh && !access) {
        this.isSignedIn = false;
        return;
      }
      runInAction(() => {
        this.isSignedIn = true;
      });

      onSuccess();
    } catch (err) {
      const error = typeof err === 'string' ? err : get(err, 'detail') || SERVER_ERROR;
      setErrors({ smsCode: error });
    }
  }

  @action // Temporary approach for hiding front-end
  async signInGuest({ userName, password, setErrors = () => {}, onSuccess = () => {} }: SignInGuestProps) {
    try {
      const { refresh, access } = await getAccessTokenGuest(userName, password);

      if (!refresh && !access) {
        this.guestExpiresIn = null;
        return;
      }
      runInAction(() => {
        const dayAfterWeek = moment().add(7, 'days');
        const dayAfterWeekUnix = dayAfterWeek.unix();

        this.guestExpiresIn = dayAfterWeekUnix;
        localStorage.setItem('eMobility/guest/expires in', `${dayAfterWeekUnix}`);
      });

      onSuccess();
    } catch (err) {
      setErrors({ userName: undefined, password: err });
    }
  }

  @action logout() {
    this.isSignedIn = false;
    TokenStore.logOut();
  }

  @action setUserPhone(phone: string = '') {
    this.userPhone = phone;
  }

  @action async getCurrentUser() {
    try {
      this.fetchError = '';
      this.loading = true;
      this.user = await this.userApi.getCurrentUser();
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async getBasicConfiguration() {
    try {
      this.fetchError = '';
      this.loading = true;
      this.configuration = await getConfiguration();
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async subscribe(email: string) {
    try {
      this.isSubscribed = false;
      this.fetchError = '';
      this.loading = true;
      await this.userApi.subscribe(email);
      this.isSubscribed = true;
      setTimeout(() => {
        this.isSubscribed = false;
      }, 3000);
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async getUserProfile(uuid: string) {
    try {
      this.loading = false;
      this.driverProfile = await this.userApi.getUserProfile(uuid);
      this.driverProfileTemp = this.driverProfile;
      this.dpId = this.driverProfile?.id;
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async createUserProfile(profile: DriverProfile) {
    try {
      this.fetchError = '';
      this.loading = false;
      let driverProfile: DriverProfile = await this.userApi.createUserProfile(profile);
      this.driverProfileTemp = driverProfile;
      this.dpId = driverProfile.id;
    } catch ({ response }) {
      console.log('response', response);
      if (response && response.data && response.data.detail) {
        this.fetchError =
          Object.keys(response.data.detail)[0].toLowerCase() +
          ': ' +
          response.data.detail[Object.keys(response.data.detail)[0]][0];
      } else {
        this.fetchError = 'Unknown error!';
      }
    } finally {
      this.loading = false;
    }
  }

  @action async updateUserProfile(profile: DriverProfile) {
    try {
      this.fetchError = '';
      this.loading = false;
      let id = '';
      if (this.dpId) {
        id = this.dpId;
      }
      if (this.driverProfile?.id) {
        id = this.driverProfile?.id;
      }
      let driverProfile: DriverProfile = await this.userApi.updateUserProfile(profile, id);
      this.driverProfileTemp = driverProfile;
    } catch ({ response }) {
      console.log('response', response);
      if (response && response.data && response.data.detail) {
        this.fetchError =
          Object.keys(response.data.detail)[0].toLowerCase() +
          ': ' +
          response.data.detail[Object.keys(response.data.detail)[0]][0];
      } else {
        this.fetchError = 'Unknown error!';
      }
    } finally {
      this.loading = false;
    }
  }
}

import React, { useState } from 'react';
import { getDistenceInMiles } from '../../../helpers/formatters/vehicle';
import styles from './Estimator.module.scss';
import classNames from 'classnames';
import { Dividing, RadioLabel } from '../../../components';
import MapWithMarker from './MapWithMarker';
import HSBar from 'react-horizontal-stacked-bar-chart';
import { ChargerStation } from '../../../types/User';

interface SliderBlockProps {
  origin?: { lat: number; lng: number };
  destination?: { lat: number; lng: number };
  distance: number | null;
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: {
    latitude: number;
    longitude: number;
  };
  pins: ChargerStation[] | null[];
}

const getTripCharData = (trips: number = 8) => {
  const data = [];
  const length = Math.floor(trips);
  const value = 3000 / length;

  for (let i = 1; i <= length; i++) {
    data.push({
      value: value,
      color: '#003EDD',
    });
  }

  return data;
};

const ParkComponent = ({
  origin,
  destination,
  distance,
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
  pins,
}: SliderBlockProps) => {
  const [parkId, setParkId] = useState(1);

  const distanceADay = Number(getDistenceInMiles(distance || 8, 0)) * 2;
  const trips = Math.floor(200 / distanceADay);
  const haveMapData = origin && destination && distance;

  return (
    <div className={styles.parkContainer}>
      <div className={styles.cOuter}>
        <div className={classNames(styles.cRadioScroll, styles.cPark)}>
          <div>
            <input
              id="park1"
              name="park"
              type="radio"
              checked={parkId === 1}
              onChange={() => {
                setParkId(1);
              }}
            />
            <RadioLabel
              containerStyle={styles.checkbox}
              inputId="park1"
              itemTitle={'Personal garage'}
              description=""
              price=""
            />
          </div>

          <div>
            <input
              id="park2"
              name="park"
              type="radio"
              checked={parkId === 2}
              onChange={() => {
                setParkId(2);
              }}
            />
            <RadioLabel
              containerStyle={styles.checkbox}
              inputId="park2"
              itemTitle="Public garage"
              description=""
              price=""
            />
          </div>

          <div>
            <input
              id="park3"
              name="park"
              type="radio"
              checked={parkId === 3}
              onChange={() => {
                setParkId(3);
              }}
            />
            <RadioLabel
              containerStyle={styles.checkbox}
              inputId="park3"
              itemTitle="On the street"
              description=""
              price=""
            />
          </div>
        </div>
      </div>

      {haveMapData ? (
        <>
          <h2 className={trips ? styles.h2Margin : ''}>
            If you drive this {distanceADay} mile round trip each day{parkId === 3 ? null : ', you could make'}:
          </h2>
          {parkId === 3 ? (
            <>
              <h4>
                Consider charging near your place of work or around the city. Most public charging stations are Level 2
                chargers.
              </h4>
              <MapWithMarker
                origin={origin}
                destination={destination}
                isGeolocationAvailable={isGeolocationAvailable}
                isGeolocationEnabled={isGeolocationEnabled}
                coords={coords}
                pins={pins}
              />
            </>
          ) : (
            <>
              <div className={styles.cBar}>
                {trips && (
                  <HSBar
                    height={16}
                    outlineWidth={0.5}
                    outlineColor="white"
                    id="new_id"
                    data={getTripCharData(Number(trips))}
                  />
                )}
              </div>
              <div className={styles.cLeft}>
                <h1 className={styles.left}>{trips} round trips</h1>
                <p>on a single charge</p>
              </div>
            </>
          )}
          <div className={styles.cCharginInfo}>
            <div className={classNames(styles.cChargingItem, styles.cChargingItemLeft)}>
              <Dividing style={styles.dividingMT} />
              <h1>{(distanceADay * 1.2).toFixed(0)} min</h1>
              <h5>time to refuel each day</h5>
              <img className={styles.chargingImg} src="/images/Subtract.svg" alt="" />
            </div>
            <div className={classNames(styles.cChargingItem, styles.cChargingItemLeft)}>
              <Dividing style={styles.dividingMT} />
              <h1>50–60 mi</h1>
              <h5>range per hour charged with a {parkId === 3 ? 'Level 2 charger' : 'Motor Faster Charger'}</h5>
            </div>
          </div>
          <h4 className={styles.semibold}>
            Not bad, huh? Now that you’ve got an idea of how easy it is to get around in an electric car:
          </h4>
        </>
      ) : (
        <>
          <div className={styles.cNoData}>
            <img src="/images/arrow_up.png" alt="" className={styles.arrowUpIcon} />
            <p className={styles.noDataText}>Enter details above to see estimates</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ParkComponent;

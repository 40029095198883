import React from 'react';
import classNames from 'classnames';
import styles from './FileUploader.module.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  text?: string;
  name?: string;
  errorText?: string | false;
  styleContainer?: string;
  fileContent: any;
}
const DEFAULT_TEXT = 'Upload file';

const FileUploader = ({
  text = DEFAULT_TEXT,
  type = 'file',
  className = styles.input,
  errorText,
  styleContainer,
  fileContent,
  ...props
}: Props) => {
  return (
    <div className={classNames(styles.cFileUploader, styleContainer)}>
      <label className={styles.container}>
        {fileContent ? (
          <>
            <div
              className={styles.previewImg}
              style={{ backgroundImage: `url(${URL.createObjectURL(fileContent)})` }}
            />
            <div className={styles.btnRemoveImg} />
          </>
        ) : (
          <img src="/images/ic-plus.svg" alt="" className={styles.img} />
        )}
        <p>{text}</p>
        <input type={type} className={className} {...props} />
      </label>
      {errorText ? <p className={styles.errorText}>{errorText}</p> : null}
    </div>
  );
};

export default FileUploader;

import React from 'react';
import classNames from 'classnames';
import styles from './TDInfoSection.module.scss';
import { Subscribe } from '../index';

interface Props {
  imageURL: string;
}

const TDInfoSection = (props: Props) => (
  <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.offsetTop50)}>
    <div className={styles.tdSection}>
      <div className={styles.subscribeSectionCombine}>
        <div>
          <h5>Send me updates</h5>
          <p>
            Give us your email, and we’ll keep you updated on <br /> our newest cars and latest offerings.
          </p>
          <Subscribe />
        </div>

        <div>
          <h5>Don’t see what you’re looking for?</h5>
          <p>
            Our team of electric car subscription specialists are available to answer any questions you may have about
            this process. <br /> Email <a href="mailto:support@trymotor.com">support@trymotor.com</a> with any questions.
          </p>
        </div>
      </div>

      <div className={styles.tdIllustration} style={{ backgroundImage: `url("${props.imageURL}")` }} />
    </div>

    {/*<div className={styles.tdSection}>
            <div className={styles.infoSection}>
              <div className={styles.cTextLimiter}>
                <p className={styles.cuText}>Not convinced yet?</p>
                <h4>
                  Test drive an <br /> electric car.
                </h4>
                <p className={styles.cuTextBottom}>
                  Take one out for a spin for 30 minutes or 24 hours. We’re confident you’ll love the performance and
                  the convenience of driving electric.
                </p>
              </div>

              <Button buttonColor={'black'} size={'medium'} onClick={() => history.push('/test-drive')}>
                Schedule a test drive
              </Button>
            </div>

            <div className={styles.tdIllustration} />
          </div>*/}

    {/*<div className={styles.subscribeSection}>
            <div>
              <h5>Send me updates</h5>
              <p>
                Give us your email, and we’ll keep you updated on <br /> our newest cars and latest offerings.
              </p>
              <Subscribe />
            </div>

            <div>
              <h5>Don’t see what you’re looking for?</h5>
              <p>
                Our team of electric car subscription specialists are available to answer any questions you may have
                about this process. <br /> Email <a href="mailto:support@trymotor.com">support@trymotor.com</a> with any
                questions.
              </p>
            </div>
          </div>*/}
  </div>
);

export default TDInfoSection;

import { AxiosRequestConfig, AxiosError } from 'axios';
import get from 'lodash/get';

import { SERVER_ERROR } from '../../appConstants/errors';
import { Interceptors } from './types';
import TokenStore from '../../stores/TokenStore/TokenStore';

export function mergeInterceptors(defaultInterceptors: Interceptors, mainInterceptors?: Interceptors): Interceptors {
  let mergedInterceptors = defaultInterceptors;
  if (typeof mainInterceptors === 'object') {
    mergedInterceptors = {
      ...defaultInterceptors,
      ...mainInterceptors,
    };
  }
  return mergedInterceptors;
}

export function addNewTokenToResponseHeader(config: AxiosRequestConfig, authorization: string | void) {
  config.headers = {
    ...config.headers,
    Authorization: authorization,
  };
}

export function injectAuthTokenToHeader(config: AxiosRequestConfig): AxiosRequestConfig {
  const authorization = TokenStore.authHeader ? { Authorization: TokenStore.authHeader } : {};

  return {
    ...config,
    headers: {
      ...config.headers,
      ...authorization,
    },
  };
}

export function getServerError(error: AxiosError): string {
  const defaultError = SERVER_ERROR;
  const errorMessage = get(error, 'response.data.detail');

  console.log('Server ERROR!', error.response);

  return typeof errorMessage === 'string' ? errorMessage : defaultError;
}

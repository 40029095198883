import React from 'react';
import classNames from 'classnames';

import styles from './RadioLabel.module.scss';

interface RadioLabelProps {
  inputId: string;
  itemTitle: string;
  description: string;
  price: string;
  containerStyle?: string | null;
}

const RadioLabel = (props: RadioLabelProps) => {
  const { inputId, itemTitle, description, price, containerStyle } = props;
  let descriptionArray = description.split('\n');

  return (
    <label className={classNames(styles.contentRadioLabel, containerStyle)} htmlFor={inputId}>
      <div>
        <h4>{itemTitle}</h4>
        {descriptionArray.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
      <p className={styles.cSavePrice}>{price}</p>
      <p className={styles.cSelected}>Selected</p>
    </label>
  );
};
export default RadioLabel;

import { History } from 'history';
import { action, observable } from 'mobx';
import Butter from 'buttercms';
import camelcaseKeys from 'camelcase-keys-deep';

export const butter = Butter('943d1cb19620ddc9ce4cd9a43e22c804c8214896');

export class CMSStore {
  cmsApi;
  routerStore;

  constructor(cmsAPI: any, routerStore: History) {
    this.cmsApi = new cmsAPI();
    if (routerStore) {
      this.routerStore = routerStore;
    }
  }

  @observable loading: boolean = false;
  @observable pageContent: any = {};
  @observable collectionContent: any = {};

  @action
  async getPage(name: string) {
    this.pageContent = {};
    this.loading = true;
    try {
      let response = await Promise.all([butter.page.retrieve('*', name, { preview: 1 })]);
      if (name === 'home' || name === 'subscribe') {
        response[0].data.data.fields.vehicles_slider = response[0].data.data.fields?.vehicles_slider?.map(
          (item: any) => {
            if (item?.features_list?.length) {
              item.features_list = item?.features_list?.split(',');
              item.popup_features_list = item?.popup_features_list?.split(',');
            }
            return item;
          }
        );
      }
      this.pageContent = camelcaseKeys(response[0].data.data);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action
  async getCollection(name: string) {
    this.loading = true;
    try {
      const response = await Promise.all([butter.content.retrieve([name], { page: 1 })]);
      const result = camelcaseKeys(response[0].data.data);
      const objName = Object.keys(result)[0];
      // @ts-ignore
      this.collectionContent[objName] = result[objName];
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}

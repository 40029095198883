import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { subscriptionOrderStatusBar } from '../../appConstants';
import { Loader, Charger, StatusBar } from 'components';

import { useHistory } from 'react-router-dom';
import '../../styles/lib/react-slider.scss';
import styles from './ChargerOfferForSubscription.module.scss';
import { ChargerPackage } from '../../types/Vehicle';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { vin: string };
  };
}

const ChargerOfferForSubscription = observer((props: Props) => {
  const {
    match: {
      params: { vin },
    },
  } = props;

  const history = useHistory();

  const { vehicleStore } = useStores();

  useEffect(() => {
    vehicleStore.getSubscriptionVehicleByVin(vin);
  }, [vehicleStore, vin]);

  let chargerPackage = {} as ChargerPackage;

  const renderLoader = () => {
    return (
      <div className={styles.cContent}>
        <Loader />
      </div>
    );
  };

  if (vehicleStore.loading) return renderLoader();
  if (vehicleStore.subscVehicleDetails?.vehicle?.chargerPackage) {
    chargerPackage = vehicleStore.subscVehicleDetails?.vehicle?.chargerPackage;
  }

  const vehicleId: string = String(vehicleStore.subscVehicleDetails?.vehicle?.id);
  const packageID = chargerPackage.id;

  const buttonHandler = async (isEnroll: boolean, isChargerJumpstart: boolean, isExistFasterCharger?: boolean) => {
    if (packageID) {
      const saveBuildCharger = {
        vehicleID: vehicleId,
        chargerPackageID: chargerPackage.id,
        isChargerPackage: isChargerJumpstart,
        isEnroll,
        isExistFasterCharger,
      };
      const buffer = JSON.stringify(saveBuildCharger);
      sessionStorage.setItem('subscribe-to-buy/charger-offer', buffer);
    }

    history.push(`/subscribe-to-buy/${vin}/order`);
  };

  const heading = 'If you don’t have a home charging solution, consider the AES Indiana Charger Bundle.';
  return (
    <div>
      <StatusBar steps={subscriptionOrderStatusBar} activeStep={'Bundle options'} />
      <Charger type={'subscription'} heading={heading} chargerPackage={chargerPackage} submitHandler={buttonHandler} />
    </div>
  );
});
export default ChargerOfferForSubscription;

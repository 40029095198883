import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import styles from './StatusHeader.module.scss';

interface Props {
  title: string;
  progress?: number;
  hideBar?: boolean;
  onClose?: () => void;
}

const StatusHeader = ({ title, progress = 0, hideBar = false, onClose }: Props) => {
  const history = useHistory();

  return (
    <div className={classNames([styles.cHeader, hideBar ? styles.cShadow : ''])}>
      <div className={styles.headerContent}>
        <h2>{title}</h2>
        <div
          className={styles.btnClose}
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              history?.goBack();
            }
          }}
        />
      </div>
      {!hideBar && (
        <div className={styles.headerBar}>
          <div style={{ width: progress + '%' }} />
        </div>
      )}
    </div>
  );
};
export default StatusHeader;

import React, { useEffect } from 'react';
import { Loader, Button, MatchesModelsCarouselSlider, SubscribeSection } from 'components';
import { MyCurrentCar, MyNextCar } from './sections';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { annualMileage, preferencesMatch, VALIDATION_RULES, preferencesMatchResults } from 'appConstants';
import { convertToSelect, convertToSelect2, convertToDD } from 'helpers/formatters/select';
import { toJS } from 'mobx';
import styles from './DriverProfile.module.scss';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

export const schema = Yup.object().shape({
  zipCode: VALIDATION_RULES.zipCode,
  learningPreferences: VALIDATION_RULES.learningPreferences,
  make: VALIDATION_RULES.make,
  model: VALIDATION_RULES.model,
  annualMileage: VALIDATION_RULES.annualMileage,
  parkingLocation: VALIDATION_RULES.parkingLocation,
});

const DriverProfile = observer(() => {
  const { authStore, vehicleStore } = useStores();

  useEffect(() => {
    const getProfile = async () => {
      let uuid: string | null = localStorage.getItem('uuid');
      if (uuid === null) {
        uuid = uuidv4();
        localStorage.setItem('uuid', uuid);
      }

      await authStore.getUserProfile(uuid);
      if (authStore.driverProfile?.id) {
        await vehicleStore.getMatchesVehicles(uuid);
      }
    };
    getProfile();
  }, [authStore, vehicleStore]);

  useEffect(() => {
    const getSelects = async () => {
      await vehicleStore.getMakes();
      await vehicleStore.getModelsByMakeId(authStore.driverProfile?.make ? authStore.driverProfile?.make : '');
      vehicleStore.getParkingLocation();
    };
    getSelects();
  }, [vehicleStore, authStore.driverProfile]);

  useEffect(() => {
    const getSelects = async () => {
      vehicleStore.getParkingLocation();
    };
    getSelects();
  }, [vehicleStore, authStore.driverProfile]);

  const handleSubmit = async (values: any) => {
    let submitedData = Object.assign({}, values);
    submitedData.preferences = values.preferences.map((item: { id: string }) => item.id);
    submitedData.budget = (parseInt(submitedData.budget) * 1000).toString();
    submitedData.uuid = localStorage.getItem('uuid');
    if (authStore.dpId) {
      await authStore.updateUserProfile(submitedData);
    } else {
      await authStore.createUserProfile(submitedData);
    }
    await vehicleStore.getMatchesVehicles(submitedData.uuid);
  };

  const getData = () => {
    if (authStore.driverProfile?.id) {
      let profile = toJS(authStore.driverProfile);
      return {
        make: authStore.driverProfile.make,
        model: authStore.driverProfile.model,
        annualMileage: authStore.driverProfile.annualMileage,
        parkingLocation: authStore.driverProfile.parkingLocation,
        zipCode: authStore.driverProfile.zipCode,
        budget: (parseInt(authStore.driverProfile.budget) / 1000).toString(),
        learningPreferences: authStore.driverProfile.learningPreferences,
        preferences: convertToDD(profile.preferences),
      };
    } else {
      return {
        make: '',
        model: '',
        annualMileage: '',
        parkingLocation: '',
        zipCode: '',
        budget: '45',
        learningPreferences: authStore.driverProfile?.learningPreferences,
        preferences: preferencesMatch,
      };
    }
  };

  const renderCriteria = (pref: string[]) => {
    let preferences = toJS(pref);
    const index = preferences.indexOf('environment_impact');
    if (index !== -1) {
      preferences.splice(index, 1);
    }

    return preferences.slice(0, 3).map((item, index) => {
      const val = preferencesMatchResults.filter(option => option.id === item);

      return <li key={index}>{val[0].name}</li>;
    });
  };

  let formik = useFormik({
    initialValues: getData(),
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const renderMatches = () => {
    if (vehicleStore.loadingMatches) return <Loader />;
    if (vehicleStore.matchesVehicles) {
      return <MatchesModelsCarouselSlider slidesList={vehicleStore.matchesVehicles} />;
    } else return <div />;
  };
  if (authStore.loading) return <Loader />;

  return (
    <div className={styles.cPage}>
      <div className={styles.cHorOffsetsMobile}>
        <h1>My driver profile</h1>
        <p>
          Help us learn a little more about you so that we can personalize your experience! Complete your driver profile
          below to see your electric vehicle matches.
        </p>

        <MyCurrentCar
          lists={{
            makes: convertToSelect(vehicleStore.makesList),
            models: convertToSelect(vehicleStore.modelsList),
            annualMileage: annualMileage,
            parkingLocation: convertToSelect2(vehicleStore.parkingLocationList),
          }}
          formik={formik}
          getModels={id => vehicleStore.getModelsByMakeId(id)}
        />

        <div className={styles.splitter} />

        <MyNextCar formik={formik} />
        <div className={styles.cError}>{authStore.fetchError}</div>

        <div className={styles.buttonCenter}>
          <Button
            buttonColor={'black'}
            size={'medium-rounded'}
            minWidth={'mw192'}
            disabled={!formik.isValid}
            onClick={() => formik.submitForm()}
          >
            {authStore.dpId ? 'Refresh vehicle matches' : 'Get my vehicle matches'}
          </Button>
        </div>

        <div className={styles.splitter} />
      </div>

      {vehicleStore.matchesVehicles?.length && (
        <div className={styles.cVehiclesMatches}>
          <div className={styles.cHorOffsetsMobile}>
            <h2>
              You have {vehicleStore.matchesVehicles?.length ? vehicleStore.matchesVehicles?.length : '0'} vehicle
              matches!
            </h2>
            <p>Based on your answers, we matched you with cars that offer:</p>
            <ul className={styles.matchesDescList}>{renderCriteria(authStore.driverProfileTemp?.preferences || [])}</ul>
          </div>

          <div className={styles.cMatches}>{renderMatches()}</div>
          <div className={styles.cSeeAllLink}>
            <Link className={styles.cuLink} to={'/makes-and-models'}>
              See all electric cars
            </Link>
          </div>
        </div>
      )}

      <div className={styles.cHorOffsetsMobile}>
        <SubscribeSection />
      </div>
    </div>
  );
});

export default DriverProfile;

import Dinero from 'dinero.js';
import { Currency } from 'dinero.js';

export const setRegularFormat = (amount: string, currency: Currency = 'USD') => {
  let buffer: number = 0;

  if (amount.indexOf('.') === -1) {
    buffer = parseInt(amount + '00');
  } else {
    buffer = parseInt(amount.replace('.', ''));
  }

  const format = '0,0';
  return Dinero({ amount: buffer, currency: currency }).toFormat(format);
};

export const setFinalPrice = (amount: string, prefix: string = '', sign: string = '$') => {
  if (amount === undefined) return '';
  if (parseInt(amount) === 0) {
    return 'Free';
  } else {
    return prefix + sign + setRegularFormat(amount);
  }
};

export const getDiscountPercents = (price: number, discount: number) => {
  const result = discount / (price / 100);
  return Math.round(result) + '%';
};

import React from 'react';
import Slider from 'react-slick';
import styles from './SingleModelSlider.module.scss';
import classNames from 'classnames';

interface ImageSliderProps {
  imagesList: string[] | null;
}

const RenderSlides = (props: ImageSliderProps) => {
  return props.imagesList?.map((item, index, array) => {
    return (
      <div key={index} className={styles.cSliderItem}>
        <div className={styles.sliderImg} style={{ backgroundImage: `url(${item})` }} />
      </div>
    );
  });
};

const SingleModelSlider = (props: ImageSliderProps) => {
  let slider: Slider | null = null;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className={styles.cSliderSingleModel}>
      <div
        className={classNames(styles.sliderButton, styles.leftButton)}
        onClick={() => {
          if (slider) {
            slider.slickPrev();
          }
        }}
      />
      <div
        className={classNames(styles.sliderButton, styles.rightButton)}
        onClick={() => {
          if (slider) {
            slider.slickNext();
          }
        }}
      />
      <Slider {...settings} ref={c => (slider = c)}>
        {RenderSlides(props)}
      </Slider>
      <p className={styles.description}>Vehicle for reference only, not exact vehicle for sale.</p>
    </div>
  );
};
export default SingleModelSlider;

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';

import { VALIDATION_RULES, getError } from 'appConstants/validations';
import { Button, Input } from 'components';
import {} from 'environment';
import styles from './Unauthorized.module.scss';

export const schema = Yup.object().shape({
  name: VALIDATION_RULES.role('Name'),
  password: VALIDATION_RULES.password,
});

const Unauthorized = observer(({ history }: RouteComponentProps) => {
  const { authStore } = useStores();
  return (
    <div className={styles.cUnauthorized}>
      <div className={styles.cContent}>
        <div>
          <Formik
            initialValues={{ name: '', password: '' }}
            validationSchema={schema}
            onSubmit={async (values, { setErrors }) => {
              await authStore.signInGuest({
                userName: values.name,
                password: values.password,
                setErrors,
                onSuccess: () => history.push('/'),
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.cForm}>
                  <div className={styles.formGroup}>
                    <Input
                      value={values.name}
                      placeholder="User name"
                      name="name"
                      onChange={handleChange}
                      errorText={getError('name', errors, touched)}
                    />

                    <Input
                      value={values.password}
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      errorText={getError('password', errors, touched)}
                    />
                    <Button buttonColor="green" fullWidth size="big" type="submit">
                      LogIn
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
export default Unauthorized;

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import get from 'lodash/get';

import { RootStore } from '../../stores/';
import TokenStore from '../../stores/TokenStore/TokenStore';
import { createAuthHeader } from '../../stores/TokenStore/helpers';
import authAPI from '../../services/auth/authApi';
import {
  INVALID_USER_CREDENTIAL_ERROR,
  EXPIRED_ACCESS_TOKEN_ERROR,
  EXPIRED_ACCESS_TOKEN_CODE,
  EXCEPTIONAL_URLS_FOR_HANDLING_ERROR,
} from 'appConstants';
import { Interceptors } from './types';
import { addNewTokenToResponseHeader, injectAuthTokenToHeader, getServerError } from './utils';

export async function refreshAccessToken(): Promise<Error | string | void> {
  try {
    const refreshToken = TokenStore.refreshToken;

    if (!refreshToken) {
      throw Error("refresh token can't be empty");
    }

    const response = await authAPI.post('/auth/tokens/refresh/', { refresh: refreshToken });
    return get(response, 'data.access');
  } catch (err) {
    TokenStore.logOut();
    console.log('refreshAccessToken ERROR', err);
  }
}

export async function responseFailInterceptor(error: AxiosError) {
  const { response, config } = error;
  const errorMessage = get(response, 'data.detail', 'Something went wrong. Please try again later.');
  const status = get(response, 'status');
  const isInvalidUserCredentials = get(response, 'data.detail') === INVALID_USER_CREDENTIAL_ERROR;
  const isExpiredAccessToken = status && status === 401;
  const isExpiredRefreshToken =
    isExpiredAccessToken &&
    get(response, 'data.detail', false) === EXPIRED_ACCESS_TOKEN_ERROR &&
    get(response, 'data.code') === EXPIRED_ACCESS_TOKEN_CODE;

  if (isInvalidUserCredentials && response) return Promise.reject(errorMessage);

  if (isExpiredRefreshToken) {
    TokenStore.logOut();
    return Promise.reject(error);
  }

  if (isExpiredAccessToken) {
    const newAccessToken = await refreshAccessToken();
    if (typeof newAccessToken !== 'string') {
      return Promise.reject(error);
    }

    addNewTokenToResponseHeader(config, createAuthHeader(newAccessToken));
    TokenStore.setAccessToken(newAccessToken);
    return await axios(config);
  }
  const url: string = error.response?.config.url ? error.response?.config.url : '';
  /*  if (error.response?.config.method === 'post' && !EXCEPTIONAL_URLS_FOR_HANDLING_ERROR.includes(url)) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    RootStore.errorsStore.setServerError(getServerError(error));
  }*/

  if (error.response?.config.method === 'get' && !EXCEPTIONAL_URLS_FOR_HANDLING_ERROR.includes(url)) {
    RootStore.errorsStore.setServerGetError(getServerError(error));
  }

  return Promise.reject(error);
}

// @TODO adding tokens to all API. In the future when we use microservices next line needs to be removed.
export async function requestSuccessInterceptors(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  return await injectAuthTokenToHeader(config);
}

export const defaultInterceptors: Interceptors = {
  responseFail: responseFailInterceptor,
  requestSuccess: requestSuccessInterceptors,
};

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import config from '../../environment';
import { getSplittedFullName } from '../../helpers/formatters/user';
import { StatusHeader, TDScheduler } from 'components';
import styles from './Schedule.module.scss';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { id: string };
  };
}

const Schedule = observer((props: Props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { authStore, orderStore } = useStores();
  const fullName: string = authStore.user?.fullName || '';
  const userInfo = {
    firstName: getSplittedFullName(fullName).firstName,
    lastName: getSplittedFullName(fullName).lastName,
    phone: authStore.user?.phone || '',
    email: authStore.user?.email || '',
  };

  const chargerID = orderStore.currentOrder?.chargerPackage?.id;
  const history = useHistory();

  useEffect(() => {
    authStore.getCurrentUser();
    orderStore.getOrderById(id);
  }, [orderStore, authStore, orderStore.getOrderById, id]);

  return (
    <div className={styles.cPage}>
      <div className={styles.wrapper}>
        <StatusHeader
          title="Charger installation"
          progress={0}
          onClose={async () => {
            await orderStore.getOrderById(id);
            history.push(`/order/${id}/status`);
          }}
        />
      </div>

      <div className={styles.wrapper}>
        {chargerID && authStore.user && (
          <TDScheduler
            categoryId={config.simplyBook.orderCategoryId}
            orderId={chargerID}
            userInfo={userInfo}
            height={`${window.innerHeight - 100}px`}
          />
        )}
      </div>
    </div>
  );
});

export default Schedule;

import React from 'react';
import classNames from 'classnames';

import styles from './Dividing.module.scss';

const Dividing = ({ style = '' }) => {
  return <div className={classNames(styles.cDividing, style)} />;
};

export default Dividing;

import React, { useEffect } from 'react';

import { Card, Loader, NoData, StatusHeader, PaymentDuePanel } from 'components';
import styles from './PaymentInstructions.module.scss';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { id: string };
  };
}

const PaymentInstructions = observer((props: Props) => {
  const { orderStore, vehicleStore } = useStores();
  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    orderStore.getPaymentInstructions(id);
    orderStore.getDueNowPackages(id);
  }, [id, orderStore]);

  const renderInstructions = () => {
    if (orderStore.loading) return <Loader />;
    if (!orderStore.paymentInstructions) return <NoData />;
    const { accountName, accountNumber, bank, reference } = orderStore.paymentInstructions;

    return (
      <ul className={styles.cPropertiesList}>
        <li>
          <h5>Account name:</h5>
          <p>{accountName}</p>
        </li>

        <li>
          <h5>Bank:</h5>
          <p>{bank}</p>
        </li>

        <li>
          <h5>Account Number:</h5>
          <p>{accountNumber}</p>
        </li>

        <li>
          <h5>Reference:</h5>
          <p>{reference}</p>
        </li>
      </ul>
    );
  };

  return (
    <div className={styles.cPage}>
      <StatusHeader title="Payment instructions" progress={0} hideBar={true} />
      <div className={styles.cContent}>
        <Card>
          {!orderStore.loadingPacks && (
            <PaymentDuePanel
              titleText={'Payment due'}
              currentPackages={orderStore.choosePackages}
              dueNowPrice={vehicleStore.buildDueNowPrice?.total}
              holdingFee={vehicleStore.buildDueNowPrice?.holdingFee}
            />
          )}
        </Card>
        <p>
          <strong>Pay by direct deposit through your bank using the payment instructions below</strong>
        </p>

        <div className={styles.splitter} />
        {renderInstructions()}

        <div className={styles.infoBox}>
          <img src="/images/information.svg" alt="" />
          <p>
            <strong>Heads up!</strong>
            <br /> It may take up to 5 business days for us to receive your payment.
          </p>
        </div>
      </div>
    </div>
  );
});

export default PaymentInstructions;

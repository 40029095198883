import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers } from 'formik';
import { Button } from '../index';
import { useStores } from '../../hooks/use-stores';
import styles from './Subscribe.module.scss';
import * as Yup from 'yup';
import { getError, VALIDATION_RULES } from '../../appConstants';

export interface formValues {
  email: string;
}

export const schema = Yup.object().shape({
  email: VALIDATION_RULES.email,
});

const Subscribe = observer(() => {
  const { authStore } = useStores();

  const handleSubmit = async (values: formValues, actions: FormikHelpers<formValues>) => {
    await authStore.subscribe(values.email);
    actions.setStatus();
    actions.resetForm();
  };
  return (
    <div>
      <Formik initialValues={{ email: '' }} validationSchema={schema} onSubmit={handleSubmit}>
        {formik => {
          const { values, errors, touched, handleChange, handleSubmit } = formik;
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.cInput}>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  value={values.email}
                  onChange={handleChange}
                />
                <Button buttonColor={'black'} size={'medium'} className={styles.inputButton} type="submit">
                  Submit
                </Button>
              </div>
              <div className={styles.error}>{getError('email', errors, touched)}</div>
            </form>
          );
        }}
      </Formik>
      <div className={styles.subscribedMsg}>{authStore.isSubscribed ? 'Has been subscribed!' : ''}</div>
    </div>
  );
});
export default Subscribe;

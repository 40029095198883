import React, { useEffect } from 'react';
import { Collapse, Loader, SubscribeSection, TopBanner } from 'components';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import { NewModelBanner } from 'components';
import styles from './EnvironmentalImpact.module.scss';

const EnvironmentalImpact = observer(() => {
  const { vehicleStore, cmsStore } = useStores();

  useEffect(() => {
    cmsStore.getPage('environmental-impact');

    // @ts-ignore
    window.se_customer_state = 'IN';
    const scriptTag = document.createElement('script');
    scriptTag.src =
      'https://content-components.prd.rotw.uplight.io/init?hostId=ipl&v=1639000557511&components=se_content_environment_impact:ev-advisor';
    document.body.insertBefore(scriptTag, document.body.firstChild);
  }, [vehicleStore, cmsStore]);

  const {
    pageContent: { fields },
  } = cmsStore;

  const renderBenefits = () => {
    return (
      <div className={styles.cBenefits}>
        <div className={classNames(styles.cInner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {fields?.benefitsEi?.map((item: any, index: number) => {
            return (
              <div className={styles.container} key={`${index}benefit`}>
                <div className={styles.cIllustration}>
                  <img src={item?.illustration} alt="" />
                  <div className={styles.icon} style={{ backgroundImage: `url(${item?.icon})` }} />
                </div>
                <div className={styles.cInfo}>
                  <h3>
                    <span>{item?.title}</span> {item?.subTitle}
                  </h3>
                  <p>{item?.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (cmsStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      {fields?.topBanner && <TopBanner topBanner={fields?.topBanner} />}

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/*--------- 54% less carbon pollution -----------*/}
          <div className={classNames(styles.cCarbonPollution, styles.desktopContentWidth, styles.cHorOffsetsMobile)}>
            <img src={fields?.carbonPollution?.illustration} alt="" />
            <h3>{fields?.carbonPollution?.title}</h3>
            <p>{fields?.carbonPollution?.description}</p>
          </div>
        </div>
        {/*--------- Benefits -----------*/}
        {renderBenefits()}

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div id="se_content_environment_impact:ev-advisor" />
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {fields?.newModelBanner && <NewModelBanner data={fields?.newModelBanner} />}

          {/* ------ Feature Looks Electric -------*/}
          <div className={styles.cFeatureLooks}>
            <h3>The Future Looks Electric</h3>
            <div className={styles.columns}>
              {fields?.futureLooksElectric?.columns?.map(
                (column: { title: string; description: string; icon: string }, index: number) => {
                  return (
                    <div className={styles.cColumn} key={`${index}fle`}>
                      <img src={column.icon} alt="" />
                      <h4>{column.title}</h4>
                      <p>{column.description}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>

          {/* ------ FAQ -------*/}
          <div className={classNames(styles.cFAQ, styles.desktopContentWidth)}>
            <div className={styles.titlesSection}>
              <h3>{fields?.frequentlyAskedQuestions?.title}</h3>
              <p>{fields?.frequentlyAskedQuestions?.description}</p>
            </div>

            <div className={styles.cFaqOffsets}>
              {fields?.frequentlyAskedQuestions?.accordionItem?.map(
                (item: { question: string; answer: string }, index: number) => {
                  return (
                    <Collapse
                      title={item.question}
                      wrapperStyle={styles.wrapper}
                      titleStyle={styles.faqTitle}
                      key={index}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </Collapse>
                  );
                }
              )}
            </div>
          </div>
          <SubscribeSection />
        </div>

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default EnvironmentalImpact;

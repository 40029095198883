export * from './stores';
export * from './validations';
export * from './content';
export * from './errors';
export * from './sliders';
export * from './pagination';

export enum AuthState {
  SIGN_IN = 'signIn',
  SIGNED_IN = 'signedIn',
  LOADING = 'loading',
}

export const ModelsIds = [
  { id: '1', model: 'leaf' },
  { id: '2', model: 'bolt' },
  { id: '3', model: 'model3' },
];

export type OrderStep =
  | 'order_new'
  | 'order_processing'
  | 'timeslot_chosen'
  | 'vehicle_reserved'
  | 'paperwork_expected'
  | 'paperwork_processing'
  | 'delivery_considered'
  | 'delivery_scheduled'
  | 'vehicle_delivered'
  | 'order_complete';

export const orderSteps = [
  'order_new',
  'order_processing',
  'timeslot_chosen',
  'vehicle_reserved',
  'paperwork_expected',
  'paperwork_processing',
  'delivery_considered',
  'delivery_scheduled',
  'vehicle_delivered',
  'order_complete',
];

export const desktopWidth = 1240;
export const tabletWidth = 1024;

export const ACCESS_TOKEN_NAME = 'emobility/accessToken';
export const REFRESH_TOKEN_NAME = 'emobility/refreshToken';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';

import { Loader, NavigationBar, Charger } from 'components';
import { useHistory } from 'react-router-dom';
import styles from './ChargerOffer.module.scss';
import { ChargerPackage } from '../../types/Vehicle';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { vehicleId: string };
  };
}

const ChargerOffer = observer((props: Props) => {
  const {
    match: {
      params: { vehicleId },
    },
  } = props;

  const history = useHistory();
  const { vehicleStore } = useStores();

  useEffect(() => {
    vehicleStore.getInventoryVehicleDetails(vehicleId);
  }, [vehicleStore, vehicleId]);

  let chargerPackage = {} as ChargerPackage;

  const renderLoader = () => {
    return (
      <div className={styles.cContent}>
        <NavigationBar linkText={'Back to order finalization'} goBack={true} />
        <Loader />
      </div>
    );
  };

  if (vehicleStore.loading) return renderLoader();
  if (vehicleStore.invVehicleDetails?.vehicle?.chargerPackage) {
    chargerPackage = vehicleStore.invVehicleDetails?.vehicle?.chargerPackage;
  }

  const packageID = chargerPackage.id;

  const buttonHandler = async (isEnroll: boolean, isChargerJumpstart: boolean) => {
    if (packageID) {
      const saveBuildCharger = {
        vehicleID: vehicleId,
        chargerPackageID: chargerPackage.id,
        isChargerPackage: isChargerJumpstart,
        isEnroll,
      };
      const buffer = JSON.stringify(saveBuildCharger);
      sessionStorage.setItem('buy/charger-offer', buffer);
    }

    history.push(`/vehicle-details/${vehicleId}/order`);
  };

  return (
    <div className={styles.cContent}>
      <NavigationBar linkText={'Back to builds & price'} goBack={true} />
      <Charger type={'regular'} chargerPackage={chargerPackage} submitHandler={buttonHandler} />
    </div>
  );
});
export default ChargerOffer;

import React from 'react';
import classNames from 'classnames';
import styles from './CheckboxChargerOfferLabel.module.scss';

interface CheckboxPackageLabelProps {
  inputId: string;
  itemTitle: string;
  itemImage: string;
  descriptionList?: string[];
  description?: string;
  price: number;
  discount: number;
  secondPrice: string;
}

const CheckboxChargerOfferLabel = (props: CheckboxPackageLabelProps) => {
  const { inputId, itemTitle, itemImage, descriptionList, price, secondPrice, description, discount } = props;
  const RenderItems = (descriptionList: string[]) => {
    return descriptionList.map((item, index) => (
      <li key={index}>
        {item}
        {index === 0 && (
          <div className={styles.price}>
            <span className={classNames(discount ? styles.underlinePrice : null)}>${price}</span>
            {!!discount && <span className={styles.withPrice}> ${price - discount}</span>}
          </div>
        )}
        {index === 1 && <div className={styles.price}>{secondPrice}</div>}
      </li>
    ));
  };

  return (
    <label className={styles.checkboxPackageLabel} htmlFor={inputId}>
      <div>
        <div className={itemImage === '' ? '' : styles.cVertical}>
          <div>
            <div className={styles.cCheckBox}>
              <h4>{itemTitle}</h4>
            </div>
            {descriptionList && descriptionList.length && (
              <ul className={styles.regularList}>{RenderItems(descriptionList)}</ul>
            )}
            {description}
          </div>

          <div className={styles.cImageBottom}>
            <img src={itemImage} alt="" />
          </div>
        </div>
        <div className={styles.shortDescription}>
          * Your exact cost is determined by the installation details and is paid separately from the vehicle. You can
          opt out after receiving the quote.
        </div>
      </div>
    </label>
  );
};
export default CheckboxChargerOfferLabel;

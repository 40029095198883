import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import 'react-phone-number-input/style.css';

import { Button, StatusHeader } from 'components';
import Verification from './Verification';
import styles from './Auth.module.scss';
import '../../styles/lib/react-phone-number-input.scss';

const SignIn = observer((props: any) => {
  const { authStore, errorsStore } = useStores();
  const [currScreen, setCurrScreen] = useState('signIn');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const onChangeInput = (value: string) => {
    setError('');
    setPhone(value ? value : '');
  };
  const onSubmit = () => {
    if (!phone) return;

    authStore.sendPhone({
      phone,
      onSuccess: () => setCurrScreen('verify'),
      setErrors: (err: string) => setError(err),
    });
  };

  useEffect(() => {}, []);

  const renderSignIn = () => {
    return (
      <div className={styles.cContent}>
        <div className={styles.cTop}>
          <PhoneInput
            autoComplete="off"
            countries={['US']}
            defaultCountry="US"
            placeholder="Enter phone number"
            onKeyDown={e => {
              if (e.key === 'Enter' && isPossiblePhoneNumber(phone)) {
                onSubmit();
              }
            }}
            value={phone}
            limitMaxLength
            onChange={onChangeInput}
          />
          {error && <p className={styles.errorText}>{error}</p>}
        </div>

        <div className={styles.cBottom}>
          <div className={styles.cBottomContent}>
            <p>If you don’t have an account, it will be created.</p>
            <p>
              By creating account, you agree to{' '}
              <a className={styles.underline} href="/terms-service" target="_blank" rel="noopener norefferer">
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a className={styles.underline} href="/privacy-policy" target="_blank" rel="noopener norefferer">
                Privacy Policy
              </a>
            </p>
          </div>
          <Button onClick={onSubmit} size="big" buttonColor="blue" fullWidth disabled={!isPossiblePhoneNumber(phone)}>
            Proceed
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.cPage}>
      <StatusHeader title="Sign in" progress={0} />
      {currScreen === 'signIn' ? (
        renderSignIn()
      ) : (
        <Verification
          goBack={() => {
            setCurrScreen('signIn');
          }}
          onSuccess={() => {
            errorsStore.clearServerError();
            history.push('/');
          }}
          type={'signIn'}
          phone={authStore.userPhone}
        />
      )}
    </div>
  );
});

export default SignIn;

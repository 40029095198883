import React from 'react';
import classNames from 'classnames';
import { getAvatarLetters } from 'helpers/formatters/user';
import styles from './Avatar.module.scss';

interface AvatarProps {
  className?: string;
  size?: 'small' | 'medium';
  color?: 'dark' | 'gradient';
  name: string | undefined;
}

const Avatar = ({ size = 'small', color = 'gradient', name, className = '', ...props }: AvatarProps) => {
  if (name) {
    return (
      <div
        className={classNames(
          {
            [styles.small]: size === 'small',
            [styles.medium]: size === 'medium',
            [styles.dark]: color === 'dark',
            [styles.gradient]: color === 'gradient',
          },
          styles.cAvatar,
          className
        )}
        {...props}
      >
        {getAvatarLetters(name)}
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          {
            [styles.small]: size === 'small',
            [styles.medium]: size === 'medium',
          },
          styles.authBtn,
          className
        )}
        {...props}
      />
    );
  }
};

export default Avatar;

import React from 'react';
import classNames from 'classnames';
import styles from './SubscriptionInfoSection.module.scss';
import { SubscribeSection, Button } from 'components';
import { useHistory } from 'react-router-dom';

const SubscriptionInfoSection = () => {
  const history = useHistory();
  return (
    <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.offsetTop50)}>
      <div className={styles.tdSection}>
        <div className={styles.infoSection}>
          <div className={styles.cTextLimiter}>
            <p className={styles.cuText}>Not ready to buy?</p>
            <h4>Check out our subscription plans that start with only a 30-day commitment</h4>
            <p className={styles.cuTextBottom}>
              Starting at $649 per month, subscription is the easiest way to get into an electric car. The flat monthly
              payment includes registration, insurance, maintenance and 24/7 concierge service.
            </p>
          </div>

          <Button buttonColor={'black'} size={'medium'} onClick={() => history.push('/subscription')}>
            See subscriptions
          </Button>
        </div>

        <div className={styles.illustration} />
      </div>

      <SubscribeSection />
    </div>
  );
};

export default SubscriptionInfoSection;

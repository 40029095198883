import React, { useEffect } from 'react';
import '../../styles/lib/react-slider.scss';

import { setLongDate } from '../../helpers/formatters/date';
import styles from './TestDriveCompleted.module.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { Loader } from '../../components';

const TestDriveCompleted = observer((props: any) => {
  const {
    match: {
      params: { orderId },
    },
  } = props;

  const { orderStore } = useStores();

  useEffect(() => {
    orderStore.getTDriveOrderById(orderId);
  }, [orderStore, orderId]);

  const renderHeader = () => {
    const bgImg = orderStore.currentTDriveOrder
      ? orderStore.currentTDriveOrder.vehicle.visual_set.background_medium
      : '';
    return (
      <div className={styles.img} style={{ backgroundImage: `url(${bgImg})` }}>
        <div className={styles.imgContent}>
          <h1 className={styles.imgTitle}>You’re all set up for your test drive!</h1>
        </div>
      </div>
    );
  };

  if (orderStore.loading) return <Loader />;

  return (
    <div className={styles.cTestDriveCompleted}>
      {renderHeader()}
      <div className={styles.cContent}>
        <p>
          You received an appointment confirmation via email. It is scheduled for:
          <strong>{setLongDate(orderStore.currentTDriveOrder?.schedule?.startDateTime)}</strong>
          See you!
        </p>
      </div>
    </div>
  );
});
export default TestDriveCompleted;

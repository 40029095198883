import { AxiosResponse } from 'axios';

import { createApi } from '../../api/apiCreator';
import environment from '../../environment';
import {
  Order,
  OrderAPI,
  OrderListAPI,
  CreateOrder,
  CreateOrderAPI,
  DriverLicense,
  OrderDocumentsAPI,
  OrderDocuments,
  CreateTDriveOrder,
  CreateTDriveOrderAnswerAPI,
  CreateTDriveOrderAnswer,
  TDriveListAPI,
  TDriveOrderAnswer,
  TDScheduler,
  TDSchedulerAPI,
  CreateChargerOrder,
  CreateChargerOrderAPI,
  ChargerOrderListAPI,
  ChargerOrder,
} from '../../types/Order';

import { PaymentIntentApi, PaymentInstructions, PaymentInstructionsAPI } from '../../types/Payment';

import {
  adaptOrderDocs,
  adaptOrderApi,
  adaptOrder,
  adaptPaymentInstructionsAPI,
  adaptTDriveOrder,
  adaptTDriveOrderAPI,
  adaptCreateTDriveOrderAPI,
  adaptTDSchedulerAPI,
  adaptChargerAPI,
  adaptChargerOrder,
  adaptChargerOrderApi,
} from './adapter';
import { ChargerPackage, ChargerPackageAPI } from '../../types/Vehicle';

export const API = createApi({ baseURL: environment.apiUrls.vehicle });

export default class OrderApi {
  API = API;

  async getOrderById(orderId: string): Promise<Order | undefined> {
    const response: AxiosResponse<OrderAPI> = await this.API.get(`orders/${orderId}/`);
    if (!response) return undefined;

    return adaptOrderApi(response.data);
  }

  async getOrders(): Promise<Order[] | undefined> {
    const response: AxiosResponse<OrderListAPI> = await this.API.get(`orders/`);
    if (!response) return undefined;

    return response.data.results.length
      ? response.data.results.map(item => {
          return adaptOrderApi(item);
        })
      : [];
  }

  async getSubscriptionOrders(): Promise<Order[] | undefined> {
    const response: AxiosResponse<OrderListAPI> = await this.API.get(`subscription_orders/`);
    if (!response) return undefined;

    return response.data.results.length
      ? response.data.results.map(item => {
          return adaptOrderApi(item);
        })
      : [];
  }

  async getChargerOrders(): Promise<ChargerOrder[] | undefined> {
    const response: AxiosResponse<ChargerOrderListAPI> = await this.API.get(`charger_bundle_orders/`);
    if (!response) return undefined;

    return response.data.results.length
      ? response.data.results.map(item => {
          return adaptChargerOrderApi(item);
        })
      : [];
  }

  async getOrderStatus(id: string): Promise<any | undefined> {
    const response: AxiosResponse<any> = await this.API.get(`orders/${id}/status/`);
    if (!response) return undefined;

    return response;
  }

  async getOrderDocuments(orderID: string): Promise<OrderDocuments | undefined> {
    const response: AxiosResponse<OrderDocumentsAPI> = await this.API.get(`orders/${orderID}/documents/`);
    if (!response) return undefined;

    return adaptOrderDocs(response.data);
  }

  async createOrder(order: CreateOrder): Promise<any | undefined> {
    const response: AxiosResponse<CreateOrderAPI> = await this.API.post(`orders/`, adaptOrder(order));
    if (!response) return undefined;
    return response.data;
  }

  async createSubscriptionOrder(order: CreateOrder): Promise<any | undefined> {
    const response: AxiosResponse<CreateOrderAPI> = await this.API.post(`subscription/`, adaptOrder(order));
    if (!response) return undefined;
    return response.data;
  }

  async updateOrder(userID: number, orderID: string): Promise<any | undefined> {
    const response: AxiosResponse<{ id: string }> = await this.API.patch(`orders/${orderID}/`, { created_by: userID });

    if (!response) return undefined;
    return response.data;
  }

  async updateSubscriptionOrder(userID: number, orderID: string): Promise<any | undefined> {
    const response: AxiosResponse<{ id: string }> = await this.API.patch(`subscription/${orderID}/`, {
      created_by: userID,
    });

    if (!response) return undefined;
    return response.data;
  }

  async setOrderCompleted(orderId: string, rate: number, comment: string): Promise<any | undefined> {
    const response: AxiosResponse<any> = await this.API.post(`orders/${orderId}/survey/`, {
      rate: rate,
      comment: comment,
    });
    if (!response) return undefined;
    return response.data;
  }

  async uploadDriverLicense(front: any, back: any): Promise<DriverLicense | undefined> {
    const getFormData = (object: any) =>
      Object.keys(object).reduce((formData, key) => {
        formData.append(key, object[key]);
        return formData;
      }, new FormData());

    const response: AxiosResponse<DriverLicense> = await this.API.post(
      `documents/driver_licenses/`,
      getFormData({
        front: front,
        back: back,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (!response) return undefined;
    return response.data;
  }

  async createPaymentIntent({
    orderId,
    amount,
    currency,
  }: {
    orderId: string;
    amount: string;
    currency: string;
  }): Promise<PaymentIntentApi | undefined> {
    const response: AxiosResponse<PaymentIntentApi> = await this.API.post('payments/', {
      order: orderId,
      amount,
      currency,
    });
    if (!response) return undefined;
    return response.data;
  }

  async createChargerPaymentIntent({
    orderId,
    amount,
    currency,
  }: {
    orderId: string;
    amount: string;
    currency: string;
  }): Promise<PaymentIntentApi | undefined> {
    const response: AxiosResponse<PaymentIntentApi> = await this.API.post('charger_package_payments/', {
      order: orderId,
      amount,
      currency,
    });
    if (!response) return undefined;
    return response.data;
  }

  async updatePaymentIntent(paymentMethodID: string, paymentIntentID: string): Promise<any | undefined> {
    const response: AxiosResponse<{ payment_method: string }> = await this.API.patch(`payments/${paymentIntentID}/`, {
      payment_method: paymentMethodID,
    });
    if (!response) return undefined;
    return response.data;
  }

  async updateChargerPaymentIntent(paymentMethodID: string, paymentIntentID: string): Promise<any | undefined> {
    const response: AxiosResponse<{ payment_method: string }> = await this.API.patch(
      `charger_package_payments/${paymentIntentID}/`,
      {
        payment_method: paymentMethodID,
      }
    );
    if (!response) return undefined;
    return response.data;
  }

  async getPaymentInstructions(orderId: number): Promise<PaymentInstructions | undefined> {
    const response: AxiosResponse<PaymentInstructionsAPI> = await this.API.get(`orders/${orderId}/dealer/`);
    if (!response) return undefined;

    return adaptPaymentInstructionsAPI(response.data);
  }

  async createTDriveOrder(order: CreateTDriveOrder): Promise<CreateTDriveOrderAnswer | undefined> {
    const response: AxiosResponse<CreateTDriveOrderAnswerAPI> = await this.API.post(
      `test_drives/`,
      adaptTDriveOrder(order)
    );
    return adaptCreateTDriveOrderAPI(response.data);
  }

  async getTDriveOrderById(orderId: string): Promise<CreateTDriveOrderAnswer | undefined> {
    const response: AxiosResponse<CreateTDriveOrderAnswerAPI> = await this.API.get(`test_drives/${orderId}/`);
    if (!response) return undefined;

    return adaptCreateTDriveOrderAPI(response.data);
  }

  async getTDriveCalendarByVehicleId(vehicleId: string): Promise<TDScheduler | undefined> {
    const response: AxiosResponse<TDSchedulerAPI> = await this.API.get(
      `test_drive_vehicles/${vehicleId}/appointment_calendar/`
    );
    if (!response) return undefined;
    return adaptTDSchedulerAPI(response.data);
  }

  async updateTDriveOrder(userID: number, orderID: string): Promise<any | undefined> {
    const response: AxiosResponse<{ id: string }> = await this.API.patch(`test_drives/${orderID}/`, {
      created_by: userID,
    });

    if (!response) return undefined;
    return response.data;
  }

  async getTestDrives(): Promise<TDriveOrderAnswer[] | undefined> {
    const response: AxiosResponse<TDriveListAPI> = await this.API.get(`test_drives/`);
    if (!response) return undefined;

    return response.data.results.length
      ? response.data.results.map(item => {
          return adaptTDriveOrderAPI(item);
        })
      : [];
  }

  async getCharger(): Promise<ChargerPackage | undefined> {
    const response: AxiosResponse<ChargerPackageAPI> = await this.API.get(`charger_bundle/`);
    if (!response) return undefined;

    return adaptChargerAPI(response.data);
  }

  async createChargerOrder(order: CreateChargerOrder): Promise<any | undefined> {
    const response: AxiosResponse<CreateChargerOrderAPI> = await this.API.post(
      `/charger_bundle_orders/`,
      adaptChargerOrder(order)
    );
    if (!response) return undefined;
    return response.data;
  }

  async updateChargerOrder(userID: number, orderID: string): Promise<any | undefined> {
    const response: AxiosResponse<{ id: string }> = await this.API.patch(`charger_bundle_orders/${orderID}/`, {
      created_by: userID,
    });

    if (!response) return undefined;
    return response.data;
  }

  async sendChargerSurvey(orderID: string) {
    const response: AxiosResponse<any> = await this.API.post(`charger_bundle_orders/${orderID}/survey/`);
    if (!response) return undefined;
    return response.data;
  }
}

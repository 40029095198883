import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';
import get from 'lodash/get';

import { Header } from 'components';
import { useHistory } from 'react-router-dom';
import Verification from './Verification';
import styles from './Auth.module.scss';

const SignUp = observer((props: any) => {
  const history = useHistory();
  const { authStore, orderStore } = useStores();

  const subscriptionOrderId = get(history, 'location.state.subscriptionOrderId');
  const paymentType = get(history, 'location.state.paymentType');
  const orderId = get(history, 'location.state.orderId');
  const phone = get(history, 'location.state.phone');
  const shouldPay = get(history, 'location.state.shouldPay');
  const parentScreen = get(history, 'location.state.parentScreen');
  const chargerOrderId = get(history, 'location.state.chargerOrderId');

  return (
    <div className={styles.cPage}>
      <Header isLoggedIn={false} />
      <Verification
        onSuccess={async () => {
          if (chargerOrderId) {
            await authStore.getCurrentUser();
            if (authStore.user?.id) {
              await orderStore.updateChargerOrder(authStore.user?.id, chargerOrderId);
            }

            if (shouldPay) {
              history.push(`/order/${chargerOrderId}/charger-payment`);
            } else {
              history.push(`/charge-at-home/order/${chargerOrderId}/confirmation`);
            }

            return;
          }

          if (subscriptionOrderId) {
            await authStore.getCurrentUser();
            if (authStore.user?.id) {
              await orderStore.updateSubscriptionOrder(authStore.user?.id, subscriptionOrderId);
            }
            history.push(`/subscribe-to-buy/confirmation/${paymentType}`);
            return;
          }

          // test drive flow
          if (!!parentScreen && parentScreen === 'testDrive') {
            if (!orderId) return;
            await authStore.getCurrentUser();
            if (authStore.user?.id) {
              await orderStore.updateTDriveOrder(authStore.user?.id, orderId);
            }
            history.push(orderId ? `/test-drive/${orderId}/scheduler` : '/');
            return;
          }

          // buy car flow
          if (shouldPay === false && orderId) {
            await authStore.getCurrentUser();
            if (authStore.user?.id) {
              await orderStore.updateOrder(authStore.user?.id, orderId);
            }
            history.push(`/order/${orderId}/status`);
            return;
          }
          history.push(orderId ? `/order/${orderId}/payment` : '/');
        }}
        goBack={() => {
          history.push(`/order/${orderId}`);
        }}
        type={'signUp'}
        phone={authStore.userPhone || phone}
      />
    </div>
  );
});

export default SignUp;

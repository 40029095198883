import React from 'react';
import { Button } from 'components';
import { useHistory } from 'react-router';
import styles from './VehicleTile.module.scss';
import { VehicleModel } from '../../../../types/MakesModels';
import { setFinalPrice } from 'helpers/formatters/money';
interface Props {
  vehicle: VehicleModel;
  tab?: string;
}

const renderPriceBar = (price: string, priceAfterIncentives?: string, tab?: string, sellingPrice?: string) => {
  if (tab === 'preowned') {
    return (
      <div className={styles.priceBarPreowned}>
        <div>
          <p className={styles.smallDescription}>Price</p>
          <div className={styles.cPrice}>
            <div className={styles.price}>{setFinalPrice(sellingPrice ? sellingPrice : '0')}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.priceBar}>
      <div>
        <p className={styles.smallDescription}>
          MSRP <br />
          <span>from</span>
        </p>
        <div className={styles.cPrice}>
          <div className={styles.price}>{setFinalPrice(price)}</div>
        </div>
      </div>
      {/* ---- */}
      <div className={styles.secondPrice}>
        <div className={styles.cPrice}>
          <div className={styles.price}>{setFinalPrice(priceAfterIncentives ? priceAfterIncentives : '')}</div>
        </div>
        <p className={styles.smallDescription}>
          est. after <br />
          incentives
        </p>
      </div>
    </div>
  );
};

const renderTechParams = (range: string, bodyStyle: string, seatingCapacity: number, capability: string) => {
  return (
    <ul className={styles.cTechParams}>
      <li>
        <div className={styles.label}>Range</div>
        <div className={styles.value}>{range ? range + ' miles' : 'No data'}</div>
      </li>
      <li>
        <div className={styles.label}>Seating</div>
        <div className={styles.value}>{seatingCapacity ? seatingCapacity : 'No data'}</div>
      </li>
      <li>
        <div className={styles.label}>Style</div>
        <div className={styles.value}>{bodyStyle ? bodyStyle : 'No data'}</div>
      </li>
      <li>
        <div className={styles.label}>Capability</div>
        <div className={styles.value}>{capability ? capability : 'No data'}</div>
      </li>
    </ul>
  );
};

const VehicleTile = (props: Props) => {
  const history = useHistory();
  const {
    vehicle: {
      year,
      model,
      make,
      range,
      bodyStyle,
      seatingCapacity,
      capability,
      priceAfterIncentives,
      image,
      price,
      id,
      msrp,
    },
    tab,
  } = props;
  return (
    <div className={styles.vehicle}>
      <div className={styles.cInnerTop}>
        <h3>
          {year} {make} {model}
        </h3>
        {image && image !== 'n/a' ? (
          <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
        ) : (
          <div className={styles.image} />
        )}
      </div>
      <div className={styles.cInnerBottom}>
        {renderTechParams(range, bodyStyle, seatingCapacity, capability)}
        {renderPriceBar(msrp, priceAfterIncentives, tab, price)}
        <Button
          onClick={() => {
            tab === 'new'
              ? history.push(`/new-vehicle-details/${id}`)
              : (window.location.href = `https://trymotor.com/purchase-form/${id}`);
          }}
          type={'button'}
          className={styles.btn}
          buttonColor={'black'}
          arrow={'right'}
          size={'medium-rounded'}
        >
          Learn more
        </Button>
      </div>
    </div>
  );
};

export default VehicleTile;

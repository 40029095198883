import React from 'react';
import { Checkbox } from 'components';
import styles from './CheckboxGroupRestyle.module.scss';

interface Props {
  options: { id: string; value: string; label: string }[];
  name: string;
  label: string;
  onChange: (res: string[]) => void;
  value: string[];
  smallDescription?: 'learningPreferences';
  errorText?: string | false;
}

const CheckboxGroupRestyle = ({ options, label, onChange, value, smallDescription, errorText }: Props) => {
  return (
    <div className={styles.cCheckboxes}>
      <div className={styles.cLabel}>
        <label>
          {label}{' '}
          {smallDescription === 'learningPreferences' && <div className={styles.sl}>&nbsp; Select all that apply</div>}
        </label>
      </div>
      <div className={styles.cList}>
        {options.map((item, index) => {
          let val = value;
          return (
            <Checkbox
              key={`ch${index}`}
              name={item.id}
              text={item.label}
              checked={!!value?.find(el => el === item.id)}
              markerColor="black"
              rounded={true}
              onChange={e => {
                if (e.target.checked) {
                  val?.push(item.id);
                } else {
                  val?.push(item.id);
                  val = val.filter(function (i) {
                    return i !== item.id;
                  });
                }
                onChange(val);
              }}
              className={styles.cuCheckbox}
            />
          );
        })}
      </div>
      <div className={styles.cError}>{errorText ? errorText : ''}</div>
    </div>
  );
};

export default CheckboxGroupRestyle;

import { action, observable, runInAction } from 'mobx';
import { History } from 'history';
import get from 'lodash/get';

import {
  Order,
  CreateOrder,
  OrderDocuments,
  CreateTDriveOrder,
  TDriveUserInfo,
  TDriveOrderAnswer,
  TDScheduler,
  CreateChargerOrder,
  ChargerOrder,
} from '../types/Order';
import { CreatePaymentIntent, PaymentInstructions, PaymentIntentApi, DLError } from '../types/Payment';
import { Package, ChargerPackage } from '../types/Vehicle';
import config from '../environment';

export class OrderStore {
  orderApi;
  routerStore;
  vehicleStore;
  authStore;
  errorsStore;

  constructor(OrderAPI: any, routerStore: History, vehicleStore: any, authStore: any, errorsStore: any) {
    this.orderApi = new OrderAPI();
    if (routerStore) {
      this.routerStore = routerStore;
    }
    this.vehicleStore = vehicleStore;
    this.authStore = authStore;
    this.errorsStore = errorsStore;
  }

  @observable loading: boolean = false;
  @observable loadingTD: boolean = false;
  @observable loadingPacks: boolean = false;
  @observable loadingDocuments: boolean = false;

  @observable fetchError: string = '';
  @observable DLError: DLError = {};
  @observable driverLicense: number | undefined = undefined;
  @observable paymentIntent: PaymentIntentApi | null = null;
  @observable chargerPaymentIntent: PaymentIntentApi | null = null;
  @observable orders: Order[] | null = null;
  @observable subscriptionOrders: Order[] | null = null;
  @observable chargerOrders: ChargerOrder[] | null = null;
  @observable orderDocuments: OrderDocuments | null = null;
  @observable paymentInstructions: PaymentInstructions | undefined;
  @observable orderStatus: any | object = {};
  @observable choosePackages: Package[] | undefined = undefined;
  @observable totalPrice: string = '0';

  @observable currentOrder: Order | undefined = undefined;
  @observable currentTDriveOrder: TDriveOrderAnswer | undefined = undefined;
  @observable currentTDriveCalendarId: string = '';

  @observable tdUserInfo: TDriveUserInfo = {} as TDriveUserInfo;
  @observable testDrives: TDriveOrderAnswer[] | null = null;
  @observable currentTDScheduler: TDScheduler | undefined = undefined;
  @observable charger: ChargerPackage | undefined = undefined;
  @observable chargerSurveySent: boolean = false;

  @action getOrderInitialValues() {
    return {
      paymentType: config.enableLoanPayment ? 'loan' : ('cash' as 'loan' | 'cash'),
      fullName: sessionStorage.getItem('orderForm/fullName') || '',
      email: sessionStorage.getItem('orderForm/email') || '',
      phone: sessionStorage.getItem('orderForm/phone') || '',
      partnersCheckbox: sessionStorage.getItem('orderForm/partnersCheckbox') === 'true' || false,
      partnersName: sessionStorage.getItem('orderForm/partnersName') || '',
      address: sessionStorage.getItem('orderForm/address') || '',
      city: sessionStorage.getItem('orderForm/city') || '',
      state: sessionStorage.getItem('orderForm/state') || '',
      zipCode: sessionStorage.getItem('orderForm/zipCode') || '',
      mailingAddressCheckbox: Boolean(sessionStorage.getItem('orderForm/mailingAddressCheckbox')) || true,
      mailingAddress: sessionStorage.getItem('orderForm/mailingAddress') || '',
      mailingCity: sessionStorage.getItem('orderForm/mailingCity') || '',
      mailingState: sessionStorage.getItem('orderForm/mailingState') || '',
      mailingZipCode: sessionStorage.getItem('orderForm/mailingZipCode') || '',
      frontDLPicture: undefined,
      backDLPicture: undefined,
    };
  }

  @action getChargerOrderInitialValues() {
    return {
      paymentType: config.enableLoanPayment ? 'loan' : ('cash' as 'loan' | 'cash'),
      fullName: sessionStorage.getItem('orderForm/fullName') || '',
      email: sessionStorage.getItem('orderForm/email') || '',
      phone: sessionStorage.getItem('orderForm/phone') || '',
      address: sessionStorage.getItem('orderForm/address') || '',
      city: sessionStorage.getItem('orderForm/city') || '',
      state: sessionStorage.getItem('orderForm/state') || '',
      zipCode: sessionStorage.getItem('orderForm/zipCode') || '',
      mailingAddressCheckbox: Boolean(sessionStorage.getItem('orderForm/mailingAddressCheckbox')) || true,
      mailingAddress: sessionStorage.getItem('orderForm/mailingAddress') || '',
      mailingCity: sessionStorage.getItem('orderForm/mailingCity') || '',
      mailingState: sessionStorage.getItem('orderForm/mailingState') || '',
      mailingZipCode: sessionStorage.getItem('orderForm/mailingZipCode') || '',
    };
  }

  @action async getOrderById(orderId: string) {
    this.currentOrder = undefined;
    try {
      this.loading = true;
      this.currentOrder = await this.orderApi.getOrderById(orderId);
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async getOrders() {
    try {
      this.loading = true;
      this.orders = await this.orderApi.getOrders();
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async getSubscriptionOrders() {
    try {
      this.loading = true;
      this.subscriptionOrders = await this.orderApi.getSubscriptionOrders();
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async getChargerOrders() {
    try {
      this.loading = true;
      this.chargerOrders = await this.orderApi.getChargerOrders();
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async getOrderStatus(id: string) {
    try {
      this.loading = true;
      const response = await this.orderApi.getOrderStatus(id);
      this.orderStatus = { [id]: response.data };
      return response;
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async placeOrder(phone: string, order: CreateOrder, isSignedIn: boolean, shouldPay: boolean) {
    try {
      this.fetchError = '';
      this.loading = true;
      let orderData = order;
      orderData.driverLicense = this.driverLicense;
      const response = await this.orderApi.createOrder(orderData);

      if (!this.routerStore) return;

      if (isSignedIn) {
        if (shouldPay) {
          this.routerStore.push(`/order/${response.id}/payment`);
        }

        if (!shouldPay) {
          runInAction(async () => {
            await this.authStore.getCurrentUser();
            await this.updateOrder(this.authStore.user.id, response.id);

            if (this.routerStore) {
              this.routerStore.push(`/order/${response.id}/status`);
            }
          });
        }
      } else {
        this.routerStore.push('/auth/sign-up', { orderId: response.id, phone: order.phone, shouldPay });
      }
    } catch ({ response }) {
      const isOrderExistError = get(response, 'data.detail.non_field_errors[0]');
      const isDriverLicenseError = get(response, 'data.driver_license');
      if (isDriverLicenseError) {
        this.fetchError = isDriverLicenseError;
        return;
      }

      if (isOrderExistError) {
        this.fetchError = isOrderExistError;
        return;
      }
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async placeSubscriptionOrder(phone: string, order: CreateOrder, isSignedIn: boolean) {
    try {
      this.fetchError = '';
      this.loading = true;
      let orderData = order;
      orderData.driverLicense = this.driverLicense;
      const response = await this.orderApi.createSubscriptionOrder(orderData);
      sessionStorage.removeItem('subscribe-to-buy/charger-offer');
      if (!this.routerStore) return;

      if (isSignedIn) {
        runInAction(async () => {
          await this.authStore.getCurrentUser();
          await this.updateSubscriptionOrder(this.authStore.user.id, response.id);

          if (this.routerStore) {
            this.routerStore.push(`/subscribe-to-buy/confirmation/${order.paymentType}`);
          }
        });
      } else {
        this.routerStore.push('/auth/sign-up', {
          subscriptionOrderId: response.id,
          paymentType: order.paymentType,
          phone: order.phone,
        });
      }
    } catch ({ response }) {
      const isOrderExistError = get(response, 'data.detail.non_field_errors[0]');
      const isDriverLicenseError = get(response, 'data.driver_license');
      if (isDriverLicenseError) {
        this.fetchError = isDriverLicenseError;
        return;
      }

      if (isOrderExistError) {
        this.fetchError = isOrderExistError;
        return;
      }
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async updateSubscriptionOrder(userID: number, orderID: string) {
    try {
      this.fetchError = '';
      this.loading = true;
      await this.orderApi.updateSubscriptionOrder(userID, orderID);
    } catch (err) {
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async updateOrder(userID: number, orderID: string) {
    try {
      this.fetchError = '';
      this.loading = true;
      await this.orderApi.updateOrder(userID, orderID);
    } catch (err) {
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async getOrderDocuments(orderID: string) {
    try {
      this.loadingDocuments = true;
      const docs = await this.orderApi.getOrderDocuments(orderID);
      this.orderDocuments = docs;

      return docs;
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loadingDocuments = false;
    }
  }

  @action async setOrderCompleted(orderId: string, rate: number, comment: string) {
    try {
      this.fetchError = '';
      this.loading = true;
      await this.orderApi.setOrderCompleted(orderId, rate, comment);
      if (this.routerStore) {
        this.routerStore.push('/');
      }
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async uploadDriverLicense(front: any, back: any) {
    try {
      this.DLError = {};
      this.loadingDocuments = true;
      const result = await this.orderApi.uploadDriverLicense(front, back);
      this.driverLicense = result.id;
    } catch ({ response }) {
      this.DLError = response.data;
    } finally {
      this.loadingDocuments = false;
    }
  }

  @action async createPaymentIntent({ orderId, amount, currency }: CreatePaymentIntent) {
    try {
      this.loading = true;
      this.paymentIntent = await this.orderApi.createPaymentIntent({ orderId, amount, currency });
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async createChargerPaymentIntent({ orderId, amount, currency }: CreatePaymentIntent) {
    try {
      this.loading = true;
      this.chargerPaymentIntent = await this.orderApi.createChargerPaymentIntent({ orderId, amount, currency });
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async updatePaymentIntent(paymentMethodID: string, paymentIntentID: string, orderId: string) {
    try {
      this.loading = true;
      const response = await this.orderApi.updatePaymentIntent(paymentMethodID, paymentIntentID);

      if (response['payment_method']) {
        this.routerStore?.push(`/order/${orderId}/status`);
      }
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async updateChargePaymentIntent(paymentMethodID: string, paymentIntentID: string, orderId: string) {
    try {
      this.loading = true;
      const response = await this.orderApi.updateChargerPaymentIntent(paymentMethodID, paymentIntentID);

      if (response['payment_method']) {
        this.routerStore?.push(`/charge-at-home/order/${orderId}/confirmation`);
      }
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async getPaymentInstructions(orderId: string) {
    try {
      this.paymentInstructions = undefined;
      this.loading = true;
      if (orderId) {
        this.paymentInstructions = await this.orderApi.getPaymentInstructions(orderId);
      }
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loading = false;
    }
  }

  @action async getDueNowPackages(orderId: string) {
    this.loadingPacks = true;
    try {
      if (orderId) {
        const currentBuild = await this.vehicleStore.getBuildByOrderId(orderId);
        const { packages, total } = currentBuild;
        this.choosePackages = packages;
        this.totalPrice = total;
      }
    } catch (err) {
      this.fetchError = 'Server Error';
    } finally {
      this.loadingPacks = false;
    }
  }

  @action async placeTDriveOrder(phone: string, order: CreateTDriveOrder, isSignedIn: boolean) {
    try {
      this.errorsStore.clearServerError();
      this.fetchError = '';
      this.loading = true;

      const response = await this.orderApi.createTDriveOrder(order);
      if (!this.routerStore) return;

      if (isSignedIn) {
        runInAction(async () => {
          await this.authStore.getCurrentUser();
          await this.updateTDriveOrder(this.authStore.user.id, response.id);

          if (this.routerStore) {
            this.routerStore.push(`/test-drive/${response.id}/scheduler`);
          }
        });
      } else {
        this.routerStore.push('/auth/sign-up', { orderId: response.id, phone: order.phone, parentScreen: 'testDrive' });
      }
    } catch (error) {
      this.fetchError = error.response?.data?.error ? error.response.data.error : 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async getTestDriveScheduler(vehicleId: number) {
    try {
      this.loading = true;
      this.currentTDScheduler = await this.orderApi.getTDriveCalendarByVehicleId(vehicleId);
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async getTDriveOrderById(orderId: string) {
    this.currentTDriveOrder = undefined;
    try {
      this.loading = true;
      this.currentTDriveOrder = await this.orderApi.getTDriveOrderById(orderId);
      this.currentTDriveCalendarId = await this.orderApi.getTDriveCalendarByVehicleId(
        this.currentTDriveOrder?.vehicle.id
      );
      const { firstName, lastName, email, phone } = this.currentTDriveOrder as TDriveOrderAnswer;

      this.tdUserInfo.firstName = firstName;
      this.tdUserInfo.lastName = lastName;
      this.tdUserInfo.email = email;
      this.tdUserInfo.phone = phone;
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async updateTDriveOrder(userID: number, orderID: string) {
    try {
      this.fetchError = '';
      this.loading = true;
      await this.orderApi.updateTDriveOrder(userID, orderID);
    } catch (err) {
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async getTestDrives() {
    try {
      this.loadingTD = true;
      this.testDrives = await this.orderApi.getTestDrives();
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loadingTD = false;
    }
  }

  @action async getCharger() {
    try {
      this.loading = true;
      this.charger = await this.orderApi.getCharger();
      const amountDueNow: string = this.charger?.dueNow || '0';
      sessionStorage.setItem('chargerPackagePrice', amountDueNow);
    } catch ({ response }) {
      this.fetchError = response;
    } finally {
      this.loading = false;
    }
  }

  @action async updateChargerOrder(userID: number, orderID: string) {
    try {
      this.fetchError = '';
      this.loading = true;
      await this.orderApi.updateChargerOrder(userID, orderID);
    } catch (err) {
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async placeChargerOrder(phone: string, order: CreateChargerOrder, isSignedIn: boolean, shouldPay: boolean) {
    try {
      this.fetchError = '';
      this.loading = true;
      const response = await this.orderApi.createChargerOrder(order);
      console.log('response.id', response.id);
      sessionStorage.removeItem('standalone-charger/is-enroll');
      if (!this.routerStore) return;

      if (isSignedIn) {
        if (shouldPay) {
          this.routerStore.push(`/order/${response.id}/charger-payment`);
        }
        if (!shouldPay) {
          runInAction(async () => {
            await this.authStore.getCurrentUser();
            await this.updateChargerOrder(this.authStore.user.id, response.id);

            if (this.routerStore) {
              this.routerStore.push(`/charge-at-home/order/${response.id}/confirmation`);
            }
          });
        }
      } else {
        console.log('shouldPay', shouldPay);
        this.routerStore.push('/auth/sign-up', {
          chargerOrderId: response.id,
          phone: order.phone,
          shouldPay,
        });
      }
    } catch ({ response }) {
      const isOrderExistError = get(response, 'data.detail.non_field_errors[0]');
      const isDriverLicenseError = get(response, 'data.driver_license');
      if (isDriverLicenseError) {
        this.fetchError = isDriverLicenseError;
        return;
      }

      if (isOrderExistError) {
        this.fetchError = isOrderExistError;
        return;
      }
      this.fetchError = 'Please enter correct data';
    } finally {
      this.loading = false;
    }
  }

  @action async sendChargerSurvey(orderId: string) {
    try {
      this.loading = true;
      console.log('orderId', orderId);
      await this.orderApi.sendChargerSurvey(orderId);
      this.chargerSurveySent = true;
    } catch ({ response }) {
      this.fetchError = 'Internal error';
    } finally {
      this.loading = false;
    }
  }
}

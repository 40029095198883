import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

interface Props {
  placeholder?: string;
  id?: string;
  style?: string;
  containerStyle?: string;
  errorText?: string | false;
  text?: string;
  value?: string;
  name?: string;
  type?: string;
  autoFocus?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({ id, value, placeholder, errorText = '', text = '', style, containerStyle, ...props }: Props) => (
  <div className={classNames(styles.cInput, errorText ? styles.cError : null, containerStyle)}>
    <input
      value={value}
      id={id || placeholder}
      className={classNames(styles.input, errorText ? styles.error : null, style)}
      {...props}
    />
    <label
      className={classNames(styles.label, value ? styles.labelActive : errorText ? styles.errorLabel : null)}
      htmlFor={id || placeholder}
    >
      {placeholder}
    </label>
    {errorText ? <p className={styles.errorText}>{errorText}</p> : text ? <p>{text}</p> : null}
  </div>
);

export default Input;

import React, { useEffect } from 'react';
import { ModalFrame, Loader, SingleModelSlider, SubscribeSection } from 'components';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { VehicleInventory } from '../../types/Vehicle';
import { setFinalPrice } from '../../helpers/formatters/money';
import { getVehicleImages } from '../../helpers/visualSet';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { desktopWidth } from '../../appConstants';
import styles from './SubscribeToBuy.module.scss';
import { RouteComponentProps } from 'react-router-dom';

const renderModalFrame = (invVehicleDetails: VehicleInventory | undefined) => {
  if (!invVehicleDetails) return;
  const { vin, color, interior, type, trim, mileage, modelNumber, stock, msrp, sellingPrice, dealerCode } =
    invVehicleDetails;

  return (
    <ModalFrame
      extDealerCode={dealerCode}
      vin={vin}
      msrp={msrp}
      price={sellingPrice}
      modelNum={modelNumber}
      modelTrim={trim.title}
      stockCode={stock}
      extColor={color.title}
      intColor={interior.title}
      isNew={type === 'new' ? 'true' : 'false'}
      miles={mileage.toString()}
    />
  );
};

const renderProperties = (invVehicleDetails: VehicleInventory | undefined) => {
  if (!invVehicleDetails) return;
  const { vin, color, interior, mileage, trim } = invVehicleDetails;
  return (
    <ul className={styles.mainProperties}>
      <li>
        <span>VIN:</span>
        <span>{vin}</span>
      </li>
      <li>
        <span>Mileage:</span>
        <span>{mileage} miles</span>
      </li>
      <li>
        <span>Range:</span>
        <span>{trim?.range} miles battery only</span>
      </li>
      <li>
        <span>Exterior:</span>
        <span>{color.title}</span>
      </li>
      <li>
        <span>Interior:</span>
        <span>{interior.title}</span>
      </li>
    </ul>
  );
};

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { vin: string };
  };
}

const SubscribeToBuy = observer((props: Props) => {
  const { width } = useWindowDimensions();
  const { vehicleStore, cmsStore } = useStores();
  const {
    match: {
      params: { vin },
    },
  } = props;

  useEffect(() => {
    async function getVehicle() {
      await vehicleStore.getSubscriptionVehicleByVin(vin);
      await cmsStore.getPage('subscribe-to-buy');
    }
    getVehicle();
  }, [vin, vehicleStore, cmsStore]);

  const renderCMSDescription = (mainDescription: any) => {
    return (
      <div className={styles.cCMSContent}>
        <h2>{mainDescription.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: mainDescription.content }} />
        <p className={styles.bottomText}>{mainDescription.bottomText}</p>
      </div>
    );
  };

  const renderCharger = (charger: any) => {
    if (!vehicleStore.subscVehicleDetails?.vehicle?.chargerPackage) {
      return;
    }
    const { maxPrice, minPrice, stationPrice, enrollPrice } = vehicleStore.subscVehicleDetails?.vehicle?.chargerPackage;

    if (!charger.hideChargerBundle) {
      return (
        <div>
          <div className={styles.cJumpStartContent}>
            <div className={styles.jsIllustration} />
            <h2>{charger.title}</h2>
            <h3>{charger.subTitle1}</h3>
            <p>{charger.description1}</p>

            <h3 className={styles.cJumpPrice}>
              <div>
                Charger: <span className={styles.oldPrice}>{setFinalPrice(stationPrice)}</span>{' '}
                <span className={styles.priceAfterDiscount}>
                  {setFinalPrice((+stationPrice - +enrollPrice).toString())}
                </span>
              </div>
              <div>
                Faster charger install:{' '}
                <span className={styles.price}>
                  {setFinalPrice(minPrice)}-{setFinalPrice(maxPrice)}*
                </span>
              </div>
              <div className={styles.footNote}>
                *Your exact cost is determined by the installation details and is paid separately from the vehicle. You
                can opt out after receiving the quote.
              </div>
            </h3>

            <h3>{charger.subTitle2}</h3>
            <p>
              {charger.description2}
              <br />
              <br />
              <span className={styles.highlightText}>Get {setFinalPrice(enrollPrice)} off</span> the charger and
              installation just for enrolling.
            </p>
          </div>
        </div>
      );
    }
  };

  const renderVehicleDetails = () => {
    const {
      pageContent: { fields },
    } = cmsStore;
    if (!fields) return;
    return (
      <div className={styles.cFullDescription}>
        {fields.mainDescription && renderCMSDescription(fields.mainDescription)}
        {fields.charger && renderCharger(fields.charger)}
      </div>
    );
  };

  const renderModalPanel = (subscVehicleDetails: VehicleInventory | undefined) => {
    if (!subscVehicleDetails) return;
    const { vehicle, sellingPrice, trim, year /*taxes, titleAndRegistration*/ } = subscVehicleDetails;
    return (
      <div className={styles.cModalPanel}>
        <div className={styles.modalPanel}>
          <h2>
            {year} {vehicle.model} {trim.title}
          </h2>
          <div className={styles.totalPrice}>
            Price: {setFinalPrice(sellingPrice)}{' '}
            {/*<div className={styles.iconInfo}>
              <div className={styles.myTooltip}>
                <p>
                  <strong>Document fees:</strong> $199
                </p>
                <p>
                  <strong>Title and Registration:</strong>{' '}
                  {setFinalPrice(titleAndRegistration ? titleAndRegistration : '0')}
                </p>
                <p>
                  <strong>Taxes:</strong> {setFinalPrice(taxes ? taxes : '0')}
                </p>
              </div>
            </div>*/}
          </div>
          {width < desktopWidth && (
            <div className={styles.cSlider}>
              <SingleModelSlider imagesList={getVehicleImages(subscVehicleDetails)} />
            </div>
          )}

          {renderProperties(subscVehicleDetails)}

          <div className={styles.cFrame}>{renderModalFrame(subscVehicleDetails)}</div>
        </div>
      </div>
    );
  };

  if (vehicleStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      <div className={styles.cContent}>
        <div className={styles.cHorOffsetsMobile}>
          <h1>
            Your exclusive <br />
            subscribe-to-buy offer
          </h1>
        </div>
        <div className={styles.cSticky}>
          {renderModalPanel(vehicleStore.subscVehicleDetails)}
          <div className={styles.cModal}>
            {width >= desktopWidth && (
              <div className={styles.cSlider}>
                <SingleModelSlider imagesList={getVehicleImages(vehicleStore.subscVehicleDetails)} />
              </div>
            )}
          </div>

          {renderVehicleDetails()}
        </div>
        <div className={styles.cHorOffsetsMobile}>
          <SubscribeSection />
        </div>
      </div>
    </div>
  );
});

export default SubscribeToBuy;

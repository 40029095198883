import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const Card = ({ children, className = '' }: Props) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};
export default Card;

import React, { useState, ReactNode } from 'react';
import { Collapse } from 'react-collapse';
import classNames from 'classnames';

import styles from './Collapse.module.scss';

interface Props {
  title: string | ReactNode;
  isOpenedDefault?: boolean;
  children: ReactNode;
  titleStyle?: string;
  wrapperStyle?: string;
  bottomBar?: ReactNode;
}

const CollapseComponent = ({
  title,
  isOpenedDefault,
  titleStyle = '',
  wrapperStyle = '',
  children,
  bottomBar,
}: Props) => {
  const [isOpened, setOpen] = useState(isOpenedDefault);

  return (
    <div className={classNames(styles.wrapper, wrapperStyle)}>
      <div className={classNames(styles.cCollapse, titleStyle)} onClick={() => setOpen(!isOpened)}>
        {typeof title === 'string' ? (
          <>
            <h4>{title}</h4>
          </>
        ) : (
          title
        )}
        <img src={`/images/${isOpened ? 'up' : 'down'}.svg`} alt="" className={styles.arrow} />
      </div>
      <Collapse isOpened={isOpened}>{children}</Collapse>
      {bottomBar}
    </div>
  );
};

export default CollapseComponent;

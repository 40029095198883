import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import styles from './TopPromo.module.scss';
import classNames from 'classnames';

const TopPromo = observer(() => {
  const { cmsStore } = useStores();
  useEffect(() => {
    cmsStore.getCollection('top_promo');
  }, [cmsStore]);

  const [isShowPromo, setShowPromo] = useState(true);

  if (!isShowPromo || !cmsStore.collectionContent?.topPromo || !cmsStore.collectionContent?.topPromo[0].isShowPromo)
    return null;

  return (
    <div className={classNames(styles.cPromo)}>
      <div className={classNames(styles.desktopWidth, styles.innerPromo, styles.cHorOffsets)}>
        <div dangerouslySetInnerHTML={{ __html: cmsStore.collectionContent?.topPromo[0].content }} />
        <button onClick={() => setShowPromo(false)} />
      </div>
    </div>
  );
});
export default TopPromo;

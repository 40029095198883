import React from 'react';
import styles from './CheckboxButtonLabel.module.scss';
import classNames from 'classnames';

interface Props {
  inputId: string;
}

const CheckboxButtonLabel = (props: Props) => {
  const { inputId } = props;
  return (
    <label className={styles.checkboxButtonLabel} htmlFor={inputId}>
      <div className={classNames(styles.btn, styles.btnNormal)}>Add to order</div>
      <div className={classNames(styles.btn, styles.btnActive)}>Added</div>
    </label>
  );
};
export default CheckboxButtonLabel;

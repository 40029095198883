import React, { useEffect } from 'react';
import { TextCarouselSlider, BannerContainer, InfoBanner, Button, SubscribeSection } from 'components';
import classNames from 'classnames';
import styles from './Home.module.scss';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { VehiclesList } from '../../components/sections';

const Home = observer(() => {
  const { cmsStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    cmsStore.getPage('home');
  }, [cmsStore]);

  const {
    pageContent: { fields },
  } = cmsStore;

  return (
    <div className={styles.cContentHome}>
      <div
        className={styles.topBanner}
        style={fields?.heroImage ? { backgroundImage: `url(${fields.heroImage})` } : {}}
      >
        <div className={classNames(styles.innerTopBanner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.topBannerContent}>
            <h1 dangerouslySetInnerHTML={{ __html: fields?.mainHeadline }} />
            <p className={styles.smallTitle}>{fields?.subTitle}</p>
            <Button
              className={styles.cuBtn}
              buttonColor={'white'}
              size={'medium'}
              arrow={'right'}
              onClick={() => history.push('/subscription')}
            >
              Learn more
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.desktopWidth}>
        <div className={classNames(styles.cSlider)}>
          <TextCarouselSlider slidesList={fields?.bannersSlider} />
        </div>
      </div>

      {!fields?.hideSubscriptionOptionsBanner && (
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <BannerContainer bName={'subscription'} bStyle={styles.bannerOffsetTop} />
        </div>
      )}
      {!fields?.hideInfoBanners && (
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <h3 className={classNames(styles.offsetTop50, styles.offsetBottom16)}>{fields?.bannersTitle}</h3>
          <div className={styles.cBanners}>
            {fields?.banners?.map(
              (item: { title: string; linkCaption: string; linkUrl: string; illustration: string }, index: number) => (
                <InfoBanner
                  key={index}
                  bType={'withIcon'}
                  bTitle={item.title}
                  bLink={{ url: item.linkUrl, caption: item.linkCaption }}
                  bIconURL={item.illustration}
                />
              )
            )}
          </div>
        </div>
      )}

      {/* ------ Models Slider -------*/}
      <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cSliderSubscription)}>
        <VehiclesList fields={fields} />
      </div>

      {/* ------ About -------*/}
      <div className={styles.bgDark}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cChargingPrograms)}>
          <div className={styles.leftCol}>
            <h3>{fields?.chargingPrograms?.title}</h3>
            <p>{fields?.chargingPrograms?.description}</p>
            <Button
              buttonColor={'black'}
              size={'medium'}
              arrow={'right'}
              onClick={() => {
                history.push('/charge-at-home');
              }}
            >
              See all charging programs
            </Button>
            <div className={styles.cIllustration}>
              <img src={fields?.chargingPrograms?.illustration} alt="" />
            </div>
          </div>

          <div className={styles.rightCol}>
            {fields?.chargingPrograms?.rightColumn?.map((item: any, index: number) => {
              return (
                <div className={styles.colItem} key={index}>
                  <img src={item.icon} alt="" />
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
        <SubscribeSection />
      </div>
    </div>
  );
});
export default Home;

import React, { useEffect, useState } from 'react';
import { Loader, StatusBar, CheckboxButtonLabel, Button } from 'components';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styles from './BundleOptions.module.scss';
import { useStores } from '../../hooks/use-stores';
import { setRegularFormat } from '../../helpers/formatters/money';
import { standAloneChargerStatusBar } from '../../appConstants';
import { lsetRemover } from '../../helpers/formatters/stringsEscaping';

const BundleOptions = observer(() => {
  const { orderStore, cmsStore } = useStores();

  useEffect(() => {
    cmsStore.getPage('bundle-options');
    orderStore.getCharger();
  }, [orderStore, cmsStore]);

  const [isEnroll, setEnroll] = useState(false);

  const chargerConfig = sessionStorage.getItem('standalone-charger/is-enroll');

  useEffect(() => {
    setEnroll(chargerConfig === '1');
    // eslint-disable-next-line
  }, []);
  const history = useHistory();
  const {
    pageContent: { fields },
  } = cmsStore;

  if (cmsStore.loading) return <Loader />;
  if (!orderStore.charger) return null;
  const { stationPrice, minPrice, maxPrice, enrollPrice } = orderStore.charger;

  const renderDiscountMark = (price: string, description: string) => {
    return (
      <div className={styles.enrollMark}>
        <div>
          <div className={styles.cPrice}>
            <span>$</span>
            {price}
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.cPage}>
      <div className={styles.topBanner}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <StatusBar steps={standAloneChargerStatusBar} activeStep={'Bundle options'} className={styles.cuStatusBar} />
          <h1>{fields?.mainTitle}</h1>

          <div className={styles.cBundleTiles}>
            <div className={classNames(styles.tile, styles.leftTile)}>
              {cmsStore.collectionContent?.topPromo &&
                cmsStore.collectionContent?.topPromo[0].isShowPromo &&
                renderDiscountMark(
                  cmsStore.collectionContent?.topPromo[0]?.price,
                  cmsStore.collectionContent?.topPromo[0]?.description
                )}
              <div className={styles.cFlexRow}>
                <div className={styles.cInner}>
                  <div className={styles.illustration} style={{ backgroundImage: `url(${fields?.chargerImage})` }} />
                  <p className={styles.description}>{fields?.chargerDescription}</p>
                  <div className={styles.cPrice}>
                    <span className={isEnroll ? styles.lineThrow : ''}>${setRegularFormat(stationPrice)}</span>{' '}
                    {isEnroll && (
                      <span className={styles.highlightingText}>
                        ${setRegularFormat((+stationPrice - +enrollPrice).toString())}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.tilesSplitter}>+</div>

                <div className={styles.cInner}>
                  <div
                    className={styles.illustration}
                    style={{ backgroundImage: `url(${fields?.installationImage})` }}
                  />
                  <p className={styles.description}>{fields?.installationDescription}</p>
                  <div className={styles.cPrice}>
                    ${setRegularFormat(minPrice)}-${setRegularFormat(maxPrice)}
                  </div>
                </div>
              </div>
              <div className={classNames(styles.cButton, styles.cInputCustomize)}>
                <input
                  id={'chargerPackage'}
                  name={'chargerPackage'}
                  type="checkbox"
                  value={'chargerPackage'}
                  checked={true}
                  readOnly={true}
                />
                <CheckboxButtonLabel inputId={'chargerPackage'} />
              </div>
            </div>

            <div className={classNames(styles.tile, styles.rightTile)}>
              <div className={styles.cInner}>
                <div className={styles.illustration} style={{ backgroundImage: `url(${fields?.enrollImage})` }} />
                <p className={styles.description}>
                  Enroll in EV Managed Charging and get ${setRegularFormat(enrollPrice)} off your bundle
                </p>
                <div className={styles.cPrice}>Free</div>
              </div>
              <div className={classNames(styles.cButton, styles.cInputCustomize)}>
                <input
                  id={'enroll'}
                  name={'enroll'}
                  type="checkbox"
                  value={'enroll'}
                  checked={isEnroll}
                  onChange={() => {
                    sessionStorage.setItem('standalone-charger/is-enroll', isEnroll ? '0' : '1');
                    setEnroll(!isEnroll);
                  }}
                />
                <CheckboxButtonLabel inputId={'enroll'} />
              </div>
            </div>
          </div>
          <div className={styles.cOrderBtn}>
            <Button
              size={'big'}
              buttonColor={'black'}
              arrow={'right'}
              className={styles.orderBtn}
              onClick={() => history.push('/charge-at-home/order')}
            >
              Order my bundle
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.howItWorks}>
            <h3>{fields?.howItWorks?.title}</h3>
            <div className={styles.infoHowItWorks}>
              {fields?.howItWorks?.infoColumn?.map((item: { title: string; description: string }, index: number) => {
                return (
                  <div className={styles.textSection} key={index}>
                    <h4>{item.title}</h4>
                    <div dangerouslySetInnerHTML={{ __html: lsetRemover(item.description) }} />
                  </div>
                );
              })}
            </div>
          </div>

          <h3>{fields?.contentTitle}</h3>
          <div className={styles.cTwoColumns}>
            <div>
              {fields?.contentColumnLeft?.contentParagraph?.map(
                (item: { title: string; description: string }, index: number) => {
                  return (
                    <div className={styles.textSection} key={index}>
                      <h4>{item.title}</h4>
                      <div dangerouslySetInnerHTML={{ __html: lsetRemover(item.description) }} />
                    </div>
                  );
                }
              )}
            </div>
            <div>
              {fields?.contentColumnRight?.contentParagraph?.map(
                (item: { title: string; description: string }, index: number) => {
                  return (
                    <div className={styles.textSection} key={index}>
                      <h4>{item.title}</h4>
                      <div dangerouslySetInnerHTML={{ __html: lsetRemover(item.description) }} />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BundleOptions;

import React from 'react';
import Slider from 'react-slick';
import { CarouselSliderLearningProps, LearningSlideContent } from 'types/Slider';
import classNames from 'classnames';
import styles from './CarouselLearningSlider.module.scss';

const RenderSlides = (slides: LearningSlideContent[]) => {
  console.log('slides', slides);
  if (!slides || !slides.length) {
    return null;
  }
  return slides.map((item, index) => (
    <div key={index} className={styles.cSliderItem}>
      <div className={styles.sliderItem}>
        <div className={styles.cImage} style={{ backgroundImage: `url(${item.illustration})` }} />
        <h4>{item.title}</h4>
        <p
          className={styles.sliderItemText}
          dangerouslySetInnerHTML={{ __html: item.description ? item.description : '' }}
        />
      </div>
    </div>
  ));
};

const CarouselLearningSlider = (props: CarouselSliderLearningProps) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  let slider: Slider | null = null;

  if (!props.slidesList) return null;
  return (
    <div className={styles.cSlider}>
      <div className={styles.cTitle}>
        <h3>{props?.title}</h3>
        <div className={styles.cButtons}>
          <button
            className={styles.btnSwipe}
            onClick={() => {
              if (slider) {
                slider.slickPrev();
              }
            }}
          />

          <button
            className={classNames(styles.btnSwipe, styles.rightDir)}
            onClick={() => {
              if (slider) {
                slider.slickNext();
              }
            }}
          />
        </div>
      </div>

      <Slider ref={c => (slider = c)} {...settings}>
        {RenderSlides(props.slidesList)}
      </Slider>
    </div>
  );
};
export default CarouselLearningSlider;

import * as Yup from 'yup';
import { VALIDATION_RULES } from 'appConstants';

export interface formValues {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  driveLicense: string;
  state: string;
  email: string;
  phone: string;
  vehicles: number;
  isBackgroundInvestigation: boolean;
  isFairCredit: boolean;
}

export const schema = Yup.object().shape({
  firstName: VALIDATION_RULES.firstName,
  middleName: VALIDATION_RULES.middleName,
  lastName: VALIDATION_RULES.lastName,
  dob: VALIDATION_RULES.dob,
  email: VALIDATION_RULES.email,
  phone: VALIDATION_RULES.phone,
  driveLicense: VALIDATION_RULES.driveLicenseNumber,
  state: VALIDATION_RULES.state,
  isBackgroundInvestigation: VALIDATION_RULES.acceptCheckbox,
  isFairCredit: VALIDATION_RULES.acceptCheckbox,
});

import { action, observable } from 'mobx';

export class ErrorsStore {
  @observable serverError: string | null = null;
  @observable serverGetError: string | null = null;

  @action setServerError(error: string) {
    this.serverError = error === 'string' ? error : 'Something went wrong';
  }

  @action setServerGetError(error: string) {
    this.serverGetError = error;
  }

  @action clearServerError() {
    this.serverError = null;
    this.serverGetError = null;
  }
}

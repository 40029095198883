import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  SubscriptionCarouselSlider,
  ReviewCarouselSlider,
  BrandsCarouselSlider,
  Collapse,
  TDInfoSection,
  TwoBanners,
  Loader,
  Button,
} from 'components';

import { VehiclesList } from 'components/sections';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import styles from './Subscription.module.scss';
import { useStores } from '../../hooks/use-stores';
import { lsetRemover } from '../../helpers/formatters/stringsEscaping';
//import { appStoreSubscription, playMarketSubscription } from 'appConstants';

const Subscription = observer(() => {
  const { cmsStore } = useStores();
  const [allCost, setAllCost] = useState(false);

  useEffect(() => {
    cmsStore.getPage('subscribe');
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = '/js/hotjar.js';
    document.body.insertBefore(scriptTag, document.body.firstChild);
  }, [cmsStore]);

  const {
    pageContent: { fields, name },
  } = cmsStore;

  if (cmsStore.loading) return <Loader />;

  const renderCostBreakdown = () => {
    if (!fields?.saveBig) return;
    if (!fields?.everythingIsIncluded) return;
    const { saveBig, everythingIsIncluded } = fields;
    return (
      <div className={styles.saveBig}>
        <h3>{saveBig.title}</h3>
        <p className={styles.subTitle}>{saveBig.subTitle}</p>

        <div className={styles.cInfographic}>
          <div className={styles.leftCol}>
            <div className={styles.cuLabel}>{saveBig.leftLabel}</div>
            <div className={styles.cuPrice}>{lsetRemover(saveBig.leftPrice)}</div>
            <div className={styles.subText}>/month</div>
          </div>
          <img className={styles.centerIllustration} src={saveBig?.centerIllustration} alt="" />
          <div className={styles.rightCol}>
            <div className={styles.cuLabel}>{saveBig.rightLabel}</div>
            <div className={styles.cuPrice}>{lsetRemover(saveBig.rightPrice)}</div>
            <div className={styles.subText}>/month</div>
          </div>
        </div>

        {allCost && (
          <div>
            <ul className={styles.cuTable}>
              <li className={styles.headers}>
                <div className={styles.col1}>{saveBig.column1Header}</div>
                <div className={styles.col2}>{saveBig.column2Header}</div>
                <div className={styles.col3}>{saveBig.column3Header}</div>
              </li>

              {saveBig.tableRows?.map(
                (
                  item: { column1: string; column2: string; column3CrossedOut: string; column3: string },
                  index: number
                ) => (
                  <li>
                    <div className={styles.col1}>{item.column1}</div>
                    <div className={styles.col2}>{item.column2}</div>
                    <div className={styles.col3}>
                      {item.column3CrossedOut ? <span>{item.column3CrossedOut + ' '}</span> : ''}
                      {item.column3}
                    </div>
                  </li>
                )
              )}
              <li className={styles.footnote}>{saveBig.footnote}</li>
            </ul>

            {/*  -------- Everything is included -------  */}
            <h3 dangerouslySetInnerHTML={{ __html: everythingIsIncluded.title }} />
            <p className={styles.subTitle}>{everythingIsIncluded.subTitle}</p>
            <ul className={styles.cEverythingIsIncluded}>
              {everythingIsIncluded.infographic?.map((item: { icon: string; description: string }, index: number) => {
                return (
                  <li key={index}>
                    <div
                      className={styles.iconBorder}
                      style={{ backgroundImage: `url(${everythingIsIncluded.iconBorder})` }}
                    >
                      <div className={styles.icon} style={{ backgroundImage: `url(${item.icon})` }} />
                    </div>
                    <div className={styles.cDescription}>{item.description}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div className={styles.cButton}>
          <Button
            size={'medium-rounded'}
            buttonColor={'black'}
            className={classNames(styles.cuButton, allCost ? styles.fullCollapse : '')}
            onClick={() => setAllCost(!allCost)}
          >
            {allCost ? 'Collapse the full breakdown' : 'See the full breakdown'}
          </Button>
        </div>
      </div>
    );
  };

  const renderTwoSections = () => {
    if (fields?.twoSections?.banner?.length) {
      const {
        twoSections: { banner },
      } = fields;
      return (
        <TwoBanners
          sectionLeft={{
            image: { url: banner[0].illustration, position: 'right center' },
            description: banner[0].title,
            link: { caption: banner[0].linkCaption, url: banner[0]?.linkUrl },
          }}
          sectionRight={{
            image: { url: banner[1].illustration, position: 'left center' },
            description: banner[1].title,
            link: { caption: banner[1].linkCaption, url: banner[1]?.linkUrl },
          }}
        />
      );
    } else return;
  };
  return (
    <div className={styles.cPage}>
      <div className={styles.topBanner}>
        <video autoPlay muted loop playsInline>
          <source src="videos/motordrive.mp4" type="video/mp4" />
        </video>
        <div className={classNames(styles.innerTopBanner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <p className={styles.smallTitle}>{name}</p>
          <h1>{fields?.headline}</h1>
          <div>

            <div>
              <Button onClick={() => {
                window.open('https://trymotor.com/', '_blank', 'noopener,noreferrer');
              }}
                buttonColor={'white'}
                size={'big'}
                fontSize={'18px'} > Get Started </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.cContent}>
        <div className={styles.cuDesktopWidth}>
          <h2 className={styles.sliderTitle}>{fields?.benefits?.title}</h2>
          <p className={styles.shortDescription}>{fields?.motorDrive?.title}</p>
          <div className={styles.cSlider}>
            <SubscriptionCarouselSlider slidesList={fields?.benefits?.columns} />
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.cIllustration}>
            <div className={styles.video}>
              <img src={fields?.motorDrive?.illustration} alt="Motor" />
            </div>
          </div>

          <div className={styles.cSteps}>
            <h3>{fields?.stepsToElectricCar?.title}</h3>
            <ul className={styles.steps}>
              {fields?.stepsToElectricCar?.columns.map(
                (step: { title: string; description: string; icon: string }, index: number) => (
                  <li key={index}>
                    <div className={styles.innerStep}>
                      <div className={styles.stepIcon} style={{ backgroundImage: `url(${step.icon})` }} />
                      <div>
                        <h4>{step.title}</h4>
                        <p>{step.description}</p>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>

          {renderCostBreakdown()}
        </div>

        <div className={classNames(styles.desktopWidth)}>
          <div className={styles.downloadSection}>
            <div className={styles.phone}>
              <div
                className={styles.imagePhone}
                style={{ backgroundImage: `url(${fields?.subscriptionStarted?.illustration})` }}
              />
            </div>
            <div className={styles.infoSection}>
              <div className={styles.cTextLimiter}>
                <p className={styles.cuText}>{fields?.subscriptionStarted?.subTitle}</p>
                <h4>{fields?.subscriptionStarted?.title}</h4>
                <p className={styles.cuTextBottom}>{fields?.subscriptionStarted?.description}</p>
              </div>

              <div>
                <Button onClick={() => {
                  window.open('https://trymotor.com/', '_blank', 'noopener,noreferrer');
                }}
                  buttonColor={'black'}
                  size={'big'}
                  fontSize={'18px'} > Get Started </Button>
              </div>
            </div>
          </div>

          {/* ------ Models Slider -------*/}
          <VehiclesList fields={fields} />

          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
            {/* ------ Banners ------------*/}
            {renderTwoSections()}

            {/* ------ FAQ -------*/}
            <div className={styles.cFAQ}>
              <div className={styles.titlesSection}>
                <h3>{fields?.frequentlyAskedQuestions?.title}</h3>
                <p>{fields?.frequentlyAskedQuestions?.description}</p>
                <p>
                  {fields?.frequentlyAskedQuestions?.subTitle}{' '}
                  <Link className={styles.cuLink} to="/subscription-faq">
                    here
                  </Link>
                  .
                </p>
              </div>

              <div className={styles.cFaqOffsets}>
                {fields?.frequentlyAskedQuestions?.accordionItem?.map(
                  (item: { question: string; answer: string }, index: number) => {
                    return (
                      <Collapse
                        key={index}
                        title={item.question}
                        wrapperStyle={styles.wrapper}
                        titleStyle={styles.faqTitle}
                      >
                        <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                      </Collapse>
                    );
                  }
                )}
              </div>
            </div>

            <div className={styles.reviewSection}>
              <ReviewCarouselSlider slidesList={fields?.reviewsSlider?.slides} />
            </div>

            <BrandsCarouselSlider sliderTitle={fields?.brandsSlider?.title} slidesList={fields?.brandsSlider?.slides} />
          </div>
        </div>

        <TDInfoSection imageURL={'/images/test-drive.png'} />

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default Subscription;

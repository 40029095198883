import React from 'react';
import styles from './Tabs.module.scss';
import classNames from 'classnames';

interface Props {
  tabs: { caption: string; value: string; onClick: () => void }[];
  activeTab: string;
  onClick: (value: string) => void;
}

const Tabs = (props: Props) => {
  return (
    <div className={styles.cTabs}>
      {props.tabs.map((item, index) => {
        return (
          <button
            key={index}
            className={classNames(styles.tabButton, item.value === props.activeTab ? styles.active : '')}
            onClick={() => {
              item.onClick();
              props.onClick(item.value);
            }}
          >
            {item.caption}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;

import forEach from 'lodash/forEach';

import { VehicleVisualSet } from '../../types/Vehicle';

export const getVehicleImgList = (visualSet: VehicleVisualSet, isVideo: boolean = true): string[] => {
  const imgList: string[] = [];

  forEach(visualSet, (val, key) => {
    if (!val) return;

    if (key === 'video' && isVideo) {
      imgList.push(val as string);
    }

    switch (key) {
      case 'front':
      case 'interior':
      case 'interior1':
      case 'interior2':
      case 'rear':
      case 'side':
        if (val !== 'n/a') {
          imgList.push(val as string);
        }
        break;

      default:
        return;
    }
  });

  return imgList;
};

export const getDistenceInMiles = (distanceInMeters: number, roundNumber: number = 1) => {
  const distanceInMiles = distanceInMeters * 0.000621371;
  return distanceInMiles.toFixed(roundNumber);
};

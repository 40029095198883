import React from 'react';
import Slider from 'react-slick';
import { desktopWidth } from 'appConstants';
import { Avatar } from 'components';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CarouselSliderReviewProps, SlideReviewContent } from 'types/Slider';
import styles from './ReviewCarouselSlider.module.scss';

const RenderSlides = (slides: SlideReviewContent[]) => {
  return slides.map((item, index) => (
    <div key={index} className={styles.cSliderItem}>
      <div className={styles.sliderItem}>
        <div className={styles.topPart}>
          <div className={styles.offsetBottom}>
            <Avatar size={'medium'} color={'dark'} name={item.name} />
          </div>

          <div className={styles.description}>{item.description}</div>
        </div>
        <div>
          <div className={styles.cName}>{item.name}</div>
        </div>
      </div>
    </div>
  ));
};

const ReviewCarouselSlider = (props: CarouselSliderReviewProps) => {
  const { width } = useWindowDimensions();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
  };

  const settingsDesktop = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: false,
    centerMode: true,
  };
  if (!props.slidesList) return null;
  if (width < desktopWidth) {
    return <Slider {...settings}>{RenderSlides(props.slidesList)}</Slider>;
  } else return <Slider {...settingsDesktop}>{RenderSlides(props.slidesList)}</Slider>;
};
export default ReviewCarouselSlider;

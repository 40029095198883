import React from 'react';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import styles from './QuizBanner.module.scss';
interface Props {
  data: {
    title: string;
    description: string;
    buttonCaption: string;
    buttonUrl: string;
    isInternalUrl: boolean;
    showBanner: boolean;
  };
}
const QuizBanner = (props: Props) => {
  const { title, description, buttonCaption, buttonUrl, isInternalUrl, showBanner } = props.data;
  const history = useHistory();
  if (!showBanner) return null;
  return (
    <div className={styles.cQuizBanner}>
      <div className={styles.inner}>
        <div className={styles.cLeft}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <Button
          size="medium-rounded"
          buttonColor="gray-new"
          arrow="right"
          minWidth="mw192"
          onClick={() => {
            isInternalUrl ? history.push(buttonUrl) : window.open(buttonUrl);
          }}
        >
          {buttonCaption}
        </Button>
      </div>
    </div>
  );
};

export default QuizBanner;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { SocialLinks } from 'components';
import { MainMenuCategory, MainMenuItems } from '../../types/Configuration';
import { Button } from 'components';
import styles from './Footer.module.scss';

const Footer = observer(() => {
  const { authStore, cmsStore } = useStores();
  const commonConfiguration = cmsStore?.collectionContent?.commonConfiguration;
  return (
    <div className={styles.cFooter}>
      <div className={classNames(styles.cTopPart, styles.desktopWidth, styles.cHorOffsetsMobile)}>
        <div className={styles.cLeft}>
          <Link
            to="/"
            className={styles.logo}
            style={{ backgroundImage: `url(${commonConfiguration ? commonConfiguration[0]?.logo : ''})` }}
          />
          <p className={styles.infoBox}>
            4300 Wilson Blvd, Suite 900 <br />
            Arlington, VA 22203 <br />
            Tel: +1 833 MOTOR EV <br />
            support@trymotor.com
          </p>
        </div>
        <div className={styles.cRight}>
          <ul className={styles.menu}>
            {authStore.configuration?.navigation.points.map((category: MainMenuCategory, index: number) => {
              return (
                <li className={styles.topLevelItem} key={index}>
                  {category.subPoints.length ? (
                    <h3>{category.title}</h3>
                  ) : (
                    <Link to={category.link || ''}>{category.title}</Link>
                  )}
                  {!!category.subPoints.length && (
                    <ul className={classNames(styles.subMenu)}>
                      {category.subPoints.map((item: MainMenuItems, index: number) => {
                        return (
                          <li className={styles.secondLevelItem} key={index}>
                            <Link to={item.link}>{item.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div>
            <Button onClick={() => {
              window.open('https://trymotor.com/subscribe/', '_blank', 'noopener,noreferrer');
            }}
              buttonColor={'black'}
              size={'medium'}> Try Electric </Button>

          </div>
        </div>
      </div>
      <div className={styles.cBottomPart}>
        <div className={classNames(styles.innerBottomPart, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.cFirst}>
            <Link className={styles.logoMotorPower} to="/" />
            <p className={styles.copyright}>© 2021 Motor, Inc. All rights reserved.</p>
          </div>

          <div className={styles.cSecond}>
            <SocialLinks color={'white'} />
            <div className={styles.offsetLinks}>
              <Link className={styles.fLink} to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className={styles.fLink} to="/terms-service">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Footer;

import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Popup.module.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';

interface Props {
  children: ReactNode;
  className?: string;
}

const Popup = observer(({ children, className = '' }: Props) => {
  const { authStore } = useStores();
  if (!authStore.isShowingPopup) return <div />;
  return (
    <div
      className={classNames(styles.cPopup, { className })}
      onClick={e => {
        authStore.setRenderPopup();
      }}
    >
      <div
        className={styles.popup}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div
          className={styles.btnClose}
          onClick={() => {
            authStore.setRenderPopup();
          }}
        />
        <div className={styles.cContent}>{children}</div>
      </div>
    </div>
  );
});

export default Popup;

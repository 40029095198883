import React, { useEffect } from 'react';
import classNames from 'classnames';
import { GradientSplitter, BannerContainer, CarouselLearningSlider } from 'components';
import styles from '../SliderContentPages/SliderContentPages.module.scss';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';

const ElectricCarLifestyle = observer(() => {
  const { cmsStore } = useStores();

  useEffect(() => {
    cmsStore.getPage('going-electric');
  }, [cmsStore]);

  const {
    pageContent: { fields },
  } = cmsStore;

  return (
    <div className={styles.cPage}>
      <div className={styles.bgDark}>
        <div className={styles.bgHead} style={{ backgroundImage: `url(${fields?.header?.heroImage})` }}>
          <div className={styles.desktopWidth}>
            <h1>
              <strong>{fields?.header?.title}</strong>
              <br />
              {fields?.header?.subTitle}
            </h1>
          </div>
        </div>
        <div className={styles.cGradient}>
          <GradientSplitter />
        </div>
      </div>

      <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cContent)}>
        <div className={styles.cContentItem} dangerouslySetInnerHTML={{ __html: fields?.topDescription }} />

        <BannerContainer bName={'subscription2'} bStyle={styles.bannerOffsetTop} />

        <div className={styles.cContentItem}>
          <h4 className={styles.bold}>{fields?.textUnit?.title}</h4>
          <p>{fields?.textUnit?.description}</p>
        </div>

        <BannerContainer bName={'calculatorSavings'} bStyle={styles.bannerOffsetTop} />

        <div className={styles.cContentItem}>
          <h4 className={styles.bold}>{fields?.textUnit2?.title}</h4>
          <p>{fields?.textUnit2?.description}</p>
        </div>

        <BannerContainer bName={'mapRoute'} bStyle={styles.cContentItem} />
        <BannerContainer bName={'testDrive'} />

        <BannerContainer bName={'dynEconomyNight'} />

        <div className={styles.cContentItem}>
          <h4 className={styles.bold}>{fields?.textUnit3?.title}</h4>
          <div className={styles.cFlex}>
            <p>{fields?.textUnit3?.description}</p>
            <img src="/images/package.svg" alt="" />
          </div>
        </div>

        <div className={styles.cContentItem}>
          <div className={styles.cSlider}>
            <CarouselLearningSlider
              title={fields?.frequentlyAskedQuestionsSlider?.title}
              slidesList={fields?.frequentlyAskedQuestionsSlider?.slider}
            />
          </div>
        </div>

        <BannerContainer bName={'ownership'} bStyle={styles.cContentItem} />
      </div>
    </div>
  );
});

export default ElectricCarLifestyle;

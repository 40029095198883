import React from 'react';
import classNames from 'classnames';
//import ReactGA from 'react-ga';
import { Button } from 'components';
import { Collapse } from 'components';
//import env from '../../environment';
import stylesCurrent from './Subscription.module.scss';

//const { appStoreLink, playMarketLink } = env;

const SubscriptionFAQ = () => {
  return (
    <div className={classNames(stylesCurrent.cSubscriptionFAQ)}>
      <h1>Subscription FAQ</h1>
      <h2>Membership Review</h2>
      <Collapse title="How does Motor work?" isOpenedDefault>
        <p>
          Complete our online application form, it takes about 2 minutes and all you need is your driver’s license. Once you’ve completed the application, you’ll pick a car, your delivery date, and we’ll deliver the car to you!
        </p>
      </Collapse>
      <Collapse title="What is included in a Motor subscription?">
        <p>
          Everything besides the fuel (electricity) is taken care of, including insurance, routine maintenance, and a
          concierge team that’s available 24/7. We also provide you with a home charger, and you can learn more about
          our Faster Charger for qualifying members at{' '}
          <a href="https://trymotor.com/fast-charger" target="_blank" rel="noreferrer noopener">
            trymotor.com/fast-charger
          </a>
          .
        </p>
      </Collapse>
      <Collapse title="How does insurance work?">
        <p>
          Motor insures all approved drivers with our fleet policy. Details can be provided upon request. All
          insurance documents are available in your app and will be in the glovebox of the car.
        </p>
      </Collapse>
      <Collapse title="How long can I keep a car?">
        <p>
          For as long as you want! We provide monthly and long-term subscriptions. Your subscription is automatically
          renewed monthly until you decide you would like to end the subscription.
        </p>
      </Collapse>
      <Collapse title="How do I change my subscription tier?">
        <p>
          You can request to change your subscription tier at any point by contacting us through your concierge or by
          just giving us a call. Change request billing will be prorated based on the remaining days left in your
          subscription month.
        </p>
      </Collapse>
      <Collapse title="How new are the cars?">
        <p>
          We deliver fresh cars in like-new condition to our members. Our fleet includes the most popular electric cars
          on the road, ranging from the Nissan Leaf to the Tesla Model X.
        </p>
      </Collapse>
      <div className={stylesCurrent.contentItem}>
        <h2>Signing Up</h2>
        <Collapse title="How do I join Motor?">
          <p>
            Complete our online application form, it takes about 2 minutes and all you need is your driver’s license. Once you’ve completed the application, you’ll pick a car, your delivery date, and we’ll deliver the car to you!
          </p>
        </Collapse>
        <Collapse title="Is Motor in my area?">
          <p>
            Today, Motor subscriptions are available in the following regions:
            <ul>
              <li>Indianapolis, IN and the surrounding area</li>
              <li>Miami Valley area in Ohio</li>
            </ul>

            If you’re outside of our service area, sign up for news and updates and we will let you know when we arrive in your city.
          </p>
        </Collapse>
        <Collapse title="Who is eligible?">
          <p>Anyone 25 years or older, with a valid US driver’s license and good driving record</p>
        </Collapse>
        <Collapse title="What is the Motor subscription activation fee?">
          <p>
            The activation fee of $400 is a one-time fee required for all new Motor subscription memberships. This includes new memberships if you have canceled and are signing up again. The fee is non-refundable and will be charged when your car is delivered. Each plan also has a monthly fee.
          </p>
        </Collapse>
        <Collapse title="How is my car delivered to me?">
          <p>
            Once you’ve scheduled a delivery date, our team will bring the car to your specified location within our
            service area. We currently offer white glove contactless delivery, and sanitize each car before handing you
            the keys to keep everyone safe.
          </p>
        </Collapse>
      </div>
      <div className={stylesCurrent.contentItem}>
        <h2>Everyday Usage</h2>
        <Collapse title="How well equipped are the cars?">
          <p>
            The packages and trim will be dependent on the electric car you select, but every car comes with a slew of
            features and at least 200 miles of range. You are always welcome to call our team at{' '}
            <a href="tel:+1(833) 668-6738">+1(833)MOTOR-EV</a>, so that we can help you find the right car for you!
          </p>
        </Collapse>
        <Collapse title="Are pets allowed in cars?">
          <p>
            We do allow pets in the cars, but members are responsible for any damages caused by their furry friends.
          </p>
        </Collapse>
        <Collapse title="What happens if my car needs maintenance?">
          <p>
            Our full-service subscription was created so you do not have to worry about maintenance issues. If something
            unforeseen occurs, simply reach out to our team at (833) 668-6738 and we will take care of the rest.
          </p>
        </Collapse>
        <Collapse title="What happens if I get into an accident?">
          <p>
            First, make sure that everyone is safe. Move your car to a safe place, exchange information with the other
            parties and notify the police. Once the situation is stable, call us at (317)516-0145. We will handle
            everything with respect to the car involved in the incident.
          </p>
        </Collapse>
      </div>
      <div className={stylesCurrent.contentItem}>
        <h2>Price & Payments</h2>
        <Collapse title="Where can I find the insurance card?">
          <p>
            You can request a digital version of your insurance card. Hard copies of the insurance card can also be found in the glovebox.
          </p>
        </Collapse>
        <Collapse title="When is payment due?">
          <p>
            A valid credit card is required to register in the app. Your first payment will be charged on the day your
            car is delivered to you. Subsequent payments will be charged on a monthly basis, in advance of the next
            month. All multi-month subscriptions are billed at the beginning of each month.
          </p>
        </Collapse>
        <Collapse title="What is the minimum commitment to a Motor subscription?">
          <p>
            Motor offers a monthly subscription service. Pay for the first 30 days and give it a try. Your subscription will renew automatically on a monthly basis after the first 30-day period. If you ever want to cancel, just let us know at (833) 668-6738 or support@trymotor.com and we will end your subscription after your current month has expired.          </p>
        </Collapse>
        <Collapse title="What about tolls?">
          <p>Toll costs are passed through and charged back to the member.</p>
        </Collapse>
      </div>
      <div className={stylesCurrent.contentItem}>
        <h2>Terms of Use</h2>
        <Collapse title="Is there a mileage limit per month?">
          <p>All Motor plans come with 1500 miles per month. There is a $0.25/mile overage charge after that threshold.</p>
        </Collapse>
        <Collapse title="Can anyone else drive the vehicle?">
          <p>
            Yes, eligible drivers may be registered as Secondary Drivers and added to the member’s account. This can be accomplished by contacting us at (833) 668-6738, support@trymotor.com, or through your digital concierge.
          </p>
        </Collapse>
        <Collapse title="Are members responsible for their Motor car?">
          <p>
            Yes, we expect our members to take care of Motor car as if they owned them. Please drive responsibly,
            keep your car clean, and let us know about any minor dings or issues through your Motor App’s help
            section on the homepage.
          </p>
        </Collapse>
        <Collapse title="What data does Motor collect?">
          <p>
            We want to ensure the best service and safety for all of our members. To do so, we collect data on how the car is used. For more information on terms of use, please reference our Terms and Conditions.
            <a href="https://trymotor.com/drive-member-agreement" target="_blank" rel="noreferrer noopener">
              Terms and Conditions
            </a>
            .
          </p>
        </Collapse>
      </div>
      <div className={stylesCurrent.contentItem}>
        <h2>Charging your car</h2>
        <Collapse title="How far can I drive with my electric car on a single charge?">
          <p>
            Our fleet has a selection of high performance electric cars. All of our cars have over 200 miles of range.
          </p>
        </Collapse>
        <Collapse title="Where and how will I charge my car?">
          <p>
            Drivers of electric cars never need to go to a gas station again! Most drivers fuel their vehicles by
            charging at work or at home, similar to their cell phones at night. Public charging stations are also
            available for longer trips or for getting a little extra juice while running errands. Charging ports vary
            across car manufacturers, similar to the difference in charging an Apple phone compared to an Android one.
            In order to use a charger, you’ll need to make sure your car’s charging port is compatible (if you ever have
            questions, just call us). One of the easiest ways to locate chargers is through www.plugshare.com. You also
            can download the app on iOS and Android.
          </p>
        </Collapse>
        <Collapse title="Who pays for charging?">
          <p>Members are responsible for the cost of charging their vehicle.</p>
        </Collapse>
        <Collapse title="How do I charge at home?">
          <p>
            At home, there are two options for charging your car. Most electric cars come equipped with a Basic charger, also called a portable Level 1 charger. This Basic charger plugs into a 120-volt outlet (the normal one in your house) to provide electricity to your vehicle. For faster fueling, you can opt for Faster, or Level 2 charger. To get these faster charging speeds, you’ll need a charger that connects to a 240-volt outlet. Motor can provide this charger and install it — just let us know if you’re interested in learning more! We currently provide a Faster Charger install free (up to $1,000) to our 3-month subscription members who opt-in.
          </p>
        </Collapse>
        <Collapse title="Should I buy a charger?">
          <p>
            If you are interested in getting a faster charger installed in your home, we can help! If you sign up for a
            long-term subscription, we will provide a faster charger (Level 2) and coordinate installation of the
            charger. You might be able to benefit from special incentives or benefits in your area.
          </p>
        </Collapse>
        <Collapse title="How do I charge elsewhere? What about public charging?">
          <p>
            We recommend using the Plugshare app on iOS and Android to locate these chargers. Plugshare compiles all the
            public chargers into one map and allows you to filter based on your desired parameters. For example, you can
            narrow the visibility to chargers that are compatible with your vehicle, or to only those with the fastest
            charging speeds.
          </p>
        </Collapse>
        <Collapse title="How do I pay for public charging?">
          <p>
            Depending on the provider, you will either need a credit card or the provider’s app. We’ve provided links
            here to download apps for companies who offer public chargers:
          </p>
          <p>
            <a href="https://greenlots.com/ev-drivers/" target="_blank" rel="noreferrer noopener">
              Greenlots
            </a>
            <br />
            <a href="https://www.evgo.com/download-app/" target="_blank" rel="noreferrer noopener">
              EVgo
            </a>
            <br />
            <a href="https://www.electrifyamerica.com/mobile-app/" target="_blank" rel="noreferrer noopener">
              Electrify America
            </a>
            <br />
            <a href="https://www.chargepoint.com/drivers/mobile/" target="_blank" rel="noreferrer noopener">
              Chargepoint
            </a>
          </p>
        </Collapse>
        <Collapse title="How fast will my car charge?">
          <p>
            Like a cellphone, the first 80% of a car’s battery charges more quickly than the last 20%. At the same
            charger, your car will charge more quickly when the battery is completely drained than when it is almost
            full. Level 3 charging is the fastest: cars can charge 80% of your battery in under 30 minutes. Level 2
            charging is more applicable to getting a partial charge while you shop or full charge while you’re at work
            or home. Level 1 charging is great when your car spends a lot of time parked at home. If you’re using your
            car a lot, you’ll want to make sure you have at least one Level 2 charger accessible.
          </p>
          <p>
            To learn more about charging your vehicle, we recommend{' '}
            <a
              href="https://chargehub.com/en/electric-car-charging-guide.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              ChargeHub’s Electric Car Charging Guide
            </a>{' '}
            and ChargePoint’s{' '}
            <a
              href="https://www.chargepoint.com/files/Quick_Guide_to_Fast_Charging.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Driver’s Checklist: A Quick Guide to Fast Charging
            </a>{' '}
            .
          </p>
        </Collapse>
      </div>
      <div className={classNames(stylesCurrent.cFinalOrder, stylesCurrent.mt30)}>
        <h4>Get Started with Motor</h4>
        <h4>
          <Button onClick={() => {
            window.open('https://trymotor.com/', '_blank', 'noopener,noreferrer');
          }}
            buttonColor={'black'}
            size={'big'}
            fontSize={'18px'} > Get Started </Button>
        </h4>
      </div>
    </div>
  );
};

export default SubscriptionFAQ;

import React, { useState } from 'react';
import { CarDescription, ModelsCarouselSliderSubscription, Popup } from 'components';

import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import classNames from 'classnames';
import styles from './VehiclesList.module.scss';
import { SlideModelSubscriptionContent } from '../../../../types/Slider';

export interface Props {
  fields: any;
}
const VehiclesList = observer((props: Props) => {
  const { authStore } = useStores();
  const { fields } = props;
  const [activeCar, setActiveCar] = useState({} as SlideModelSubscriptionContent);

  if (!fields) return null;

  return (
    <div>
      <Popup>
        <CarDescription closePopup={() => authStore.setRenderPopup(false)} carData={activeCar} />
      </Popup>
      <div className={classNames(styles.titlesSection, styles.cHorOffsetsMobile)}>
        <h3>{fields?.availableCars?.title}</h3>
        <p>{fields?.availableCars?.description}</p>
        <p className={styles.disclaimer}>{fields?.availableCars?.subTitle}</p>
      </div>

      {fields?.vehiclesSlider && (
        <ModelsCarouselSliderSubscription
          slidesList={fields?.vehiclesSlider}
          showPopup={item => {
            setActiveCar(item);
            authStore.setRenderPopup(true);
          }}
        />
      )}
    </div>
  );
});

export default VehiclesList;

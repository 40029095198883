import axios, { AxiosResponse } from 'axios';

import { createApi } from '../../api/apiCreator';
import environment from '../../environment';
import {
  User,
  UserAPI,
  AdditionalCost,
  AdditionalCostAPI,
  ChargerStation,
  ChargerStationAPI,
  DriverProfile,
} from '../../types/User';
import {
  adaptUserApi,
  adaptAditionalCostApi,
  adaptChargerStations,
  adaptDriverProfile,
  adaptDriverProfileAPI,
} from './adapter';
import { MapCoords } from '../../stores/AdvisorStore';
import config from '../../environment';

export const API = createApi({ baseURL: environment.apiUrls.auth });

export default class UserApi {
  API = API;

  async getCurrentUser(): Promise<User | undefined> {
    const response: AxiosResponse<UserAPI> = await this.API.get(`users/current/`);
    if (!response) return undefined;
    return adaptUserApi(response.data);
  }

  async getAdditionalCost({
    distance,
    mileage,
  }: {
    distance: string;
    mileage: number;
  }): Promise<AdditionalCost | undefined> {
    const response: AxiosResponse<AdditionalCostAPI> = await this.API.post(`/advisors/additional_cost/`, {
      distance_mi: distance,
      miles_to_gallon: mileage,
    });

    if (!response) return undefined;
    return adaptAditionalCostApi(response.data);
  }

  async getChargingStation({ origin, destination }: MapCoords): Promise<undefined | ChargerStation[]> {
    const response: AxiosResponse<ChargerStationAPI[]> = await axios.get(
      `https://api.openchargemap.io/v3/poi/?output=json&polyline=(${origin.lat},${origin.lng}),(${destination.lat},${destination.lng})&compact=true&verbose=false&key=${config.chargemapAPIKey}`
    );

    if (!response) return undefined;
    return adaptChargerStations(response.data);
  }

  async subscribe(email: string): Promise<any> {
    return await this.API.post(`subscription_emails/`, { email: email });
  }

  async getUserProfile(uuid: string): Promise<any> {
    const resp = await this.API.get(`/driver_profile/${uuid}`);
    return adaptDriverProfileAPI(resp.data);
  }

  async createUserProfile(profile: DriverProfile): Promise<any> {
    const respDP = await this.API.post('/driver_profile/', adaptDriverProfile(profile));
    return respDP.data;
  }

  async updateUserProfile(profile: DriverProfile, id: string): Promise<any> {
    const respDPUP = await this.API.put(`/driver_profile/${profile.uuid}/`, adaptDriverProfile(profile));
    return respDPUP.data;
  }
}

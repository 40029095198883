import { AxiosResponse } from 'axios';

import { createApi } from '../../api/apiCreator';
import environment from '../../environment';
import {
  Vehicle,
  VehicleAPI,
  VehicleListAPI,
  SaveBuildAPI,
  CurrentBuildAnswer,
  CurrentBuildAnswerAPI,
  TestDriveVehicleListAPI,
  VehicleInventory,
  VehicleInventoryAPI,
  VehicleInventoryListAPI,
  makeModelsFilters,
} from '../../types/Vehicle';
import { MAKES_MODELS_LIMIT } from '../../appConstants';

import { VehicleModel } from '../../types/MakesModels';
import { Pagination } from '../../types/Configuration';

import {
  adaptVehicleApi,
  adaptCurrentBuildAnswerApi,
  adaptTestDriveVehicleApi,
  adaptVehicleInventoryApi,
} from './adapter';

import { TestDriveVehicle } from '../../types/TestDrive';
import camelcaseKeys from 'camelcase-keys-deep';

export const API = createApi({ baseURL: environment.apiUrls.vehicle });
export default class VehicleApi {
  API;

  constructor(api = API) {
    this.API = api;
  }

  async getTestDriveVehicles(): Promise<TestDriveVehicle[] | undefined> {
    const vehicleResp: AxiosResponse<TestDriveVehicleListAPI> = await this.API.get(`test_drive_vehicles/`);
    if (!vehicleResp) return undefined;

    return vehicleResp.data.results.map(item => {
      return adaptTestDriveVehicleApi(item);
    });
  }

  async getVehicles(): Promise<Vehicle[] | undefined> {
    const vehicleResp: AxiosResponse<VehicleListAPI> = await this.API.get(`vehicles/`);
    if (!vehicleResp) return undefined;

    return vehicleResp.data.results.map(item => {
      return adaptVehicleApi(item);
    });
  }

  async getInventoryVehicles(): Promise<VehicleInventory[] | undefined> {
    const vehicleResp: AxiosResponse<VehicleInventoryListAPI> = await this.API.get(`inventory_vehicles/`);
    if (!vehicleResp) return undefined;

    return vehicleResp.data.results.map(item => {
      return adaptVehicleInventoryApi(item);
    });
  }

  async saveBuildCharger({
    buildID,
    chargerPackageID,
    isEnroll,
    isChargerPackage,
  }: {
    buildID: string;
    chargerPackageID: string;
    isEnroll: boolean;
    isChargerPackage: boolean;
  }): Promise<any | undefined> {
    const varietyResp: AxiosResponse<SaveBuildAPI> = await this.API.patch(`/vehicle_builds/${buildID}/`, {
      charger_package: chargerPackageID,
      is_enroll: isEnroll,
      is_charger_package: isChargerPackage,
    });
    if (!varietyResp) return undefined;
    return varietyResp.data;
  }

  async getVehicleDetails(vehicleSlug: string): Promise<Vehicle | undefined> {
    const vehicleResp: AxiosResponse<VehicleAPI> = await this.API.get(`vehicles/${vehicleSlug}`);
    if (!vehicleResp) return undefined;
    return adaptVehicleApi(vehicleResp.data);
  }

  async getInventoryVehicleDetails(id: number): Promise<VehicleInventory | undefined> {
    const vehicleResp: AxiosResponse<VehicleInventoryAPI> = await this.API.get(`inventory_vehicles/${id}`);
    if (!vehicleResp) return undefined;
    return adaptVehicleInventoryApi(vehicleResp.data);
  }

  async getBuildVarietyByID(buildID: string): Promise<CurrentBuildAnswer | undefined> {
    const resp: AxiosResponse<CurrentBuildAnswerAPI> = await this.API.get(`/vehicle_builds/${buildID}/`);
    if (!resp) return undefined;

    return adaptCurrentBuildAnswerApi(resp.data);
  }

  async getBuildByOrderId(orderId: string): Promise<VehicleInventory | undefined> {
    const varietyResp: AxiosResponse<VehicleInventoryAPI> = await this.API.get(`/orders/${orderId}/vehicle_build/`);
    if (!varietyResp) return undefined;
    return adaptVehicleInventoryApi(varietyResp.data);
  }

  async getSubscriptionInventoryVehicleDetails(vin: string): Promise<VehicleInventory | undefined> {
    const vehicleResp: AxiosResponse<VehicleInventoryAPI> = await this.API.get(`subscription_builds/${vin}/`);
    if (!vehicleResp) return undefined;
    return adaptVehicleInventoryApi(vehicleResp.data);
  }

  async getFilters(type?: string): Promise<makeModelsFilters | undefined> {
    let isPreowned = type === 'Preowned' ? '?is_preowned=true' : '';
    const buffer: AxiosResponse<makeModelsFilters> = await this.API.get(`make_and_model_filters/${isPreowned}`);
    if (!buffer) return undefined;
    return buffer.data;
  }

  async getMakesAndModels(page?: number, filtersResult: string = ''): Promise<Pagination<VehicleModel[]> | undefined> {
    let offset = 0;
    if (page) {
      offset = page * MAKES_MODELS_LIMIT - MAKES_MODELS_LIMIT;
    }
    const buffer: any = camelcaseKeys(
      await this.API.get(`vincentric_vehicles/?limit=${MAKES_MODELS_LIMIT}&offset=${offset}${filtersResult}`)
    );
    return buffer.data;
  }

  async getMakesAndModelsPreowned(
    page?: number,
    filtersResult: string = ''
  ): Promise<Pagination<VehicleModel[]> | undefined> {
    let offset = 0;
    if (page) {
      offset = page * MAKES_MODELS_LIMIT - MAKES_MODELS_LIMIT;
    }
    const buffer: any = camelcaseKeys(
      await this.API.get(`preowned_vehicles/?limit=${MAKES_MODELS_LIMIT}&offset=${offset}${filtersResult}`)
    );
    return buffer.data;
  }

  async getMMVehicle(vehicleId: string, condition: string): Promise<Pagination<VehicleModel[]> | undefined> {
    const url = condition === 'new' ? `vincentric_vehicles/${vehicleId}` : `preowned_vehicles/${vehicleId}`;
    const buffer: any = camelcaseKeys(await this.API.get(url));
    return buffer.data;
  }

  async getSimilarVehicles(vehicleId: string): Promise<VehicleModel[] | undefined> {
    const buffer: any = camelcaseKeys(await this.API.get(`vincentric_vehicles/${vehicleId}/get_recommendations/`));
    return buffer.data;
  }

  async getMakes() {
    const buffer: any = camelcaseKeys(await this.API.get(`/vincentric_vehicles/makes/`));
    return buffer.data;
  }

  async getModelsByMakeId(id: string) {
    const buffer: any = camelcaseKeys(await this.API.get(`/vincentric_vehicles/models/?make=${id}`));
    return buffer.data;
  }

  async getParkingLocation() {
    const buffer: any = camelcaseKeys(await this.API.get(`/parking_locations/`));
    return buffer.data.results;
  }

  async getMatchesVehicles(uuid: string) {
    const buffer: any = camelcaseKeys(await this.API.get(`/driver_profile/${uuid}/recommendations/`));
    return buffer.data;
  }
}

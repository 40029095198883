import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Charger.module.scss';
import classNames from 'classnames';
import { Button } from '../../../../components';

export interface Props {
  level2Charger: {
    title: string;
    descriptionP1: string;
    descriptionP2: string;
    buttonUrl: string;
    buttonCaption: string;
    illustration: string;
  };
}

const Charger = (props: Props) => {
  const history = useHistory();
  const { title, descriptionP1, descriptionP2, buttonUrl, buttonCaption, illustration } = props.level2Charger;
  return (
    <div className={classNames(styles.cLevel2Charger, styles.desktopContentWidth)}>
      <div className={styles.leftCol}>
        <h3>{title}</h3>
        <p>{descriptionP1}</p>
        <p>{descriptionP2}</p>
        <Button buttonColor={'black'} size={'medium'} arrow={'right'} onClick={() => history.push(buttonUrl)}>
          {buttonCaption}
        </Button>
      </div>

      <div className={styles.rightCol} style={{ backgroundImage: `url(${illustration})` }} />
    </div>
  );
};

export default Charger;

import React, { useEffect, useState } from 'react';
import { Loader, Tabs, Pagination, SubscribeSection, NewModelBanner } from 'components';
import { FiltersPanel, VehicleTile, QuizBanner } from 'components/sections';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import styles from './MakesAndModels.module.scss';
import { RouteComponentProps, useHistory, Link } from 'react-router-dom';
import { VehicleModel } from '../../types/MakesModels';
import { desktopWidth, MAKES_MODELS_LIMIT } from '../../appConstants';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { carsState: string; page?: number };
  };
}
const renderTiles = (makesModels: VehicleModel[] | undefined, loading: boolean, fields: any, activeTab: string) => {
  if (loading) return <Loader />;
  if (!makesModels?.length)
    return (
      <div className={styles.cNodata}>
        <h2>No vehicles matches found</h2>
        <h3>Consider trying fewer filters and updating your search</h3>
        <p>
          Not sure what EV is right for you? Check out{' '}
          <Link to="/subscription">vehicle subscriptions through Motor</Link> to try before you buy
        </p>
      </div>
    );
  return (
    <div className={styles.cVehicles}>
      {makesModels?.map((item, index) => {
        return (
          <>
            <VehicleTile vehicle={item} tab={activeTab} />
            {index === 1 && fields?.takeTheQuizBanner ? <QuizBanner data={fields?.takeTheQuizBanner} /> : ''}
            {index === 5 && fields?.newModelBanner ? <NewModelBanner data={fields?.newModelBanner} /> : ''}
          </>
        );
      })}
    </div>
  );
};

const MakesAndModels = observer((props: Props) => {
  const { vehicleStore, cmsStore } = useStores();
  const {
    match: {
      params: { carsState, page },
    },
  } = props;

  const [filterResult, setFilterResult] = useState('');
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isDesktop = width >= desktopWidth;
  let pageNum = page;
  if (!page) {
    pageNum = 1;
  }
  const tabs = [
    {
      value: 'new',
      caption: 'New',
      onClick: () => {},
    },
    {
      value: 'preowned',
      caption: 'Pre-Owned',
      onClick: () => {},
    },
  ];

  useEffect(() => {
    cmsStore.getPage('makes-and-models');
  }, [cmsStore]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [vehicleStore, carsState, pageNum]);

  useEffect(() => {
    if (vehicleStore.makeModelsTab === 'new') {
      vehicleStore.getMakesAndModels(pageNum, filterResult);
    }
    if (carsState === 'preowned') {
      vehicleStore.getMakesAndModelsPreowned(pageNum, filterResult);
    }
    // eslint-disable-next-line
  }, [pageNum, filterResult]);

  const getInitialData = () => {
    if (!carsState) {
      history.push('/makes-and-models/new');
    }
    vehicleStore.setTab(carsState);
    if (carsState === 'new') {
      vehicleStore.getMakesAndModels(pageNum, '');
      vehicleStore.getFilters();
    }
    if (carsState === 'preowned') {
      vehicleStore.getMakesAndModelsPreowned(pageNum, '');
      vehicleStore.getFilters('Preowned');
    }
  };

  const {
    pageContent: { fields },
  } = cmsStore;

  const renderTabContent = () => {
    const vehicles = carsState === 'new' ? vehicleStore.makesModels : vehicleStore.makesModelsPreowned;
    return (
      <>
        <div className={classNames(styles.desktopWidth)}>
          {vehicleStore.filters && !vehicleStore.loadingFilters && (
            <FiltersPanel
              isFiltersLoading={vehicleStore.loadingFilters}
              filters={toJS(vehicleStore.filters)}
              onChange={result => {
                setFilterResult(result);
                history.push(`/makes-and-models/${carsState}`);
              }}
            />
          )}
        </div>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.cVehiclesOuter}>{renderTiles(vehicles, vehicleStore.loading, fields, carsState)}</div>
        </div>
        {!!vehicleStore.pagination?.count && vehicleStore.pagination?.count > MAKES_MODELS_LIMIT && (
          <Pagination
            currentPage={pageNum}
            data={vehicleStore.pagination}
            clickHandler={page => {
              history.push(`/makes-and-models/${carsState}/${page}`);
            }}
          />
        )}
      </>
    );
  };

  if (cmsStore.loading) return <Loader />;

  return (
    <div className={styles.cPage}>
      <div
        className={styles.topBanner}
        style={{ backgroundImage: `url(${isDesktop ? fields?.heroImage : fields?.mobileHeroImage})` }}
      >
        {fields?.blurCover && <div className={styles.cover} />}
        <div className={classNames(styles.innerTopBanner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <p className={styles.smallTitle}>{fields?.topBannerText}</p>
          <h1>{fields?.mainHeadline}</h1>
          <Tabs
            activeTab={carsState}
            tabs={tabs}
            onClick={value => {
              vehicleStore.setTab(value);
              history.push(`/makes-and-models/${value}`);
            }}
          />
        </div>
      </div>

      <div className={styles.cContent}>
        {renderTabContent()}
        {/* ----- end -------*/}
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <SubscribeSection className={styles.subscribeOffset} />
        </div>
      </div>
    </div>
  );
});

export default MakesAndModels;

import React from 'react';
import ReactTooltip from 'react-tooltip';

import { setRegularFormat } from '../../helpers/formatters/money';
import { holdingFee as holdingFeeConst } from 'appConstants';
import styles from './PaymentDuePanel.module.scss';
import { Package } from '../../types/Vehicle';

interface Props {
  titleText?: string;
  currentPackages?: Package[];
  dueNowPrice?: string;
  holdingFee?: string;
}

const PaymentDuePanel = ({ titleText = 'Due now', currentPackages = [], holdingFee = holdingFeeConst }: Props) => {
  const dueNowPackages = currentPackages.filter((pack: Package) => pack.isDueNow);

  const PurchaseTitle = () => (
    <div className={styles.cTopBar}>
      <div className={styles.purchase}>
        <h5 className={styles.caption}>{titleText}</h5>
      </div>
    </div>
  );

  return (
    <div>
      <PurchaseTitle />
      <div className={styles.cOpenContent}>
        <div>
          <p className={styles.keyTitle}>
            Holding fee{' '}
            <span
              data-tip={`The holding fee of $${setRegularFormat(
                holdingFee
              )} is a one-time fee required for all new AES Indiana | Motor orders. The fee is non-refundable.`}
              className={styles.iconInfo}
            />
          </p>

          <h5 className={styles.val}>${setRegularFormat(holdingFee)}</h5>
        </div>
        <ReactTooltip className={styles.tooltip} />

        {dueNowPackages.map(({ id, title, additionalCost }: Package) => {
          return (
            <div key={id}>
              <p className={styles.keyTitle}>{title}</p>
              <h5 className={styles.val}>${setRegularFormat(additionalCost)}</h5>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PaymentDuePanel;

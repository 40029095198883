import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { GradientSplitter } from 'components';
import styles from './InfoBanner.module.scss';

interface Props {
  bTitle?: string;
  bDescription?: string;
  bType?: 'regular' | 'withIcon';
  bLink?: { url: string; caption: string };
  bStyle?: string;
  bIconURL?: string;
}

const InfoBanner = ({ bTitle, bType = 'regular', bLink, bStyle = '', bDescription, bIconURL }: Props) => {
  switch (bType) {
    case 'regular':
      return (
        <div className={classNames(styles.cContentItem, bStyle)}>
          <div className={classNames(styles.cBox, styles.grayBox)}>
            <div className={styles.cHorOffsets}>
              {bTitle && <h4 dangerouslySetInnerHTML={{ __html: bTitle }} />}
              {bDescription && <h5 dangerouslySetInnerHTML={{ __html: bDescription }} />}
            </div>
            <GradientSplitter style={bLink?.url ? styles.splitterOffsets : styles.splitterOffsetTop} />
            <div className={styles.cHorOffsets}>{!!bLink?.url && <Link to={bLink.url}>{bLink.caption}</Link>}</div>
          </div>
        </div>
      );

    case 'withIcon':
      return (
        <div className={classNames(styles.cContentItem, bStyle)}>
          <div className={classNames(styles.cInfoBox)}>
            <div>
              {bTitle && <h5 dangerouslySetInnerHTML={{ __html: bTitle }} />}
              {!!bLink?.url && (
                <Link className={styles.readMoreLink} to={bLink.url}>
                  {bLink.caption}
                </Link>
              )}
            </div>
            <img src={bIconURL} alt="" />
          </div>
        </div>
      );
  }
};
export default InfoBanner;

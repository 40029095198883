import React from 'react';
import 'react-phone-number-input/style.css';
import { Button } from 'components';
import styles from './ChargerOrderTile.module.scss';
import { ChargerOrder } from '../../../../types/Order';
import { useHistory } from 'react-router-dom';

interface Props {
  order: ChargerOrder;
}

const ChargerOrderTile = (props: Props) => {
  const { order } = props;
  const history = useHistory();

  const renderButton = () => {
    return (
      <Button
        className={styles.btn}
        onClick={() => {
          history.push(`/charge-at-home/order/${order.id}/confirmation`);
        }}
        buttonColor={'white'}
      >
        View Order
      </Button>
    );
  };

  return (
    <div className={styles.cOrder} key={` ${order.id}`}>
      <div className={styles.order}>
        <div className={styles.orderContent}>
          <h3 className={styles.orderTitle}>AES Indiana Level 2 Charger Bundles</h3>
          <p>Powered by Motor</p>
          {renderButton()}
        </div>
      </div>
    </div>
  );
};

export default ChargerOrderTile;

import {
  Vehicle,
  VehicleAPI,
  Package,
  PackageAPI,
  Color,
  ColorAPI,
  Interior,
  InteriorAPI,
  CustomFeatureAPI,
  CustomFeature,
  IncentiveAPI,
  Incentive,
  CurrentBuildAnswerAPI,
  CurrentBuildAnswer,
  VehicleInventoryAPI,
  VehicleInventory,
} from '../../types/Vehicle';
import { TestDriveVehicleAPI, TestDriveVehicle } from '../../types/TestDrive';

export const adaptTestDriveVehicleApi = (vehicle: TestDriveVehicleAPI): TestDriveVehicle => {
  return {
    id: vehicle.id,
    model: vehicle.model,
    visualSet: {
      id: vehicle['visual_set'].id,
      front: vehicle['visual_set'].front,
      side: vehicle['visual_set'].side,
      rear: vehicle['visual_set'].rear,
      interior: vehicle['visual_set'].interior,
      threeD: vehicle['visual_set']['three_d'],
      video: vehicle['visual_set'].video,
      backgroundLarge: vehicle['visual_set'].background_large,
      backgroundSmall: vehicle['visual_set'].background_small,
      backgroundMedium: vehicle['visual_set'].background_medium,
    },
    fullTestDrivePrice: vehicle.full_test_drive_price,
    quickTestDrivePrice: vehicle.quick_test_drive_price,
    year: vehicle.year,
  };
};

export const adaptVehicleApi = (vehicle: VehicleAPI): Vehicle => {
  return {
    slug: vehicle.slug,
    overviewFeatures: vehicle.overview_features,
    highlevelFeatures: vehicle.highlevel_features,
    priceAfterEstimatedSavings: vehicle.price_after_estimated_savings,
    id: vehicle.id,
    model: vehicle.model,
    overviewDescription: vehicle['overview_description'],
    chargerPackage: vehicle.charger_package
      ? {
          maxPrice: vehicle.charger_package.charger_install_max_price,
          minPrice: vehicle.charger_package.charger_install_min_price,
          stationPrice: vehicle.charger_package.charger_station_price,
          description: vehicle.charger_package.description,
          enrollPrice: vehicle.charger_package.enroll_get_off_price,
          image: vehicle.charger_package.image,
          title: vehicle.charger_package.title,
          id: vehicle.charger_package.id,
          dueNow: vehicle.charger_package.due_now,
        }
      : null,
    visualSet: {
      id: vehicle['visual_set'].id,
      front: vehicle['visual_set'].front,
      side: vehicle['visual_set'].side,
      rear: vehicle['visual_set'].rear,
      interior: vehicle['visual_set'].interior,
      threeD: vehicle['visual_set']['three_d'],
      video: vehicle['visual_set'].video,
      backgroundLarge: vehicle['visual_set'].background_large,
      backgroundSmall: vehicle['visual_set'].background_small,
      backgroundMedium: vehicle['visual_set'].background_medium,
    },
    warranty: vehicle.warranty,
    year: vehicle.year,
  };
};

export const adaptPackagesApi = (packages: PackageAPI[]): Package[] => {
  return packages.map(p => ({
    id: p.id,
    title: p.title,
    description: p.description,
    additionalCost: p.additional_cost,
    image: p.image,
    isDueNow: p.is_due_now,
  }));
};

export const adaptIncentivesApi = (incentives: IncentiveAPI[]): Incentive[] => {
  return incentives.map(i => ({
    id: i.id,
    title: i.title,
    description: i.description,
    discount: i.discount,
    image: i.image,
    isDefault: i.is_default,
  }));
};

export const adaptCustomFeaturesApi = (feature: CustomFeatureAPI[]): CustomFeature[] => {
  return feature.map(p => ({
    id: p.id,
    title: p.title,
    category: p.category,
    additionalCost: p.additional_cost,
  }));
};

export const adaptColorApi = (colors: ColorAPI[]): Color[] => {
  return colors.map(c => ({
    id: c.id,
    title: c.title,
    hexCode: c.hex_code,
    additionalCost: c.additional_cost,
    front: c.front,
    side: c.side,
    rear: c.rear,
  }));
};

export const adaptInteriorApi = (interiors: InteriorAPI[]): Interior[] => {
  return interiors.map(i => ({
    id: i.id,
    title: i.title,
    image: i.image,
    hexCode: i.hex_code,
    additionalCost: i.additional_cost,
  }));
};

export const adaptCurrentBuildAnswerApi = (build: CurrentBuildAnswerAPI): CurrentBuildAnswer => {
  const buildAdapted: CurrentBuildAnswer = {
    id: build.id,
    createdAt: build.created_at,
    createdBy: build.created_by,
    total: build.total,
    type: build.type,
    variety: build.variety,
    dueNowTotal: build.due_now_total,
    version: {
      description: build.trim.description,
      holdingFee: build.trim.holding_fee,
      price: build.trim.price,
      title: build.trim.title,
    },
    color: build.color,
    interior: build.interior,
    packages: build.packages,
    incentives: build.incentives,
    customFeatures: build.custom_features,
    loanDetail: {
      afterEstimatedSavingsMo: build.loan_detail.after_estimated_savings_mo,
      apr: build.loan_detail.apr,
      downpayment: build.loan_detail.downpayment,
      financedAmount: build.loan_detail.financed_amount,
      paymentMo: build.loan_detail.payment_mo,
      termMo: build.loan_detail.term_mo,
    },
  };

  if (build.charger_package) {
    buildAdapted.chargerPackage = {
      isEnroll: build.charger_package.is_enroll,
      isJumpstart: build.charger_package.is_jumpstart,
      maxPrice: build.charger_package.max_price,
      minPrice: build.charger_package.min_price,
      package: {
        maxPrice: build.charger_package.package.charger_install_max_price,
        minPrice: build.charger_package.package.charger_install_min_price,
        chargerStationPrice: build.charger_package.package.charger_station_price,
        description: build.charger_package.package.description,
        enrollPrice: build.charger_package.package.enroll_get_off_price,
        id: build.charger_package.package.id,
        image: build.charger_package.package.image,
        title: build.charger_package.package.title,
      },
    };
  }

  return buildAdapted;
};

export const adaptVehicleInventoryApi = (vehicle: VehicleInventoryAPI): VehicleInventory => {
  return {
    id: vehicle.id,
    color: {
      id: vehicle.color.id,
      title: vehicle.color.title,
      hexCode: vehicle.color.hex_code,
      additionalCost: vehicle.color.additional_cost,
      front: vehicle.color.front,
      rear: vehicle.color.rear,
      side: vehicle.color.side,
    },
    interior: {
      id: vehicle.interior.id,
      title: vehicle.interior.title,
      image: vehicle.interior.image,
      hexCode: vehicle.interior.hex_code,
      additionalCost: vehicle.interior.additional_cost,
      view1: vehicle.interior.view_1,
      view2: vehicle.interior.view_2,
    },
    vehicle: adaptVehicleApi(vehicle.vehicle),
    trim: {
      id: vehicle.trim.id,
      title: vehicle.trim.title,
      description: vehicle.trim.description,
      price: vehicle.trim.price,
      colors: adaptColorApi(vehicle.trim.colors),
      interiors: adaptInteriorApi(vehicle.trim.interiors),
      packages: adaptPackagesApi(vehicle.trim.packages),
      incentives: adaptIncentivesApi(vehicle.trim.incentives),
      customFeatures: adaptCustomFeaturesApi(vehicle.trim.custom_features as CustomFeatureAPI[]),
      range: vehicle.trim.range,
    },
    vin: vehicle.vin,
    stock: vehicle.stock,
    type: vehicle.type,
    modelNumber: vehicle.model_number,
    mileage: vehicle.mileage,
    sellingPrice: vehicle.selling_price,
    invoice: vehicle.invoice,
    certified: vehicle.certified,
    motorNotes: vehicle.motor_notes,
    dealerCode: vehicle.dealer_code,
    year: vehicle.year,
    windowSticker: vehicle.window_sticker,
    visualSet: vehicle.visual_set,
    downpayment: vehicle.downpayment,
    apr: vehicle.apr,
    termMo: vehicle.term_mo,
    paymentMo: vehicle.payment_mo,
    financedAmount: vehicle.financed_amount,
    holdingFee: vehicle.holding_fee,
    taxes: vehicle.taxes,
    titleAndRegistration: vehicle.title_and_registration,
    msrp: vehicle.msrp,
  };
};

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import get from 'lodash/get';
import { observer } from 'mobx-react';

import { useStores } from '../../hooks/use-stores';
import { Card, PaymentDuePanel } from 'components';
import PaymentForm from './Form';
import config from '../../environment';

import styles from './Payment.module.scss';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { id: string };
  };
}

const stripePromise = loadStripe(config.stripeKey);

const Payment = observer((props: Props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { orderStore, authStore, vehicleStore } = useStores();
  const userId = get(authStore, 'user.id');

  useEffect(() => {
    vehicleStore.getBuildByOrderId(id);
    if (!authStore.user?.id) {
      authStore.getCurrentUser();
      return;
    }
    orderStore.updateOrder(authStore.user.id, id);
  }, [authStore, authStore.user, id, orderStore, userId, vehicleStore]);

  const updatePaymentIntent = async (paymentMethodID: string) => {
    console.log('vehicleStore.buildDueNowPrice?.holdingFee', vehicleStore.buildDueNowPrice?.holdingFee);
    const paymentData = {
      orderId: id,
      amount: vehicleStore.buildDueNowPrice?.holdingFee || '0',
      currency: 'usd',
    };
    await orderStore.createPaymentIntent(paymentData);

    const paymentIntentID = orderStore.paymentIntent?.id;
    if (!paymentIntentID) return;
    await orderStore.updatePaymentIntent(paymentMethodID, paymentIntentID, id);
  };

  return (
    <>
      <div className={styles.cContent}>
        <Card>
          {!orderStore.loadingPacks && (
            <PaymentDuePanel
              currentPackages={undefined}
              dueNowPrice={undefined}
              holdingFee={vehicleStore.buildDueNowPrice?.holdingFee}
            />
          )}
        </Card>

        <Elements stripe={stripePromise}>
          <Card>
            <PaymentForm updatePaymentIntent={updatePaymentIntent} />
          </Card>
        </Elements>
      </div>
    </>
  );
});
export default Payment;

import { CarouselSliderBrandsProps } from '../../types/Slider';

export const brandsSliderContent: CarouselSliderBrandsProps = {
  slidesList: [
    {
      image: '/images/sliders/brands/indystar.png',
      url:
        'https://www.indystar.com/story/news/local/transportation/2020/09/03/indianapolis-electric-car-subscription-service-motor-drive-launches/3455853001/',
    },
    {
      image: '/images/sliders/brands/ibj.png',
      url: 'https://www.ibj.com/articles/electric-vehicle-subscription-service-launched-in-indianapolis-market',
    },
    {
      image: '/images/sliders/brands/indianapolis.png',
      url: 'https://www.indianapolismonthly.com/news-and-opinion/motor-drive#.X0au5Ozsprg.twitter',
    },
    {
      image: '/images/sliders/brands/inside.png',
      url: 'https://www.insideindianabusiness.com/story/43434811/aes-indiana-motor-to-facilitate-electric-car-adoption',
    },
    {
      image: '/images/sliders/brands/wish.png',
      url: 'https://www.wishtv.com/news/try-an-electric-car-without-making-a-long-term-commitment/',
    },
  ],
};

export const reportsSliderContent: CarouselSliderBrandsProps = {
  slidesList: [
    {
      image: '/images/sliders/reports/carfax.png',
      url: 'https://www.carfax.eu/',
    },
    {
      image: '/images/sliders/reports/autocheck.png',
      url: 'https://www.autocheck.com/',
    },
    {
      image: '/images/sliders/reports/vehiclehistory.png',
      url: 'https://vehiclehistory.bja.ojp.gov/',
    },
  ],
};

import React from 'react';
import styles from './StaticPages.module.scss';

const HoldingFee = () => {
  return (
    <div className={styles.cPage}>
      <h1>Holding fee</h1>
      <h4>Subtitle</h4>
      <h5>Heading</h5>
      <p>
        <strong>Emphasized text.</strong> Personal Information You Give Us: We receive the personal information you
        provide to us such as your name, email address, phone number, address, and home profile. Our Services offer you
        the opportunity to create a profile and submit questions and comments that may be visible to other users of the
        Services. The information you provide in areas that are visible by other logged-in users of the Services.
      </p>
    </div>
  );
};

export default HoldingFee;

import React from 'react';
import classNames from 'classnames';

import 'react-phone-number-input/style.css';
import { Button } from 'components';
import styles from './RegularOrderTile.module.scss';
import { Order } from '../../../../types/Order';
import { useHistory } from 'react-router-dom';

interface Props {
  order: Order;
  type?: 'regular' | 'afterSubscription';
}

const RegularOrderTile = (props: Props) => {
  const { order, type } = props;
  const history = useHistory();

  const renderButton = () => {
    return (
      <Button
        className={styles.btn}
        onClick={() => {
          switch (order.statusShort) {
            case 'pending':
              return history.push(`/order/${order.id}/payment`);
            case 'completed':
              return history.push(`/order/${order.id}/completed`);
            default:
              return history.push(`/order/${order.id}/status`);
          }
        }}
        buttonColor={order.statusShort === 'pending' ? 'blue' : 'white'}
      >
        {order.statusShort === 'pending' ? 'Pay' : 'View Order'}
      </Button>
    );
  };

  const renderAfterSnbscriptionButton = () => {
    return (
      <Button
        className={styles.btn}
        onClick={() => {
          if (order.paymentType === 'loan') {
            history.push(`/subscribe-to-buy/confirmation/loan`);
          } else {
            history.push(`/subscribe-to-buy/confirmation/cash`);
          }
        }}
        buttonColor={'white'}
      >
        View Order
      </Button>
    );
  };

  return (
    <div className={styles.cOrder} key={` ${order.id}`}>
      <div
        className={styles.order}
        style={{ backgroundImage: `url(${order.vehicleBuild.vehicle?.visual_set.background_medium})` }}
      >
        <div className={styles.orderContent}>
          <Button className={classNames(styles.btnSmall, order.statusShort === 'completed' ? styles.dark : null)}>
            {order.statusShort || 'In progress'}
          </Button>

          {type === 'afterSubscription' && <span className={styles.cAfterSubscription}>after subscription</span>}

          <h3 className={styles.orderTitle}>
            {order.vehicleBuild.vehicle?.year} {order.vehicleBuild.vehicle?.model}
          </h3>
          {type === 'afterSubscription' ? renderAfterSnbscriptionButton() : renderButton()}
        </div>
      </div>
    </div>
  );
};

export default RegularOrderTile;

import Butter from 'buttercms';
export const butter = Butter('943d1cb19620ddc9ce4cd9a43e22c804c8214896');

export default class CmsApi {
  async getPage(name: string) {
    await butter.page
      .retrieve('*', name, {
        preview: 1,
      })
      .then(function (resp) {
        console.log(resp.data);
        return resp.data;
      })
      .catch(function (resp) {
        console.log(resp);
      });
  }
}

import React, { useEffect } from 'react';
import classNames from 'classnames';

import { Button, StatusBar } from 'components';
import styles from './ConfirmationChargerOrder.module.scss';
import { standAloneChargerStatusBar } from '../../appConstants';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { useHistory } from 'react-router-dom';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { orderId: string };
  };
}
const ConfirmationChargerOrder = observer((props: Props) => {
  const {
    match: {
      params: { orderId },
    },
  } = props;
  const { orderStore, cmsStore } = useStores();

  useEffect(() => {
    cmsStore.getPage('charger-order-confirmation');
  }, [cmsStore]);

  const {
    pageContent: { fields },
  } = cmsStore;

  const history = useHistory();

  return (
    <div className={classNames([styles.cPage, styles.cHorOffsetsMobile])}>
      <StatusBar steps={standAloneChargerStatusBar} activeStep={'Schedule installation'} />
      <div className={styles.cContent}>
        <h1>{fields?.title}</h1>
        <h3 className={styles.offsetTop24}>{fields?.description}</h3>
        <div className={styles.cTiles}>
          <h2 className={styles.offsetTop24}>{fields?.readyForInstallation?.title}</h2>
          <ul>
            {fields?.readyForInstallation?.tiles?.map((item: { icon: string; description: string }, index: string) => {
              return (
                <li key={index}>
                  <div className={styles.icon} style={{ backgroundImage: `url(${item.icon})` }} />
                  <p>{item.description}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.cButtons}>
          <Button
            onClick={() => {
              !fields?.buttonLeft?.isInternalLink
                ? window.open(fields?.buttonLeft?.url)
                : history.push(fields?.buttonLeft?.url);
            }}
            buttonColor="black"
            size="big"
            fullWidth
            arrow={'right'}
          >
            {fields?.buttonLeft?.caption}
          </Button>

          {!orderStore.chargerSurveySent ? (
            <Button
              onClick={() => {
                orderStore.sendChargerSurvey(orderId);
              }}
              buttonColor="black"
              size="big"
              fullWidth
              arrow={'right'}
            >
              {fields?.buttonRight?.caption}
            </Button>
          ) : (
            <div className={styles.sentButton}>Survey sent!</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ConfirmationChargerOrder;

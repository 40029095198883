import React, { useState, useEffect } from 'react';
import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker } from 'react-google-maps';

import { ChargerStation } from '../../../types/User';
import styles from './SearchInput.module.scss';

interface MapComponentProps {
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: {
    latitude: number;
    longitude: number;
  };
  origin?: { lat: number; lng: number };
  destination?: { lat: number; lng: number };
  pins: ChargerStation[] | null[];
}
/*eslint-disable  no-undef*/
const directionsService = new google.maps.DirectionsService();

const DirectionMap = withGoogleMap((props: any) => {
  const [directions, setDirections] = useState<any>();
  let bounds: { ne: { lat: number; lng: number }; sw: { lat: number; lng: number } };

  useEffect(() => {
    if (!props.origin || !props.destination) return;

    directionsService.route(
      {
        origin: props.origin,
        destination: props.destination,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      }
    );
  }, [props.origin, props.destination]);

  return directions ? (
    <GoogleMap
      ref={ref => {
        if (!ref) return;

        let ne = ref.getBounds().getNorthEast();
        let sw = ref.getBounds().getSouthWest();
        bounds = { ne: { lat: ne.lat(), lng: ne.lng() }, sw: { lat: sw.lat(), lng: sw.lng() } };
      }}
      defaultZoom={10}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      <DirectionsRenderer directions={directions}></DirectionsRenderer>
      {props.pins.length &&
        props.pins.map((p: { latitude: number; longitude: number }, i: number) => (
          <Marker
            position={{ lat: p.latitude, lng: p.longitude }}
            key={`${i} ${bounds}`}
            defaultIcon="/images/charger_pin.png"
          />
        ))}
    </GoogleMap>
  ) : null;
});

const MapWithMarker = ({
  coords,
  origin,
  destination,
  isGeolocationAvailable,
  isGeolocationEnabled,
  pins,
}: MapComponentProps) => {
  if (!isGeolocationEnabled) {
    console.warn('isGeolocationEnabled');
  }

  return !isGeolocationAvailable ? (
    <h3>Your browser does not support Geolocation</h3>
  ) : coords ? (
    <div>
      {origin && destination && pins ? (
        <DirectionMap
          origin={origin}
          destination={destination}
          lng={coords.longitude}
          lat={coords.latitude}
          pins={pins}
          containerElement={<div className={styles.cMap} />}
          mapElement={<div className={styles.map} />}
        />
      ) : null}
    </div>
  ) : (
    <h3>Getting the location data </h3>
  );
};

export default React.memo(MapWithMarker);

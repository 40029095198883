import React from 'react';

import styles from './Loader.module.scss';
import loaderImg from '../../assets/loader.svg';

const Loader = () => {
  return (
    <div className={styles.cLoader}>
      <img src={loaderImg} alt="" />
    </div>
  );
};
export default Loader;

import React, { useEffect, useState } from 'react';
import styles from './Charger.module.scss';
import { Button, CheckboxChargerOfferLabel } from '../index';
import classNames from 'classnames';
import { getArrayFromList } from '../../helpers/formatters/htmlCode';
import ReactTooltip from 'react-tooltip';
import { ChargerPackage } from '../../types/Vehicle';

interface Props {
  type?: 'regular' | 'subscription';
  heading?: string;
  chargerPackage: ChargerPackage;
  submitHandler: (isEnroll: boolean, isChargerJumpstart: boolean, isExistFasterCharger?: boolean) => void;
}

const Charger = (props: Props) => {
  const { title, image, description, maxPrice, minPrice, stationPrice } = props.chargerPackage;
  const { type, heading } = props;

  const preparedHeading = heading || 'Special charger offer from AES Indiana + Motor';
  const preparedDescription =
    description ||
    '<li>JuiceBox 40 WiFi Enabled EV Charging Station. Up to 7x faster charge.</li>' +
      '<li>Faster charger install. Installation by a certified electrician.</li>';

  const [isChargerJumpstart, setChargerJumpstart] = useState(true);
  const [isExistFasterCharger, setFasterCharger] = useState(false);
  const [isEnroll, setEnroll] = useState(false);

  const buffer = sessionStorage.getItem(
    type === 'subscription' ? 'subscribe-to-buy/charger-offer' : 'buy/charger-offer'
  );
  const chargerConfig = buffer ? JSON.parse(buffer) : undefined;

  useEffect(() => {
    if (chargerConfig) {
      const { isChargerPackage, isEnroll, isExistFasterCharger } = chargerConfig;
      setChargerJumpstart(isChargerPackage);
      setFasterCharger(isExistFasterCharger);
      setEnroll(isEnroll);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.cContent}>
      <section className={classNames(styles.offsetTop, styles.cWrapper)}>
        <div className={styles.cHorOffsets}>
          <h1 className={styles.heading}>{preparedHeading}</h1>
          <p className={styles.noticeText}>Please select your charging solution:</p>
          <div className={styles.cInputCustomize}>
            <div>
              <input
                id={'chargerPackage'}
                name={'chargerPackage'}
                type="checkbox"
                value={'chargerPackage'}
                checked={isChargerJumpstart}
                onChange={e => {
                  setChargerJumpstart(!isChargerJumpstart);
                  setFasterCharger(false);
                  setEnroll(false);
                }}
              />
              <CheckboxChargerOfferLabel
                inputId={'chargerPackage'}
                itemTitle={title as string}
                itemImage={image || '/images/temp/package.svg'}
                discount={isEnroll && isChargerJumpstart ? 250 : 0}
                descriptionList={getArrayFromList(preparedDescription as string)}
                price={parseInt(stationPrice as string)}
                secondPrice={`$${parseInt(minPrice as string)}–$${parseInt(maxPrice as string)}*`}
              />
            </div>

            {type === 'subscription' && (
              <div>
                <div className={styles.flex}>
                  <input
                    id={'fasterCharger'}
                    name={'fasterCharger'}
                    type="checkbox"
                    value={'fasterCharger'}
                    checked={isExistFasterCharger}
                    onChange={() => {
                      setFasterCharger(!isExistFasterCharger);
                      setChargerJumpstart(false);
                      setEnroll(false);
                    }}
                  />
                  <label className={classNames(styles.checkboxLabel, styles.cuPadd)} htmlFor={'fasterCharger'}>
                    <div className={styles.cCheckBox}>
                      <div className={styles.chTitle}>
                        Keep the faster charger I had installed through my subscription{' '}
                      </div>
                    </div>
                  </label>
                </div>
                <div className={styles.splitter} />
              </div>
            )}

            <div className={styles.flex}>
              <input
                id={'enroll'}
                name={'enroll'}
                type="checkbox"
                value={'enroll'}
                checked={isEnroll}
                onChange={() => {
                  if (isChargerJumpstart || isExistFasterCharger) {
                    setEnroll(!isEnroll);
                  }
                }}
              />
              <label
                className={classNames(
                  styles.checkboxLabel,
                  isChargerJumpstart || isExistFasterCharger ? null : styles.checkboxDisabled
                )}
                htmlFor={'enroll'}
              >
                <div className={styles.cCheckBox}>
                  <div className={styles.chTitle}>Enroll in Managed Charging</div>
                </div>
              </label>

              <img
                src="/images/infoImg.svg"
                alt=""
                data-html={true}
                data-tip={
                  '<p>Motor offers customers with a charging unit to opt-in to a managed charging program. This program allows Motor or its affiliates to control when your car charges when electricity demand is high. If you opt-in, you will receive $250 off the Charger jumpstart package price. For more information, please review the Motor terms and conditions found <a href="/terms-service">here</Link>.</p>'
                }
              />
              <ReactTooltip className={styles.tooltip} clickable={true} delayHide={2000} html={true} />
            </div>

            <div className={styles.descBox}>
              <p>
                The charger will automatically optimize your charging for times that are best for you and the
                environment.
              </p>
              <p className={styles.pBold}>
                Get <span className={styles.greenText}>$250 off</span> the Charger jumpstart package price when you
                enroll.
              </p>
            </div>
          </div>
          <p className={styles.description}>
            By enrolling, you agree to{' '}
            <a href="/terms-service" target="_blank" rel="noopener norefferer" className={styles.link}>
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a href="/privacy-policy" target="_blank" rel="noopener norefferer" className={styles.link}>
              Privacy Policy
            </a>
          </p>
          <Button
            onClick={() =>
              props.submitHandler(
                isEnroll,
                isChargerJumpstart,
                type === 'subscription' ? isExistFasterCharger : undefined
              )
            }
            buttonColor="green"
            fullWidth
            size="big"
            className={styles.btn}
          >
            Proceed
          </Button>
        </div>
      </section>
    </div>
  );
};
export default Charger;

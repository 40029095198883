import React from 'react';
import styles from './VehicleSpecs.module.scss';
import { MMVehicle } from '../../../../types/Vehicle';

export interface Props {
  vehicleDetails: MMVehicle;
}

const VehicleSpecs = (props: Props) => {
  const { vehicleDetails } = props;

  if (!vehicleDetails) return null;
  const { range, seatingCapacity, bodyStyle, capability, batteryCapacity, mpge } = vehicleDetails;
  return (
    <div className={styles.cSpecs}>
      <h4>Key features</h4>
      <ul className={styles.mainProperties}>
        <li>
          <span>Range:</span>
          <span>{range ? `${range} miles battery only` : 'n/a'}</span>
        </li>
        <li>
          <span>Seating:</span>
          <span>{seatingCapacity ? `Seats up to ${seatingCapacity}` : 'n/a'}</span>
        </li>
        <li>
          <span>Style:</span>
          <span>{bodyStyle ? bodyStyle : 'n/a'}</span>
        </li>
        <li>
          <span>Capability:</span>
          <span>{capability ? capability : 'n/a'}</span>
        </li>
        <li>
          <span>Battery:</span>
          <span>{batteryCapacity ? `${batteryCapacity} kWh` : 'n/a'}</span>
        </li>
        <li>
          <span>MPGe:</span>
          <span>{mpge ? mpge : 'n/a'}</span>
        </li>
      </ul>
    </div>
  );
};

export default VehicleSpecs;

import React from 'react';
import { InfoBanner } from 'components';
import env from 'environment';

interface Props {
  bName:
    | 'subscription'
    | 'subscription2'
    | 'testDrive'
    | 'testDrive2'
    | 'mapRoute'
    | 'lifestyle'
    | 'ownership'
    | 'ownership2'
    | 'calculatorSavings'
    | 'dynEconomy'
    | 'dynEconomy2'
    | 'dynEconomyNight'
    | 'dynEcology'
    | 'dynChargers';
  bStyle?: string;
}

const BannerContainer = ({ bName, bStyle = '' }: Props) => {
  switch (bName) {
    case 'subscription':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle={'AES Indiana | Motor has subscription plans that start with only a 30-day commitment.'}
          bLink={{ url: '/subscription', caption: 'Explore our Subscription options' }}
        />
      );

    case 'subscription2':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle={'Did you know?'}
          bDescription={
            'AES Indiana | Motor doesn’t believe in commitment issues? Precisely why we offer electric car subscriptions that empower you to try before you buy?'
          }
          bLink={{ url: '/own', caption: 'Explore our Subscription options' }}
        />
      );

    case 'dynEconomy':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle="Did you know?"
          bDescription="On average, electric cars owners spend 50% less on fuel compared to gas powered car owners. And, they’ll
              never have to pump gas again!"
        />
      );

    case 'dynEconomyNight':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle="Did you know?"
          bDescription="<p>
              AES Indiana offers a rate plan that helps you save money when you charge at night. You won't be the only one
              recharging your battery when day is done.
            </p>
            <p>Don’t worry, AES Indiana | Motor will get you on the best rate for your car.</p>"
        />
      );

    case 'dynEcology':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle="Did you know?"
          bDescription="<p>Combining an electric car with AES Indiana’s Green Energy program results in 100% carbon-free transportation?</p>
            <p>Don’t worry, AES Indiana | Motor will get you on the best rate for your car.</p>"
        />
      );

    case 'dynChargers':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle="Did you know?"
          bDescription="There are more than 130 charging stations in the Indianapolis area?"
        />
      );

    case 'dynEconomy2':
      return (
        <InfoBanner
          bStyle={bStyle}
          bTitle="Did you know?"
          bDescription="On average, electric cars owners spend 50% less on fuel compared to gas powered car owners? Lets see how much you’ll save by going electric."
        />
      );

    case 'mapRoute':
      return (
        <InfoBanner
          bStyle={bStyle}
          bType="withIcon"
          bTitle={'Electric cars are more than up to the challenge. Plug in your daily destinations to see how.'}
          bIconURL={'/images/icon-map.svg'}
          bLink={{ url: '/estimator/charging', caption: 'Map your route' }}
        />
      );

    case 'calculatorSavings':
      return (
        <InfoBanner
          bStyle={bStyle}
          bType="withIcon"
          bTitle={"Electric cars save your money and our planet. Numbers don't lie. Give it a try."}
          bIconURL={'/images/savings.svg'}
          bLink={{ url: '/estimator/cost', caption: 'Try our Calculator' }}
        />
      );

    case 'lifestyle':
      return (
        <InfoBanner
          bStyle={bStyle}
          bType="withIcon"
          bTitle="Integrating an Electric Car into your everyday life is simpler than you might think and has huge
                advantages."
          bIconURL={'/images/icon_advisor_charging.svg'} // /images/icon_advisor_charging.svg
          bLink={{ url: '/electric-car-lifestyle', caption: 'Try the Electric Lifestyle' }}
        />
      );

    case 'ownership':
      return (
        <InfoBanner
          bStyle={bStyle}
          bType="withIcon"
          bTitle="Ready to get behind the wheel? AES Indiana has vehicle and ownership options to suit all electrified lifestyles."
          bIconURL={'/images/ownership.svg'}
          bLink={{ url: '/own', caption: 'Explore Ownership options' }}
        />
      );

    case 'ownership2':
      return (
        <InfoBanner
          bStyle={bStyle}
          bType="withIcon"
          bTitle="Interested in owning an electric car? Try out our simplified purchasing process."
          bIconURL={'/images/icon_advisor_estimate.svg'}
          bLink={{ url: '/own', caption: 'Explore Ownership options' }}
        />
      );

    case 'testDrive':
      if (env.enableTestDrive) {
        return (
          <InfoBanner
            bStyle={bStyle}
            bType="withIcon"
            bTitle={'Driving is believing. Set up a time to take an electric car for a spin. You may never come back.'}
            bIconURL={'/images/icon-test-drive.svg'}
            bLink={{ url: '/test-drive', caption: 'Go for a Ride' }}
          />
        );
      } else return <div />;

    case 'testDrive2':
      if (env.enableTestDrive) {
        return (
          <InfoBanner
            bStyle={bStyle}
            bType="withIcon"
            bTitle={"Want to try an electric car out, no strings attached? We'll deliver for a test drive."}
            bIconURL={'/images/icon-test-drive.svg'}
            bLink={{ url: '/test-drive', caption: 'Sign Up' }}
          />
        );
      } else return <div />;

    default:
      return <div />;
  }
};
export default BannerContainer;

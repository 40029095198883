import React from 'react';
import { FormikValues } from 'formik';

import 'react-phone-number-input/style.css';
import { FileUploader } from 'components';
import styles from './DriverLicenseUploader.module.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';

interface Props {
  formik: FormikValues;
  text: string;
  fieldName: string;
  relatedFieldName: string;
  errorText: string;
}

const DriverLicenseUploader = observer((props: Props) => {
  const { orderStore } = useStores();

  const { formik, text, fieldName, relatedFieldName, errorText } = props;
  const value = formik.values[fieldName];
  const relatedValue = formik.values[relatedFieldName];

  return (
    <FileUploader
      styleContainer={styles.inputFU50}
      text={text}
      errorText={errorText}
      fileContent={value}
      onClick={(e: React.MouseEvent) => {
        const target = e.currentTarget as HTMLInputElement;
        if (value) {
          e.preventDefault();
          target.value = '';
          formik.setFieldValue(fieldName, undefined);
          orderStore.driverLicense = undefined;
        }
      }}
      onChange={async (e: React.ChangeEvent) => {
        const element = e.target as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (!value && fileList !== null) {
          formik.setFieldValue(fieldName, fileList[0]);
          if (relatedValue) {
            formik.setStatus({ loading: true });
            await orderStore.uploadDriverLicense(fileList[0], relatedValue);
            formik.setStatus({ loading: false });
          }
        }
      }}
    />
  );
});

export default DriverLicenseUploader;

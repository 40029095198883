import React from 'react';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';

import { Button } from 'components';
import mainImage from '../../assets/main-404.svg';
import styles from './Page404.module.scss';

const Page404 = (props: RouteComponentProps) => {
  return (
    <div className={classNames([styles.cPage, styles.cHorOffsets])}>
      <div className={styles.cContent}>
        <img src={mainImage} alt="" />
        <h1>Page is not found</h1>
        <p>The page you’re looking for doesn’t exist</p>
        <Button onClick={() => props.history.push('/')} buttonColor="green" size="big" fullWidth>
          Return to the home
        </Button>
      </div>
    </div>
  );
};

export default Page404;

import React from 'react';
import { Checkbox } from 'components';
import styles from './CheckboxGroup.module.scss';

interface Props {
  options: { id: string; value: string; label: string }[];
  name: string;
  onChange: (res: string[]) => void;
  value: string[];
}

const CheckboxGroup = ({ options, name, onChange, value }: Props) => {
  return (
    <div className={styles.cFilter}>
      <h5>{name}</h5>
      <div className={styles.filterContent}>
        {options.map((item, index) => {
          let val = value;
          return (
            <Checkbox
              key={`ch${index}`}
              name={item.id}
              text={item.label}
              checked={!!value?.find(el => el === item.id)}
              markerColor="purple"
              rounded={true}
              onChange={e => {
                if (e.target.checked) {
                  val?.push(item.id);
                } else {
                  val?.push(item.id);
                  val = val.filter(function (i) {
                    return i !== item.id;
                  });
                }
                onChange(val);
              }}
              className={styles.cuCheckbox}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxGroup;

import { preferencesMatch } from '../../appConstants';

export const convertToSelect = (obj: { id: string; name: string }[] | undefined) => {
  if (!obj) return undefined;
  return obj.map(item => {
    return { label: item.name, value: item.id };
  });
};

export const convertToSelect2 = (obj: { id: string; name: string }[] | undefined) => {
  if (!obj) return undefined;
  return obj.map(item => {
    return { label: item.name, value: item.id };
  });
};

export const convertToDD = (obj: string[]): { id: string; name: string }[] | undefined => {
  if (!obj) return undefined;
  let a = obj.map(item => {
    return preferencesMatch.find(item2 => item2.id === item) || { id: '', name: '' };
  });
  return a;
};

import { createApi } from '../../api/apiCreator';
import environment from '../../environment';
import { AccessTokens } from '../../api/apiCreator/types';
import { Configuration } from '../../types/Configuration';
import { injectAuthTokenToHeader } from '../../api/apiCreator/utils';
import TokenStore from '../../stores/TokenStore/TokenStore';
import { adaptConfigurationAPI } from './adapter';

const { auth } = environment.apiUrls;
const authAPI = createApi({ baseURL: auth });

export async function sendPhone(phone: string): Promise<AccessTokens> {
  const resp = await authAPI.post<AccessTokens>('/auth/mobile/', { phone });

  return resp.data;
}

export async function getAccessToken({ otp, phone }: { otp: string; phone: string }): Promise<AccessTokens> {
  const resp = await authAPI.post<AccessTokens>('/auth/tokens/otp/', { otp, phone });

  await TokenStore.setAccessToken(resp.data.access);
  await TokenStore.setRefreshToken(resp.data.refresh);
  injectAuthTokenToHeader(resp);

  return resp.data;
}

export async function getAccessTokenGuest(username: string, password: string): Promise<AccessTokens> {
  const resp = await authAPI.post<AccessTokens>('/auth/tokens/password/', { username, password });

  return resp.data;
}

export async function getConfiguration(): Promise<Configuration> {
  const resp = await authAPI.get(`/configuration/`);
  return adaptConfigurationAPI(resp.data);
}

export default authAPI;

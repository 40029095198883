import React, { useEffect } from 'react';
import { ModelsCarouselSlider, Collapse, Button, Loader, SubscriptionInfoSection, TopBanner } from 'components';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import styles from './Own.module.scss';

const Own = observer(() => {
  const { vehicleStore, cmsStore } = useStores();

  useEffect(() => {
    cmsStore.getPage('own');
    vehicleStore.getInventoryVehicles();
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = '/js/hotjar.js';
    document.body.insertBefore(scriptTag, document.body.firstChild);
  }, [vehicleStore, cmsStore]);

  const history = useHistory();
  const {
    pageContent: { fields },
  } = cmsStore;

  if (cmsStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      {fields?.topBanner && <TopBanner topBanner={fields?.topBanner} />}

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/*--------- Maximum Savings -----------*/}
          <div className={styles.cMaximumSavings}>
            <h3>{fields?.maximumSavings?.title}</h3>
            <p>{fields?.maximumSavings?.description}</p>
            <ul>
              {fields?.maximumSavings?.columns.map(
                (item: { title: string; description: string; icon: string }, index: number) => {
                  return (
                    <li key={index}>
                      <div className={classNames(styles.ellipseImg)}>
                        <div className={styles.img} style={{ backgroundImage: `url(${item.icon})` }} />
                      </div>
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </div>

          <div className={classNames(styles.desktopWidth)}>
            {/* ------ Models Slider -------*/}
            <div className={classNames(styles.titlesSection, styles.cHorOffsetsMobile)}>
              <h3>{fields?.availableCars?.title}</h3>
              <p>{fields?.availableCars?.description}</p>
            </div>

            {vehicleStore.loading && <Loader />}
            <ModelsCarouselSlider
              isPurchase={true}
              slidesList={vehicleStore.ownVehicles.slidesList}
              showPopup={id => {
                window.location.href = `https://trymotor.com/purchase-form/${id}`;
              }}
            />
          </div>
          <div className={styles.splitter} />

          {/*--------- Drivers Save Big -----------*/}
          <div className={styles.cDriversSaveBig}>
            <div className={styles.leftCol}>
              <h3>{fields?.driversSaveBig?.title}</h3>
              <p>{fields?.driversSaveBig?.description}</p>
              <Button
                buttonColor={'black'}
                size={'medium'}
                onClick={() => history.push(fields?.driversSaveBig?.buttonUrl)}
              >
                {fields?.driversSaveBig?.buttonCaption}
              </Button>
            </div>
            <div className={styles.rightCol}>
              <div className={styles.content}>
                <p>{fields?.driversSaveBig?.topText}</p>
                <div className={styles.value}>
                  <span>$</span>
                  {fields?.driversSaveBig?.price}
                </div>
                <p className={styles.textRight}>{fields?.driversSaveBig?.bottomText}</p>
              </div>
            </div>
          </div>

          <div className={styles.splitter} />

          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
            {/* ------ FAQ -------*/}
            <div className={styles.cFAQ}>
              <div className={styles.titlesSection}>
                <h3>{fields?.frequentlyAskedQuestions?.title}</h3>
                <p>{fields?.frequentlyAskedQuestions?.description}</p>
              </div>

              <div className={styles.cFaqOffsets}>
                {fields?.frequentlyAskedQuestions?.accordionItem?.map(
                  (item: { question: string; answer: string }, index: number) => {
                    return (
                      <Collapse title={item.question} wrapperStyle={styles.wrapper} titleStyle={styles.faqTitle}>
                        <p>{item.answer}</p>
                      </Collapse>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>

        <SubscriptionInfoSection />

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default Own;

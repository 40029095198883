import {
  STORE_ADVISOR,
  STORE_AUTH,
  STORE_ORDER,
  STORE_ROUTER,
  STORE_VEHICLE,
  STORE_ERRORS,
  STORE_CMS,
} from '../appConstants';
import { AuthStore } from './AuthStore';
import { RouterStore } from './RouterStore';
import { AdvisorStore } from './AdvisorStore';
import { VehicleStore } from './VehicleStore';
import { OrderStore } from './OrderStore';
import { ErrorsStore } from './ErrorsStore';
import { CMSStore } from './CMSStore';
import { history } from '../helpers/history';
import VehicleApi from '../services/vehicle/vehicleApi';
import OrderApi from '../services/order/orderApi';
import cmsApi from '../services/cms/cmsApi';

const RouterStoreInstance = new RouterStore(history);
const AuthStoreInstance = new AuthStore();
const ErrorsStoreInstance = new ErrorsStore();
const VehicleStoreInstance = new VehicleStore(VehicleApi, RouterStoreInstance.history);
const CMSStoreInstance = new CMSStore(cmsApi, RouterStoreInstance.history);

export const RootStore = {
  [STORE_AUTH]: AuthStoreInstance,
  [STORE_ROUTER]: new RouterStore(history),
  [STORE_ADVISOR]: new AdvisorStore(),
  [STORE_VEHICLE]: new VehicleStore(VehicleApi, RouterStoreInstance.history),
  [STORE_ORDER]: new OrderStore(
    OrderApi,
    RouterStoreInstance.history,
    VehicleStoreInstance,
    AuthStoreInstance,
    ErrorsStoreInstance
  ),
  [STORE_ERRORS]: ErrorsStoreInstance,
  [STORE_CMS]: CMSStoreInstance,
};

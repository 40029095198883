import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';
import ReactDOM from 'react-dom';
import { useLocation, withRouter } from 'react-router-dom';

import { Route } from 'react-router';
import { Router, Redirect } from 'react-router';
import ReactGA from 'react-ga';

import App from './containers/App/App';
import Unauthorized from './pages/Unauthorized';
import * as serviceWorker from './serviceWorker';
import { history } from './helpers/history';
import './index.css';
import './styles/lib/slick.css';
import './styles/lib/react-tabs.css';
import config from './environment';

ReactGA.initialize(config.googleAnalyticsId);
ReactGA.pageview(window.location.pathname + window.location.search);

const ScrollToTopCompoennt = withRouter(ScrollToTop);

const RootProvider = observer(() => {
  const { authStore, cmsStore } = useStores();
  const isProd = process.env.REACT_APP_STAGE === 'prod';

  useEffect(() => {
    authStore.getBasicConfiguration();
  }, [authStore]);

  useEffect(() => {
    cmsStore.getCollection('common_configuration');
  }, [cmsStore]);

  return isProd ? (
    <Router history={history}>
      <ScrollToTopCompoennt>
        <App />
        <Route path="/login" component={Unauthorized} />
      </ScrollToTopCompoennt>
    </Router>
  ) : (
    <Router history={history}>
      <ScrollToTopCompoennt>{authStore.isGuestSignedIn ? <App /> : <Redirect to="/login" />}</ScrollToTopCompoennt>

      <Route path="/login" component={Unauthorized} />
    </Router>
  );
});

ReactDOM.render(<RootProvider />, document.getElementById('root'));
serviceWorker.unregister();

function ScrollToTop(props: any) {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 2000);
    }
  }, [pathname, hash]);

  return props.children;
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

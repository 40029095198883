import {
  Configuration,
  ConfigurationAPI,
  MainMenuCategory,
  MainMenuCategoryAPI,
  MainMenuItemsAPI,
  MainMenuItems,
} from '../../types/Configuration';

export const adaptMenuPoints = (points: MainMenuItemsAPI[]): MainMenuItems[] => {
  return points.map(item => ({
    title: item.title,
    link: item.link,
    isInternalLink: item.is_internal_link,
    isOpenInNewTab: item.is_open_in_new_tab,
    order: item.order,
  }));
};

export const adaptMenuCategories = (points: MainMenuCategoryAPI[]): MainMenuCategory[] => {
  return points.map(cat => ({
    title: cat.title,
    isInternalLink: cat.is_internal_link,
    isOpenInNewTab: cat.is_open_in_new_tab,
    link: cat.link,
    order: cat.order,
    subPoints: adaptMenuPoints(cat.sub_points),
  }));
};

export const adaptConfigurationAPI = (config: ConfigurationAPI): Configuration => {
  return {
    navigation: {
      points: adaptMenuCategories(config.navigation.points),
    },
  };
};

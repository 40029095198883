import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/use-stores';
import classNames from 'classnames';

import { Button, Loader } from 'components';
import '../../styles/lib/react-slider.scss';
import { useHistory } from 'react-router-dom';
import styles from './OrderFinal.module.scss';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { id: string };
  };
}

const OrderFinal = observer((props: Props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const history = useHistory();
  const { orderStore } = useStores();
  const [bgUrl, setBgUrl] = useState<string | null>(null);

  useEffect(() => {
    if (orderStore && orderStore.orders) {
      const currentOrder = orderStore.orders.find(o => o.id === id);
      const bgImg = currentOrder?.vehicleBuild.vehicle.visual_set.background_large;

      if (bgImg !== bgUrl) {
        setBgUrl(bgImg || null);
      }
    } else {
      orderStore.getOrders();
    }
  }, [orderStore, orderStore.orders, id, bgUrl]);

  return (
    <>
      {bgUrl ? (
        <div className={styles.cFinalOrder}>
          <img className={styles.mainImage} src={bgUrl} alt="" />

          <div className={classNames(styles.container, styles.cHorOffsets)}>
            <h2 className={styles.question}>Your order is completed!</h2>

            <p>
              Congrats with a new vehicle. Enjoy your ride! <br /> You can find your purchase details anytime in the
              account.
            </p>
            <Button
              onClick={() => {
                history.push('/account');
              }}
              buttonColor="green"
              fullWidth
              size="big"
              className={styles.btn}
            >
              See Account & Orders
            </Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
});
export default OrderFinal;

import React, { useState } from 'react';
import { Button, CheckboxGroup } from 'components';
import { useFormik } from 'formik';
import { makeModelsFilters } from '../../../../types/Vehicle';
import styles from './FiltersPanel.module.scss';

interface Props {
  filters: makeModelsFilters;
  onChange: (res: string) => void;
  isFiltersLoading: boolean;
}

const FiltersPanel = ({ filters, onChange, isFiltersLoading }: Props) => {
  const [isOpenedMenu, setOpenedMenu] = useState(false);
  let initialValues: any = {};
  Object.keys(filters).forEach(function (key) {
    initialValues[key] = [];
  }, filters);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      setOpenedMenu(false);
      let filtersParams: string = '';
      Object.keys(values).forEach(function (key) {
        if (values[key].length) {
          filtersParams = `${filtersParams}&${key}=${values[key].toString()}`;
        }
      }, filters);
      onChange(filtersParams);
    },
  });

  let htmlL: any = [];
  let htmlR: any = [];
  let html2: any = [];

  const renderFilterResults = () => {
    if (isFiltersLoading) {
      return;
    }
    return (
      <div className={styles.cResults}>
        <div className={styles.innerResults}>
          {Object.keys(formik.values).forEach(function (key) {
            formik.values[key].map((item: string, index: number) => {
              let currItem: { id: string; label: string } | undefined = filters[key].find(f => f.id === item);
              return html2.push(
                <div className={styles.cResult} key={`${key}res${index}`}>
                  {currItem?.label}
                  <button
                    type="button"
                    className={styles.closeBtn}
                    onClick={() => {
                      let arr: string[] = formik.values[key].filter((item: string) => {
                        return item !== currItem?.id;
                      });
                      formik.setFieldValue(key, arr);
                    }}
                  />
                </div>
              );
            });
          }, filters)}
          {html2}
        </div>
      </div>
    );
  };
  const renderButtons = () => {
    return (
      <div className={styles.cRightAlign}>
        <Button className={styles.btn} type="submit" buttonColor={'black'} size={'medium-rounded'}>
          Update
          <div className={styles.update} />
        </Button>

        <Button
          onClick={() => {
            Object.keys(formik.values).forEach(function (key) {
              formik.setFieldValue(key, []);
            });
          }}
          className={styles.btn}
          type="submit"
          buttonColor={'gray-new'}
          size={'medium-rounded'}
        >
          Reset
        </Button>
      </div>
    );
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.cFilters}>
        <div className={styles.leftCol}>
          <div
            className={styles.barCaption}
            onClick={() => {
              setOpenedMenu(prev => !prev);
            }}
          >
            Filter <span className={isOpenedMenu ? styles.arrowT : styles.arrowD} />
          </div>
          {renderFilterResults()}
        </div>
        {isOpenedMenu && (
          <div className={styles.modal}>
            {Object.keys(filters).forEach(function (key, index) {
              if (key === 'make') {
                htmlR.push(
                  <div key={key} className={styles.cFilter}>
                    <CheckboxGroup
                      name={key}
                      options={filters[key]}
                      value={formik.values[key]}
                      onChange={res => formik.setFieldValue(key, res)}
                    />
                  </div>
                );
              } else {
                htmlL.push(
                  <div key={key} className={styles.cFilter}>
                    <CheckboxGroup
                      name={key}
                      options={filters[key]}
                      value={formik.values[key]}
                      onChange={res => formik.setFieldValue(key, res)}
                    />
                  </div>
                );
              }
            }, filters)}
            <div className={styles.cControls}>
              <div className={styles.colLeft}>{htmlL}</div>
              <div className={styles.colRight}>{htmlR}</div>
            </div>
            {renderButtons()}
          </div>
        )}

        {renderButtons()}
      </div>
    </form>
  );
};

export default FiltersPanel;

import React from 'react';
import classNames from 'classnames';

import styles from './GradientSplitter.module.scss';

const GradientSplitter = ({ style = '' }) => {
  return <div className={classNames(styles.cSplitter, style)} />;
};

export default GradientSplitter;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';

import { Button, StatusBar } from 'components';
import styles from './ConfirmationSubscribeToBuy.module.scss';
import { subscriptionOrderStatusBar, subscriptionOrderStatusBarCash } from '../../appConstants';

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: {
    params: { paymentMethod: string };
  };
}

const ConfirmationSubscribeToBuy = (props: Props) => {
  const {
    match: {
      params: { paymentMethod },
    },
  } = props;
  const loanText = 'Next, complete your financing application through our partner at Tom Wood Nissan.';
  const cashText = 'The AES + Motor team will contact you via email to finalize the transaction.';
  return (
    <div className={classNames([styles.cPage, styles.cHorOffsetsMobile])}>
      <StatusBar
        steps={paymentMethod === 'loan' ? subscriptionOrderStatusBar : subscriptionOrderStatusBarCash}
        activeStep={'Next steps'}
      />
      <div className={styles.cContent}>
        <h1>Thank you!</h1>
        <h3>Your vehicle order is processing.</h3>
        <p>{paymentMethod === 'loan' ? loanText : cashText}</p>
        {paymentMethod === 'loan' && (
          <div>
            <Button onClick={() => props.history.push('/apply-financing')} buttonColor="green" size="big" fullWidth>
              Proceed
            </Button>
            <div className={styles.cBrand}>
              <span>Financing through our partner, </span>
              <div className={styles.illustr} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationSubscribeToBuy;

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './InfoList.module.scss';
import { setFinalPrice } from '../../../../helpers/formatters/money';
//import { appStoreHeader, appStoreSubscription, playMarketHeader } from 'appConstants';
//import { StoresButtons } from '../../../../components';
//import classNames from 'classnames';
import { Button } from 'components';
export interface Props {
  vehicleDetails: {
    allowSubscribe: boolean;
    allowTestDrive: boolean;
    msrp: string;
    dealerLink: string;
  };
}

const InfoList = (props: Props) => {
  //const [isDownloadOpen, setDownloadOpen] = useState(false);
  if (!props.vehicleDetails) return null;
  const { allowSubscribe, allowTestDrive, msrp, dealerLink } = props.vehicleDetails;
  /*  let dLink = dealerLink.replace('https://', '');
  dLink = dLink.replace('http://', '');
  dLink = dLink.replace('http://www.', '');
  dLink = dLink.replace('https://www.', '');*/
  return (
    <ul className={styles.cInfoList}>
      <li>
        <div className={styles.illustration} />
        <div>
          <h4>Purchase this electric car</h4>
          <p>Starting at {setFinalPrice(msrp ? msrp.toString() : '0')}</p>
          {dealerLink && (
            <a className={styles.gradient} href={dealerLink} rel="noreferrer noopener" target="_blank">
              Learn more {/*at {dLink}↗*/}
            </a>
          )}
        </div>
      </li>

      {allowSubscribe && (
        <li>
          <div className={styles.illustration} style={{ backgroundImage: `url("/images/infoList/il2.svg")` }} />
          <div>
            <h4>Subscribe to this electric car</h4>
            <p>For a flat monthly fee</p>
            <Link className={styles.gradient} to="/subscription">
              Learn more↗
            </Link>
            <Button onClick={() => {

              window.open('https://trymotor.com', '_blank', 'noopener,noreferrer');

            }}
              buttonColor={'black'}
              size={'medium'}
            >
              Get Started
            </Button>

          </div>
        </li>
      )}

      {allowTestDrive && (
        <li>
          <div className={styles.illustration} style={{ backgroundImage: `url("/images/infoList/il3.svg")` }} />
          <div>
            <h4>Schedule a test drive</h4>
            <p>We’ll even drive it to you</p>
            <a className={styles.gradient} href="/" rel="noreferrer noopener">
              We’ll even drive it to you↗
            </a>
          </div>
        </li>
      )}
    </ul>
  );
};

export default InfoList;

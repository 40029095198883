import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, StoresButtons, SocialLinks, Avatar } from 'components';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { MainMenuCategory, MainMenuItems } from '../../types/Configuration';
import { appStoreHeader, desktopWidth, playMarketHeader } from '../../appConstants';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TopPromo from '../TopPromo/TopPromo';
import { useHistory } from 'react-router-dom';
import styles from './Header.module.scss';

interface Props {
  isLoggedIn: boolean;
  pathname?: string;
}

const Header = observer(({ isLoggedIn, pathname }: Props) => {
  const { authStore, cmsStore } = useStores();
  useEffect(() => {
    isLoggedIn && authStore.getCurrentUser();
  }, [authStore, isLoggedIn]);
  const history = useHistory();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(-1);
  const [isDownloadOpen, setDownloadOpen] = useState(false);

  const { width } = useWindowDimensions();

  const hideAllMenu = () => {
    setMenuOpen(false);
    setOpenSubMenu(-1);
    setDownloadOpen(false);
  };

  const showMenu = () => {
    return (
      <div className={styles.cMenu}>
        {openSubMenu !== -1 && (
          <div
            onClick={() => {
              hideAllMenu();
            }}
            className={styles.cover}
          />
        )}
        <div className={styles.cContent}>
          <ul className={styles.categoriesList}>
            {authStore.configuration?.navigation.points.map((category: MainMenuCategory, index: number) => {
              const active = index !== openSubMenu && category.subPoints.length;
              return (
                <li
                  key={index}
                  onClick={() => {
                    setMenuOpen(false);
                    active ? setOpenSubMenu(index) : setOpenSubMenu(-1);
                  }}
                >
                  {category.subPoints.length ? (
                    <h3 className={classNames(index === openSubMenu ? styles.arrowTop : '')}>{category.title}</h3>
                  ) : (
                    <Link
                      className={classNames(pathname === category.link ? styles.active : '')}
                      to={category.link || ''}
                    >
                      {category.title}
                    </Link>
                  )}
                  <ul className={classNames(styles.cMenuItems, openSubMenu === index ? styles.act : '')}>
                    {category.subPoints.map((item: MainMenuItems, index: number) => {
                      return (
                        <li key={index}>
                          <Link to={item.link}>{item.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>

        {desktopWidth > width && (
          <div className={styles.mobileMenuFooter}>
            <Button
              onClick={() => {
                history.push('/driver-profile');
                hideAllMenu();
              }}
              buttonColor={'black'}
              size={'medium'}
            >
              Driver Profile
            </Button>

            <SocialLinks style={styles.offsetTop} />
            <p className={styles.bottomInfo}>
              © 2021 Motor Drive, Inc.br <br />
              All rights reserved.{' '}
              <Link
                to="/terms-service"
                onClick={() => {
                  hideAllMenu();
                }}
              >
                Terms of use
              </Link>{' '}
              |{' '}
              <Link
                to="/privacy-policy"
                onClick={() => {
                  hideAllMenu();
                }}
              >
                Privacy Policy
              </Link>{' '}
              | +1 (833) MOTOR-EV
            </p>
          </div>
        )}
      </div>
    );
  };

  const showAvatar = () => {
    return isLoggedIn ? (
      <Link
        onClick={() => {
          hideAllMenu();
        }}
        to="/account"
        className={styles.authBtn}
      >
        <Avatar name={authStore.user?.fullName} />
      </Link>
    ) : (
      <Link
        className={styles.authBtn}
        onClick={() => {
          hideAllMenu();
        }}
        to="/auth/sign-in"
      />
    );
  };

  const commonConfiguration = cmsStore?.collectionContent?.commonConfiguration;
  return (
    <div className={styles.cHeader}>
      <TopPromo />
      {(isMenuOpen || openSubMenu !== -1 || isDownloadOpen) && (
        <div
          onClick={() => {
            hideAllMenu();
          }}
          className={styles.closingCover}
        />
      )}
      <div className={styles.headerWrapper}>
        <div className={styles.cLeft}>
          {desktopWidth > width && (
            <button
              onClick={() => setMenuOpen(!isMenuOpen)}
              className={classNames(styles.btnMenu, isMenuOpen && styles.btnMenuClose)}
            />
          )}
          <Link to="/" className={styles.cLogo}>
            <img
              className={styles.logo}
              src={commonConfiguration ? commonConfiguration[0]?.logo : ''}
              alt={commonConfiguration ? commonConfiguration[0]?.logoAlt : ''}
            />
          </Link>
        </div>

        <div className={styles.cRight}>
          {(isMenuOpen || desktopWidth <= width) && showMenu()}

          {showAvatar()}

          <div className={styles.cDownload}>
            <Button
              onClick={() => {
                history.push('/driver-profile');
              }}
              buttonColor={'black'}
              size={'medium'}
            >
              Driver Profile
            </Button>

            {isDownloadOpen && (
              <div className={styles.downloadMenu}>
                <StoresButtons appStoreLink={appStoreHeader} playMarketLink={playMarketHeader} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default Header;

export const getSplittedFullName = (fullName: string) => {
  const lastNamePos: number = fullName.indexOf(' ');
  let firstName = '';
  let lastName = '';
  if (lastNamePos !== -1) {
    firstName = fullName.slice(0, lastNamePos).trim();
    lastName = fullName.slice(lastNamePos).trim();
  } else {
    firstName = fullName;
    lastName = 'Unknown';
  }

  return { firstName: firstName, lastName: lastName };
};

export const getAvatarLetters = (fullName: string) => {
  const lastNamePos: number = fullName.indexOf(' ');
  let firstName = '';
  let lastName = '';
  if (lastNamePos !== -1) {
    firstName = fullName.slice(0, lastNamePos).trim();
    lastName = fullName.slice(lastNamePos).trim();
  } else {
    firstName = fullName;
    lastName = ' ';
  }

  return firstName[0] + lastName[0];
};

import React, { useEffect } from 'react';
import { Collapse, Button, Loader, SubscribeSection, TopBanner } from 'components';
import { Charger } from './sections';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { desktopWidth } from '../../appConstants';
import styles from './ChargeAtHome.module.scss';

const ChargeAtHome = observer(() => {
  const { vehicleStore, cmsStore } = useStores();
  const { width } = useWindowDimensions();
  const isDesktop = width >= desktopWidth;
  useEffect(() => {
    cmsStore.getPage('charge-at-home');
    vehicleStore.getInventoryVehicles();
  }, [vehicleStore, cmsStore]);

  const history = useHistory();
  const {
    pageContent: { fields },
  } = cmsStore;

  if (cmsStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      {fields?.topBanner && <TopBanner topBanner={fields?.topBanner} />}

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/*--------- Charge Faster -----------*/}
          <div className={classNames(styles.cChargeFaster, styles.desktopContentWidth)}>
            <div className={classNames(styles.titlesSection, styles.cHorOffsetsMobile)}>
              <h3>{fields?.chargeFaster?.title}</h3>
              <p>{fields?.chargeFaster?.description}</p>
            </div>
            <ul>
              {fields?.chargeFaster?.columns.map(
                (item: { title: string; description: string; icon: string }, index: number) => {
                  return (
                    <li key={index}>
                      <div className={styles.img} style={{ backgroundImage: `url(${item.icon})` }} />

                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </div>

          {/*--------- Level 2 charger -----------*/}
          {fields?.level2Charger && <Charger level2Charger={fields?.level2Charger} />}
        </div>

        {/*--------- Benefits -----------*/}
        <div className={styles.cBenefits}>
          <div className={classNames(styles.cInner, styles.desktopContentWidth, styles.cHorOffsetsMobile)}>
            <ul className={styles.leftCol}>
              {fields?.benefits?.leftColumn?.map(
                (
                  item: { title: string; subTitle: string; description: string; illustration: string },
                  index: string
                ) => {
                  return (
                    <li key={index}>
                      <img src={item.illustration} alt="" />
                      <h4>{item.title}</h4>
                      <h5>{item.subTitle}</h5>
                      <p>{item.description}</p>
                    </li>
                  );
                }
              )}
            </ul>
            <div className={styles.rightCol}>
              <h3>{fields?.benefits?.title}</h3>
              <p>{fields?.benefits?.description}</p>
              <Button
                buttonColor={'white'}
                size={'medium'}
                arrow={'right'}
                onClick={() => history.push(fields?.benefits?.buttonUrl)}
              >
                {fields?.benefits?.buttonCaption}
              </Button>
            </div>
          </div>
        </div>

        {/*--------- Charging Program -----------*/}
        <div className={classNames(styles.cChargingProgram, styles.desktopContentWidth, styles.cHorOffsetsMobile)}>
          <div
            className={styles.leftCol}
            style={{ backgroundImage: `url(${fields?.chargingProgram?.illustration})` }}
          />

          <div className={styles.rightCol}>
            <h3>{fields?.chargingProgram?.title}</h3>
            <p>
              {fields?.chargingProgram?.descriptionP1}{' '}
              <a href={fields?.chargingProgram?.learnMoreUrl}>Learn more here</a>
            </p>
            <p>{fields?.chargingProgram?.descriptionP2}</p>
            <Button
              buttonColor={'black'}
              size={'medium'}
              arrow={'right'}
              className={styles.buttonsOffset}
              onClick={() => history.push(fields?.chargingProgram?.buttonUrl)}
            >
              {fields?.chargingProgram?.buttonCaption}
            </Button>

            <Button
              buttonColor={'black'}
              size={'medium'}
              arrow={'right'}
              onClick={() => window.open(fields?.chargingProgram?.button2Url)}
            >
              {fields?.chargingProgram?.button2Caption}
            </Button>
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          {/* ------ Public charging program -------*/}
          <div
            className={styles.cPublicCharger}
            style={{
              backgroundImage: `url(${
                isDesktop
                  ? fields?.publicChargingProgram?.backgroundImage
                  : fields?.publicChargingProgram?.mobileBackgroundImage
              })`,
            }}
          >
            <h4>{fields?.publicChargingProgram?.description}</h4>
            <Button
              buttonColor={'black'}
              size={'medium'}
              arrow={'right'}
              onClick={() =>
                fields?.publicChargingProgram?.isInternalUrl
                  ? history.push(fields?.publicChargingProgram?.buttonUrl)
                  : window.open(fields?.publicChargingProgram?.buttonUrl)
              }
            >
              {fields?.publicChargingProgram?.buttonCaption}
            </Button>
          </div>

          {/* ------ FAQ -------*/}
          <div className={classNames(styles.cFAQ, styles.desktopContentWidth)}>
            <div className={styles.titlesSection}>
              <h3>{fields?.frequentlyAskedQuestions?.title}</h3>
              <p>{fields?.frequentlyAskedQuestions?.description}</p>
            </div>

            <div className={styles.cFaqOffsets}>
              {fields?.frequentlyAskedQuestions?.accordionItem?.map(
                (item: { question: string; answer: string }, index: number) => {
                  return (
                    <Collapse
                      title={item.question}
                      wrapperStyle={styles.wrapper}
                      titleStyle={styles.faqTitle}
                      key={index}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </Collapse>
                  );
                }
              )}
            </div>
          </div>
          <SubscribeSection />
        </div>

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default ChargeAtHome;

import { VehicleInventory } from '../types/Vehicle';

export const getVehicleImages = (invVehicleDetails: VehicleInventory | undefined) => {
  let result: string[] = [];

  invVehicleDetails?.color?.front
    ? result.push(invVehicleDetails?.color?.front)
    : invVehicleDetails?.vehicle.visualSet?.front && result.push(invVehicleDetails?.vehicle.visualSet?.front);

  invVehicleDetails?.color?.side
    ? result.push(invVehicleDetails?.color?.side)
    : invVehicleDetails?.vehicle.visualSet?.side && result.push(invVehicleDetails?.vehicle.visualSet?.side);

  invVehicleDetails?.color?.rear
    ? result.push(invVehicleDetails?.color?.rear)
    : invVehicleDetails?.vehicle.visualSet?.rear && result.push(invVehicleDetails?.vehicle.visualSet?.rear);

  invVehicleDetails?.interior?.view1
    ? result.push(invVehicleDetails?.interior?.view1)
    : invVehicleDetails?.vehicle.visualSet?.interior && result.push(invVehicleDetails?.vehicle.visualSet?.interior);

  invVehicleDetails?.interior?.view2 && result.push(invVehicleDetails?.interior?.view2);
  return result;
};

import {
  UserAPI,
  User,
  AdditionalCostAPI,
  AdditionalCost,
  ChargerStationAPI,
  ChargerStation,
  DriverProfile,
  DriverProfileAPI,
} from '../../types/User';

export const adaptUserApi = (user: UserAPI): User => {
  return {
    id: user.id,
    phone: user.phone,
    email: user.email,
    password: user.password,
    username: user.username,
    fullName: user.full_name,
  };
};

export const adaptAditionalCostApi = (cost: AdditionalCostAPI): AdditionalCost => {
  return {
    electricVehicle: {
      chargingFuel: cost.electric_vehicle.charging_fuel,
      insurance: cost.electric_vehicle.insurance,
      maintenance: cost.electric_vehicle.maintenance,
      taxCredits: cost.electric_vehicle.tax_credits,
      total: cost.electric_vehicle.total,
    },
    gasVehicle: {
      chargingFuel: cost.gas_vehicle.charging_fuel,
      insurance: cost.gas_vehicle.insurance,
      maintenance: cost.gas_vehicle.maintenance,
      taxCredits: cost.gas_vehicle.tax_credits,
      total: cost.gas_vehicle.total,
    },
  };
};

export const adaptChargerStations = (station: ChargerStationAPI[]): ChargerStation[] => {
  const adaptedStations = station.map(s => {
    return {
      latitude: s.AddressInfo.Latitude,
      longitude: s.AddressInfo.Longitude,
      title: s.AddressInfo.Title,
    };
  });

  return adaptedStations;
};

export const adaptDriverProfile = (dp: DriverProfile): DriverProfileAPI => {
  return {
    uuid: dp.uuid,
    id: dp.id,
    make: dp.make,
    model: dp.model,
    annual_mileage: dp.annualMileage,
    budget: dp.budget,
    zip_code: dp.zipCode,
    preferences: dp.preferences,
    learning_preferences: dp.learningPreferences,
    parking_location: dp.parkingLocation,
  };
};

export const adaptDriverProfileAPI = (dp: DriverProfileAPI): DriverProfile => {
  return {
    uuid: dp.uuid,
    id: dp.id,
    make: dp.make,
    model: dp.model,
    annualMileage: dp.annual_mileage,
    budget: dp.budget,
    zipCode: dp.zip_code,
    preferences: dp.preferences,
    learningPreferences: dp.learning_preferences,
    parkingLocation: dp.parking_location,
  };
};

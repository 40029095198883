import React from 'react';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import styles from './NewModelBanner.module.scss';
import classNames from 'classnames';

interface Props {
  data: {
    title: string;
    description: string;
    buttonCaption: string;
    buttonUrl: string;
    isInternalUrl: boolean;
    showBanner: boolean;
    illustration: string;
  };
  isBackground?: boolean;
}

const NewModelBanner = (props: Props) => {
  const { title, description, buttonCaption, buttonUrl, isInternalUrl, showBanner, illustration } = props.data;
  const history = useHistory();
  if (!showBanner) return null;
  return (
    <div className={classNames(styles.cQuizBanner, props.isBackground ? styles.bg : '')}>
      <div className={styles.cLeft}>
        <h3>{title}</h3>
        <p>{description}</p>
        <Button
          size="medium-rounded"
          buttonColor="black"
          arrow="right"
          minWidth="mw192"
          onClick={() => {
            isInternalUrl ? history.push(buttonUrl) : window.open(buttonUrl);
          }}
        >
          {buttonCaption}
        </Button>
      </div>
      <div className={styles.illustration} style={{ backgroundImage: `url(${illustration})` }} />
    </div>
  );
};

export default NewModelBanner;

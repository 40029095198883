import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { desktopWidth } from 'appConstants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CarouselSliderTextProps, TextSlideContent } from 'types/Slider';
import styles from './TextCarouselSlider.module.scss';

const RenderSlides = (slides: TextSlideContent[], width: number) => {
  if (!slides) return null;
  let styleTile: any = {};

  if (slides.length < 3 && width >= desktopWidth) {
    styleTile.width = 'calc(50% - 12px)';
  }
  return slides.map((item, index) => (
    <div key={index} className={styles.cSliderItem} style={styleTile}>
      <div className={styles.sliderItem} style={{ backgroundColor: item.backgroundColor }}>
        <div className={styles.icon} style={{ backgroundImage: `url(${item.icon})` }} />
        <p
          className={styles.sliderItemText}
          dangerouslySetInnerHTML={{ __html: item.description ? item.description : '' }}
        />

        <div className={styles.cSliderItemText}>
          <Link to={item.linkUrl ? item.linkUrl : '/'} className={styles.link}>
            {item.linkCaption}
          </Link>
        </div>
      </div>
    </div>
  ));
};

const TextCarouselSlider = (props: CarouselSliderTextProps) => {
  const { width } = useWindowDimensions();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  if (width < desktopWidth) {
    return <Slider {...settings}>{RenderSlides(props.slidesList, width)}</Slider>;
  } else return <div className={styles.cDesktopSlider}>{RenderSlides(props.slidesList, width)}</div>;
};
export default TextCarouselSlider;

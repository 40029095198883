import React, { useEffect } from 'react';
import { useStores } from 'hooks/use-stores';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Loader } from 'components';
import { RegularOrderTile, ChargerOrderTile } from 'components/sections';
import logoutIcon from '../../assets/logout.svg';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { setPhoneFormat } from '../../helpers/formatters/phone';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import config from '../../environment';
import styles from './Account.module.scss';

interface Props extends RouteComponentProps {
  phone: string;
}

const Account = observer((props: Props) => {
  const { authStore, orderStore } = useStores();
  const history = useHistory();
  const { user } = authStore;
  const phone = (user && user.phone) || props.phone;

  useEffect(() => {
    const getOrders = async () => {
      await orderStore.getOrders();
      await orderStore.getSubscriptionOrders();
      await orderStore.getChargerOrders();
    };
    const getTestDrives = async () => {
      await orderStore.getTestDrives();
    };

    authStore.getCurrentUser();
    getOrders();
    getTestDrives();
  }, [authStore, orderStore]);

  /* --------- Orders Tab ---------*/
  const renderOrdersTab = () => {
    if (orderStore.loading)
      return (
        <div className={styles.cAccount}>
          <Loader />
        </div>
      );

    if (!orderStore.orders && !orderStore.subscriptionOrders) return <div>No Orders</div>;
    return (
      <div>
        {orderStore.orders?.map(o => (
          <RegularOrderTile order={o} />
        ))}
        {orderStore.subscriptionOrders?.map(so => (
          <RegularOrderTile order={so} type={'afterSubscription'} />
        ))}
        {orderStore.chargerOrders?.map(cho => (
          <ChargerOrderTile order={cho} />
        ))}
      </div>
    );
  };

  const renderTestDriveTab = () => {
    if (orderStore.loadingTD)
      return (
        <div className={styles.cAccount}>
          <Loader />
        </div>
      );

    if (!orderStore.testDrives) return <div>No Test Drives</div>;
    return orderStore.testDrives.map(td => (
      <div className={styles.cOrder} key={` ${td.id}`}>
        <div className={styles.order} style={{ backgroundImage: `url(${td.vehicle.visualSet.backgroundMedium})` }}>
          <div className={classNames(styles.orderContent, td.status === 'completed' ? styles.cCompleted : null)}>
            <Button className={classNames(styles.btnSmall, td.status === 'completed' ? styles.dark : null)}>
              {td.status === 'pending' || td.status === 'in_progress' ? 'In progress' : 'Completed'}
            </Button>
            <h3 className={styles.orderTitle}>
              {td.vehicle.year} {td.vehicle.model}
            </h3>
            {(td.status === 'pending' || td.status === 'in_progress') && (
              <Button
                className={styles.btn}
                onClick={() => {
                  history.push(`/test-drive/${td.id}/completed`);
                }}
                buttonColor={'white'}
              >
                View Order
              </Button>
            )}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.cAccount}>
      <div className={styles.cName}>
        <h1 className={styles.name}>{user?.fullName}</h1>
        <Button
          className={styles.btnLogout}
          onClick={() => {
            authStore.logout();
            history.push('/');
          }}
        >
          <img src={logoutIcon} alt="logout" />
        </Button>
      </div>
      <div className={styles.cPhone}>
        <h5>{!!phone && setPhoneFormat(phone)}</h5>
      </div>

      <Tabs disabledTabClassName={styles.disabledTab}>
        <TabList className={styles.tabList}>
          <Tab className={styles.tabItm}>
            <h4>Orders</h4>
          </Tab>
          {config.enableTestDrive && (
            <Tab className={styles.tabItm}>
              <h4>Test drives</h4>
            </Tab>
          )}
        </TabList>

        <TabPanel>{renderOrdersTab()}</TabPanel>
        <TabPanel>{renderTestDriveTab()}</TabPanel>
      </Tabs>
    </div>
  );
});

export default Account;

import React from 'react';
import classNames from 'classnames';
import ReactGA from 'react-ga';

import styles from './StoresButtons.module.scss';
interface Props {
  style?: string;
  type?: 'column' | 'horizontal' | 'hybrid';
  border?: boolean;
  itemWidth?: string;
  appStoreLink: string;
  playMarketLink: string;
}
const StoresButtons = ({
  style = '',
  type = 'column',
  border = false,
  itemWidth,
  appStoreLink,
  playMarketLink,
}: Props) => {
  return (
    <div
      className={classNames(
        {
          [styles.cStores]: type === 'column',
          [styles.cStoresHorizontal]: type === 'horizontal',
          [styles.cStoresHybrid]: type === 'hybrid',
          [styles.bordered]: border === true,
        },
        style
      )}
    >
      <a
        href={appStoreLink}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.appStore}
        style={itemWidth ? { width: itemWidth } : undefined}
        onClick={() => {
          ReactGA.event({
            category: 'Applications',
            action: 'Moved to App store',
          });
        }}
      >
        &nbsp;
      </a>
      <a
        href={playMarketLink}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.playMarket}
        style={itemWidth ? { width: itemWidth } : undefined}
        onClick={() => {
          ReactGA.event({
            category: 'Applications',
            action: 'Moved to Play market',
          });
        }}
      >
        &nbsp;
      </a>
    </div>
  );
};

export default StoresButtons;

import React, { useEffect } from 'react';
import { TopBanner, Button, Loader, SubscribeSection } from 'components';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Ev101.module.scss';
import { useStores } from '../../hooks/use-stores';

const Ev101 = observer(() => {
  const { cmsStore } = useStores();

  useEffect(() => {
    cmsStore.getPage('ev-101');
  }, [cmsStore]);

  const history = useHistory();
  const {
    pageContent: { fields },
  } = cmsStore;

  if (cmsStore.loading) return <Loader />;
  return (
    <div className={styles.cPage}>
      {fields?.topBanner && <TopBanner topBanner={fields?.topBanner} />}

      <div className={styles.cContent}>
        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.c2columns}>
            <div className={styles.paddR}>
              <h3>{fields?.curiousAbout?.title}</h3>
              <p>{fields?.curiousAbout?.description}</p>
            </div>
            <div>
              <img src={fields?.curiousAbout?.illustration} alt="" />
            </div>
          </div>

          <div className={styles.cInfoGraphic}>
            <div className={styles.topText}>{fields?.evOwnersSpend?.topText}</div>
            <div className={styles.cPercents}>
              <div className={styles.cFlexWrapper}>
                <img src={fields?.evOwnersSpend?.illustration} alt="" />
                <div className={styles.percentValue}>{fields?.evOwnersSpend?.percents}</div>
                <div className={styles.percentDesc}>
                  <div className={styles.top}>%</div>
                  <div className={styles.bottom}>less</div>
                </div>
              </div>
              <div className={styles.bottomText}>{fields?.evOwnersSpend?.bottomText}</div>
            </div>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: fields?.evOwnersSpend?.description }}
            />
          </div>
        </div>

        <div className={classNames(styles.bgHighlite)}>
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
            <div className={classNames(styles.c2columns)}>
              <div>
                <img src={fields?.chargingStations?.illustration} alt="" />
              </div>
              <div className={styles.paddL}>
                <h3>{fields?.chargingStations?.title}</h3>
                <p>{fields?.chargingStations?.description}</p>
                <Button
                  captionFont={'NHG'}
                  buttonColor={'black'}
                  size={'big'}
                  arrow={'right'}
                  onClick={() =>
                    fields?.publicChargingProgram?.buttonWithExternalUrl
                      ? window.open(fields?.chargingStations?.buttonUrl)
                      : history.push(fields?.chargingStations?.buttonUrl)
                  }
                >
                  {fields?.chargingStations?.buttonCaption}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={classNames(styles.c2columns, styles.mobileOppositeFlow)}>
            <div>
              <h3>{fields?.ownersSave?.title}</h3>
              <p>{fields?.ownersSave?.description}</p>
              <Button
                captionFont={'NHG'}
                buttonColor={'black'}
                size={'big'}
                arrow={'right'}
                onClick={() =>
                  fields?.publicChargingProgram?.buttonWithExternalUrl
                    ? window.open(fields?.ownersSave?.buttonUrl)
                    : history.push(fields?.ownersSave?.buttonUrl)
                }
              >
                {fields?.ownersSave?.buttonCaption}
              </Button>
            </div>
            <div className={styles.paddL}>
              <img src={fields?.ownersSave?.illustration} alt="" />
            </div>
          </div>
        </div>

        <div className={classNames(styles.bgHighlite)}>
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
            <div className={classNames(styles.c2columns)}>
              <div>
                <img src={fields?.evsProduce?.illustration} alt="" />
              </div>
              <div className={styles.paddL}>
                <h3>{fields?.evsProduce?.title}</h3>
                <p>{fields?.evsProduce?.description}</p>
                <Button
                  captionFont={'NHG'}
                  buttonColor={'black'}
                  size={'big'}
                  arrow={'right'}
                  onClick={() =>
                    fields?.evsProduce?.buttonWithExternalUrl
                      ? window.open(fields?.evsProduce?.buttonUrl)
                      : history.push(fields?.evsProduce?.buttonUrl)
                  }
                >
                  {fields?.evsProduce?.buttonCaption}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <div className={styles.c2columns}>
            <div className={styles.paddR}>
              <h3>{fields?.ourAutomotiveFuture?.title}</h3>
              <p>{fields?.ourAutomotiveFuture?.description}</p>
              <Button
                captionFont={'NHG'}
                buttonColor={'black'}
                size={'big'}
                arrow={'right'}
                onClick={() =>
                  fields?.ourAutomotiveFuture?.buttonWithExternalUrl
                    ? window.open(fields?.ourAutomotiveFuture?.buttonUrl)
                    : history.push(fields?.ourAutomotiveFuture?.buttonUrl)
                }
              >
                {fields?.ourAutomotiveFuture?.buttonCaption}
              </Button>
            </div>
            <div>
              <img src={fields?.ourAutomotiveFuture?.illustration} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.bgHighlite} id="map">
          <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile, styles.cFrame)}>
            <h3>Charging stations near me</h3>
            <p>Most public charging stations are Level 2 chargers.</p>
            <iframe
              title="PlugShare"
              src="https://www.plugshare.com/widget2.html?spanLng=0.21685&amp;spanLat=0.21685&amp;latitude=39.768403&amp;longitude=-86.158068&amp;plugs=1,2,3,4,5,6,42,13,7,8,9,10,11,12,14,15,16,17"
              width="100%"
              height="500"
              allow="geolocation"
            />
          </div>
        </div>

        <div className={classNames(styles.desktopWidth, styles.cHorOffsetsMobile)}>
          <SubscribeSection className={styles.cuSubscribeOffset} />
        </div>

        {/* ----- end -------*/}
      </div>
    </div>
  );
});

export default Ev101;

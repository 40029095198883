import React from 'react';
import styles from './SubscribeSection.module.scss';
import classNames from 'classnames';
import { Subscribe } from 'components';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
export interface Props {
  className?: string;
}
const SubscribeSection = observer((props: Props) => {
  const { cmsStore } = useStores();
  const commonConfiguration = cmsStore?.collectionContent?.commonConfiguration;
  const { className } = props;
  return (
    <div className={classNames(styles.subscribeSection, className)}>
      <div>
        <h5>Send me updates</h5>
        <p>
          Give us your email and we'll give you updates on <br /> our services and exclusive offers.
        </p>
        <Subscribe />
      </div>

      <div>
        <h5>Don’t see what you’re looking for?</h5>
        <p>
          Our team of electric vehicle specialists are available to answer any questions you may have about this
          process. <br /> Email{' '}
          <a href={commonConfiguration ? `mailto:${commonConfiguration[0]?.supportEmail}` : ''}>
            {commonConfiguration ? commonConfiguration[0]?.supportEmail : ''}
          </a>{' '}
          with any questions.
        </p>
      </div>
    </div>
  );
});

export default SubscribeSection;

import React from 'react';
import styles from './SummaryPanel.module.scss';
import classNames from 'classnames';
import { Card, GradientSplitter } from '../../../../components';
import { setRegularFormat } from '../../../../helpers/formatters/money';

export interface Props {
  charger:
  | {
    stationPrice: string;
    enrollPrice: string;
    minPrice: string;
    maxPrice: string;
  }
  | undefined;
}

const SummaryPanel = (props: Props) => {
  const isEnroll: string | null = sessionStorage.getItem('standalone-charger/is-enroll');
  const amountDueNow = sessionStorage.getItem('chargerPackagePrice');

  if (props.charger) {
    const { stationPrice, enrollPrice, minPrice, maxPrice } = props.charger;
    return (
      <Card key={'s2'}>
        <div>
          <div>
            <div className={styles.flexItem}>
              <h3>JuiceBox 40 WiFi Enabled EV Charging Station</h3>
              <h5 className={styles.priceBottom}>${setRegularFormat(stationPrice)}</h5>
            </div>
            {isEnroll === '1' && (
              <div className={styles.flexItem}>
                <h3>Managed charging enrollment</h3>
                <h5>–${setRegularFormat(enrollPrice)}</h5>
              </div>
            )}
            <div className={classNames(styles.flexItem, styles.offsetTop)}>
              <h3>Faster charger install powered by Motor</h3>
              <h5 className={styles.priceRange}>
                ${Number(minPrice).toFixed(0)}–$
                {Number(maxPrice).toFixed(0)}*
              </h5>
            </div>

            <p className={styles.smallText}>
              *Your exact installation cost is paid separately from the bundle and will be determined by your home
              charging infrastructure. You can always opt out once you receive the quote.  Taxes and fees not included.
            </p>
          </div>

          <GradientSplitter style={styles.offsetTop} />

          <div className={classNames(styles.flexItem, styles.offsetBottom0)}>
            <h3>Due now </h3>
            <h3>${amountDueNow !== null ? amountDueNow : '0'}</h3>
          </div>
          <p className={styles.smallText}>
            You will see a $1500 hold on your card when you complete your order, but this amount will be adjusted to
            reflect final costs when installation is complete. See full{' '}
            <a href="https://trymotor.com/drive-member-agreement" target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>
            .
          </p>
        </div>
      </Card>
    );
  } else return null;
};

export default SummaryPanel;

import React, { ErrorInfo } from 'react';
import { Switch, Route } from 'react-router';

import { Header, Footer, NoData } from 'components';
import ChargerPaymentPage from '../../pages/ChargerPayment/ChargerPayment';
import Main from 'containers/Main/Main';
import SignIn from 'pages/Auth/SignIn';
import Schedule from 'pages/Schedule/Schedule';
import SignUp from 'pages/Auth/SignUp';
import PaymentInstructions from 'pages/PaymentInstructions';
import styles from './App.module.scss';

class App extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: errorInfo.componentStack });
    console.log('componentDidCatch', error, errorInfo);
  }

  renderError = () => (
    <div className={styles.cApp}>
      <Header isLoggedIn={false} />
      <NoData />
      <Footer />
    </div>
  );

  render() {
    const { error } = this.state;

    return error ? (
      this.renderError()
    ) : (
      <Switch>
        <Route path="/auth/sign-in" component={SignIn} />
        <Route path="/auth/sign-up" component={SignUp} />
        <Route path="/order/:id/schedule" component={Schedule} />
        <Route exact path="/order/:id/charger-payment" component={ChargerPaymentPage} />
        <Route path="/order/:id/payment-instructions" component={PaymentInstructions} />

        <Route path="/" component={Main} />
      </Switch>
    );
  }
}

export default App;

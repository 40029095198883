import * as Yup from 'yup';
import { VALIDATION_RULES } from 'appConstants';
import config from '../../../../environment';

export interface formValues {
  paymentType: 'loan' | 'cash';
  fullName: string;
  email: string;
  phone: string;
  partnersCheckbox: boolean;
  partnersName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  mailingAddressCheckbox: boolean;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;
  frontDLPicture: any;
  backDLPicture: any;
}

export const initialValues = {
  paymentType: config.enableLoanPayment ? 'loan' : ('cash' as 'loan' | 'cash'),
  fullName: sessionStorage.getItem('orderForm/fullName') || '',
  email: sessionStorage.getItem('orderForm/email') || '',
  phone: sessionStorage.getItem('orderForm/phone') || '',
  partnersCheckbox: sessionStorage.getItem('orderForm/partnersCheckbox') === 'true' || false,
  partnersName: sessionStorage.getItem('orderForm/partnersName') || '',
  address: sessionStorage.getItem('orderForm/address') || '',
  city: sessionStorage.getItem('orderForm/city') || '',
  state: sessionStorage.getItem('orderForm/state') || '',
  zipCode: sessionStorage.getItem('orderForm/zipCode') || '',
  mailingAddressCheckbox: Boolean(sessionStorage.getItem('orderForm/mailingAddressCheckbox')) || true,
  mailingAddress: sessionStorage.getItem('orderForm/mailingAddress') || '',
  mailingCity: sessionStorage.getItem('orderForm/mailingCity') || '',
  mailingState: sessionStorage.getItem('orderForm/mailingState') || '',
  mailingZipCode: sessionStorage.getItem('orderForm/mailingZipCode') || '',
  frontDLPicture: undefined,
  backDLPicture: undefined,
};

export const schema = Yup.object().shape({
  fullName: VALIDATION_RULES.fullName,
  email: VALIDATION_RULES.email,
  phone: VALIDATION_RULES.phone,
  partnersCheckbox: Yup.boolean(),
  partnersName: Yup.string().when('partnersCheckbox', {
    is: true,
    then: VALIDATION_RULES.roleMinMax('Partners name'),
  }),
  address: VALIDATION_RULES.address,
  city: VALIDATION_RULES.roleMinMax('City'),
  state: VALIDATION_RULES.roleMinMax('State'),
  zipCode: VALIDATION_RULES.zipCode,
  mailingAddressCheckbox: Yup.boolean(),
  mailingAddress: Yup.string().when('mailingAddressCheckbox', {
    is: false,
    then: VALIDATION_RULES.roleMinMax('Mailing address'),
  }),
  mailingCity: Yup.string().when('mailingAddressCheckbox', {
    is: false,
    then: VALIDATION_RULES.roleMinMax('Mailing city'),
  }),
  mailingState: Yup.string().when('mailingAddressCheckbox', {
    is: false,
    then: VALIDATION_RULES.roleMinMax('Mailing state'),
  }),
  mailingZipCode: Yup.string().when('mailingAddressCheckbox', {
    is: false,
    then: VALIDATION_RULES.zipCode,
  }),
});

import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import styles from './InputRestyle.module.scss';

interface Props {
  placeholder?: string;
  id?: string;
  style?: string;
  containerStyle?: string;
  errorText?: string | false;
  text?: string;
  value?: string;
  name?: string;
  type?: string;
  autoFocus?: boolean;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  smallDescription?: 'zip';
}

const InputRestyle = ({
  id,
  value,
  placeholder,
  errorText = '',
  text = '',
  style,
  containerStyle,
  label,
  smallDescription,
  ...props
}: Props) => (
  <div className={classNames(styles.cInput, errorText ? styles.cError : null, containerStyle)}>
    <div className={styles.cLabel}>
      <label htmlFor={id}>{label}</label>
      {smallDescription === 'zip' && (
        <div
          data-tip={
            '<p>By providing your zip code, we can more accurately share information about electric car and charger incentives you may be eligible for.</p>'
          }
          data-class={styles.tooltipText}
          className={styles.sd}
        >
          Why does my location matter?
        </div>
      )}
      <ReactTooltip className={styles.tooltip} html={true} />
    </div>
    <input
      value={value}
      id={id}
      placeholder={placeholder}
      className={classNames(styles.input, errorText ? styles.error : null, style)}
      {...props}
    />
    {errorText ? <p className={styles.errorText}>{errorText}</p> : null}
  </div>
);

export default InputRestyle;

import React from 'react';
import classNames from 'classnames';
import { desktopWidth } from '../../appConstants';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './TopBanner.module.scss';

interface Props {
  topBanner?: {
    subTitle?: string;
    mainTitle?: string;
    backgroundImage?: string;
    backgroundImageMobile?: string;
    height?: number;
    heightMobile?: number;
    backgroundPositionX: string;
    backgroundPositionY: string;
    backgroundPositionXMobile: string;
    backgroundPositionYMobile: string;
    mainTitleMobile: string;
    mainTitleMaxWidth: string;
    mainTitleMaxWidthMobile: string;
    subTitleColor?: string;
    mainTitleColor?: string;
  };
}

const TopBanner = (props: Props) => {
  const { width } = useWindowDimensions();
  const isDesktop = width >= desktopWidth;

  if (!props.topBanner) return null;
  const {
    backgroundImage,
    backgroundImageMobile,
    subTitle,
    mainTitle,
    height,
    heightMobile,
    backgroundPositionX,
    backgroundPositionY,
    backgroundPositionXMobile,
    backgroundPositionYMobile,
    mainTitleMobile,
    mainTitleMaxWidth,
    mainTitleMaxWidthMobile,
    subTitleColor,
    mainTitleColor,
  } = props.topBanner;

  let containerStyle: any = {};
  let h1Style: any = {};
  let title = isDesktop ? mainTitle : mainTitleMobile;

  if (isDesktop) {
    if (backgroundImage) {
      containerStyle.backgroundImage = `url(${backgroundImage})`;
    }
    if (height) {
      containerStyle.height = `${height}px`;
    }
    if (backgroundPositionX) {
      containerStyle.backgroundPositionX = backgroundPositionX;
    }
    if (backgroundPositionY) {
      containerStyle.backgroundPositionY = backgroundPositionY;
    }
    if (mainTitleMaxWidth) {
      h1Style.maxWidth = `${mainTitleMaxWidth}px`;
    }
  } else {
    if (backgroundImageMobile) {
      containerStyle.backgroundImage = `url(${backgroundImageMobile})`;
    }
    if (heightMobile) {
      containerStyle.height = `${heightMobile}px`;
    }
    if (backgroundPositionXMobile) {
      containerStyle.backgroundPositionX = backgroundPositionXMobile;
    }
    if (backgroundPositionYMobile) {
      containerStyle.backgroundPositionY = backgroundPositionYMobile;
    }
    if (mainTitleMaxWidthMobile) {
      h1Style.maxWidth = `${mainTitleMaxWidthMobile}px`;
    }
  }

  if (mainTitleColor) {
    h1Style.color = mainTitleColor;
  }

  return (
    <div className={styles.topBanner} style={containerStyle}>
      <div className={classNames(styles.innerTopBanner, styles.desktopWidth, styles.cHorOffsetsMobile)}>
        <p style={subTitleColor ? { color: subTitleColor } : {}} className={styles.smallTitle}>
          {subTitle}
        </p>
        <h1 style={h1Style} dangerouslySetInnerHTML={{ __html: title || '' }} />
      </div>
    </div>
  );
};
export default TopBanner;

import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  minWidth?: 'mw192';
  size?: 'medium' | 'medium-plus' | 'medium-rounded' | 'big';
  buttonColor?: 'white' | 'green' | 'blue' | 'dark' | 'gray' | 'gray-new' | 'black';
  arrow?: 'right' | 'down' | 'top' | 'left';
  captionFont?: 'NHG';
  fontSize?: '14px' | '16px' | '18px';
}

const Button = ({
  children,
  buttonColor = 'white',
  className,
  fullWidth,
  disabled,
  size = 'medium',
  arrow,
  captionFont,
  minWidth,
  ...props
}: ButtonProps) => (
  <button
    type="button"
    disabled={disabled}
    className={classNames(
      {
        [styles.medium]: size === 'medium',
        [styles.mediumPlus]: size === 'medium-plus',
        [styles.mediumRounded]: size === 'medium-rounded',
        [styles.big]: size === 'big',
        [styles.whiteButton]: buttonColor === 'white',
        [styles.greenButton]: buttonColor === 'green',
        [styles.darkButton]: buttonColor === 'dark',
        [styles.grayButton]: buttonColor === 'gray',
        [styles.grayNewButton]: buttonColor === 'gray-new',
        [styles.blueButton]: buttonColor === 'blue',
        [styles.blackButton]: buttonColor === 'black',
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
        [styles.arrow]: arrow !== undefined,
        [styles.arrowRight]: arrow === 'right',
        [styles.arrowDown]: arrow === 'down',
        [styles.arrowTop]: arrow === 'top',
        [styles.arrowLeft]: arrow === 'left',
        [styles.fontNHG]: captionFont === 'NHG',
        [styles.mw192]: minWidth === 'mw192',
      },
      styles.cuButton,
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;

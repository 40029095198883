import React from 'react';
import styles from './StatusBar.module.scss';
import classNames from 'classnames';

export interface Props {
  steps: string[];
  activeStep: string;
  className?: string;
}

const StatusBar = (props: Props) => {
  const { steps, activeStep } = props;
  return (
    <div className={classNames(styles.cStatusBar, props.className)}>
      <ul>
        {steps.map((item, index) => {
          return (
            <li
              key={index}
              className={classNames({
                [styles.active]: item === activeStep,
                [styles.disable]: item === 'Apply for Financing (N/A)',
              })}
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StatusBar;

import debounce from 'lodash/debounce';

export const debounceFunc = debounce(({ mileage, distance }, getAdditionalCost) => {
  getAdditionalCost({ distance, mileage });
}, 900);

const setLocationNameByCoords = async (lat: number, lng: number, saveName: (name: string) => void) => {
  /*eslint-disable  no-undef*/
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ location: { lat, lng } }, (results, status) => {
    if (status === 'OK') {
      if (results) {
        saveName(results[0].formatted_address);
      } else {
        window.alert('No results found');
      }
    } else {
      window.alert('Geocoder failed due to: ' + status);
    }
  });
};

interface Coords {
  lat: number;
  lng: number;
}

type setCoords = (coords: Coords) => void;

export const setMyLocationToOrigin = (setOrigin: setCoords, setOriginName: (val: string) => void) => {
  navigator.geolocation.getCurrentPosition(position => {
    setOrigin({ lat: position.coords.latitude, lng: position.coords.longitude });
    setLocationNameByCoords(position.coords.latitude, position.coords.longitude, (name: string) => setOriginName(name));
  });
};

export const setMyLocationToDestination = (setDestination: setCoords, setDestinationName: (val: string) => void) => {
  navigator.geolocation.getCurrentPosition(position => {
    setDestination({ lat: position.coords.latitude, lng: position.coords.longitude });
    setLocationNameByCoords(position.coords.latitude, position.coords.longitude, (name: string) =>
      setDestinationName(name)
    );
  });
};

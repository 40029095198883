import axios, { AxiosRequestConfig } from 'axios';
import { Interceptors } from './types';
import { mergeInterceptors } from './utils';
import { defaultInterceptors } from './interceptors';

const createApi = (config: AxiosRequestConfig, interceptors?: Interceptors) => {
  const { headers, ...configRestParams } = config;
  const axiosInstance = axios.create({
    headers: {
      'X-OP-Language': 'en',
      ...headers,
    },
    ...configRestParams,
  });

  const { requestFail, requestSuccess, responseFail, responseSuccess } = mergeInterceptors(
    defaultInterceptors,
    interceptors
  );

  axiosInstance.interceptors.response.use(responseSuccess, responseFail);

  axiosInstance.interceptors.request.use(requestSuccess, requestFail);

  return axiosInstance;
};

export default createApi;

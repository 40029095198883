import React from 'react';
import classNames from 'classnames';
import styles from './StaticPages.module.scss';

const TermsService = () => {
  return (
    <div className={classNames(styles.cPage, styles.cTerms)}>
      <p>Updated: December 20, 2020</p>
      <h1>TERMS OF SERVICE</h1>
      <h4>INTRODUCTION</h4>
      <p>
        These Terms of Service (the <strong>"Terms"</strong>) are a legal agreement between you and Uplight, Inc. (
        <strong>"Uplight"</strong>, <strong>“Company”</strong>, <strong>"we"</strong>, <strong>"our"</strong>, or{' '}
        <strong>"us"</strong>) governing your access to and use of the Company's websites (the <strong>"Sites"</strong>)
        and the online and offline services which Company agrees to provide to you in connection with the Sites (the{' '}
        <strong>"Services"</strong>), which may include Marketplace and other tools, resources, and information that
        makes energy efficiency a fun, social activity. The Sites and Services may be provided to you on behalf of your
        energy service provider (your <strong>"ESP"</strong>) or other third parties supporting these Services.
      </p>
      <p>
        You represent and certify that you are of legal age to form a binding contract and are not a person barred from
        receiving Services under the laws of the United States or other applicable jurisdictions. To register as a user
        of the Services you must be 18 years or over. In the event that you are agreeing to this Terms on behalf of a
        third party entity, you represent and warrant that you have sufficient right to bind such third party to these
        Terms, in which case, all references to “you” in these Terms shall be references to such third party.
      </p>
      <br />
      <p>
        <strong>
          BY CLICKING APPLY REBATES OR BY OTHERWISE ACCESSING THE SITE OR USING ANY OF THE SERVICES YOU AGREE TO BE
          BOUND BY THESE TERMS.
        </strong>
      </p>
      <p>
        In the case of inconsistencies between these Terms and information included in any other materials related to
        the Sites or the Services (e.g., promotional materials and mailers), these Terms will always govern and take
        precedence.
      </p>

      <h4>1. Services</h4>
      <h5>1.1 Activities</h5>
      <p>
        The Services may include a variety of energy efficiency tools, resources, access to products, and activities. In
        connection with the Services, we may promote certain energy efficiency activities (each, an{' '}
        <strong>"Activity"</strong>), some of which may be subject to additional guidelines, rules or terms (
        <strong>"Additional Terms"</strong>). For example, we may offer sweepstakes, rewards points or other contests of
        skill on a Site that are subject to <strong>"Official Rules"</strong>. If there is any conflict between these
        Terms and the Additional Terms, the Additional Terms take precedence in relation to that Activity. Before you
        participate in any Activity or earn any type of rewards, you should always read the Official Rules.
      </p>

      <h5>1.2 Marketplace</h5>
      <p>
        <strong>(a) General</strong> - The Services may include <strong>"Marketplace"</strong>, Uplight's online
        marketplace that allows users to purchase certain products listed on the Sites <strong>("Products")</strong>.
        Some Products may be available for sale directly on the Sites <strong>("Direct Purchase")</strong>. Some
        purchases, including all vehicles, must be completed by making a payment to the manufacturer of the Products or
        a third party retailer that we've partnered with <strong>("Merchant")</strong>. These purchases are referred to
        as <strong>("Merchant Purchases")</strong>. Your data will be shared with the third-party Merchant in order to
        fulfill this order. Please contact privacy@uplight.com for more information. Uplight does not broker, sell, or
        lease motor vehicles. Neither the accuracy of information provided on the Site, nor the availability, price,
        quality, or safety of vehicles, is guaranteed or controlled by Uplight, and Uplight assumes no responsibility
        for the foregoing.
      </p>

      <p>
        <strong>(b) Rebates</strong> - Certain Products purchased through Marketplace may be eligible for a rebate (
        <strong>"Rebate"</strong>). Whether or not the purchase qualifies for a Rebate depends on a number of factors (
        <strong>"Rebate Requirements"</strong>), which may include, without limitation, (i) your ESP, (ii) whether you
        have previously earned a rebate for a similar product, (iii) where you use the Product purchased, (iv) whether
        you intend to use the Product in your primary residence, and (v) whether you participate in certain qualifying
        programs such as demand response management programs. The Rebate Requirements for each available Rebate are
        described on the Sites. Uplight may rely on your confirmation that you meet the Rebate Requirements. You
        understand and agree that if Uplight applies a Rebate and subsequently learns that the Rebate Requirements have
        not be met, then Uplight may charge you the amount of the Rebate that was applied to the transaction (
        <strong>"Charge-back"</strong>). You further agree that Uplight shall have no liability to you or any third
        party arising from its applicable of a Charge-back.
      </p>

      <h5>1.3 Availability</h5>
      <p>
        Uplight uses reasonable efforts to ensure that the online features of the Services are available 24 hours a day,
        7 days a week. However, there will be occasions when the Services will be interrupted for maintenance, upgrades
        and emergency repairs or due to failure of telecommunications links and equipment that are beyond the control of
        Uplight. Uplight will use reasonable commercial efforts to minimize such disruption where it is within the
        reasonable control of Uplight. You agree that Uplight shall not be liable to you for any unavailability,
        modification, suspension or discontinuance of the Services. You are responsible for obtaining access to the
        Services and understand that such access may involve third party fees (such as Internet service provider or
        airtime charges). You are responsible for those fees. In addition, you must provide and are responsible for all
        equipment necessary to access the Services.
      </p>

      <h5>1.4 Registration</h5>
      <p>
        You may access online features of the Services through your account on the Sites (your{' '}
        <strong>"Account"</strong>). You may log into your Account using a phone number and a one-time code. You are
        responsible for safeguarding your code and you agree not to disclose your code to any third party. You are
        solely responsible for any activities or actions taken under your Account, whether or not you have authorized
        such activities or actions. You agree that the information that you provide to Uplight about yourself upon
        registration of your Account, in connection with Rebate Requirements, and at all other times, (
        <strong>"Customer Data"</strong>) will be true, accurate, current and complete, and you further agree that you
        will maintain and promptly update the Customer Data to ensure that it remains true, accurate, current and
        complete. You may not impersonate any other person or use a phone number or code that you are not authorized to
        use. If you become aware of any unauthorized use of your Account, you agree to notify Uplight immediately.
      </p>

      <h5>1.5 Licenses</h5>
      <p>
        Subject to these Terms, Uplight grants you a personal, limited, revocable, non-exclusive and non-transferable
        license to access and use the Sites and Services. This license is exclusive to you and you may not sub-license
        the use of the Sites. Uplight expressly retains all ownership rights, title and interest in and to all aspects
        of the Services and the Sites, including, but not limited to, all current and future patents, copyrights,
        trademarks, trade secrets, know-how, and other proprietary rights included or embodied in the Sites. You may not
        modify the Sites or Services, create derivative works of the Sites or Services, or reverse engineer, reverse
        compile, reverse assemble or do any other operation with the Sites or Services that would reveal any source
        code, trade secrets, know-how or other proprietary information. This license shall not be construed or
        interpreted as granting or providing rights to you to use, reproduce, modify, distribute, perform, display,
        possess or control the source code or any other aspect of the Sites. You may not remove or modify any notice of
        confidentiality, trade secret, trademark or copyright encoded or embodied in the Sites or displayed by, on, or
        in the Sites. You may use the Sites only while these Terms remain in effect. Under no circumstances shall you
        have any rights of any kind in or to the Sites after any termination or expiration of your agreement to these
        Terms for any reason.
      </p>

      <h4>2. User Content and Submissions</h4>

      <h5>2.1 Your Energy Usage Data</h5>
      <p>
        Certain aspects of the Services rely on access to data about energy consumption at your home your (
        <strong>"Energy Usage Data"</strong>). As a part of the registration process, we may provide instructions about
        how you can authorize Uplight to access this Energy Usage Data from your ESP. Some ESP's cannot share Energy
        Usage Data. If your ESP cannot share Energy Usage Data with Uplight, or if you do not wish to have your ESP
        share your Energy Usage Data with Uplight, your use of certain aspects of the Services may be limited. In all
        cases, our access to, and use of, your Energy Usage Data will be governed by our Privacy Policy, which can be
        viewed by clicking on the Privacy Policy link that appears at the bottom of each page of the Sites or by
        visiting this sites Privacy Policy.
      </p>

      <h5>2.2 Copyright in Your Content</h5>
      <p>
        In connection with your access to, or use of, the Sites or the Services, you may make available certain
        information, data, text, software, music, sound, photographs, graphics, video, messages, or other materials (
        <strong>"Content"</strong>). Uplight does not claim ownership rights in any such Content that you make
        available. By submitting your Content and accepting the consideration set forth in these Terms, you
        unconditionally grant to Uplight a non-exclusive, perpetual, irrevocable, worldwide, fully-paid, transferable
        right and license, with the right to sub-license through multiple levels of sub licensees, under all of your
        intellectual property rights in the Content, to (a)reproduce, create derivative works of, distribute, publicly
        perform, publicly display, digitally transmit, and otherwise use the Content in any medium or format, whether
        now known or hereafter discovered and (b)exercise any and all other present or future rights in the Content. As
        between you and Company, you remain the owner of all Content that you make available in connection with the
        Services. You represent and warrant to Uplight that you are the owner of the copyright to the Content or that
        you have written permission from the copyright owner to use such Content. In addition, you warrant that all
        moral rights in any Content have been waived. You agree to indemnify and hold Uplight harmless for any violation
        of this provision.
      </p>

      <p>
        <strong>Submissions</strong> - You may not use, store, or make available any of the following material in
        connection with your access to or use of the Sites or Services, nor may you obtain any such materials using the
        Sites or Services:
      </p>
      <p>
        <strong>(a)</strong> Any threatening, obscene, pornographic or profane material or any other material that could
        give rise to any civil or criminal liability under applicable law;
      </p>
      <p>
        <strong>(b)</strong> Any material that could infringe rights of privacy, publicity or copyrights without the
        permission of the owner of these rights and the persons (or their parents or legal guardians, where applicable)
        whose likenesses are displayed in the material; or
      </p>
      <p>
        <strong>(c)</strong> Any material sent from an anonymous or false address.
      </p>

      <h5>2.3 Review of Content and Materials</h5>
      <p>
        While Uplight does not and cannot review all material on the Sites, and is not responsible for its content,
        Uplight reserves the right to remove, delete, move, or edit Content that it, in its sole discretion, deems
        abusive, defamatory, obscene, in violation of the law, including but not limited to copyright or trademark law,
        or otherwise unacceptable. Uplight will not be liable for the Content of any submission. You agree to indemnify
        and hold Uplight harmless for any violation of this provision.
      </p>

      <h4>3. Pricing and Payment</h4>
      <h5>3.1 Generally</h5>
      <p>
        The fees for the Services are posted on the Sites. Fees are subject to change without notice. You agree to pay
        Uplight in advance the applicable fees for the Services provided by Uplight under these Terms. Uplight will bill
        your credit card for all fees. You will provide Uplight with accurate and complete billing information including
        legal name, address, telephone number, and credit card or debit card billing information. If such information is
        false or fraudulent, Uplight reserves the right to terminate your use of the Services and your access to the
        Sites in addition to seeking any other legal remedies. Uplight is not responsible for any charges or expenses
        (e.g., for overdrawn accounts, exceeding credit card limits, etc.) resulting from charges billed by unauthorized
        parties. Each charge will be considered valid unless disputed by you in writing within thirty (30) days after
        the billing date. No adjustments will be made for disputed charges made more than thirty (30) days after the
        billing date. All fees will be paid in U.S. dollars and are due as set forth on the Sites. Uplight's fees are
        exclusive of all taxes, levies, or duties imposed by taxing authorities, and you will be responsible for payment
        of all such taxes, levies, or duties, excluding only United States (federal or state) taxes based solely on
        Uplight's income.
      </p>

      <h5>3.2 Direct Purchases</h5>
      <p>
        If you make Direct Purchase, Uplight will bill your credit card via a third party payment service provider (PSP)
        for the retail price of the product. By submitting a Direct Purchase transaction, you agree that the third party
        PSPS may process your credit card information to complete the transaction. The relevant PSP may have specific
        terms and conditions applicable to such processing, and you agree to be bound by the PSP’s applicable terms of
        use and privacy policies. Your selected payment will be charged for a Direct Purchase at the time the order is
        placed. All Products purchased via Direct Purchase are made pursuant to a shipment contract. This means that the
        risk of loss and title for such items pass to you upon our delivery of the applicable Product to the carrier. If
        you purchase a Product via Merchant Purchase, your purchase is subject to the policies provided by the
        applicable Merchant with whom you complete the transaction.
      </p>
      <h5>3.3 Pricing</h5>
      <p>
        Except where otherwise noted, the prices displayed for Products represent the full retail price listed on the
        Product itself suggested by the manufacturer or supplier of the Product. The prices for Products listed for sale
        via Direct Purchase are subject to change without notice and may not reflect the current price in every market
        on any particular day. When a Product is listed for sale via Merchant Purchase, the final retail price will be
        provided by the applicable Merchant.
      </p>

      <h5>3.4 Returns and Refunds</h5>
      <p>
        Products sold via a Merchant Purchase are subject to the return and refund policies of the applicable Merchant
        with whom you complete the transaction, and in no event shall Uplight or your ESP, if applicable, be responsible
        for any returns, refunds, or exchanges. If you purchase Products via Direct Purchase, please refer to the return
        policy located on the website. Uplight MAKES NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE PRODUCTS OR FOR
        THE CONDUCT OF ANY MERCHANT.
      </p>

      <h4>4. Third Party Software and Linking</h4>
      <p>
        Although we may make software, hyperlinks, and other products of third-party companies available to you, your
        use of such products is subject to the respective terms and conditions imposed by the third party owning,
        manufacturing or distributing such products, and the agreement for your use will be between you and such third
        party. Uplight makes no warranty with regard to the products or websites of any other entity. Uplight has no
        control over the content or availability of any third-party software or website. In particular, (a) Uplight
        makes no warranty that any third-party software you download or web site you visit will be free of any
        contaminating or destructive code, such as viruses, worms or Trojan horses and (b) Uplight notifies you that it
        is your responsibility to become familiar with any web site's privacy and other policies and terms of service,
        and to contact that site's webmaster or site administrator with any concerns.
      </p>

      <h4>5. Suspension and Termination</h4>
      <p>
        Uplight reserves the right to suspend or terminate your access to and use of the Sites and Services, at any
        time, without notice, for any reason, including but not limited to <strong>(a)</strong> your breach of these
        Terms, any Additional Terms, or any other policies or guidelines set forth by Uplight, or <strong>(b)</strong>{' '}
        conduct that Uplight believes is harmful to other users of the Sites or Services, or the business of Uplight or
        other third party information providers. Further, you agree that Uplight shall not be liable to you or any third
        party for any termination of your access to the Sites or Services. Uplight reserves the right at any time and
        from time to time to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with
        or without notice. You agree that Uplight shall not be liable to you or to any third party for any modification,
        suspension or discontinuance of the Services.
      </p>

      <h4>6. Privacy</h4>
      <p>
        Uplight respects the privacy of is users. Uplight collects, uses and discloses information about you, including
        your Energy Usage Data, in accordance with the Uplight Privacy Policy. You understand and agree that,
        notwithstanding any measures taken to prevent unauthorized disclosure, use of or connection to the Internet
        provides the opportunity for unauthorized third parties to circumvent such precautions and illegally gain access
        to confidential information. Accordingly, Uplight cannot and does not guarantee the privacy, security or
        authenticity of any information so transmitted over or stored in any system connected to the Internet.
      </p>

      <h4>7. Disclaimer of Warranty</h4>
      <p>
        THE SITES AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITHOUT WARRANTIES OF ANY KIND
        EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. Uplight MAKES NO WARRANTY THAT THE SITES OR THE SERVICES WILL BE
        UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, THAT THE SITES OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS, THAT THE SITES, INCLUDING THE SERVICES WILL BE AVAILABLE, OR THAT DATA ARE SECURE FROM UNAUTHORIZED
        ACCESS. Uplight (AND IF APPLICABLE, YOUR ESP) MAKES NO WARRANTY REGARDING ANY SOFTWARE, GOODS, SERVICES,
        PROMOTIONS, OR THE DELIVERY OF ANY GOODS OR SERVICES, PURCHASED, ACCESSED OR OBTAINED THROUGH THE SITES OR
        ADVERTISED THROUGH THE SITES. NO ADVICE OR INFORMATION GIVEN BY Uplight, ITS EMPLOYEES OR AFFILIATES SHALL
        CREATE A WARRANTY.
      </p>

      <h4>8. Rules of Conduct</h4>
      <p>
        <strong>Distribution of Content</strong> - You agree that you will not distribute any content that:
      </p>
      <p>
        <strong>(a)</strong> is defamatory, abusive, harassing, threatening, or an invasion of a right of privacy of
        another person; is bigoted, hateful, or racially or otherwise offensive; is violent, vulgar, obscene,
        pornographic or otherwise sexually explicit; or otherwise harms or can reasonably be expected to harm any person
        or entity;
      </p>
      <p>
        <strong>(b)</strong> is illegal or encourages or advocates illegal activity or the discussion of illegal
        activities with the intent to commit them, including a submission that is, or represents an attempt to engage
        in, child pornography, stalking, sexual assault, fraud, trafficking in obscene or stolen material, drug dealing
        and/or drug abuse, harassment, theft, or conspiracy to commit any criminal activity;
      </p>
      <p>
        <strong>(c)</strong> infringes or violates any right of a third party including: (i) copyright, patent,
        trademark, trade secret or other proprietary or contractual rights; (ii) right of privacy (specifically, you
        must not distribute another person's personal information of any kind without their express permission) or
        publicity; or (iii) any confidentiality obligation;
      </p>
      <p>
        <strong>(d)</strong> is commercial, business-related or advertises or offers to sell any products, services or
        otherwise (whether or not for profit), or solicits others (including solicitations for contributions or
        donations);
      </p>
      <p>
        <strong>(e)</strong> contains a virus or other harmful component, or otherwise tampers with, impairs or damages
        the Sites or any connected network, or otherwise interferes with any person or entity's use or enjoyment of the
        Sites;
      </p>
      <p>(f) does not generally pertain to the designated topic or theme of the Sites;</p>
      <p>
        <strong>(g)</strong> violates any specific restrictions applicable to a public forum, including its age
        restrictions and procedures;
      </p>
      <p>
        <strong>(h)</strong> is antisocial, disruptive, or destructive, including “flaming,” “spamming,” “flooding,”
        “trolling,” and “griefing” as those terms are commonly understood and used on the Internet.
      </p>

      <h5>8.2 Use of the Services</h5>
      <p>
        You expressly agree that you are solely responsible for any and all acts and omissions that occur under your
        Account, and you agree not to engage in any unacceptable uses of the Services, which include, without
        limitation, use of the Services to:
      </p>
      <p>
        <strong>(a)</strong> register for the Sites and the Services if you have not acknowledged reading and agreed to
        abide by these Terms and the Privacy Policy;
      </p>
      <p>
        <strong>(b)</strong> interfere, disrupt or attempt to gain unauthorized access to other accounts on the Sites or
        any other computer network;
      </p>
      <p>
        <strong>(c)</strong> use the Sites or the Services in connection with any commercial endeavors;
      </p>
      <p>
        <strong>(d)</strong> post your personal information such as instant messaging addresses, personal URLs, physical
        addresses and phone numbers in any publicly viewable areas of the Sites;
      </p>
      <p>
        <strong>(e)</strong> post or give out any financial information or transmit electronically or physically any
        money to other users;
      </p>
      <p>
        <strong>(f)</strong> create user accounts by automated means or under fraudulent or false pretenses;
      </p>
      <p>
        <strong>(g)</strong> create or transmit unsolicited electronic communications such as spam to users or promote
        any products or services;
      </p>
      <p>
        <strong>(h)</strong> submit or upload any type of material that is unlawful, harmful, hateful, threatening,
        abusive, harassing, defamatory, offensive, obscene, pornographic, lewd, lascivious, or otherwise objectionable,
        as determined by Uplight;
      </p>
      <p>
        <strong>(i)</strong> submit or upload any content for which you do not own all applicable rights or that
        infringes the proprietary rights of other parties;
      </p>
      <p>
        <strong>(j)</strong> submit, engage in or upload any type of competitions or content that can cause physical or
        mental harm to the participants or visitors to the Sites;
      </p>
      <p>
        <strong>(k)</strong> harass, threaten or intentionally embarrass or cause harm or distress to another person or
        group;
      </p>
      <p>
        <strong>(l)</strong> collect and publish any information about any of our users;
      </p>
      <p>
        <strong>(m)</strong> adapt, modify or reverse engineer any portion of the Services or the Sites;
      </p>
      <p>
        <strong>(n)</strong> use any spider, robot, retrieval application, or any other device to retrieve any portion
        of the Sites;
      </p>
      <p>
        <strong>(o)</strong> reformat any of the pages that are part of the Sites;
      </p>
      <p>
        <strong>(p)</strong> disseminate, store or transmit viruses, worms, trojan horses or other malicious code or
        program;
      </p>
      <p>
        <strong>(q)</strong> encourage conduct that would constitute a criminal or civil offense;
      </p>
      <p>
        <strong>(r)</strong> violate any applicable federal, state, local or international law or regulation;
      </p>
      <p>
        <strong>(s)</strong> exploit any person, including but not limited to exploiting any child under 18 years of
        age;
      </p>
      <p>
        <strong>(t)</strong> invade the privacy of any person, including but not limited to submitting personally
        identifying or otherwise private information about a person without their consent;
      </p>
      <p>
        <strong>(u)</strong> solicit personal information from a child under 13 years of age;
      </p>
      <p>
        <strong>(v)</strong> submit false or misleading information to Uplight, the Sites or other users; or
      </p>
      <p>
        <strong>(w)</strong> engage in any other activity deemed by Uplight to be in conflict with the spirit of these
        Terms and the Privacy Policy.
      </p>

      <h5>8.3 Monitoring</h5>
      <p>
        We cannot and do not assure that other users are or will be complying with the foregoing Rules of Conduct or any
        other provisions of these Terms, and, as between you and us, you hereby assume all risk of harm or injury
        resulting from any such lack of compliance.
      </p>

      <h4>9. Proprietary and Privacy Protection for Other Users' Content on the Sites</h4>
      <p>
        Uplight hereby notifies you that all the information, content, image files, software and materials on the Sites
        may be protected by U.S. and international copyright and other intellectual property laws and by other
        applicable laws, including privacy laws. Uplight is unable to provide you with permission to copy display or
        distribute material for which you do not own the copyright or other intellectual property rights. You may not
        copy or distribute such material without the written consent of the owner, and you are solely responsible for
        any copyright or other intellectual property law violations that you may incur as a result of your activities on
        the Sites. Uplight has the absolute right to terminate your account or exclude you from any Site if you use our
        Services to violate the intellectual property rights or other rights of third parties. You agree to indemnify
        and hold Uplight harmless for any violation of this provision.
      </p>

      <h4>10. Copyright Infringement</h4>
      <h5>10.1 Claims of Copyright Infringement</h5>
      <p>
        Uplight has adopted and implemented a policy that provides for the termination, in appropriate circumstances as
        determined by us in our sole discretion, of users who are infringes of copyright. Pursuant to Title 17, United
        States Code, Section 512(c)(2), notifications of claimed copyright infringement must be sent to the service
        provider's designated agent. <br />
        Notification must be submitted to the following: <br />
        <strong>Service Provider:</strong> Uplight, Inc. <br />
        <strong>Attn: Legal Full Address:</strong> 1215 Spruce Street, 3rd Floor Boulder, CO 80302 <br />
        <strong>Email:</strong> <a href="mailto:policy@uplight.com">policy@uplight.com</a>
      </p>

      <h5>10.2 Notice of Infringement</h5>
      <p>To be effective, the notification must be a written communication that includes the following:</p>
      <p>
        <strong>(a)</strong> A physical or electronic signature of person authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed;
      </p>
      <p>
        <strong>(b)</strong> Identification of the copyrighted work claimed to have been infringed, or, if multiple
        copyrighted works at a single online site are covered by a single notification, a representative list of such
        works at that site;
      </p>
      <p>
        <strong>(c)</strong> Identification of the material that is claimed to be infringing or to be the subject of
        infringing activity and that is to be removed or access to which is to be disabled, and information reasonably
        sufficient to permit us to locate the material;
      </p>
      <p>
        <strong>(d)</strong> Information reasonably sufficient to permit us to contact the complaining party, such as an
        address, telephone number, and, if available, an electronic mail address at which the complaining party may be
        contacted;
      </p>
      <p>
        <strong>(e)</strong> A statement that the complaining party has a good-faith belief that use of the material in
        the manner complained of is not authorized by the copyright owner, its agent, or the law; and
      </p>
      <p>
        <strong>(f)</strong> A statement that the information in the notification is accurate, and under penalty of
        perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed.
      </p>

      <h5>10.3 Take down Notices</h5>
      <p>
        We may give you notice that we have removed or disabled access to certain material by means of a general notice
        on the Sites, electronic mail to your e-mail address in our records, or by written communication sent by
        first-class mail to your physical address in our records. If you receive such a notice, you may provide
        counter-notification in writing to the designated agent that includes the information below. To be effective,
        the counter-notification must be a written communication that includes the following:
      </p>
      <p>
        <strong>(a)</strong> Your physical or electronic signature;
      </p>
      <p>
        <strong>(b)</strong> Identification of the material that has been removed or to which access has been disabled,
        and the location at which the material appeared before it was removed or access to it was disabled;
      </p>
      <p>
        <strong>(c)</strong> A statement from you under the penalty of perjury, that you have a good faith belief that
        the material was removed or disabled as a result of a mistake or misidentification of the material to be removed
        or disabled; and
      </p>
      <p>
        <strong>(d)</strong> Your name, physical address and telephone number, and a statement that you consent to the
        jurisdiction of a Federal District Court for the judicial district in which your physical address is located, or
        if your physical address is outside of the United States, for any judicial district in which Uplight may be
        found, and that you will accept service of process from the person who provided notification of allegedly
        infringing material or an agent of such person.
      </p>

      <h4>11. Indemnity; Limitation of Liability</h4>
      <p>
        <strong>11.1</strong> You agree to indemnify, and hold Uplight, its officers, directors, employees and agents,
        harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation
        reasonable attorney's fees and costs, arising out of or in any way connected with
      </p>
      <p>
        <strong>(a)</strong> your access to or use of the Sites or Services;
      </p>
      <p>
        <strong>(b)</strong> your violation of these Terms;
      </p>
      <p>
        <strong>(c)</strong> your violation of any third party right, including without limitation any intellectual
        property right or any publicity, confidentiality, property or privacy right; or
      </p>
      <p>
        <strong>(d)</strong> any claim that your use of the Sites or Services caused damage to a third party.
      </p>
      <p>
        <strong>11.2</strong> YOU ACKNOWLEDGE THAT YOUR ACCESS TO AND USE OF THE SITES OR SERVICES AND ANY RELIANCE UPON
        EITHER IS AT YOUR SOLE RISK, THAT YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED WITH ALL NECESSARY
        SERVICING OR REPAIRS OF ANY EQUIPMENT YOU USE IN CONNECTION WITH YOUR USE OF THE SITES OR SERVICES. YOU AGREE
        THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL Uplight OR YOUR ESP, IF
        APPLICABLE, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED
        TO, DAMAGES FOR LOSS OF PROFITS, USE, IMAGES, DATA OR OTHER INTANGIBLES, EVEN IF Uplight OR YOUR ESP HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, THAT RESULT FROM THE USE OR THE INABILITY TO USE THE SITES OR THE
        SERVICES, FROM ANY CHANGES TO THE SITES OR THE SERVICES OR FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
        TRANSMISSIONS OR CONTENT. YOU SPECIFICALLY ACKNOWLEDGE THAT DOWN-TIME AND COMPUTER VIRUSES ARE RISKS INHERENT IN
        THE USE OF THE INTERNET AND SOFTWARE PRODUCTS, AND YOU AGREE TO ASSUME RESPONSIBILITY FOR ANY HARM OR DAMAGES OF
        ANY KIND OR CHARACTER WHATSOEVER RESULTING FROM THESE POSSIBLE HARMS. YOU ALSO SPECIFICALLY ACKNOWLEDGE THAT YOU
        MAY BE DISCLOSING SENSITIVE, PRIVATE AND CONFIDENTIAL INFORMATION ABOUT YOURSELF IN YOUR USE OF THE SITES AND
        THE SERVICES AND YOU AGREE TO ASSUME RESPONSIBILITY FOR ANY HARM OR DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER
        RESULTING FROM YOUR RELEASE OF SUCH CONTENT.
      </p>
      <p>
        <strong>11.3</strong> IF YOU ARE DISSATISFIED WITH THE SITES OR THE SERVICES OR WITH ANY OF THESE TERMS, OR FEEL
        Uplight HAS BREACHED THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITES AND THE
        SERVICES. THE TOTAL LIABILITY OF Uplight TO YOU FOR ANY CLAIM ARISING FROM OR RELATING TO THESE TERMS OR USE OF
        THE SITES OR THE SERVICES SHALL NOT EXCEED THE AMOUNT PAID BY YOU FOR THE SERVICES. IT IS THE INTENTION OF YOU
        AND Uplight THAT THIS PROVISION BE CONSTRUED BY A COURT AS BEING THE BROADEST LIMITATION OF LIABILITY CONSISTENT
        WITH APPLICABLE LAW.
      </p>
      <p>
        <strong>11.4</strong> SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF INCIDENTAL, CONSEQUENTIAL
        OR OTHER TYPES OF DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      </p>

      <h4>12. General</h4>
      <h5>12.1 Modification</h5>
      <p>
        Uplight may modify these Terms at any time. Modifications become effective immediately upon your first access to
        or use of the Sites or Services after the “Last Revised” date at the top of these Terms. If you have created an
        Account, we shall notify you of any material changes to these Terms by email sent to the address you have
        provided to Uplight for your Account. Your continued access to or use of the Sites or Services after the
        modifications have become effective will be deemed your conclusive acceptance of the modified Terms. If you do
        not agree with the modifications, do not access or use the Sites or Services.
      </p>

      <h5>12.2 Applicable Law and Jurisdiction</h5>
      <p>
        You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
        related to the use of the Sites or the Services or these Terms must be brought, if at all, within one year from
        the accrual of the claim or cause of action or be forever barred. You and Uplight agree that these Terms and any
        claims hereunder shall be governed by and subject to the laws of the state of Colorado, without giving effect to
        any principles of conflicts of law.
      </p>

      <h5>12.3 No Resale of Services</h5>
      <p>
        You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any
        portion of the Services, use of the Services, or access to the Services.
      </p>

      <h5>12.4 Independent Contractors</h5>
      <p>
        No joint venture, partnership, employment, or agency relationship exists between you and Uplight as a result of
        these Terms or use of the Sites or the Services.
      </p>

      <h5>12.5 Enforcement</h5>
      <p>
        If any legal action is brought to enforce these Terms, the prevailing party will be entitled to reimbursement of
        its attorneys’ fees, court costs, and other collection expenses, in addition to any other relief it may receive
        from the other party.
      </p>

      <h5>12.6 Force Majeure</h5>
      <p>
        Uplight will not be liable by reason of any failure or delay in the performance of its obligations hereunder on
        account of strikes, shortages, riots, insurrection, fires, flood, storm, explosions, acts of God, war,
        governmental action, labor conditions, earthquakes, material shortages or any other cause which is beyond
        Uplight’s reasonable control.
      </p>

      <h5>12.7 Waiver</h5>
      <p>
        The failure of Uplight to enforce any right or provision in these Terms will not constitute a waiver of such
        right or provision unless acknowledged and agreed to by Uplight in writing.
      </p>

      <h5>12.8 Construction</h5>
      <p>The headings of Sections of these Terms are for convenience and are not to be used in interpretation.</p>

      <h5>12.9 Contact</h5>
      <p>
        Uplight is located in Boulder, Colorado. Any questions, comments or suggestions, including any report of
        violation of these Terms should be provided to the Administrator as follows:
        <br /> By Postal Mail: 2580 55th St, Suite 100, Boulder, CO 80301
      </p>

      <h5>12.10 Entire Agreement</h5>
      <p>
        These Terms constitute the entire agreement between you and Uplight and govern your use of the Sites and the
        Services, superseding any prior agreements between you and Uplight. The failure of Uplight to exercise or
        enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any
        provision of these Terms is found by a court of competent jurisdiction to be invalid, you and Uplight
        nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the
        provision, and the other provisions of these Terms shall remain in full force and effect.
      </p>

      <p>
        <strong>
          "BY CLICKING APPLY REBATES OR BY OTHERWISE ACCESSING THE SITE OR USING ANY OF THE SERVICES YOU AGREE TO BE
          BOUND BY THESE TERMS."
        </strong>
      </p>
    </div>
  );
};

export default TermsService;

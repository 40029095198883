import { createAuthHeader } from './helpers';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../appConstants';

class TokenStore {
  public accessToken: string | null = null;
  public refreshToken: string | null = null;
  public history: { push: (path: string) => void } | null = null;

  public constructor() {
    this.init();
  }

  public async init() {
    this.accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  public setAccessToken = async (token: string) => {
    this.accessToken = token;
    localStorage.setItem(ACCESS_TOKEN_NAME, token);

    return this.accessToken;
  };

  public setRefreshToken = async (token: string): Promise<string> => {
    this.refreshToken = token;

    localStorage.setItem(REFRESH_TOKEN_NAME, token);
    return this.refreshToken;
  };

  public removeTokens() {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
  }

  public logOut = () => {
    this.removeTokens();

    if (this.history) {
      this.history.push('/');
    }
    window.location.reload();
  };

  public get authHeader(): string | void {
    return createAuthHeader(this.accessToken);
  }
}

export default new TokenStore();
